import { FC, PropsWithChildren } from 'react';

type Props = {
  color: 'green' | 'red';
};

export const Badge: FC<PropsWithChildren<Props>> = ({ color, children }) => (
  <span
    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
      color === 'green' ? 'bg-lp-green text-white' : 'bg-red-500 text-white'
    }`}
  >
    {children}
  </span>
);
