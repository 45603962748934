import Table from 'rc-table';
import { ColumnsType } from 'rc-table/lib/interface';
import { FC, useMemo, useState } from 'react';
import { ApiAllFeedbackQuery, Feedback_Bool_Exp, useApiAllFeedbackQuery } from '../api/generated/graphql';
import { Badge } from '../components/Badge';
import { TLink } from '../components/TLink';
import Select from 'react-select';
import { Loader } from 'react-feather';
import { queryIsLoading } from '../utils';

type TableRow = NonNullable<ApiAllFeedbackQuery>['feedback'][0];

function formatMessagePreview(message: string): string {
  return message.length > 20 ? `${message.substr(0, 20)}...` : message;
}

const LIMIT = 20;

type Props = {
  taxonomyId: number | undefined;
};

const Pagination = ({
  start,
  end,
  total,
  previousDisabled,
  nextDisabled,
  onPrevious,
  onNext,
}: {
  start: number;
  end: number;
  total: number;
  onPrevious: () => void;
  onNext: () => void;
  previousDisabled: boolean;
  nextDisabled: boolean;
}) => (
  <nav
    className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 w-full mt-8"
    aria-label="Pagination"
  >
    <div className="hidden sm:block">
      <p className="text-sm text-gray-700">
        Showing <span className="font-medium">{start}</span> to <span className="font-medium">{end}</span> of{' '}
        <span className="font-medium">{total}</span> results
      </p>
    </div>
    <div className="flex-1 flex justify-between space-x-2 sm:justify-end">
      <button onClick={onPrevious} className="lp-button -default" disabled={previousDisabled}>
        Previous
      </button>
      <button onClick={onNext} className="lp-button -default" disabled={nextDisabled}>
        Next
      </button>
    </div>
  </nav>
);

export const FeedbackList: FC<Props> = ({ taxonomyId }) => {
  const [status, setStatus] = useState({ label: 'Status: open', value: 'open' });
  const [pages, setPages] = useState<number[]>([]);
  const conditions = useMemo(() => {
    const conds: Feedback_Bool_Exp[] = [];
    if (taxonomyId !== undefined) {
      conds.push({ feedback_taxonomy_references: { taxonomy_id: { _eq: taxonomyId } } });
    }
    conds.push({ status: { _eq: status.value } });
    if (pages.length > 0) {
      conds.push({ id: { _lt: pages[pages.length - 1] } });
    }
    return conds;
  }, [taxonomyId, status, pages]);

  const conditionsAggregate = useMemo(() => {
    return conditions.filter((x) => !x.id);
  }, [conditions]);

  const query = useApiAllFeedbackQuery({
    variables: { where: { _and: conditions }, where_aggregate: { _and: conditionsAggregate }, limit: LIMIT },
    fetchPolicy: 'cache-and-network',
  });

  const columns: ColumnsType<TableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 30,
    },
    {
      title: 'Message preview',
      dataIndex: 'message',
      key: 'message',
      width: 250,
      render: (value: string) => formatMessagePreview(value),
    },
    {
      title: 'Taxonomy references',
      key: 'taxonomy',
      render: (value: string, row) =>
        row.feedback_taxonomy_references.map((x) => (
          <TLink key={row.id + '/' + x.taxonomy.id} className="text-lp-green" to={`taxonomy/${x.taxonomy.id}`}>
            {x.taxonomy.common_name} - {x.taxonomy.scientific_name}
          </TLink>
        )),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (value) => (value === 'open' ? <Badge color="green">Open</Badge> : <Badge color="red">Closed</Badge>),
    },
    {
      title: 'Created at',
      dataIndex: 'created_at',
      key: 'created_at',
      width: 200,
      render: (value) => new Date(Date.parse(value)).toLocaleString(),
    },
    {
      title: 'Operations',
      key: 'operations',
      render: (_value, row, _index) => (
        <TLink key={`operations-${row.id}`} className="lp-button-link" to={`feedback/${row.id}`}>
          View
        </TLink>
      ),
    },
  ];

  const loading = queryIsLoading(query);

  return (
    <>
      <Select
        isSearchable={false}
        className="w-48 mb-4"
        value={status}
        options={[
          { label: 'Status: open', value: 'open' },
          { label: 'Status: closed', value: 'closed' },
        ]}
        isClearable={false}
        onChange={(dropdownValue) => {
          setPages([]);
          setStatus(dropdownValue!);
        }}
      />
      {loading && <Loader className="animate-spin" />}
      {!loading && (
        <Table
          className="lp-table"
          columns={columns}
          data={query.data?.feedback ?? []}
          rowKey="id"
          footer={() =>
            query.data && (
              <Pagination
                start={pages.length * LIMIT + Math.min(1, query.data.feedback.length)}
                end={pages.length * LIMIT + query.data.feedback.length}
                total={query.data.feedback_aggregate.aggregate?.count ?? 0}
                previousDisabled={pages.length === 0}
                nextDisabled={
                  query.data.feedback_aggregate.aggregate!.count <= pages.length * LIMIT + query.data.feedback!.length
                }
                onPrevious={() => {
                  const copy = [...pages];
                  copy.pop();
                  setPages(copy);
                }}
                onNext={() => setPages([...pages, query!.data!.feedback[query!.data!.feedback.length - 1].id])}
              />
            )
          }
        />
      )}
    </>
  );
};
