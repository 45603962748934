import { FC, useState } from 'react';
import Select from 'react-select';
import { useApiAllAudioQuery } from '../api/generated/graphql';

interface Props {
  value: string | undefined;
  onChange: (imageId: string | undefined) => void;
  inputId: string;
}

export const TaxonomyAudioSelect: FC<Props> = ({ value, onChange, inputId }: Props) => {
  const { data, loading } = useApiAllAudioQuery();

  const [searchTerm, setSearchTerm] = useState('');

  const options = data?.audio
    ?.map((x) => ({ label: x.id, value: x.id }))
    ?.filter((x) => x.label.toLowerCase().includes(searchTerm.toLowerCase()));

  return (
    <Select
      inputId={inputId}
      isSearchable={true}
      isClearable={true}
      inputValue={searchTerm}
      onInputChange={(e) => setSearchTerm(e)}
      value={options?.find((x) => x.value === value)}
      options={options}
      isLoading={loading}
      onChange={(dropdownValue) => {
        const selected = data?.audio.find((y) => y.id === dropdownValue?.value);
        onChange(selected?.id);
      }}
    />
  );
};
