import { FC } from 'react';
import { useApiAllLanguageQuery } from '../api/generated/graphql';

interface Props {
  value: Record<string, string>;
  onChange: (data: Record<string, string>) => void;
}

export const NamesEdit: FC<Props> = ({ value, onChange }) => {
  const { data } = useApiAllLanguageQuery();

  const languages = data?.language ?? [];

  return (
    <div className="space-y-3">
      <h3>Names</h3>
      {languages.map((language) => (
        <div key={language.key}>
          <label className="lp-input-text-label" htmlFor={language.key}>
            {language.name}
          </label>
          <input
            id={language.key}
            type="text"
            className="lp-input-text mt-1 w-full"
            value={value[language.key] ?? ''}
            onChange={(e) => onChange({ ...value, [language.key]: e.target.value })}
          />
        </div>
      ))}
    </div>
  );
};
