import React, { useEffect } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { setContext } from 'apollo-link-context';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import { LocalizedRoutes } from './routes';
import i18n from './i18n';
import { Dashboard } from './components/Dashboard';
import { useRecoilState } from 'recoil';
import { userState } from './state';

const authLink = setContext(
  (_, { headers }) =>
    new Promise((resolve) => {
      Auth.currentSession().then((session) => {
        const token = session.getIdToken().getJwtToken();
        resolve({
          headers: { ...headers, Authorization: `Bearer ${token}` },
        });
      });
    })
);

const createApolloClient = () => {
  return new ApolloClient({
    link: ApolloLink.from([
      // Not sure what's wrong here, both types are of type ApolloLink but they
      // are incompatible. Maybe because they are being imported from different
      // libraries?
      (authLink as unknown) as ApolloLink,
      new HttpLink({
        uri: '/v1/graphql',
      }),
    ]),
    cache: new InMemoryCache(),
  });
};

function App() {
  const client = React.useMemo(() => createApolloClient(), []);
  const [, setUser] = useRecoilState(userState);
  useEffect(() => {
    async function GetUserInfo() {
      const userInfo = await Auth.currentUserInfo();
      setUser(userInfo);
    }
    GetUserInfo();
  }, [setUser]);

  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Dashboard>
            <Route path={['/fi', '/en']}>
              <LocalizedRoutes />
            </Route>
            <Route exact path="/">
              <Redirect to={`/${i18n.language ?? 'fi'}`} />
            </Route>
          </Dashboard>
        </Switch>
      </Router>
    </ApolloProvider>
  );
}

export default App;
