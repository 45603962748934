import { TLink } from './TLink';
import {
  useApiChangeFeedbackStatusMutation,
  useApiSingleFeedbackLazyQuery,
  useApiAddFeedbackCommentMutation,
  ApiSingleFeedbackQuery,
  useApiDeleteFeedbackCommentMutation,
  ApiSingleTaxonomyDocument,
  ApiSingleFeedbackDocument,
} from '../api/generated/graphql';
import { Badge } from './Badge';
import { toast } from 'react-toastify';
import { FC, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { userState } from '../state';
import { Loader } from 'react-feather';
import { queryIsLoading } from '../utils';

type Comment = NonNullable<ApiSingleFeedbackQuery['feedback_by_pk']>['feedback_comments'][0];

const Comments: FC<{ comments: Comment[]; feedbackId: number; refetch: () => void }> = ({
  comments,
  feedbackId,
  refetch,
}) => {
  const [message, setMessage] = useState('');
  const [addCommentMutation, addCommentMutationStatus] = useApiAddFeedbackCommentMutation();
  const [deleteCommentMutation, deleteCommentMutationStatus] = useApiDeleteFeedbackCommentMutation();
  const user = useRecoilValue(userState);

  const addComment = async (comment: string) => {
    try {
      await addCommentMutation({ variables: { feedback_id: feedbackId, message: comment } });
      toast.success('Comment created.');
      setMessage('');
    } catch (e) {
      toast.error('Failed to add comment');
    }
    await refetch();
  };

  const deleteComment = async (id: number) => {
    try {
      await deleteCommentMutation({ variables: { id: id } });
      toast.success('Comment deleted');
    } catch (e) {
      toast.error('Failed to delete comment');
    }
    await refetch();
  };

  return (
    <section aria-labelledby="comments-title" className="w-full my-4">
      <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden w-full max-w-2xl">
        <div className="divide-y divide-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <h2 id="comments-title" className="text-lg font-medium text-gray-900">
              Internal comments
            </h2>
          </div>
          <div className="px-4 py-6 sm:px-6">
            <ul className="space-y-8">
              {comments.length === 0 && <div>No comments</div>}
              {comments.map((comment) => (
                <li key={`${comment.id}`}>
                  <div className="flex space-x-3">
                    <div>
                      <div className="text-sm">
                        <a href="#" className="font-medium text-gray-900">
                          {comment.created_by.email}
                        </a>
                      </div>
                      <div className="mt-1 text-sm text-gray-700">
                        <p>{comment.message}</p>
                      </div>
                      <div className="mt-2 text-sm space-x-2">
                        <span className="text-gray-500 font-medium">
                          {new Date(Date.parse(comment.created_at)).toLocaleString()}
                        </span>
                        <span className="text-gray-500 font-medium">&middot;</span>{' '}
                        {user && user.username === comment.created_by.id && (
                          <button
                            type="button"
                            className="text-red-500 font-medium"
                            onClick={() => deleteComment(comment.id)}
                            disabled={deleteCommentMutationStatus.loading}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-6 sm:px-6">
          <div className="flex space-x-3">
            <div className="min-w-0 flex-1">
              <form>
                <div>
                  <label htmlFor="comment" className="sr-only">
                    About
                  </label>
                  <textarea
                    id="comment"
                    name="comment"
                    rows={3}
                    className="shadow-sm block w-full focus:ring-blue-500 focus:border-blue-500 sm:text-sm border border-gray-300 rounded-md"
                    placeholder="Add a comment"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="mt-3 flex items-center justify-between">
                  <button
                    type="button"
                    className="lp-button"
                    onClick={() => addComment(message)}
                    disabled={addCommentMutationStatus.loading || message.length === 0}
                  >
                    Comment
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

function createGitlabNewIssueLink(query: ApiSingleFeedbackQuery) {
  const feedbackId = query.feedback_by_pk!.id;
  const title = encodeURIComponent(`Feedback num. ${feedbackId}`);

  const references = query?.feedback_by_pk?.feedback_taxonomy_references
    ?.map(
      (y) =>
        `[${y.taxonomy.common_name} - ${y.taxonomy.scientific_name}](https://${window.location.host}/fi/taxonomy/${y.taxonomy.id})`
    )
    ?.join(', ');

  const description = encodeURIComponent(
    `
# Feedback num. ${feedbackId} from website.

ID: [${feedbackId}](https://${window.location.host}/fi/feedback/${feedbackId})

Message: ${query.feedback_by_pk!.message}

Created at: ${new Date(Date.parse(query.feedback_by_pk!.created_at)).toLocaleString()}

Taxonomy references: ${references}


Created by admin panel issue template.
`
  );

  return `https://gitlab.com/lpng-dev/lp-frontend/-/issues/new?issue[title]=${title}&issue[description]=${description}`;
}

export default function FeedbackDetails({ id }: { id: number }) {
  const [load, query] = useApiSingleFeedbackLazyQuery({ variables: { id }, fetchPolicy: 'cache-and-network' });

  const [change, changeStatus] = useApiChangeFeedbackStatusMutation();

  useEffect(() => {
    load({ variables: { id } });
  }, [load, id]);

  const handleStatusChange = async (changeTo: 'closed' | 'open') => {
    try {
      const refetchTaxonomyQueries =
        query.data?.feedback_by_pk?.feedback_taxonomy_references?.map((y) => ({
          query: ApiSingleTaxonomyDocument,
          variables: { id: y.taxonomy.id },
        })) ?? [];
      await change({
        variables: { feedback_id: query.data!.feedback_by_pk!.id!, status: changeTo },
        refetchQueries: [...refetchTaxonomyQueries, { query: ApiSingleFeedbackDocument, variables: { id } }],
      });
      toast.success(changeTo === 'closed' ? 'Closed feedback' : 'Opened feedback');
    } catch (e) {
      toast.error(changeTo === 'closed' ? 'Failed to close feedback' : 'Failed to open feedback');
    }
  };

  const loading = queryIsLoading(query);

  let content;
  if (!loading && query.data?.feedback_by_pk) {
    const { id, email, status, message, metadata, feedback_taxonomy_references } = query.data.feedback_by_pk;
    content = (
      <>
        <div className="lp-description-panel w-full max-w-2xl">
          <div className="header flex flex-col justify-between">
            <div>
              {status === 'open' && <Badge color="green">Open</Badge>}
              {status === 'closed' && <Badge color="red">Closed</Badge>}
            </div>
            <div className="flex flex-row justify-between items-center">
              <h3 className="title">Feedback #{id}</h3>
              {status === 'open' && (
                <button
                  className="lp-button -default"
                  disabled={changeStatus.loading}
                  onClick={() => {
                    handleStatusChange('closed');
                  }}
                >
                  Close feedback
                </button>
              )}
              {status === 'closed' && (
                <button
                  className="lp-button -default"
                  disabled={changeStatus.loading}
                  onClick={() => {
                    handleStatusChange('open');
                  }}
                >
                  Open feedback
                </button>
              )}
            </div>
          </div>
          <div className="body">
            <dl className="list">
              <div className="item">
                <dt className="title">Email address</dt>
                <dd className="value">{email ? email : '-'}</dd>
              </div>
              <div className="item">
                <dt className="title">Message</dt>
                <dd className="value">{message}</dd>
              </div>
              <div className="item">
                <dt className="title">Language</dt>
                <dd className="value">{metadata.language}</dd>
              </div>
              <div className="item">
                <dt className="title">Url</dt>
                <dd className="value">
                  <a className="text-lp-green" href={`https://luontoportti.com/${metadata.language}${metadata.url}`}>
                    {metadata.url}
                  </a>
                </dd>
              </div>
              <div className="item">
                <dt className="title">User-Agent</dt>
                <dd className="value">{metadata.userAgent ?? '-'}</dd>
              </div>
              <div className="item">
                <dt className="title">Taxonomy references</dt>
                <dd className="value">
                  {feedback_taxonomy_references.length === 0 && '-'}
                  {feedback_taxonomy_references.map((y) => (
                    <TLink key={y.taxonomy.id} to={`taxonomy/${y.taxonomy.id}`} className="text-lp-green">
                      {y.taxonomy.common_name} - {y.taxonomy.scientific_name}
                    </TLink>
                  ))}
                </dd>
              </div>
              <div className="item">
                <dt className="title"></dt>
                <dd className="value space-x-2">
                  <a
                    className="lp-button -default"
                    target="_blank"
                    rel="noreferrer noopener"
                    href={createGitlabNewIssueLink(query.data)}
                  >
                    Create new Gitlab issue
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <Comments
          feedbackId={query.data.feedback_by_pk.id}
          comments={query.data.feedback_by_pk.feedback_comments}
          refetch={query.refetch}
        />
      </>
    );
  } else {
    content = <Loader className="animate-spin" />;
  }

  return (
    <>
      <div className="pb-5">
        <TLink to="feedback" className="p-2 bg-gray-200 text-gray-800 px-3 font-medium text-sm rounded-sm">
          Back to the list
        </TLink>
      </div>
      {content}
    </>
  );
}
