import { FC, useCallback, useMemo } from 'react';
import Select from 'react-select';
import { useApiAllCharacteristicSectionsQuery } from '../../api/generated/graphql';
import { withFallbackName } from '../../utils';
import { ReactComponent as Delete } from '../../assets/delete.svg';

interface SelectedSection {
  id: number;
  name: string;
  showInListing: boolean;
}

interface Props {
  values: SelectedSection[];
  onChange: (values: SelectedSection[]) => void;
}

export const CharacteristicSectionSelectMulti: FC<Props> = ({ values, onChange }) => {
  const { data, loading } = useApiAllCharacteristicSectionsQuery();

  const options = useMemo(
    () =>
      data?.characteristic_section
        .filter((x) => !values.find((v) => v.id === x.id))
        .map((x) => ({
          label: withFallbackName(x),
          value: { id: x.id, showInListing: true, name: withFallbackName(x) },
        })) ?? [],
    [data?.characteristic_section, values]
  );

  const selectOption = useCallback(
    (option) => {
      onChange([...values, option.value]);
    },
    [values, onChange]
  );

  const removeValue = useCallback(
    (id) => {
      onChange(values.filter((v) => v.id !== id));
    },
    [values, onChange]
  );

  const setShowInListing = useCallback(
    (id, value) => {
      onChange(values.map((v) => (v.id === id ? { ...v, showInListing: value } : v)));
    },
    [values, onChange]
  );

  return (
    <div className="flex flex-col">
      <label className="lp-input-text-label">
        Add characteristic section
        <Select isSearchable={true} value={null} options={options} isLoading={loading} onChange={selectOption} />
      </label>
      <table className="w-full lp-table">
        <thead>
          <tr className="font-bold">
            <td className="w-full">Name</td>
            <td className="px-2">List</td>
            <td>Remove</td>
          </tr>
        </thead>
        <tbody>
          {values.map((value, i) => (
            <tr key={value.id} className={i % 2 ? 'bg-gray' : 'bg-white'}>
              <td>{value.name}</td>
              <td className="text-center">
                <input
                  type="checkbox"
                  checked={value.showInListing}
                  onChange={() => setShowInListing(value.id, !value.showInListing)}
                />
              </td>
              <td>
                <button className="h-8 w-8 m-auto flex items-center" onClick={() => removeValue(value.id)}>
                  <Delete />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
