import React, { FC, useCallback, useState } from 'react';
import { DropTargetMonitor } from 'react-dnd';
import { toast } from 'react-toastify';
import {
  ApiSingleTaxonomyQuery,
  useApiAddTaxonomyImageMutation,
  useApiDeleteTaxonomyImageMutation,
  useApiAllImageExtensionsQuery,
  ApiSingleTaxonomyDocument,
} from '../api/generated/graphql';
import { v4 as uuidv4 } from 'uuid';
import { GridImage } from './GridImage';
import { FileuploadBox } from './FileuploadBox';
import { ImageDetails } from './ImageDetails';
import { UploadImage, fileListToUploadImages } from './ImageUploadUtils';
import { s3bucket, s3client } from '../AppWithAuth';
import { DeleteObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import { classNames, fileExtensionToMimeType, isDefined, mimeTypeToDefaultOutputExtension, move } from '../utils';
import { WarningBody, WarningBox, WarningTitle } from './WarningBox';
import { gql, useApolloClient } from '@apollo/client';
import { Loader } from 'react-feather';
import { sortBy } from 'lodash';

interface Props {
  data?: ApiSingleTaxonomyQuery;
  id: string;
}

type TaxonomyImage = NonNullable<ApiSingleTaxonomyQuery['taxonomy_by_pk']>['taxonomy_images'][0];

export const SpeciesImages: FC<Props> = ({ data, id }) => {
  const [uploadImages, setUploadImages] = useState<(UploadImage & { copyright?: string | undefined })[]>([]);
  const [uploading, setUploading] = useState(false);
  const [selectedImage, setSelectedImage] = useState<TaxonomyImage>();
  const [addImage] = useApiAddTaxonomyImageMutation();
  const [deleteImage, deleteStatus] = useApiDeleteTaxonomyImageMutation();
  const allExts = useApiAllImageExtensionsQuery();
  const client = useApolloClient();
  const [movingImage, setMovingImage] = useState(false);

  const handleFileDrop = useCallback(async (monitor: DropTargetMonitor) => {
    if (monitor) {
      const files = monitor.getItem().files;
      if (files) {
        setUploadImages(await fileListToUploadImages(files));
      }
    }
  }, []);

  const handleFileChoose = useCallback(async (files: FileList | null) => {
    setUploadImages(await fileListToUploadImages(files));
  }, []);

  const handleImageDrop = useCallback(
    async ({ sourceImageId, targetImageId }: { sourceImageId: string; targetImageId: string }) => {
      const images =
        data?.taxonomy_by_pk?.taxonomy_images?.map((y) => ({
          imageId: y.image_id,
          orderNum: y.ordernum ?? 999,
        })) ?? [];
      const changes = changesToMoveImage({ images, targetImageId, sourceImageId });
      if (changes.length > 0) {
        const mutation = createChangeTaxonomyImageOrdersMutation(id, changes);
        try {
          setMovingImage(true);
          await client.mutate({
            mutation: mutation,
            refetchQueries: [
              {
                query: ApiSingleTaxonomyDocument,
                variables: { id: Number(id) },
              },
            ],
            awaitRefetchQueries: true,
          });
        } catch (e) {
          toast.error('Failed to change order of images');
        } finally {
          setMovingImage(false);
        }
      }
    },
    [client, id, data]
  );

  const missingImageCopyrights =
    data?.taxonomy_by_pk?.taxonomy_images?.filter((y) => !y.image.copyright || y.image.copyright.length === 0)
      ?.length ?? 0;

  const handleFileUpload = async () => {
    if (id) {
      let imageOrderNum = data?.taxonomy_by_pk?.taxonomy_images ? data.taxonomy_by_pk.taxonomy_images.length : 9999;
      setUploading(true);
      const tasks = uploadImages.map(async ({ file, dataUrl, copyright }, upIndex) => {
        return new Promise((resolve, reject) => {
          const imageId = uuidv4();
          const imageFile = new Image();
          imageFile.src = dataUrl;
          imageFile.onload = async () => {
            const fileMimeType = file.type;
            const validExtensions = allExts.data?.image_file_extension
              ?.map((y) => {
                const mimeType = fileExtensionToMimeType(y.extension);
                return mimeType ? { extension: y.extension, mimeType } : undefined;
              })
              ?.filter(isDefined);
            const mimeMatch = validExtensions?.find((y) => y.mimeType === fileMimeType);

            if (!mimeMatch) {
              toast.error(`Uploading ${file.name} failed. Unsupported file extension.`);
              return;
            }

            try {
              const uploadParams = {
                Bucket: s3bucket,
                Key: imageId,
                Body: file,
              };
              await s3client.send(new PutObjectCommand(uploadParams));
              await addImage({
                variables: {
                  taxonomy_id: Number(id),
                  ordernum: imageOrderNum + 1 + upIndex,
                  id: imageId,
                  filename: file.name,
                  file_extension: mimeMatch.extension,
                  width: imageFile.width,
                  height: imageFile.height,
                  copyright: copyright?.length ? copyright : null,
                  output_format: mimeTypeToDefaultOutputExtension(mimeMatch.mimeType),
                },
              });
              resolve(true);
              toast(`File ${file.name} upload successful!`);
            } catch {
              reject();
              toast.error(`File ${file.name} upload failed`);
            }
          };
          imageFile.onerror = () => {
            reject();
            toast.error('Could not read the image data');
          };
        });
      });
      Promise.allSettled(tasks).then(() => {
        client.clearStore();
        setUploading(false);
      });
      setUploadImages([]);
    }
  };

  const handleDelete = async () => {
    if (selectedImage?.image_id === undefined) return;
    else {
      if (!window.confirm('Delete image?')) return;
      try {
        await deleteImage({
          variables: { id: selectedImage.image_id },
          refetchQueries: [{ query: ApiSingleTaxonomyDocument, variables: { id: Number(id) } }],
        });
        await s3client.send(
          new DeleteObjectCommand({
            Bucket: s3bucket,
            Key: selectedImage.image_id,
          })
        );
        toast.success('Delete successful!');
      } catch {
        toast.error('Delete failed!');
      }
    }
    setSelectedImage(undefined);
  };

  return (
    <div className="twocolumn images">
      <div className="gallery">
        <h2>Images</h2>
        <div className="imagegrid max-w-xl relative">
          {movingImage && <Loader className="animate-spin absolute w-32 h-32 inset-x-1/2 inset-y-1/2 -ml-16 -mt-16" />}
          {data?.taxonomy_by_pk?.taxonomy_images
            .slice()
            .sort((a, b) => a.ordernum! - b.ordernum!)
            .map((x) => (
              <GridImage
                key={x.image_id}
                image={x.image}
                image_id={x.image_id}
                ordernum={x.ordernum ?? 999}
                onClick={() => setSelectedImage(x)}
                selected={selectedImage?.image_id === x.image_id}
                disabled={movingImage}
                onDrop={handleImageDrop}
              />
            ))}
          {data?.taxonomy_by_pk?.taxonomy_images?.length === 0 && <div>No images found</div>}
        </div>
      </div>
      <div>
        <div className="uploadform">
          <h2>Add an image</h2>
          {missingImageCopyrights > 0 && (
            <>
              <WarningBox>
                <WarningTitle>
                  Images have missing <em>copyright</em> fields.
                </WarningTitle>
                <WarningBody>
                  {missingImageCopyrights}/{data?.taxonomy_by_pk?.taxonomy_images?.length ?? 0} images are missing{' '}
                  <em>copyright</em> -field. Fill in the missing copyright fields to ensure copyright is displayed
                  correctly on the website before uploading new images.
                </WarningBody>
              </WarningBox>
              <WarningBox>
                <WarningTitle>Legacy copyright (old list of photographers)</WarningTitle>
                <WarningBody>
                  {data?.taxonomy_by_pk?.taxonomy_articles?.find((y) => y.language === 'fi')?.legacy_copyright}
                </WarningBody>
              </WarningBox>
            </>
          )}

          {uploading && (
            <div>
              <Loader className="animate-spin" /> Uploading...
            </div>
          )}

          {!uploading && (
            <form>
              <div className="pb-4">
                <div>
                  <div className="mt-6 flex flex-col space-y-2">
                    {uploadImages.length > 0 ? (
                      <table className="lp-table">
                        <thead>
                          <tr>
                            <td>Preview</td>
                            <td>Filename</td>
                            <td>Copyright</td>
                          </tr>
                        </thead>
                        <tbody>
                          {uploadImages.map(({ file, dataUrl, copyright }, ind) => {
                            return (
                              <tr key={`${file.name}${file.size}${ind}`}>
                                <td>{dataUrl && <img className="h-20 w-20" src={dataUrl} alt="uploaded file" />}</td>
                                <td>{file.name}</td>
                                <td>
                                  <input
                                    type="text"
                                    className="lp-input-text w-full"
                                    value={copyright ?? ''}
                                    placeholder="Etunimi Sukunimi"
                                    onChange={(e) => {
                                      setUploadImages(
                                        uploadImages.map((x, index) =>
                                          index === ind
                                            ? {
                                                ...x,
                                                copyright: e.target.value.length > 0 ? e.target.value : undefined,
                                              }
                                            : x
                                        )
                                      );
                                    }}
                                  />
                                  <button
                                    className="lp-button-link"
                                    type="button"
                                    onClick={() => {
                                      setUploadImages(uploadImages.map((y) => ({ ...y, copyright: copyright })));
                                    }}
                                  >
                                    copy this to all
                                  </button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <div className="sm:col-span-6">
                        <FileuploadBox onDrop={handleFileDrop} onSelect={handleFileChoose} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <div className="flex justify-start">
                  <button
                    type="button"
                    disabled={uploadImages.length === 0}
                    onClick={() => handleFileUpload()}
                    className="lp-button"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
        {selectedImage && (
          <ImageDetails
            taxonomyId={Number(id)}
            image={selectedImage}
            handleDelete={handleDelete}
            deleting={deleteStatus.loading}
          />
        )}
      </div>
    </div>
  );
};

function moveImage({
  images,
  targetImageId,
  sourceImageId,
}: {
  images: { imageId: string; orderNum: number }[];
  targetImageId: string;
  sourceImageId: string;
}): { imageId: string; orderNum: number }[] {
  if (targetImageId === sourceImageId) return images;

  const sorted = sortBy(images, (x) => x.orderNum);
  const sourceImageIndex = sorted.findIndex((x) => x.imageId === sourceImageId);
  const targetImageIndex = sorted.findIndex((x) => x.imageId === targetImageId);

  if (sourceImageIndex === -1 || targetImageIndex === -1) {
    throw new Error('Target and source must be in the list');
  }

  const moved = move(sorted, sourceImageIndex, targetImageIndex);
  const withOrdering = moved.reduce((acc, next) => {
    const previous = acc[acc.length - 1];
    if (previous === undefined) {
      return [{ ...next, orderNum: 0 }];
    } else {
      return [...acc, { ...next, orderNum: previous.orderNum + 1 }];
    }
  }, [] as typeof images);

  return withOrdering;
}

function changesToMoveImage({
  images,
  targetImageId,
  sourceImageId,
}: {
  images: { imageId: string; orderNum: number }[];
  targetImageId: string;
  sourceImageId: string;
}): { imageId: string; orderNum: number }[] {
  const originalOrdering = new Map(images.map((y) => [y.imageId, y.orderNum]));
  const imagesAfterMove = moveImage({ images, targetImageId, sourceImageId });
  const changes = imagesAfterMove.filter((x) => x.orderNum !== originalOrdering.get(x.imageId));
  return changes;
}

// Dynamically creating the query since it is not possible to otherwise
// update multiple rows in the same query (and transaction). Hopefully,
// in the future this will be possible without a hack like this.
function createChangeTaxonomyImageOrdersMutation(taxonomyId: string, images: { imageId: string; orderNum: number }[]) {
  let mutationString = 'mutation changeTaxonomyImageOrderNums {';
  images.forEach((image, index) => {
    mutationString += `
    update_${index}: update_taxonomy_image(
      where: { _and: [{ taxonomy_id: { _eq: ${taxonomyId} } }, { image_id: { _eq: "${image.imageId}" } }] }
      _set: { ordernum: ${image.orderNum} }
    ) {
      affected_rows
    }`;
  });
  mutationString += '\n}';
  return gql`
    ${mutationString}
  `;
}
