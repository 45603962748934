export interface User {
  attributes: { sub: string; email_verified: boolean; email: string };
  id: string | number;
  username: string;
}

export enum WidgetType {
  FishParts = 'fish_parts',
  MapAreaBiotaProvinces = 'map_area_biota_provinces',
  MapAreaBalticSea = 'map_area_baltic_sea',
  MapAreaFungi = 'map_area_fungi',
  MapAreaFishes = 'map_area_fishes',
}

export function widgetTypeFromString(string: string): WidgetType | undefined {
  switch (string) {
    case WidgetType.FishParts:
      return WidgetType.FishParts;
    case WidgetType.MapAreaBiotaProvinces:
      return WidgetType.MapAreaBiotaProvinces;
    case WidgetType.MapAreaBalticSea:
      return WidgetType.MapAreaBalticSea;
    case WidgetType.MapAreaFungi:
      return WidgetType.MapAreaFungi;
    case WidgetType.MapAreaFishes:
      return WidgetType.MapAreaFishes;
    default:
      return undefined;
  }
}
