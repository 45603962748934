import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ContentPageList } from '../components/ContentPages/ContentPageList';
import { SingleContentPage } from '../components/ContentPages/SingleContentPage';

export function ContentPages() {
  const match = useRouteMatch();

  const linkPath = useMemo(() => match.path.replace('/*', ''), [match]);

  return (
    <div className="lp-page">
      <Switch>
        <Route exact path={`${linkPath}/:id`}>
          <SingleContentPage />
        </Route>
        <Route path={`${linkPath}`}>
          <ContentPageList />
        </Route>
      </Switch>
    </div>
  );
}
