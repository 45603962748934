import { FC, useEffect, useState } from 'react';
import Table from 'rc-table';
import {
  useApiAllCharacteristicGroupsQuery,
  useApiAddCharacteristicGroupMutation,
  useApiChangeCharacteristicGroupMutation,
  useApiDeleteCharacteristicGroupMutation,
} from '../../api/generated/graphql';
import { ColumnsType } from 'rc-table/lib/interface';
import { toast } from 'react-toastify';
import { SlideOver } from '../SlideOver';
import { CharacteristicSectionSelect } from './CharacteristicSectionSelect';
import { ImageSelect } from '../ImageSelect';
import { withFallbackName } from '../../utils';
import { NamesEdit } from '../NamesEdit';
import { LanguagesPreview } from '../LanguagesPreview';
import { WidgetType } from '../../model';
import Select from 'react-select';

interface Props {}

interface TableRow {
  key: string;
  id: number;
  name: string;
  names: Record<string, string>;
  sectionName: string;
  sectionId: number;
  ordernum: number;
  iconId: string;
  widgetType: string | undefined;
}

interface NewGroup {
  id: number | undefined;
  names: Record<string, string>;
  sectionId: number | undefined;
  ordernum: number;
  iconId: string | undefined;
  widgetType: string | undefined;
}

const widgetOptions = [{ value: WidgetType.FishParts, label: 'Fish parts' }];

export const CharacteristicGroups: FC<Props> = () => {
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [newGroup, setNewGroup] = useState<NewGroup>({
    id: undefined,
    names: {},
    sectionId: undefined,
    ordernum: 0,
    iconId: undefined,
    widgetType: undefined,
  });
  const { data, loading, refetch } = useApiAllCharacteristicGroupsQuery();

  const [add, addStatus] = useApiAddCharacteristicGroupMutation();
  const [change, changeStatus] = useApiChangeCharacteristicGroupMutation();
  const [delete_, deleteStatus] = useApiDeleteCharacteristicGroupMutation();

  const tableData: TableRow[] =
    data?.characteristic_group.map((y) => {
      return {
        key: y.id.toString(),
        id: y.id,
        name: withFallbackName(y),
        names: y.names,
        sectionName: withFallbackName(y.characteristic_section),
        ordernum: y.ordernum,
        iconId: y.icon_id,
        sectionId: y.characteristic_section.id,
        widgetType: y.widget_type && y.widget_type.length > 0 ? y.widget_type : undefined,
      };
    }) ?? [];

  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns: ColumnsType<TableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 30,
    },
    {
      title: 'Section',
      dataIndex: '',
      key: 'section',
      render: (value) => value.sectionName,
      width: 200,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Name translations',
      dataIndex: '',
      key: 'nameTranslations',
      width: 200,
      render: (_value, row, _index) => <LanguagesPreview translations={row.names} />,
    },
    {
      title: 'Order',
      dataIndex: 'ordernum',
      key: 'ordernum',
      width: 60,
    },
    {
      title: 'Operations',
      dataIndex: '',
      key: 'operations',
      render: (value, row, index) => (
        <button
          onClick={() => {
            setNewGroup({ ...row });
            setSlideOverOpen(true);
          }}
          className="lp-button-link"
        >
          Edit
        </button>
      ),
    },
  ];

  const handleCloseSlideOver = () => {
    setSlideOverOpen(false);
    setNewGroup({
      id: undefined,
      names: {},
      sectionId: undefined,
      ordernum: 0,
      iconId: undefined,
      widgetType: undefined,
    });
  };

  const handleAdd = async () => {
    if (newGroup.sectionId === undefined || newGroup.iconId === undefined) {
      return;
    }
    try {
      await add({
        variables: {
          sectionId: newGroup.sectionId,
          iconId: newGroup.iconId,
          ordernum: newGroup.ordernum,
          names: newGroup.names,
          widgetType: newGroup.widgetType && newGroup.widgetType.length > 0 ? newGroup.widgetType : null,
        },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Add successful!');
    } catch {
      toast.error('Add failed!');
    }
  };

  const handleUpdate = async () => {
    if (newGroup.id === undefined || newGroup.sectionId === undefined || newGroup.iconId === undefined) {
      return;
    }
    try {
      await change({
        variables: {
          id: newGroup.id,
          sectionId: newGroup.sectionId,
          iconId: newGroup.iconId,
          ordernum: newGroup.ordernum,
          names: newGroup.names,
          widgetType: newGroup.widgetType && newGroup.widgetType.length > 0 ? newGroup.widgetType : null,
        },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Save successful!');
    } catch {
      toast.error('Save failed!');
    }
  };

  const handleDelete = async () => {
    if (newGroup.id === undefined) {
      handleCloseSlideOver();
    } else {
      if (!window.confirm('Delete group?')) return;
      try {
        await delete_({ variables: { id: newGroup.id } });
        await refetch();
        handleCloseSlideOver();
        toast.success('Delete successful!');
      } catch {
        toast.error('Delete failed!');
      }
    }
  };

  return (
    <>
      <button className="lp-button mb-4" type="button" disabled={false} onClick={() => setSlideOverOpen(true)}>
        Add new group
      </button>

      {data && !loading && tableData.length > 0 && <Table className="lp-table" columns={columns} data={tableData} />}

      <SlideOver
        show={slideOverOpen}
        title={newGroup.id === undefined ? 'New group' : 'Edit group'}
        saving={addStatus.loading || changeStatus.loading}
        deleting={deleteStatus.loading}
        onCancelClick={handleCloseSlideOver}
        onDeleteClick={handleDelete}
        onSaveClick={() => {
          if (newGroup.id === undefined) {
            handleAdd();
          } else {
            handleUpdate();
          }
        }}
      >
        <div className="space-y-6 pt-6 pb-5">
          <div>
            <h3>General</h3>
            <div>
              <label className="lp-input-text-label" htmlFor="section">
                Section *
              </label>
              <CharacteristicSectionSelect
                inputId="section"
                value={newGroup.sectionId}
                onChange={(value) => setNewGroup({ ...newGroup, sectionId: value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="icon">
                Icon *
              </label>
              <ImageSelect
                inputId="icon"
                value={newGroup.iconId}
                onChange={(value) => setNewGroup({ ...newGroup, iconId: value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="ordernum">
                Order *
              </label>
              <input
                id="ordernum"
                type="number"
                className="lp-input-text mt-1 w-full"
                value={newGroup?.ordernum ?? 0}
                onChange={(e) => setNewGroup({ ...newGroup, ordernum: parseInt(e.target.value) })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="widget">
                Widget
              </label>
              <Select
                inputId="widget"
                isClearable={true}
                value={widgetOptions.find((x) => x.value === newGroup.widgetType)}
                options={widgetOptions}
                isLoading={loading}
                onChange={(dropdownValue) => {
                  setNewGroup({ ...newGroup, widgetType: dropdownValue?.value });
                }}
              />
            </div>
          </div>
          <NamesEdit value={newGroup.names} onChange={(names) => setNewGroup({ ...newGroup, names })} />
        </div>
      </SlideOver>
    </>
  );
};
