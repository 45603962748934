import { FC, useMemo, useRef } from 'react';
import { ApiSingleTaxonomyQuery, Scalars } from '../api/generated/graphql';
import { useDrag, useDrop } from 'react-dnd';
import { classNames } from '../utils';

type Image = NonNullable<ApiSingleTaxonomyQuery['taxonomy_by_pk']>['taxonomy_images'][0]['image'];

export interface GridImageProps {
  image: Image;
  image_id: Scalars['uuid'];
  ordernum: number;
  onClick: () => any;
  selected: boolean;
  disabled: boolean;
  onDrop: (props: { sourceImageId: string; targetImageId: string }) => void;
}

interface DnDItemType extends GridImageProps {
  type: string;
}

export const GridImage: FC<GridImageProps> = (props) => {
  const { image, ordernum, image_id, onClick, selected, onDrop, disabled } = props;

  const ref = useRef<HTMLButtonElement>(null);

  const [{ isDragging }, dragRef] = useDrag({
    item: { type: 'gridImage', image, ordernum, image_id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => !props.disabled,
  });
  const [{ isOver }, dropRef] = useDrop({
    accept: 'gridImage',
    drop: (item: DnDItemType) => {
      if (image_id !== item.image_id) {
        onDrop({ sourceImageId: item.image_id, targetImageId: image_id });
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const className = useMemo(() => {
    return classNames({
      imagecontainer: true,
      mainimage: ordernum === 0,
      hoverimage: isOver,
      'opacity-50': isDragging || disabled,
      selectedimage: selected,
    });
  }, [selected, isOver, isDragging, disabled, ordernum]);

  dragRef(dropRef(ref));

  return (
    <button ref={ref} disabled={disabled} className={className} onClick={onClick}>
      {image.urls && <img src={image.urls[0]?.url} alt="Missing alt" />}
    </button>
  );
};
