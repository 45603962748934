import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TLink } from './TLink';

interface Props {
  href: string;
  title: string;
  icon?: any;
}

export function DashboardLink({ href, title, icon }: Props) {
  const history = useHistory();
  const { i18n } = useTranslation();
  const linkclass =
    history.location.pathname === `/${i18n.language}${href ? '/' + href : ''}` ? 'navlinkactive' : 'navlink';
  return (
    <TLink to={href} className={linkclass}>
      {icon && <div className="mr-4">{icon}</div>}
      {title}
    </TLink>
  );
}
