import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';

export function Profile() {
  const { i18n } = useTranslation();
  const SignOut = async function () {
    try {
      await Auth.signOut();
    } catch {
      console.error('Error signing use out');
    }
  };

  return (
    <div className="profilecontainer">
      <h1>Profile</h1>
      <h2>Language</h2>
      <div className="languagecontainer">
        <button className="dashbutton" type="button" onClick={() => i18n.changeLanguage('fi')}>
          FI
        </button>
        <button className="dashbutton" type="button" onClick={() => i18n.changeLanguage('en')}>
          EN
        </button>
      </div>
      <div className="logoutcontainer">
        <button className="dashbutton" type="button" onClick={() => SignOut()}>
          Log out
        </button>
      </div>
    </div>
  );
}
