import { FC } from 'react';
import { ApiSingleTaxonomyQuery } from '../api/generated/graphql';
import { TaxonomyAncestorsTree } from './TaxonomyAncestorsTree';

interface Props {
  data?: ApiSingleTaxonomyQuery;
}

export const TaxonomyAncestors: FC<Props> = ({ data }) => {
  const treeTaxonomies = [
    ...(data?.taxonomy_by_pk?.ancestors ?? []),
    ...(data?.taxonomy_by_pk ? [data?.taxonomy_by_pk] : []),
  ];
  return (
    <div className="space-y-4">
      <TaxonomyAncestorsTree taxonomies={treeTaxonomies} selectedTaxonomyId={data?.taxonomy_by_pk?.id} />
    </div>
  );
};
