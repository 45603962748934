import { FC, useMemo, useState } from 'react';
import {
  useApiChangeTaxonomyWidgetMutation,
  ApiSingleTaxonomyQuery,
  useApiAddTaxonomyWidgetMutation,
  useApiDeleteTaxonomyWidgetMutation,
  ApiSingleTaxonomyDocument,
} from '../../api/generated/graphql';
import { toast } from 'react-toastify';
import { ColumnsType } from 'rc-table/lib/interface';
import Table from 'rc-table';
import { WidgetPreview } from './WidgetPreview';
import { SlideOver } from '../SlideOver';
import {
  TAXONOMY_WIDGET_FISH_LENGTH,
  TAXONOMY_WIDGET_FUNGUS_COLORING,
  widgetTypeToString,
  WIDGET_TYPES,
} from './model';
import Select from 'react-select';
import { WidgetDataEdit } from './WidgetDataEdit';
import { Loader } from 'react-feather';

interface Props {
  data?: ApiSingleTaxonomyQuery;
}

interface TableRow {
  key: string;
  widget_type_id: string;
  widget_data: unknown;
}

interface NewWidget {
  existing: boolean;
  widget_type_id: string;
  widget_data: unknown;
}

export const WidgetsList: FC<Props> = ({ data }) => {
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [change, changeStatus] = useApiChangeTaxonomyWidgetMutation();
  const [add, addStatus] = useApiAddTaxonomyWidgetMutation();
  const [delete_, deleteStatus] = useApiDeleteTaxonomyWidgetMutation();

  const tableData = useMemo(
    () =>
      data?.taxonomy_by_pk?.taxonomy_widgets?.map((x) => ({
        key: x.widget_type_id,
        widget_type_id: x.widget_type_id,
        widget_data: x.widget_data,
      })),
    [data]
  );

  const [newWidget, setNewWidget] = useState<NewWidget>({
    existing: false,
    widget_type_id: '',
    widget_data: undefined,
  });

  const columns: ColumnsType<TableRow> = [
    {
      title: 'Type',
      dataIndex: 'widget_type_id',
      key: 'widget_type_id',
      width: 350,
      render: (value, row, index) => widgetTypeToString(value),
    },
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      width: 50,
      render: (value, row, index) => <WidgetPreview widget={row} />,
    },
    {
      title: 'Operations',
      width: 50,
      key: 'operations',
      render: (value, row, index) => (
        <button
          onClick={() => {
            setNewWidget({ existing: true, widget_type_id: row.widget_type_id, widget_data: row.widget_data });
            setSlideOverOpen(true);
          }}
          className="lp-button-link"
        >
          Edit
        </button>
      ),
    },
  ];

  const handleCloseSlideOver = () => {
    setSlideOverOpen(false);
    setNewWidget({
      existing: false,
      widget_type_id: '',
      widget_data: undefined,
    });
  };

  const handleAdd = async () => {
    try {
      await add({
        variables: {
          taxonomy_id: data!.taxonomy_by_pk!.id,
          widget_type_id: newWidget.widget_type_id,
          widget_data: newWidget.widget_data,
        },
        refetchQueries: [{ query: ApiSingleTaxonomyDocument, variables: { id: data!.taxonomy_by_pk!.id } }],
      });
      handleCloseSlideOver();
      toast.success('Add successful!');
    } catch {
      toast.error('Add failed!');
    }
  };

  const handleDelete = async () => {
    if (!newWidget.existing) {
      handleCloseSlideOver();
    } else {
      if (!window.confirm('Delete widget?')) return;
      try {
        await delete_({
          variables: {
            taxonomy_id: data!.taxonomy_by_pk!.id,
            widget_type_id: newWidget.widget_type_id,
          },
          refetchQueries: [{ query: ApiSingleTaxonomyDocument, variables: { id: data!.taxonomy_by_pk!.id } }],
        });
        handleCloseSlideOver();
        toast.success('Delete successful!');
      } catch {
        toast.error('Delete failed!');
      }
    }
  };

  const handleUpdate = async () => {
    if (!newWidget.existing) return;
    try {
      await change({
        variables: {
          taxonomy_id: data!.taxonomy_by_pk!.id,
          widget_type_id: newWidget.widget_type_id,
          widget_data: newWidget.widget_data,
        },
        refetchQueries: [{ query: ApiSingleTaxonomyDocument, variables: { id: data!.taxonomy_by_pk!.id } }],
      });
      handleCloseSlideOver();
      toast.success('Save successful!');
    } catch {
      toast.error('Save failed!');
    }
  };

  let content: JSX.Element;
  if (data) {
    content = (
      <>
        <div className="w-full md:max-w-sm space-y-4">
          <button className="lp-button mb-4" type="button" disabled={false} onClick={() => setSlideOverOpen(true)}>
            Add new widget
          </button>
          <Table className="lp-table" columns={columns} data={tableData} />
        </div>
        <SlideOver
          show={slideOverOpen}
          title={!newWidget.existing ? 'New widget' : 'Edit widget'}
          saving={addStatus.loading || changeStatus.loading}
          deleting={deleteStatus.loading}
          onCancelClick={handleCloseSlideOver}
          onDeleteClick={handleDelete}
          onSaveClick={() => {
            if (!newWidget.existing) {
              handleAdd();
            } else {
              handleUpdate();
            }
          }}
        >
          <div className="pt-6 pb-5 space-y-4">
            <div>
              <label className="lp-input-text-label" htmlFor="type">
                Type
              </label>
              <Select
                inputId="type"
                options={WIDGET_TYPES.map((x) => ({ label: widgetTypeToString(x), value: x }))}
                className="text-sm max-w-sm"
                isDisabled={newWidget.existing}
                value={{
                  label: newWidget.widget_type_id.length > 0 ? widgetTypeToString(newWidget.widget_type_id) : '',
                  value: newWidget.widget_type_id,
                }}
                isOptionDisabled={(x) =>
                  newWidget.existing ||
                  (data?.taxonomy_by_pk?.taxonomy_widgets?.some((y) => y.widget_type_id === x.value) ?? false)
                }
                onChange={(e) => {
                  let newWidgetData: unknown;
                  if (e?.value === TAXONOMY_WIDGET_FISH_LENGTH) {
                    newWidgetData = 1;
                  } else if (e?.value === TAXONOMY_WIDGET_FUNGUS_COLORING) {
                    newWidgetData = false;
                  } else {
                    newWidgetData = undefined;
                  }
                  setNewWidget({ existing: false, widget_type_id: e?.value ?? '', widget_data: newWidgetData });
                }}
              />
            </div>
            {newWidget.widget_type_id !== '' && (
              <WidgetDataEdit
                widget={newWidget}
                onChange={(data) => setNewWidget({ ...newWidget, widget_data: data })}
              />
            )}
            {newWidget.widget_type_id === '' && <div>Please select a widget type first.</div>}
          </div>
        </SlideOver>
      </>
    );
  } else {
    content = <Loader className="animate-spin" />;
  }
  return content;
};
