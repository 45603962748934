import React from 'react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import App from './App';
import { RecoilRoot } from 'recoil';
import Amplify, { Auth } from 'aws-amplify';
import { S3Client } from '@aws-sdk/client-s3';
import { currentEnvironment } from './utils';

const environment = currentEnvironment();

if (environment === 'prod') {
  Amplify.configure({
    Auth: {
      identityPoolId: 'eu-west-1:1dccd6f7-c3a0-4a28-8bbf-2af282e39907',
      mandatorySignIn: true,
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_Cb7FXOY6C',
      userPoolWebClientId: '4lplmn3el7ld1njstfs6vgsr8o',
    },
  });
} else {
  Amplify.configure({
    Auth: {
      identityPoolId: 'eu-west-1:c39c7405-653a-43ff-aeac-46417950544c',
      mandatorySignIn: true,
      region: 'eu-west-1',
      userPoolId: 'eu-west-1_rAX7YzqMJ',
      userPoolWebClientId: '5n32ftnd87ioi647rd42715kdb',
    },
  });
}

export const s3client = new S3Client({
  region: 'eu-west-1',
  credentials: Auth.currentCredentials,
});
export const s3bucket = environment === 'prod' ? 'lportti-prod-images' : 'lportti-staging-images';

const AuthStateApp: React.FunctionComponent = () => {
  return (
    <React.Fragment>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </React.Fragment>
  );
};

export default withAuthenticator(AuthStateApp);
