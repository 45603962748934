import { FC } from 'react';
import Select from 'react-select';
import { useApiAllCharacteristicsWithWhereQuery } from '../../api/generated/graphql';
import { withFallbackName } from '../../utils';

interface Props {
  value: number | undefined;
  onChange: (groupId: number | undefined) => void;
  inputId: string;
  groupId: number | undefined;
}
export const CharacteristicSelect: FC<Props> = ({ value, onChange, inputId, groupId }) => {
  const { data, loading } = useApiAllCharacteristicsWithWhereQuery({
    variables: {
      where:
        groupId !== undefined
          ? { characteristic_group_id: { _eq: groupId } }
          : { characteristic_group_id: { _is_null: true } },
    },
  });

  const options =
    data?.characteristic.map((x) => ({
      label: withFallbackName(x),
      value: x.id,
    })) ?? [];

  return (
    <Select
      inputId={inputId}
      isSearchable={true}
      value={options?.find((x) => x.value === value)}
      options={options}
      isLoading={loading}
      onChange={(dropdownValue) => {
        onChange(dropdownValue?.value ?? undefined);
      }}
    />
  );
};
