import { FC, useEffect, useMemo, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  ApiSingleTaxonomyQuery,
  useApiChangeArticleTextMutation,
  useApiAddArticleTextMutation,
  useApiSpeciesListQuery,
  ApiSingleTaxonomyDocument,
} from '../api/generated/graphql';
import { LanguageTabs } from './LanguageTabs';
import { toast } from 'react-toastify';
import { PluginManager } from 'tinymce';
import { commonNameToUrlSlug } from '../utils';

function createMarkup(x: string | undefined) {
  if (!x) {
    return { __html: 'No content yet' };
  }
  const parser = new DOMParser();
  const doc1 = parser.parseFromString(x, 'text/html');

  const links = doc1?.getElementsByTagName('a');
  if (links) {
    for (let i = 0; i < links.length; ++i) {
      const element = links.item(i);
      element?.setAttribute('target', '_blank');
      element?.setAttribute('rel', 'noreferrer noopener');
    }
  }

  const permlinks = doc1?.getElementsByTagName('lp-taxonomy-link');
  if (permlinks) {
    for (let i = permlinks.length - 1; i >= 0; i--) {
      const element = permlinks.item(i);
      if (!element) {
        continue;
      }
      const attributes = element?.attributes;
      const id = attributes?.getNamedItem('data-id')?.value;
      const fragment = attributes?.getNamedItem('data-fragment')?.value;
      const fragmentSuffix = fragment && fragment.length > 0 ? `#${fragment}` : '';
      const linkElement = document.createElement('a');
      linkElement.setAttribute('href', `/fi/taxonomy/${id}${fragmentSuffix}`);
      linkElement.setAttribute('target', '_blank');
      linkElement.innerText = element.innerHTML;
      element?.replaceWith(linkElement);
    }
  }

  return { __html: doc1 ? doc1.documentElement.innerHTML : '' };
}

const URL_SLUG_REGEX = /^[a-zA-Z0-9-]+$/;

interface Props {
  data?: ApiSingleTaxonomyQuery;
}

export const Descriptions: FC<Props> = ({ data }) => {
  const [activeUrlSlug, setActiveUrlSlug] = useState<string>('');
  const [activeArticle, setActiveArticle] = useState<string>('');
  const [activeInformationStrike, setActiveInformationStrike] = useState<string>('');
  const [activeLanguage, setActiveLanguage] = useState<string>('');
  const [activePublished, setActivePublished] = useState(false);
  const [articleExists, setArticleExists] = useState(false);
  const [specieslist, setspecieslist] = useState<any>(null);
  const [editorLangKey, setEditorLangKey] = useState<string>('');

  // TODO: Set active language based on first article language variant if current UI language variant is not found.

  // TODO: store all variants in state so that the use can hop between languages and save when done.
  useEffect(() => {
    const result = data?.taxonomy_by_pk?.taxonomy_articles.find((x) => x.language === activeLanguage);
    setArticleExists(result !== undefined);
    // clean up some control characters from the article markup
    setActiveArticle(result?.article.replace(/\\n|\\t/gm, '') ?? '');
    setActiveUrlSlug(result?.url_slug ?? '');
    setActiveInformationStrike(result?.information_strike?.replace(/\\n|\\t/gm, '') ?? '');
    setActivePublished(result?.published ?? false);
  }, [data, activeLanguage]);

  const [changeArticleText, changeStatus] = useApiChangeArticleTextMutation();
  const [addArticleText, addStatus] = useApiAddArticleTextMutation();
  const { data: specieslistdata } = useApiSpeciesListQuery({
    variables: { lang: activeLanguage },
  });
  useEffect(() => {
    if (specieslistdata) {
      setspecieslist(
        specieslistdata.taxonomy.map((x) => {
          return {
            value: x.id.toString(),
            text: `${x.taxonomy_common_names.find(Boolean)?.name} - ${x.scientific_name}`,
            scientific: x.scientific_name,
          };
        })
      );
      setEditorLangKey(activeLanguage);
    }
  }, [specieslistdata, activeLanguage]);

  const preferredCommonName =
    data?.taxonomy_by_pk?.taxonomy_common_names?.find((x) => x.language === activeLanguage && x.preferred)?.name ?? '';
  const autofillUrlSlug = commonNameToUrlSlug(preferredCommonName);
  const urlSlugIsValid = activeUrlSlug.length === 0 || URL_SLUG_REGEX.test(activeUrlSlug);

  const handleDescriptionChange = async () => {
    if (!data?.taxonomy_by_pk) return;
    if (!urlSlugIsValid) {
      window.alert(`Invalid url slug: ${activeUrlSlug}. Please fix the url slug before saving.`);
      return;
    }
    if (activeUrlSlug.length === 0) {
      window.alert(`Missing url slug. Please fill in the url slug before saving.`);
      return;
    }
    try {
      const refetchQueries = [{ query: ApiSingleTaxonomyDocument, variables: { id: data.taxonomy_by_pk.id } }];
      if (articleExists) {
        await changeArticleText({
          variables: {
            taxonomyId: data?.taxonomy_by_pk.id,
            lang: activeLanguage,
            article: activeArticle,
            published: activePublished,
            informationStrike: activeInformationStrike.length === 0 ? undefined : activeInformationStrike,
            urlSlug: activeUrlSlug,
          },
          refetchQueries,
        });
      } else {
        await addArticleText({
          variables: {
            taxonomyId: data?.taxonomy_by_pk.id,
            lang: activeLanguage,
            article: activeArticle,
            published: activePublished,
            informationStrike: activeInformationStrike.length === 0 ? undefined : activeInformationStrike,
            section: '',
            urlSlug: activeUrlSlug,
          },
          refetchQueries,
        });
        setArticleExists(true);
      }

      toast.success('Update successful!');
    } catch {
      toast.error('Update failed!');
    }
  };

  const editorSetupFunction = useMemo(() => {
    return (editor: any) => {
      const editorManager: any = editor.editorManager;
      const pluginManager: PluginManager = editorManager.PluginManager as PluginManager;
      pluginManager.add('lplink', function (editor) {
        /*
              Use to store the instance of the Dialog
               */
        var _dialog: any = false;

        var _speciesOptions = specieslist;

        var _searchValue = '';
        var _titleValue = '';

        function _getDialogConfig() {
          return {
            title: 'Link to a species',
            body: {
              type: 'panel',
              items: [
                {
                  type: 'input',
                  name: 'title',
                  label: 'Title',
                },
                {
                  type: 'input',
                  name: 'search',
                  label: 'Search',
                },
                {
                  type: 'listbox',
                  name: 'id',
                  label: 'Id',
                  items: _speciesOptions,
                },
                {
                  type: 'input',
                  name: 'fragment',
                  label: 'Fragment (hash)',
                },
              ],
            },
            buttons: [
              {
                type: 'cancel',
                text: 'Close',
              },
              {
                type: 'submit',
                text: 'Save',
                primary: true,
              },
            ],
            initialData: {
              title: _titleValue,
              search: _searchValue,
              id: _speciesOptions[0] ? _speciesOptions[0].value : '',
              fragment: '',
            },
            onChange: (api: any, changedComponent: any) => {
              var data: any = api.getData();
              if (changedComponent.name === 'search' && data) {
                _speciesOptions = specieslist.filter(function (x: any) {
                  return (
                    x.text.toLowerCase().startsWith(data.search.toLowerCase()) ||
                    x.scientific.toLowerCase().startsWith(data.search.toLowerCase())
                  );
                });
                _searchValue = data.search;
                _dialog.redial(_getDialogConfig());
                _dialog.focus('search');
              } else if (changedComponent.name === 'title' && data) {
                _titleValue = data.title;
              }
            },
            onSubmit: (api: any) => {
              var data: any = api.getData();
              /* Insert content when the window form is submitted */
              var fragmentAttr = data.fragment && data.fragment.length > 0 ? ` data-fragment=${data.fragment}` : '';
              editor.insertContent(
                `<lp-taxonomy-link data-id=${data.id}${fragmentAttr}>${data.title}</lp-taxonomy-link>`
              );
              api.close();
            },
          };
        }

        function _onAction() {
          // Open a Dialog, and update the dialog instance var
          _dialog = editor.windowManager.open(_getDialogConfig() as any);
        }

        // Define the Toolbar button
        editor.ui.registry.addButton('lplink', {
          text: 'Specieslink',
          onAction: _onAction,
        });
      });

      pluginManager.add('lplistitem', function (editor) {
        // Define the Toolbar button
        editor.ui.registry.addButton('lplistitem', {
          text: 'Fill list item',
          tooltip: 'Fill list item',
          disabled: true,
          onAction: function () {
            editor.insertContent('<h3>Title</h3><p>Content</p>');
          },
          onSetup: function (buttonApi) {
            var editorEventCallback = function (eventApi: any) {
              buttonApi.setDisabled(eventApi.element.nodeName.toLowerCase() !== 'li');
            };
            editor.on('NodeChange', editorEventCallback);
            return function (buttonApi) {
              editor.off('NodeChange', editorEventCallback);
            };
          },
        });
      });
    };
  }, [specieslist]);

  return (
    <>
      <LanguageTabs current={activeLanguage} onChange={setActiveLanguage} />
      <div>
        <label className="lp-input-text-label" htmlFor={`${activeLanguage}_url_slug`}>
          Url slug *
        </label>
        <input
          id={`${activeLanguage}_url_slug`}
          type="text"
          className="lp-input-text mt-1 w-full max-w-xs"
          value={activeUrlSlug}
          onChange={(e) => setActiveUrlSlug(e.target.value)}
        />
        {activeUrlSlug.length === 0 && autofillUrlSlug.length > 0 && (
          <button
            className="lp-button-link"
            onClick={() => {
              setActiveUrlSlug(autofillUrlSlug);
            }}
          >
            Autofill with {autofillUrlSlug} (derived from name: {preferredCommonName})
          </button>
        )}
        {!urlSlugIsValid && (
          <span className="text-red-500 text-sm">
            Invalid url slug. Allowed letters: a-z, digits 0-9, and dashes '-'.
          </span>
        )}
      </div>
      <div className="twocolumn article">
        <div className="editorcontainer">
          <h2 className="text-2xl my-8">Main text</h2>
          {specieslistdata && (
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              key={`editor_${editorLangKey}`}
              value={activeArticle ?? ''}
              init={{
                height: 700,
                menubar: true,
                entity_encoding: 'raw',
                plugins: [
                  'advlist lists link image preview',
                  'searchreplace visualblocks',
                  'insertdatetime paste lplink lplistitem',
                ],
                toolbar:
                  // eslint-disable-next-line no-multi-str
                  'undo redo | formatselect | bold italic backcolor | \
                    bullist numlist | lplink lplistitem link image',
                extended_valid_elements: 'lp-taxonomy-link[data-id|data-fragment]',
                custom_elements: '~lp-taxonomy-link',
                setup: editorSetupFunction,
                content_css: '/editor.css',
              }}
              onEditorChange={setActiveArticle}
            />
          )}
          <div className="flex items-center mt-4">
            <input
              id="published"
              name="published"
              type="checkbox"
              className="lp-input-checkbox"
              checked={activePublished}
              onChange={(e) => setActivePublished(e.target.checked)}
            />
            <label className="font-sm ml-2" htmlFor="published">
              Published
            </label>
          </div>
          <button
            onClick={() => handleDescriptionChange()}
            disabled={changeStatus.loading || addStatus.loading}
            className="lp-button mt-4"
          >
            Save
          </button>
          <h2 className="text-2xl my-8">Information strike</h2>
          {specieslistdata && (
            <Editor
              apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
              key={`editor2_${editorLangKey}`}
              value={activeInformationStrike ?? ''}
              init={{
                height: 700,
                menubar: true,
                entity_encoding: 'raw',
                plugins: [
                  'advlist lists link image preview',
                  'searchreplace visualblocks',
                  'insertdatetime paste lplink lplistitem',
                ],
                toolbar:
                  // eslint-disable-next-line no-multi-str
                  'undo redo | formatselect | bold italic backcolor | \
                    bullist numlist | lplink lplistitem link image',
                extended_valid_elements: 'lp-taxonomy-link[data-id|data-fragment]',
                custom_elements: '~lp-taxonomy-link',
                setup: editorSetupFunction,
                content_css: '/editor.css',
              }}
              onEditorChange={setActiveInformationStrike}
            />
          )}
          <button
            onClick={() => handleDescriptionChange()}
            disabled={changeStatus.loading || addStatus.loading}
            className="lp-button mt-4"
          >
            Save
          </button>
        </div>
        <div className="preview">
          <h2 className="text-2xl my-8">Preview</h2>
          <div className="previewcontainer" dangerouslySetInnerHTML={createMarkup(activeArticle)}></div>
          <h2 className="text-2xl my-8">Information strike preview</h2>
          <div className="previewcontainer" dangerouslySetInnerHTML={createMarkup(activeInformationStrike)}></div>
        </div>
      </div>
    </>
  );
};
