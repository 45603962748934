import { FC, useEffect, useState } from 'react';
import Table from 'rc-table';
import {
  useApiAllVariationsQuery,
  useApiAddVariationMutation,
  useApiChangeVariationMutation,
  useApiDeleteVariationMutation,
} from '../../api/generated/graphql';
import { ColumnsType } from 'rc-table/lib/interface';
import { toast } from 'react-toastify';
import { withFallbackName } from '../../utils';
import { SlideOver } from '../SlideOver';
import { NamesEdit } from '../NamesEdit';
import { CharacteristicSectionSelect } from './CharacteristicSectionSelect';
import { LanguagesPreview } from '../LanguagesPreview';

interface Props {}

interface TableRow {
  key: string;
  id: number;
  name: string;
  names: Record<string, string>;
  sectionId: number;
  sectionName: string;
  show: boolean;
}

interface NewVariation {
  id: number | undefined;
  names: Record<string, string>;
  sectionId: number | undefined;
  show: boolean;
}

export const Variations: FC<Props> = () => {
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [newVariation, setNewVariation] = useState<NewVariation>({
    id: undefined,
    names: {},
    sectionId: undefined,
    show: true,
  });
  const { data, loading, refetch } = useApiAllVariationsQuery();

  const [add, addStatus] = useApiAddVariationMutation();

  const [change, changeStatus] = useApiChangeVariationMutation();

  const [delete_, deleteStatus] = useApiDeleteVariationMutation();

  const tableData: TableRow[] =
    data?.variation.map((y) => {
      return {
        key: y.id.toString(),
        id: y.id,
        name: withFallbackName(y),
        names: y.names,
        sectionId: y.characteristic_section_id,
        sectionName: withFallbackName(y.characteristic_section),
        show: y.show,
      };
    }) ?? [];

  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns: ColumnsType<TableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 30,
    },
    {
      title: 'Section',
      dataIndex: 'sectionName',
      key: 'sectionName',
      width: 200,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Name translations',
      dataIndex: '',
      key: 'nameTranslations',
      width: 200,
      render: (_value, row, _index) => <LanguagesPreview translations={row.names} />,
    },
    {
      title: 'Show',
      dataIndex: 'show',
      key: 'show',
      width: 20,
      render: (value) =>
        value ? <span className="text-lp-green">yes</span> : <span className="text-red-500">no</span>,
    },
    {
      title: 'Operations',
      dataIndex: '',
      key: 'operations',
      render: (value, row, index) => (
        <button
          onClick={() => {
            setNewVariation({ ...row });
            setSlideOverOpen(true);
          }}
          className="lp-button-link"
        >
          Edit
        </button>
      ),
    },
  ];

  const handleCloseSlideOver = () => {
    setSlideOverOpen(false);
    setNewVariation({
      id: undefined,
      names: {},
      sectionId: undefined,
      show: true,
    });
  };

  const handleAdd = async () => {
    if (newVariation.sectionId === undefined) return;
    try {
      await add({
        variables: { names: newVariation.names, sectionId: newVariation.sectionId, show: newVariation.show },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Add successful!');
    } catch {
      toast.error('Add failed!');
    }
  };

  const handleUpdate = async () => {
    if (newVariation.id === undefined || newVariation.sectionId === undefined) return;
    try {
      await change({
        variables: {
          id: newVariation.id,
          names: newVariation.names,
          sectionId: newVariation.sectionId,
          show: newVariation.show,
        },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Save successful!');
    } catch {
      toast.error('Save failed!');
    }
  };

  const handleDelete = async () => {
    if (newVariation.id === undefined) {
      handleCloseSlideOver();
    } else {
      if (!window.confirm('Delete variation?')) return;
      try {
        await delete_({ variables: { id: newVariation.id } });
        await refetch();
        handleCloseSlideOver();
        toast.success('Delete successful!');
      } catch {
        toast.error('Delete failed!');
      }
    }
  };

  return (
    <>
      <button
        className="lp-button mb-4"
        type="button"
        disabled={addStatus.loading}
        onClick={() => setSlideOverOpen(true)}
      >
        Add new variation
      </button>

      {data && !loading && tableData.length > 0 && (
        <div className="lp-table-container">
          <Table className="lp-table" columns={columns} data={tableData} />
        </div>
      )}

      <SlideOver
        show={slideOverOpen}
        title={newVariation.id === undefined ? 'New variation' : 'Edit variation'}
        saving={addStatus.loading || changeStatus.loading}
        deleting={deleteStatus.loading}
        onCancelClick={handleCloseSlideOver}
        onDeleteClick={handleDelete}
        onSaveClick={() => {
          if (newVariation.id === undefined) {
            handleAdd();
          } else {
            handleUpdate();
          }
        }}
      >
        <div className="space-y-6 pt-6 pb-5">
          <div>
            <h3>General</h3>
            <label className="lp-input-text-label" htmlFor="section">
              Section *
            </label>
            <CharacteristicSectionSelect
              inputId="section"
              value={newVariation.sectionId}
              onChange={(value) => setNewVariation({ ...newVariation, sectionId: value })}
            />
          </div>
          <div className="flex items-center">
            <input
              id="show"
              name="show"
              type="checkbox"
              className="lp-input-checkbox"
              checked={newVariation.show}
              onChange={(e) => setNewVariation({ ...newVariation, show: e.target.checked })}
            />
            <label className="font-sm ml-2" htmlFor="show">
              Show
            </label>
          </div>
          <NamesEdit value={newVariation.names} onChange={(names) => setNewVariation({ ...newVariation, names })} />
        </div>
      </SlideOver>
    </>
  );
};
