import { sortBy } from 'lodash';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useApiAllImgProxyPresetsQuery, useApiSingleImageWithPresetsQuery } from '../api/generated/graphql';

function ImagePreview() {
  const params = useParams<{ imageId: string }>();
  const presetsQuery = useApiAllImgProxyPresetsQuery();
  const image = useApiSingleImageWithPresetsQuery({
    variables: { id: params.imageId, presets: `{${presetsQuery.data?.imgproxy_preset.map((x) => x.name).join(',')}}` },
    skip: presetsQuery.loading || !presetsQuery.data,
  });
  const urls = sortBy(image.data?.image_by_pk?.urls ?? [], (x) => x.preset);
  return (
    <div className="space-y-12">
      <h1>{image.data?.image_by_pk?.filename ?? ''}</h1>
      {urls.map((x) => (
        <div key={x.preset}>
          <h2>{x.preset}</h2>
          <button
            className="lp-button -default mr-2 mb-2"
            onClick={() => {
              navigator.clipboard.writeText(x.url);
            }}
          >
            copy link
          </button>
          {x.url}
          <img src={x.url} alt={x.preset} loading="lazy" />
        </div>
      ))}
    </div>
  );
}

export default ImagePreview;
