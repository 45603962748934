import { FC, useEffect, useState } from 'react';
import {
  useApiChangeTaxonomyMutation,
  ApiSingleTaxonomyDocument,
  ApiSingleTaxonomyQuery,
} from '../api/generated/graphql';
import { CharacteristicSectionSelectMulti } from './Identification/CharacteristicSectionSelectMulti';
import { toast } from 'react-toastify';
import { TaxonomyAudioSelect } from './TaxonomyAudioSelect';
import { withFallbackName } from '../utils';
import { RankSelect } from './RankSelect';
import { WarningBox, WarningTitle, WarningBody } from '../components/WarningBox';
import { Loader } from 'react-feather';

interface Props {
  data?: ApiSingleTaxonomyQuery;
}

interface Taxonomy {
  id: number;
  rankId: string;
  published: boolean;
  identificationPublished: boolean;
  characteristicSections: Array<{ id: number; showInListing: boolean; name: string }>;
  audioId: string | undefined;
}

export const General: FC<Props> = ({ data }) => {
  const [taxonomy, setTaxonomy] = useState<Taxonomy | undefined>(undefined);

  const [change, changeStatus] = useApiChangeTaxonomyMutation();

  useEffect(() => {
    const taxa = data?.taxonomy_by_pk;
    if (taxonomy === undefined && taxa) {
      setTaxonomy({
        id: taxa.id,
        rankId: taxa.rank_id,
        published: taxa.published,
        identificationPublished: taxa.identification_published,
        characteristicSections: taxa.taxonomy_characteristic_sections.map((x) => ({
          id: x.characteristic_section_id,
          name: withFallbackName(x.characteristic_section),
          showInListing: x.show_in_section_listing,
        })),
        audioId: taxa.taxonomy_audios[0]?.audio_id,
      });
    }
  }, [data?.taxonomy_by_pk, taxonomy]);

  const handleSave = async () => {
    if (!taxonomy) return;
    try {
      await change({
        variables: {
          id: taxonomy.id,
          published: taxonomy.published,
          identificationPublished: taxonomy.identificationPublished,
          characteristicSections: taxonomy.characteristicSections.map((x) => ({
            taxonomy_id: taxonomy.id,
            characteristic_section_id: x.id,
            show_in_section_listing: x.showInListing,
          })),
          audioIds: taxonomy.audioId ? [{ audio_id: taxonomy.audioId, taxonomy_id: taxonomy.id }] : [],
          rankId: taxonomy.rankId,
        },
        refetchQueries: [{ query: ApiSingleTaxonomyDocument, variables: { id: taxonomy.id } }],
      });
      toast.success('Save successful!');
    } catch {
      toast.error('Save failed!');
    }
  };

  let content: JSX.Element;
  if (taxonomy) {
    content = (
      <div className="w-full md:max-w-sm space-y-4">
        <div>
          <label className="lp-input-text-label" htmlFor="rank">
            Rank
          </label>
          <RankSelect
            value={taxonomy.rankId}
            onChange={(rankId) => setTaxonomy({ ...taxonomy, rankId: rankId! })}
            isClearable={false}
            inputId="rank"
          />
          {taxonomy?.rankId !== data?.taxonomy_by_pk?.rank_id && (
            <WarningBox>
              <WarningTitle>Changes to taxonomy rank can cause the tree to be invalid</WarningTitle>
              <WarningBody>
                <strong>For example:</strong> when changing rank <em>family</em> to <em>species</em>, the taxonomy
                should not have children which are the same or higher rank as <em>species</em>. If this happens it can
                be resolved by moving around the children to another taxonomy or by modifying the rank of all
                descendants.
              </WarningBody>
            </WarningBox>
          )}
        </div>

        <CharacteristicSectionSelectMulti
          values={taxonomy.characteristicSections}
          onChange={(sections) => setTaxonomy({ ...taxonomy, characteristicSections: sections })}
        />

        <div className="flex items-center">
          <input
            id="published"
            name="published"
            type="checkbox"
            className="lp-input-checkbox"
            checked={taxonomy.published}
            onChange={(e) => setTaxonomy({ ...taxonomy, published: e.target.checked })}
          />
          <label className="font-sm ml-2" htmlFor="published">
            Published
          </label>
        </div>

        <div className="flex items-center">
          <input
            id="identificationPublished"
            name="identificationPublished"
            type="checkbox"
            className="lp-input-checkbox"
            checked={taxonomy.identificationPublished}
            onChange={(e) => setTaxonomy({ ...taxonomy, identificationPublished: e.target.checked })}
          />
          <label className="font-sm ml-2" htmlFor="identificationPublished">
            Identification published
          </label>
        </div>

        <div>
          <label className="lp-input-text-label" htmlFor="audio">
            Audio
          </label>
          <TaxonomyAudioSelect
            value={taxonomy.audioId}
            onChange={(audioId) => setTaxonomy({ ...taxonomy, audioId })}
            inputId="audio"
          />
        </div>

        <button type="button" disabled={changeStatus.loading} className="lp-button flex ml-auto" onClick={handleSave}>
          Save
        </button>
      </div>
    );
  } else {
    content = <Loader className="animate-spin" />;
  }
  return content;
};
