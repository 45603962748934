import { FC, useState } from 'react';
import Select from 'react-select';
import { useApiFindImageQuery, useApiSingleImageQuery } from '../api/generated/graphql';

interface Props {
  value: string | undefined;
  onChange: (imageId: string | undefined) => void;
  inputId: string;
}

export const ImageSelect: FC<Props> = ({ value, onChange, inputId }: Props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { data, loading } = useApiFindImageQuery({
    variables: { searchTerm: `%${searchTerm}%` },
    skip: searchTerm.length === 0,
    fetchPolicy: 'cache-and-network',
  });

  const selectedQuery = useApiSingleImageQuery({ variables: { id: value! }, skip: value === undefined });
  const selectedImage = selectedQuery.data?.image_by_pk;
  const selectedOption: { value: string; label: string; image: string } | undefined = selectedImage
    ? { value: selectedImage.id, label: selectedImage.filename ?? selectedImage.id, image: selectedImage.urls![0].url }
    : undefined;
  const options = data?.image.map((x) => ({ label: x.filename ?? x.id, value: x.id, image: x.urls![0].url })) ?? [];

  return (
    <Select
      inputId={inputId}
      isSearchable={true}
      inputValue={searchTerm}
      onInputChange={(x) => setSearchTerm(x)}
      value={selectedOption}
      options={[...options, ...(selectedOption ? [selectedOption] : [])]}
      isLoading={loading || selectedQuery.loading}
      isClearable={true}
      onChange={(dropdownValue) => {
        onChange(dropdownValue?.value ?? undefined);
      }}
      formatOptionLabel={(x) => (
        <div className="flex flex-row">
          <img src={x.image} alt="" className="h-6 w-6 mr-2" />
          {x.label}
        </div>
      )}
    />
  );
};
