import { FC } from 'react';
import { ApiSingleTaxonomyQuery } from '../api/generated/graphql';
import { classNames } from '../utils';
import { TLink } from './TLink';

interface Props {
  taxonomies: NonNullable<ApiSingleTaxonomyQuery['taxonomy_by_pk']>['ancestors'];
  selectedTaxonomyId: number | undefined;
  className?: string;
}

const INDENT_SIZE = 12;

export const TaxonomyAncestorsTree: FC<Props> = ({ className, taxonomies, selectedTaxonomyId }) => {
  return (
    <div className={classNames({ 'lp-taxonomy-tree': true, [className ?? '']: className !== undefined })}>
      <div className="rows">
        {taxonomies?.map((x, index) => (
          <div
            key={x.id}
            className={classNames({
              row: true,
              '-selected': x.id === selectedTaxonomyId,
              '-first': index === 0,
              '-last': index === (taxonomies?.length ?? 0) - 1,
            })}
          >
            <div style={{ minWidth: `${(index + 1) * INDENT_SIZE}px` }} className="left" />
            <div className="right">
              <h4 className="ranktitle">{x.rank.name}</h4>
              <TLink className="link" to={`taxonomy/${x.id}`}>
                {x.common_name ? `${x.common_name} - ${x.scientific_name}` : x.scientific_name}
              </TLink>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
