import { toast } from 'react-toastify';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  ApiSingleTaxonomyDocument,
  ApiSingleTaxonomyQuery,
  useApiChangeTaxonomyCommonNamesMutation,
} from '../api/generated/graphql';
import { LanguageTabs } from './LanguageTabs';
import { ReactComponent as Delete } from '../assets/delete.svg';
import { ColumnsType } from 'rc-table/lib/interface';
import Table from 'rc-table';

interface Props {
  id: number;
  data?: ApiSingleTaxonomyQuery;
}

interface TableRow {
  key: string;
  language: string;
  name: string;
  preferred: boolean;
}

export const Names: FC<Props> = ({ id, data }) => {
  const [change, changeStatus] = useApiChangeTaxonomyCommonNamesMutation();
  const [activeLanguage, setActiveLanguage] = useState<string>('');
  const [tableData, setTableData] = useState<TableRow[]>([]);

  useEffect(() => {
    const result = data?.taxonomy_by_pk?.taxonomy_common_names.filter((x) => x.language === activeLanguage);
    setTableData(result?.map((x, index) => ({ key: index.toString(), ...x })) ?? []);
  }, [data, activeLanguage]);

  const hasPreferred = tableData.length === 0 || tableData.some((x) => x.preferred);
  const hasEmptyNames = tableData.some((x) => x.name.length === 0);
  const hasDuplicates = new Set(tableData.map((x) => x.name)).size === tableData.length;

  const handleSave = useCallback(async () => {
    try {
      await change({
        variables: {
          id,
          language: activeLanguage,
          values: tableData.map(({ name, preferred }) => ({
            language: activeLanguage,
            name,
            taxonomy_id: id,
            preferred,
          })),
        },
        refetchQueries: [{ query: ApiSingleTaxonomyDocument, variables: { id: id } }],
      });
      toast.success('Update success!');
    } catch {
      toast.error('Update failed!');
    }
  }, [id, change, activeLanguage, tableData]);

  const columns: ColumnsType<TableRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 350,
      render: (value, row, index) => (
        <input
          id={`common-name-${index}`}
          type="text"
          className="lp-input-text mt-1"
          value={value}
          onChange={(e) => {
            setTableData(
              tableData?.map((x, thisIndex) => (index === thisIndex ? { ...x, name: e.target.value } : x)) ?? []
            );
          }}
        />
      ),
    },
    {
      title: 'Preferred',
      dataIndex: 'preferred',
      key: 'preferred',
      width: 50,
      render: (value, row, index) => (
        <label className="p-4 block">
          <input
            name="preferred"
            type="radio"
            className="lp-input-radio"
            checked={value}
            onChange={(e) =>
              setTableData(
                tableData?.map((x, thisIndex) =>
                  index === thisIndex ? { ...x, preferred: e.target.checked } : { ...x, preferred: false }
                )
              )
            }
          />
        </label>
      ),
    },
    {
      title: 'Operations',
      width: 50,
      key: 'operations',
      render: (_value, _row, index) => (
        <button
          type="button"
          className="h-8 w-8 flex items-center"
          onClick={() => {
            const newTableData = tableData?.slice() ?? [];
            newTableData.splice(index, 1);
            setTableData(newTableData);
          }}
        >
          <Delete />
        </button>
      ),
    },
  ];

  return (
    <div className="space-y-4">
      <LanguageTabs current={activeLanguage} onChange={setActiveLanguage} />
      <button
        className="lp-button mb-4"
        type="button"
        disabled={false}
        onClick={() => {
          const data = tableData ?? [];
          setTableData([
            ...data,
            {
              key: data.length.toString(),
              language: activeLanguage,
              name: '',
              preferred: data.every((x) => !x.preferred),
            },
          ]);
        }}
      >
        Add new common name
      </button>
      <Table className="lp-table" data={tableData ?? []} columns={columns} />

      <div>
        {!hasPreferred && <span className="text-red-500 text-sm">Select one name to be preferred. </span>}
        {hasEmptyNames && <span className="text-red-500 text-sm">Fill all names. </span>}
        {!hasDuplicates && <span className="text-red-500 text-sm">Remove duplicate names. </span>}
      </div>

      <button
        type="button"
        disabled={changeStatus.loading || !hasPreferred || hasEmptyNames || !hasDuplicates}
        className="lp-button flex ml-auto"
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};
