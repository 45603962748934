import { FC } from 'react';
import { fungusEdibilityToString, isTaxonomyWidget, TaxonomyWidgetUnknown } from './model';

interface Props {
  widget: TaxonomyWidgetUnknown;
}

export const WidgetPreview: FC<Props> = ({ widget }) => {
  if (isTaxonomyWidget(widget)) {
    switch (widget.widget_type_id) {
      case 'fish_length':
        return <div>{widget.widget_data}</div>;
      case 'fungus_coloring':
        return <div>{widget.widget_data ? 'yes' : 'no'}</div>;
      case 'fungus_edibility':
        return (
          <div>
            {fungusEdibilityToString(widget.widget_data)} ({widget.widget_data})
          </div>
        );
      case 'link_gbif':
        return <div>{widget.widget_data}</div>;
      case 'link_kalahavainnot':
        return <div>{widget.widget_data}</div>;
      case 'link_kasviatlas':
        return <div>{widget.widget_data}</div>;
      case 'link_lintuatlas':
        return (
          <div>
            <div>FI: {widget.widget_data?.fi ?? 'n/a'}</div>
            <div>EN: {widget.widget_data?.en ?? 'n/a'}</div>
          </div>
        );
    }
  } else {
    return <div>Unsupported widget type</div>;
  }
};
