import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { DashboardLink } from './components/DashboardLink';
import { Identification } from './pages/Identification';
import { ContentPages } from './pages/ContentPages';
import { Profile } from './pages/Profile';
import { Taxonomy } from './pages/Taxonomy';
import { TaxonomyTree } from './pages/TaxonomyTree';
import { ImageManagement } from './pages/ImageManagement';
import { Feedback } from './pages/Feedback';
import { Home } from './pages/Home';
import ImagePreview from './pages/ImagePreview';

export function NavLinks() {
  return (
    <>
      <DashboardLink href="" title="Home" />
      <DashboardLink href="taxonomy/list" title="Taxonomy" />
      <DashboardLink href="identification/sections" title="Identification" />
      <DashboardLink href="contentpages" title="Content Pages" />
      <DashboardLink href="imagemanagement/imagelist" title="Images" />
      <DashboardLink href="feedback" title="Feedback" />
    </>
  );
}

export function LocalizedRoutes() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Home />
      </Route>
      <Route exact path={[`${path}/taxonomy/tree`, `${path}/taxonomy/list`]}>
        <TaxonomyTree />
      </Route>
      <Route exact path={[`${path}/taxonomy/:id`, `${path}/taxonomy/:id/*`]}>
        <Taxonomy />
      </Route>
      <Route exact path={`${path}/profile`}>
        <Profile />
      </Route>
      <Route exact path={[`${path}/identification`, `${path}/identification/*`]}>
        <Identification />
      </Route>
      <Route exact path={[`${path}/contentpages`, `${path}/contentpages/*`]}>
        <ContentPages />
      </Route>
      <Route exact path={[`${path}/imagemanagement`, `${path}/imagemanagement/*`]}>
        <ImageManagement />
      </Route>
      <Route exact path={[`${path}/feedback`, `${path}/feedback/*`]}>
        <Feedback />
      </Route>
      <Route exact path={`${path}/imagepreview/:imageId`}>
        <ImagePreview />
      </Route>
      <Route exact path={`${path}/404`}>
        <div>Not Found</div>
      </Route>
      <Redirect to={`${path}/404`} />
    </Switch>
  );
}
