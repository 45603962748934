import { FC, useState } from 'react';
import Select from 'react-select';
import { useApiAllTaxonomyQuery } from '../api/generated/graphql';

interface Props {
  value: number | undefined;
  onChange: (taxonomy: { id: number; path: string } | undefined) => void;
  inputId: string;
  isClearable?: boolean;
}
export const TaxonomySelect: FC<Props> = ({ value, onChange, inputId, isClearable }) => {
  const { data, loading } = useApiAllTaxonomyQuery();

  const [searchTerm, setSearchTerm] = useState('');

  const options =
    data?.taxonomy
      .map((x) => ({ label: `${x.id}: ${x.scientific_name} - ${x.common_name ?? ''}`, value: x.id }))
      ?.filter((x) => x.label.toLowerCase().includes(searchTerm.toLowerCase()))
      ?.slice(0, 50) ?? [];

  return (
    <Select
      inputId={inputId}
      isSearchable={true}
      isClearable={isClearable}
      inputValue={searchTerm}
      onInputChange={(e) => setSearchTerm(e)}
      value={options?.find((x) => x.value === value)}
      options={options}
      isLoading={loading}
      onChange={(dropdownValue) => {
        const selected = data?.taxonomy.find((y) => y.id === dropdownValue?.value);
        onChange(selected);
      }}
    />
  );
};
