import { FC } from 'react';
import Select from 'react-select';
import { fungusEdibilityToString, isTaxonomyWidget, TaxonomyWidgetUnknown } from './model';

interface Props {
  widget: TaxonomyWidgetUnknown;
  onChange: (data: unknown) => void;
}

export const PreviewLink: FC<{ href: string }> = ({ href }) => (
  <a href={href} className="text-sm text-lp-blue" target="_blank" rel="noreferrer">
    {href}
  </a>
);

export const WidgetDataEdit: FC<Props> = ({ widget, onChange }) => {
  if (isTaxonomyWidget(widget)) {
    switch (widget.widget_type_id) {
      case 'fish_length':
        return (
          <div>
            <label className="lp-input-text-label" htmlFor="fish_length_id">
              Length (1-5)
            </label>
            <input
              type="range"
              min={1}
              max={5}
              id="fish_length_id"
              value={widget.widget_data}
              className="lp-input-text w-full mb-2"
              onChange={(e) => onChange(parseInt(e.target.value))}
            />
          </div>
        );
      case 'fungus_coloring':
        return (
          <div className="flex items-center">
            <input
              id="fungus_coloring"
              name="fungus_coloring"
              type="checkbox"
              className="lp-input-checkbox"
              checked={widget.widget_data}
              onChange={(e) => onChange(e.target.checked)}
            />
            <label className="font-sm ml-2" htmlFor="fungus_coloring">
              Has coloring
            </label>
          </div>
        );
      case 'fungus_edibility':
        return (
          <div>
            <label className="lp-input-text-label" htmlFor="fungus_edibility">
              Edibility
            </label>
            <Select
              inputId="fungus_edibility"
              options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((x) => ({
                label: `${fungusEdibilityToString(x)} (${x})`,
                value: x,
              }))}
              className="text-sm max-w-sm"
              value={
                widget.widget_data !== undefined
                  ? {
                      label: `${fungusEdibilityToString(widget.widget_data)} (${widget.widget_data})`,
                      value: widget.widget_data,
                    }
                  : undefined
              }
              onChange={(e) => e && onChange(e.value)}
            />
          </div>
        );
      case 'link_gbif':
        return (
          <div>
            <label className="lp-input-text-label" htmlFor="gbif_id">
              ID
            </label>
            <input
              type="text"
              id="gbif_id"
              value={widget.widget_data ?? ''}
              className="lp-input-text w-full mb-2"
              onChange={(e) => (e.target.value.length > 0 ? onChange(e.target.value) : onChange(undefined))}
            />
            Link preview:{' '}
            {widget.widget_data?.length ? (
              <PreviewLink href={`https://www.gbif.org/species/${widget.widget_data ?? ''}`} />
            ) : (
              'n/a'
            )}
          </div>
        );
      case 'link_kalahavainnot':
        return (
          <div>
            <label className="lp-input-text-label" htmlFor="kalahavainnot_id">
              ID
            </label>
            <input
              type="text"
              id="kalahavainnot_id"
              value={widget.widget_data ?? ''}
              className="lp-input-text w-full mb-2"
              onChange={(e) => (e.target.value.length > 0 ? onChange(e.target.value) : onChange(undefined))}
            />
            Link preview:{' '}
            {widget.widget_data?.length ? (
              <PreviewLink href={`https://kalahavainnot.luke.fi/kartta?speciesId=${widget.widget_data}`} />
            ) : (
              'n/a'
            )}
          </div>
        );
      case 'link_kasviatlas':
        return (
          <div>
            <label className="lp-input-text-label" htmlFor="kasviatlas_id">
              ID
            </label>
            <input
              type="text"
              id="kasviatlas_id"
              value={widget.widget_data ?? ''}
              className="lp-input-text w-full mb-2"
              onChange={(e) => (e.target.value.length > 0 ? onChange(e.target.value) : onChange(undefined))}
            />
            Link preview:{' '}
            {widget.widget_data?.length ? (
              <PreviewLink href={`https://kasviatlas.fi/kartat/?year=2020&taxon=${widget.widget_data}`} />
            ) : (
              'n/a'
            )}
          </div>
        );
      case 'link_lintuatlas':
        return (
          <div>
            <div>
              <label className="lp-input-text-label" htmlFor="fi">
                FI
              </label>
              <input
                type="text"
                id="fi"
                value={widget.widget_data?.fi ?? ''}
                className="lp-input-text w-full mb-2"
                onChange={(e) =>
                  onChange({ ...widget.widget_data, fi: e.target.value.length === 0 ? undefined : e.target.value })
                }
              />
              Link preview:{' '}
              {widget.widget_data?.fi?.length ? (
                <PreviewLink href={`http://atlas3.lintuatlas.fi/tulokset/laji/${widget.widget_data['fi'] ?? ''}`} />
              ) : (
                'n/a'
              )}
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="en">
                EN
              </label>
              <input
                type="text"
                id="en"
                value={widget.widget_data?.en ?? ''}
                className="lp-input-text w-full mb-2"
                onChange={(e) =>
                  onChange({ ...widget.widget_data, en: e.target.value.length === 0 ? undefined : e.target.value })
                }
              />
              Link preview:{' '}
              {widget.widget_data?.en?.length ? (
                <PreviewLink href={`http://atlas3.lintuatlas.fi/results/species/${widget.widget_data['en'] ?? ''}`} />
              ) : (
                'n/a'
              )}
            </div>
          </div>
        );
    }
  } else {
    return <div>Unsupported widget type</div>;
  }
};
