import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps } from 'react-router-dom';

export function TLink(props: LinkProps) {
  const { children, to } = props;
  const { i18n } = useTranslation();
  const target = to ? `/${i18n.language}/${to}` : `/${i18n.language}`;
  return (
    <Link {...props} to={target}>
      {children}
    </Link>
  );
}
