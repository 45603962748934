import { ReactNode, useEffect } from 'react';
import { Transition } from '@headlessui/react';
import { ReactComponent as Close } from '../assets/close.svg';
import { useKeyPress } from '../utils';

interface Props {
  show: boolean;
  title: string;
  description?: string;
  children?: ReactNode;
  saving: boolean;
  deleting?: boolean;
  hideDeleteButton?: boolean;
  containerClass?: string;
  onSaveClick: () => void;
  onDeleteClick?: () => void;
  onCancelClick: () => void;
}

export function SlideOver({
  show,
  title,
  description,
  children,
  saving,
  deleting,
  hideDeleteButton,
  containerClass,
  onSaveClick,
  onDeleteClick,
  onCancelClick,
}: Props) {
  const escapePressed = useKeyPress('Escape');

  useEffect(() => {
    if (escapePressed) {
      onCancelClick();
    }
  }, [escapePressed, onCancelClick]);

  return (
    <Transition
      show={show}
      className="fixed inset-0 overflow-hidden"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="absolute inset-0 overflow-hidden">
        <Transition.Child
          className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          aria-hidden="true"
        ></Transition.Child>

        <div className="absolute inset-y-0 pl-16 max-w-full right-0 flex">
          <Transition.Child
            className={`relative w-screen max-w-md ${containerClass}`}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Transition.Child
              className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
              enter="ease-in-out duration-500"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in-out duration-500"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <button
                className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={onCancelClick}
              >
                <span className="sr-only">Close panel</span>
                <Close className="w-6 h-6" />
              </button>
            </Transition.Child>
            <div className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
              <div className="flex-1 h-0 overflow-y-auto">
                <div className="py-6 px-4 bg-indigo-700 sm:px-6">
                  <div className="flex items-center justify-between">
                    <h2 className="text-lg font-medium text-white" id="slide-over-title">
                      {title}
                    </h2>
                  </div>
                  <div className="mt-1">
                    <p className="text-sm text-indigo-300">{description}</p>
                  </div>
                </div>
                <div className="flex-1 flex flex-col justify-between">
                  <div className="px-4 divide-y divide-gray-200 sm:px-6">{children}</div>
                </div>
              </div>
              <div className="flex-shrink-0 px-4 py-4 flex justify-end">
                {!hideDeleteButton && (
                  <button className="mr-auto ml-4 lp-button -danger" onClick={onDeleteClick} disabled={deleting}>
                    Delete
                  </button>
                )}
                <button type="button" className="lp-button -default" onClick={onCancelClick}>
                  Cancel
                </button>
                <button type="submit" className="ml-4 lp-button" onClick={onSaveClick} disabled={saving}>
                  Save
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition>
  );
}
