import { FC, useEffect, useState } from 'react';
import Table from 'rc-table';
import {
  useApiAllCharacteristicSectionsQuery,
  useApiAddCharacteristicSectionMutation,
  useApiChangeCharacteristicSectionMutation,
  useApiDeleteCharacteristicSectionMutation,
} from '../../api/generated/graphql';
import { ColumnsType } from 'rc-table/lib/interface';
import { toast } from 'react-toastify';
import { withFallbackName } from '../../utils';
import { SlideOver } from '../SlideOver';
import { ImageSelect } from '../ImageSelect';
import { LanguageTabs } from '../LanguageTabs';
import { useTranslation } from 'react-i18next';
import { LanguagesPreview } from '../LanguagesPreview';

interface Props {}

interface TableRow {
  key: string;
  id: number;
  name: string;
  names: Record<string, string>;
  descriptions: Record<string, string>;
  metadataDescriptions: Record<string, string>;
  urlSlugs: Record<string, string>;
  enabledLanguages: string[];
  identificationEnabledLanguages: string[];
  iconId: string | undefined;
  imageId: string | undefined;
  ordernum: number;
}

interface NewSection {
  id: number | undefined;
  names: Record<string, string>;
  descriptions: Record<string, string>;
  metadataDescriptions: Record<string, string>;
  urlSlugs: Record<string, string>;
  enabledLanguages: string[];
  identificationEnabledLanguages: string[];
  iconId: string | undefined;
  imageId: string | undefined;
  ordernum: number;
}

export const CharacteristicSections: FC<Props> = () => {
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const { i18n } = useTranslation();

  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [newSection, setNewSection] = useState<NewSection>({
    id: undefined,
    names: {},
    descriptions: {},
    metadataDescriptions: {},
    urlSlugs: {},
    enabledLanguages: [],
    identificationEnabledLanguages: [],
    iconId: undefined,
    imageId: undefined,
    ordernum: 9999,
  });
  const { data, loading, refetch } = useApiAllCharacteristicSectionsQuery();

  const [add, addStatus] = useApiAddCharacteristicSectionMutation();

  const [change, changeStatus] = useApiChangeCharacteristicSectionMutation();

  const [delete_, deleteStatus] = useApiDeleteCharacteristicSectionMutation();

  const tableData: TableRow[] =
    data?.characteristic_section.map((y) => {
      return {
        key: y.id.toString(),
        id: y.id,
        name: withFallbackName(y),
        names: y.names,
        descriptions: y.descriptions,
        metadataDescriptions: y.metadata_descriptions,
        urlSlugs: y.url_slugs,
        enabledLanguages: y.enabled_languages,
        identificationEnabledLanguages: y.identification_enabled_languages,
        iconId: y.icon_id ?? undefined,
        imageId: y.image_id ?? undefined,
        ordernum: y.ordernum,
      };
    }) ?? [];

  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns: ColumnsType<TableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 30,
    },
    {
      title: 'Order',
      dataIndex: 'ordernum',
      key: 'order',
      width: 30,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Name translations',
      dataIndex: '',
      key: 'nameTranslations',
      width: 200,
      render: (_value, row, _index) => <LanguagesPreview translations={row.names} />,
    },
    {
      title: 'Published for languages',
      dataIndex: 'enabledLanguages',
      key: 'enabledLanguages',
      width: 250,
      render: (value, row) => value.join(', '),
    },
    {
      title: 'Identification published for languages',
      dataIndex: 'identificationEnabledLanguages',
      key: 'identificationEnabledLanguages',
      width: 250,
      render: (value, row) => value.join(', '),
    },
    {
      title: 'Operations',
      dataIndex: '',
      key: 'operations',
      render: (value, row, index) => (
        <button
          onClick={() => {
            setNewSection({ ...row });
            setSlideOverOpen(true);
          }}
          className="lp-button-link"
        >
          Edit
        </button>
      ),
    },
  ];

  const handleCloseSlideOver = () => {
    setSlideOverOpen(false);
    setNewSection({
      id: undefined,
      names: {},
      descriptions: {},
      metadataDescriptions: {},
      urlSlugs: {},
      enabledLanguages: [],
      identificationEnabledLanguages: [],
      iconId: undefined,
      imageId: undefined,
      ordernum: 9999,
    });
  };

  const handleAdd = async () => {
    try {
      await add({
        variables: {
          names: newSection.names,
          descriptions: newSection.descriptions,
          metadataDescriptions: newSection.metadataDescriptions,
          urlSlugs: newSection.urlSlugs,
          enabledLanguages: newSection.enabledLanguages,
          identificationEnabledLanguages: newSection.identificationEnabledLanguages,
          imageId: newSection.imageId,
          iconId: newSection.iconId,
          ordernum: newSection.ordernum,
        },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Add successful!');
    } catch {
      toast.error('Add failed!');
    }
  };

  const handleUpdate = async () => {
    if (newSection.id === undefined) return;
    try {
      await change({
        variables: {
          id: newSection.id,
          names: newSection.names,
          descriptions: newSection.descriptions,
          metadataDescriptions: newSection.metadataDescriptions,
          urlSlugs: newSection.urlSlugs,
          enabledLanguages: newSection.enabledLanguages,
          identificationEnabledLanguages: newSection.identificationEnabledLanguages,
          imageId: newSection.imageId,
          iconId: newSection.iconId,
          ordernum: newSection.ordernum,
        },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Save successful!');
    } catch {
      toast.error('Save failed!');
    }
  };

  const handleDelete = async () => {
    if (newSection.id === undefined) {
      handleCloseSlideOver();
    } else {
      if (!window.confirm('Delete section?')) return;
      try {
        await delete_({ variables: { id: newSection.id } });
        await refetch();
        handleCloseSlideOver();
        toast.success('Delete successful!');
      } catch {
        toast.error('Delete failed!');
      }
    }
  };

  return (
    <>
      <button
        className="lp-button mb-4"
        type="button"
        disabled={addStatus.loading}
        onClick={() => setSlideOverOpen(true)}
      >
        Add new section
      </button>

      {data && !loading && tableData.length > 0 && (
        <div className="lp-table-container">
          <Table className="lp-table" columns={columns} data={tableData} />
        </div>
      )}

      <SlideOver
        show={slideOverOpen}
        title={newSection.id === undefined ? 'New section' : 'Edit section'}
        saving={addStatus.loading || changeStatus.loading}
        deleting={deleteStatus.loading}
        onCancelClick={handleCloseSlideOver}
        onDeleteClick={handleDelete}
        onSaveClick={() => {
          if (newSection.id === undefined) {
            handleAdd();
          } else {
            handleUpdate();
          }
        }}
      >
        <div className="pt-6 pb-5">
          <div>
            <label className="lp-input-text-label" htmlFor="image">
              Image
            </label>
            <ImageSelect
              inputId="image"
              value={newSection.imageId}
              onChange={(value) => setNewSection({ ...newSection, imageId: value })}
            />
          </div>
          <div>
            <label className="lp-input-text-label" htmlFor="icon">
              Icon
            </label>
            <ImageSelect
              inputId="icon"
              value={newSection.iconId}
              onChange={(value) => setNewSection({ ...newSection, iconId: value })}
            />
          </div>
          <div className="mb-8">
            <label className="lp-input-text-label" htmlFor="order">
              Order *
            </label>
            <input
              type="number"
              className="lp-input-text mt-1 w-full"
              value={newSection.ordernum}
              onChange={(e) => setNewSection({ ...newSection, ordernum: parseInt(e.target.value) })}
            />
          </div>
          <LanguageTabs current={activeLanguage} onChange={(language) => setActiveLanguage(language)} />
          <div>
            <div>
              <label className="lp-input-text-label" htmlFor={activeLanguage}>
                Name
              </label>
              <input
                id={activeLanguage}
                type="text"
                className="lp-input-text mt-1 w-full"
                value={newSection.names[activeLanguage] ?? ''}
                onChange={(e) =>
                  setNewSection({ ...newSection, names: { ...newSection.names, [activeLanguage]: e.target.value } })
                }
              />
              <div className="flex items-center mt-2">
                <input
                  id={`${activeLanguage}-enabled`}
                  name={`${activeLanguage}-enabled`}
                  type="checkbox"
                  className="lp-input-checkbox"
                  checked={newSection.enabledLanguages.includes(activeLanguage)}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    const enabledLanguages = checked
                      ? [...newSection.enabledLanguages, activeLanguage]
                      : newSection.enabledLanguages.filter((x: string) => x !== activeLanguage);
                    setNewSection({ ...newSection, enabledLanguages });
                  }}
                />
                <label className="font-sm ml-2" htmlFor={`${activeLanguage}-enabled`}>
                  Published
                </label>
              </div>
              <div className="flex items-center mt-2">
                <input
                  id={`${activeLanguage}-identification-enabled`}
                  name={`${activeLanguage}-identification-enabled`}
                  type="checkbox"
                  className="lp-input-checkbox"
                  checked={newSection.identificationEnabledLanguages.includes(activeLanguage)}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    const identificationEnabledLanguages = checked
                      ? [...newSection.identificationEnabledLanguages, activeLanguage]
                      : newSection.identificationEnabledLanguages.filter((x: string) => x !== activeLanguage);
                    setNewSection({ ...newSection, identificationEnabledLanguages });
                  }}
                />
                <label className="font-sm ml-2" htmlFor={`${activeLanguage}-identification-enabled`}>
                  Identification published
                </label>
              </div>
            </div>

            <div>
              <label className="lp-input-text-label" htmlFor={`${activeLanguage}-url-slug`}>
                Url slug
              </label>
              <input
                id={`${activeLanguage}-url-slug`}
                type="text"
                className="lp-input-text mt-1 w-full"
                value={newSection.urlSlugs[activeLanguage] ?? ''}
                onChange={(e) =>
                  setNewSection({
                    ...newSection,
                    urlSlugs: { ...newSection.urlSlugs, [activeLanguage]: e.target.value },
                  })
                }
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor={`${activeLanguage}-description`}>
                Description
              </label>
              <textarea
                id={`${activeLanguage}-description`}
                className="lp-textarea h-32"
                value={newSection.descriptions[activeLanguage] ?? ''}
                onChange={(e) =>
                  setNewSection({
                    ...newSection,
                    descriptions: { ...newSection.descriptions, [activeLanguage]: e.target.value },
                  })
                }
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor={`${activeLanguage}-meta-description`}>
                Meta description
              </label>
              <textarea
                id={`${activeLanguage}-meta-description`}
                className="lp-textarea h-32"
                value={newSection.metadataDescriptions[activeLanguage] ?? ''}
                onChange={(e) =>
                  setNewSection({
                    ...newSection,
                    metadataDescriptions: { ...newSection.metadataDescriptions, [activeLanguage]: e.target.value },
                  })
                }
              />
            </div>
          </div>
        </div>
      </SlideOver>
    </>
  );
};
