import { FC } from 'react';
import Select from 'react-select';
import { useApiAllCharacteristicGroupsQuery } from '../../api/generated/graphql';
import { withFallbackName } from '../../utils';

interface Props {
  value: number | undefined;
  onChange: (groupId: number | undefined) => void;
  inputId: string;
}
export const CharacteristicGroupSelect: FC<Props> = ({ value, onChange, inputId }) => {
  const { data, loading } = useApiAllCharacteristicGroupsQuery();

  const options =
    data?.characteristic_group.map((x) => ({
      label: `${withFallbackName(x.characteristic_section)} - ${withFallbackName(x)}`,
      value: x.id,
    })) ?? [];

  return (
    <Select
      inputId={inputId}
      isSearchable={true}
      isClearable={true}
      value={options?.find((x) => x.value === value)}
      options={options}
      isLoading={loading}
      onChange={(dropdownValue) => {
        onChange(dropdownValue?.value ?? undefined);
      }}
    />
  );
};
