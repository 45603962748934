import { FC } from 'react';
import Select from 'react-select';
import { useApiAllRankQuery } from '../api/generated/graphql';

interface Props {
  value: string | undefined;
  onChange: (rank: string | undefined) => void;
  inputId: string;
  isClearable?: boolean;
}
export const RankSelect: FC<Props> = ({ value, onChange, inputId, isClearable }) => {
  const { data, loading } = useApiAllRankQuery();

  const options = data?.rank.map((x) => ({ label: x.name ? `${x.id} - ${x.name}` : x.id, value: x.id })) ?? [];

  return (
    <Select
      inputId={inputId}
      isSearchable={true}
      isClearable={isClearable}
      value={options?.find((x) => x.value === value)}
      options={options}
      isLoading={loading}
      onChange={(dropdownValue) => {
        onChange(dropdownValue?.value ?? undefined);
      }}
    />
  );
};
