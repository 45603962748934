import { useMemo } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import FeedbackDetails from '../components/FeedbackDetails';
import { FeedbackList } from '../components/FeedbackList';

export function Feedback() {
  const match = useRouteMatch();

  const linkPath = useMemo(() => match.path.replace('/*', ''), [match]);

  return (
    <div className="lp-page">
      <Switch>
        <Route exact path={[`${linkPath}`]}>
          <FeedbackList taxonomyId={undefined} />
        </Route>
        <Route
          path={`${linkPath}/:id`}
          render={(x) => <FeedbackDetails id={parseInt(x.match.params.id!, 10)} />}
        ></Route>
      </Switch>
    </div>
  );
}
