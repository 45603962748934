import { FC, useEffect, useMemo, useState } from 'react';
import Table from 'rc-table';
import {
  useApiChangeTaxonomyCharValuesMutation,
  useApiSingleCharacteristicsSectionQuery,
  Taxonomy_Characteristic_Value,
  ApiSingleTaxonomyQuery,
} from '../api/generated/graphql';
import Select from 'react-select';
import { ReactComponent as Delete } from '../assets/delete.svg';
import { ColumnsType } from 'rc-table/lib/interface';
import { toast } from 'react-toastify';
import { toGraphqlArray } from '../api/utils';
import { CharacteristicSectionSelect } from './Identification/CharacteristicSectionSelect';

type TaxonomyCharacteristicValues = NonNullable<
  ApiSingleTaxonomyQuery['taxonomy_by_pk']
>['taxonomy_characteristic_values'];

interface Props {
  taxonomyId: number;
  sectionIds: number[];
  characteristicValues: TaxonomyCharacteristicValues;
}

interface CharacteristicOption {
  label: string;
  value: number;
  groupLabel: string;
  groupValue: number;
}

interface TableRow {
  key: string;
  group: string | null | undefined;
  groupId: number | undefined;
  charName: string | null | undefined;
  charValueId?: number;
  charId: number;
  frequency?: string;
  variations?: number[];
}

export const Characteristics: FC<Props> = ({ taxonomyId, sectionIds, characteristicValues }) => {
  const [tableData, settableData] = useState<TableRow[]>([]);
  const [selectedCharacteristic, setSelectedCharacteristic] = useState<CharacteristicOption>();
  const [sectionId, setSectionId] = useState<number | undefined>(sectionIds[0]);

  const { data, loading } = useApiSingleCharacteristicsSectionQuery({
    variables: { id: sectionId! },
    skip: sectionId === undefined || sectionId === null,
  });

  const visibleTableData = useMemo(
    () =>
      tableData.filter(
        (x) =>
          x.groupId !== undefined &&
          data?.characteristic_section_by_pk?.characteristic_groups?.some((y) => y.id === x.groupId)
      ),
    [tableData, data]
  );

  const [changeValues, mutationStatus] = useApiChangeTaxonomyCharValuesMutation();

  const characterOptions = useMemo(() => {
    return data?.characteristic_section_by_pk?.characteristic_groups
      .map((x) => {
        return x.characteristics.map((y) => {
          return {
            label: y.name,
            value: y.id,
            groupLabel: x.name,
            groupValue: x.id,
          };
        });
      })
      .flat();
  }, [data]);

  const variationNames = useMemo(
    () => new Map(data?.characteristic_section_by_pk?.variations.map((x) => [x.id, x.name ?? x.names['fi']])),
    [data]
  );

  useEffect(() => {
    if (!characteristicValues) {
      return;
    }
    settableData(
      characteristicValues.map((y, index) => {
        return {
          key: `${y.characteristic_value.characteristic.id}_${y.characteristic_value.id}`,
          group: y.characteristic_value.characteristic.characteristic_group?.name,
          groupId: y.characteristic_value.characteristic.characteristic_group?.id,
          charName: y.characteristic_value.characteristic.name,
          charValueId: y.characteristic_value.id,
          charId: y.characteristic_value.characteristic.id,
          frequency: y.frequency ?? undefined,
          variations: y.variations,
        };
      })
    );
  }, [characteristicValues]);

  const columns: ColumnsType<TableRow> = [
    {
      title: 'No.',
      width: 20,
      render: (_value, _row, index) => {
        return index + 1;
      },
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      width: 200,
    },
    {
      title: 'Character name',
      dataIndex: 'charName',
      key: 'charName',
      width: 200,
    },
    {
      title: 'Character value',
      dataIndex: 'charValueId',
      key: 'charValueId',
      width: 300,
      render: (value, row) => {
        const options = data?.characteristic_section_by_pk?.characteristic_groups
          .find((x) => x.id === row.groupId)
          ?.characteristics.find((y) => y.id === row.charId)
          ?.characteristic_values.map((z) => ({
            value: z.id,
            label: z.name,
          }));
        const defaultValue = options?.find((x) => x.value === value);

        return (
          <Select
            isSearchable={true}
            defaultValue={defaultValue}
            options={options as any}
            onChange={(dropdownValue) => {
              const tableCopy = tableData.slice();
              const targetIndex = tableCopy.findIndex((x) => x.charValueId === row.charValueId);
              if (targetIndex !== -1 && dropdownValue) {
                tableCopy[targetIndex].charValueId = dropdownValue?.value;
                settableData(tableCopy);
              }
            }}
          />
        );
      },
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      key: 'frequency',
      width: 180,
      render: (value, row) => (
        <Select
          defaultValue={
            value === 'Common' ? { value: 'Common', label: 'Common' } : { value: 'Possible', label: 'Possible' }
          }
          options={[
            { value: 'Common', label: 'Common' },
            { value: 'Possible', label: 'Possible' },
          ]}
          onChange={(dropdownValue) => {
            const tableCopy = tableData.slice();
            const targetIndex = tableCopy.findIndex((x) => x.charValueId === row.charValueId);
            if (targetIndex !== -1 && dropdownValue) {
              tableCopy[targetIndex].frequency = dropdownValue?.value;
              settableData(tableCopy);
            }
          }}
        />
      ),
    },
    {
      title: 'Variations',
      dataIndex: 'variations',
      key: 'variations',
      width: 250,
      render: (value, row) => (
        <Select
          defaultValue={row.variations?.map((x) => ({ label: variationNames.get(x), value: x })) ?? []}
          isMulti={true}
          onChange={(dropdownValue) => {
            const tableCopy = tableData.slice();
            const targetIndex = tableCopy.findIndex((x) => x.charValueId === row.charValueId);
            if (targetIndex !== -1 && dropdownValue) {
              tableCopy[targetIndex] = { ...tableCopy[targetIndex], variations: dropdownValue.map((x) => x.value) };
              settableData(tableCopy);
            }
          }}
          options={[
            {
              label: 'Variations',
              value: '',
              options:
                data?.characteristic_section_by_pk?.variations.map((x) => ({
                  label: variationNames.get(x.id),
                  value: x.id,
                })) ?? [],
            },
          ]}
        />
      ),
    },
    {
      title: 'Operations',
      dataIndex: '',
      key: 'operations',
      render: (_value, _row, index) => (
        <button
          type="button"
          className="h-8 w-8 flex items-center"
          onClick={() => {
            const newTableData = tableData.slice();
            newTableData.splice(index, 1);
            settableData(newTableData);
          }}
        >
          <Delete />
        </button>
      ),
    },
  ];

  const handleCharAdd = () => {
    const tableCopy = tableData.slice();
    tableCopy.unshift({
      key: `${selectedCharacteristic!.value}_add_${tableData.length}`,
      group: selectedCharacteristic!.groupLabel,
      groupId: selectedCharacteristic!.groupValue,
      charName: selectedCharacteristic!.label,
      charId: selectedCharacteristic!.value,
      frequency: 'Common',
      variations: [],
    });
    settableData(tableCopy);
  };

  const handleCharValueSave = async () => {
    try {
      await changeValues({
        variables: {
          taxonomyId: taxonomyId,
          values: tableData.map((x) => {
            return {
              taxonomy_id: taxonomyId,
              frequency: x.frequency,
              characteristic_value_id: x.charValueId,
              variations: x.variations && x.variations.length > 0 ? toGraphqlArray(x.variations) : undefined,
            };
          }),
        },
      });
      toast.success('Update successful!');
    } catch {
      toast.error('Update failed!');
    }
  };

  return (
    <>
      <div className="controls">
        <div className="max-w-sm">
          <CharacteristicSectionSelect
            value={sectionId}
            onChange={setSectionId}
            inputId="section"
            filterOption={(opt) => sectionIds.includes(opt)}
          />
        </div>
        <div className="characteradd">
          <span className="charaddheader">Add new characteristic</span>
          <Select
            className="charaddselect"
            options={characterOptions as any}
            isSearchable={true}
            isClearable={true}
            onChange={(e: any) => setSelectedCharacteristic(e)}
          />
          <button
            className="lp-button"
            type="button"
            onClick={() => handleCharAdd()}
            disabled={!selectedCharacteristic}
          >
            Add
          </button>
        </div>
        <div className="tablesave">
          <button className="lp-button" disabled={mutationStatus.loading} onClick={() => handleCharValueSave()}>
            Save
          </button>
        </div>
      </div>
      {characteristicValues && !loading && visibleTableData.length > 0 && (
        <Table className="characteristicstable" columns={columns} data={visibleTableData} />
      )}
    </>
  );
};
