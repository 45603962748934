import React, { useEffect, useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useApiSingleTaxonomyLazyQuery } from '../api/generated/graphql';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { TLink } from '../components/TLink';
import { Tablinks } from '../components/Tablinks';
import { Characteristics } from '../components/Characteristics';
import { Names } from '../components/Names';
import { Descriptions } from '../components/Descriptions';
import { SpeciesImages } from '../components/SpeciesImages';
import { General } from '../components/General';
import { ScientificNames } from '../components/ScientificNames';
import { WidgetsList } from '../components/Widgets/WidgetsList';
import { TaxonomyAncestors } from '../components/TaxonomyAncestors';
import { FeedbackList } from '../components/FeedbackList';
import { Loader } from 'react-feather';
import { currentEnvironment, queryIsLoading, environmentBaseUrl } from '../utils';
import { ExternalLink } from 'react-feather';

export function Taxonomy() {
  const match = useRouteMatch<{ id: string }>();
  const {
    params: { id },
  } = match;

  const [load, query] = useApiSingleTaxonomyLazyQuery({
    variables: { id: Number(match.params.id) },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const { data } = query;
  const loading = queryIsLoading(query);

  useEffect(() => {
    load({ variables: { id: Number(match.params.id) } });
  }, [match.params.id, load]);

  const linkPath = useMemo(() => match.path.replace('/*', ''), [match]);

  return (
    <DndProvider backend={HTML5Backend}>
      {loading ? (
        <Loader className="animate-spin" />
      ) : (
        <div className="pagecontainer">
          <div className="pb-5">
            <TLink to="taxonomy/list" className="p-2 bg-gray-200 text-gray-800 px-3 font-medium text-sm rounded-sm">
              Back to the list
            </TLink>
          </div>
          <div className="flex flex-row items-center  space-x-4 py-4 text-2xl names">
            <span>
              {data?.taxonomy_by_pk?.scientific_name} -{' '}
              {data?.taxonomy_by_pk?.taxonomy_common_names.find((x) => x.language === 'fi')?.name}
            </span>
            <span className="rank">
              (
              {data?.taxonomy_by_pk?.rank?.name
                ? `${data?.taxonomy_by_pk?.rank_id} - ${data?.taxonomy_by_pk?.rank?.name}`
                : data?.taxonomy_by_pk?.rank_id ?? ''}
              )
            </span>{' '}
            {data?.taxonomy_by_pk && (
              <a
                className="flex flex-row text-xs"
                target="_blank"
                rel="noreferrer"
                href={`${environmentBaseUrl(currentEnvironment())}/t/${data.taxonomy_by_pk.id}`}
              >
                To site
                <ExternalLink className="ml-1" size={16} />
              </a>
            )}
          </div>
          <Tablinks
            links={[
              { link: `taxonomy/${match.params.id}`, title: 'General' },
              { link: `taxonomy/${match.params.id}/taxonomy`, title: 'Taxonomy' },
              { link: `taxonomy/${match.params.id}/scientific_names`, title: 'Scientific names' },
              { link: `taxonomy/${match.params.id}/common_names`, title: 'Common names' },
              { link: `taxonomy/${match.params.id}/characteristics`, title: 'Characteristics' },
              { link: `taxonomy/${match.params.id}/descriptions`, title: 'Descriptions' },
              { link: `taxonomy/${match.params.id}/images`, title: 'Images' },
              { link: `taxonomy/${match.params.id}/widgets`, title: 'Widgets' },
              {
                link: `taxonomy/${match.params.id}/feedback`,
                title: `Feedback (${data?.taxonomy_by_pk?.feedback_taxonomy_references?.length ?? 0})`,
              },
            ]}
          />
          <Switch>
            <Route exact path={linkPath}>
              <General data={data} />
            </Route>
            <Route path={`${linkPath}/characteristics`}>
              <Characteristics
                taxonomyId={parseInt(id)}
                sectionIds={
                  data?.taxonomy_by_pk?.taxonomy_characteristic_sections?.map((x) => x.characteristic_section_id) ?? []
                }
                characteristicValues={data?.taxonomy_by_pk?.taxonomy_characteristic_values ?? []}
              />
            </Route>
            <Route path={`${linkPath}/taxonomy`}>
              <TaxonomyAncestors data={data} />
            </Route>
            <Route path={`${linkPath}/scientific_names`}>
              <ScientificNames
                value={data?.taxonomy_by_pk?.taxonomy_scientific_names ?? []}
                taxonomyId={parseInt(id)}
              />
            </Route>
            <Route path={`${linkPath}/common_names`}>
              <Names id={parseInt(id)} data={data} />
            </Route>
            <Route path={`${linkPath}/descriptions`}>
              <Descriptions data={data} />
            </Route>
            <Route path={`${linkPath}/images`}>
              <SpeciesImages data={data} id={id} />
            </Route>
            <Route path={`${linkPath}/widgets`}>
              <WidgetsList data={data} />
            </Route>
            <Route path={`${linkPath}`}>
              <FeedbackList taxonomyId={parseInt(id)} />
            </Route>
          </Switch>
        </div>
      )}
    </DndProvider>
  );
}
