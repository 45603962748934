import { FC } from 'react';
import { useApiAllLanguageQuery } from '../api/generated/graphql';
import { classNames } from '../utils';

interface Props {
  translations: Record<string, string>;
}

export const LanguagesPreview: FC<Props> = ({ translations }) => {
  const allLanguages = useApiAllLanguageQuery();

  return (
    <div>
      {allLanguages.data?.language?.map((lang, index) => (
        <span key={lang.key}>
          <span
            className={classNames({
              'text-lp-green': !!translations[lang.key]?.length,
              'text-red-500': !translations[lang.key]?.length,
            })}
          >
            {lang.key}
          </span>
          {index !== (allLanguages.data?.language?.length ?? 0) - 1 ? ', ' : ''}
        </span>
      ))}
    </div>
  );
};
