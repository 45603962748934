import { NetworkStatus } from '@apollo/client';
import { useEffect, useState } from 'react';
import i18n from './i18n';

export function IntlNavigate(history: any, to: string) {
  history.push(`${i18n.language}/${to}`);
}

export function isDefined<T>(x: T | undefined | null): x is T {
  return x !== undefined && x !== null;
}

export function withFallbackName({
  name,
  names,
}: {
  name?: string | undefined | null;
  names: Record<string, string>;
}): string {
  const fallback = ['en', 'fi']
    .map((language) => (names[language] ? { name: names[language], language: language } : undefined))
    .filter(isDefined)[0];
  const fallbackName = fallback ? `${fallback.name} (${fallback.language})` : 'n/a';
  return name ?? fallbackName;
}

export function useKeyPress(targetKey: any) {
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  useEffect(() => {
    const downHandler = ({ key }: any) => {
      if (key === targetKey) {
        setKeyPressed(true);
      }
    };
    const upHandler = ({ key }: any) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };

    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, [targetKey]);
  return keyPressed;
}

export function classNames(names: Record<string, boolean>): string {
  return Object.keys(names)
    .map((name) => (names[name] === true ? name : undefined))
    .filter((x) => x !== undefined)
    .join(' ');
}

// Derived from https://www.thesauruslex.com/typo/eng/enghtml.htm
const URL_SLUG_REPLACEMENTS = new Map([
  // da
  ['Ø', 'O'],
  ['ø', 'o'],
  ['Å', 'a'],
  ['å', 'a'],
  ['Æ', 'AE'],
  ['æ', 'ae'],

  // de
  ['Ä', 'A'],
  ['ä', 'a'],
  ['Ö', 'Ö'],
  ['ö', 'o'],
  ['Ü', 'U'],
  ['ü', 'u'],
  ['ß', 'ss'],

  // es
  ['Á', 'A'],
  ['á', 'a'],
  ['É', 'E'],
  ['é', 'e'],
  ['Í', 'I'],
  ['í', 'i'],
  ['Ó', 'O'],
  ['ó', 'o'],
  ['Ñ', 'N'],
  ['ñ', 'n'],
  ['Ú', 'U'],
  ['ú', 'u'],
  ['Ü', 'U'],
  ['ü', 'u'],
  ['¡', 'i'],
  ['ª', 'a'],
  ['¿', 'i'],
  ['º', 'o'],

  // french
  ['À', 'A'],
  ['à', 'a'],
  ['Â', 'A'],
  ['â', 'a'],
  ['Ç', 'C'],
  ['ç', 'c'],
  ['È', 'E'],
  ['è', 'e'],
  ['É', 'E'],
  ['é', 'e'],
  ['Ê', 'E'],
  ['ê', 'e'],
  ['Ë', 'E'],
  ['ë', 'e'],
  ['Î', 'I'],
  ['î', 'i'],
  ['Ï', 'I'],
  ['ï', 'i'],
  ['Ô', 'O'],
  ['ô', 'o'],
  ['Œ', 'OE'],
  ['œ', 'oe'],
  ['Ù', 'U'],
  ['ù', 'u'],
  ['Û', 'U'],
  ['û', 'u'],
  ['Ü', 'U'],
  ['ü', 'u'],
  ['Ÿ', 'Y'],
  ['ÿ', 'y'],

  // others
  [' ', '-'],
]);

export function commonNameToUrlSlug(commonName: string): string {
  return Array.from(commonName)
    .map((x) => (URL_SLUG_REPLACEMENTS.has(x) ? URL_SLUG_REPLACEMENTS.get(x) : x))
    .join('')
    .toLowerCase();
}

const EXTENSION_TO_MIME_TYPE = {
  bmp: 'image/bmp',
  gif: 'image/gif',
  jpeg: 'image/jpeg',
  svg: 'image/svg+xml',
  tiff: 'image/tiff',
  png: 'image/png',
  ico: 'image/x-icon',
  heic: 'image/heic',
} as const;

export function fileExtensionToMimeType(fileExtension: string) {
  return fileExtension in EXTENSION_TO_MIME_TYPE
    ? EXTENSION_TO_MIME_TYPE[fileExtension as keyof typeof EXTENSION_TO_MIME_TYPE]
    : undefined;
}

export function mimeTypeToDefaultOutputExtension(
  mimeType: NonNullable<ReturnType<typeof fileExtensionToMimeType>>
): 'jpeg' | 'gif' | 'svg' {
  switch (mimeType) {
    case 'image/bmp':
      return 'jpeg';
    case 'image/gif':
      return 'gif';
    case 'image/heic':
      return 'jpeg';
    case 'image/jpeg':
      return 'jpeg';
    case 'image/png':
      return 'jpeg';
    case 'image/svg+xml':
      return 'svg';
    case 'image/tiff':
      return 'jpeg';
    case 'image/x-icon':
      return 'jpeg';
  }
}

type Env = 'prod' | 'staging' | 'local';

export function currentEnvironment(location: Location = window.location): Env {
  const hostname = location.hostname.toLowerCase();
  const isProd = ['luontoportti.fi', 'luontoportti.com', 'luontoportti.info'].some((y) => hostname.includes(y));
  const isStaging = ['nature-test.com'].some((y) => hostname.includes(y));
  if (isProd) {
    return 'prod';
  } else if (isStaging) {
    return 'staging';
  } else {
    return 'local';
  }
}

export function environmentBaseUrl(environment: Env): string {
  switch (environment) {
    case 'local':
      return 'http://localhost:3000';
    case 'staging':
      return 'https://nature-test.com';
    case 'prod':
      return 'https://luontoportti.com';
  }
}

export function queryIsLoading<T>(query: {
  loading: boolean;
  data: T | undefined | null;
  networkStatus: NetworkStatus;
}): boolean {
  return (
    (query.loading && (query.data === null || query.data === undefined)) ||
    query.networkStatus === NetworkStatus.refetch
  );
}

export function move<T>(array: T[], from: number, to: number) {
  const copy = [...array];
  copy.splice(to, 0, ...copy.splice(from, 1));
  return copy;
}
