import { FC, useEffect, useState, useMemo } from 'react';
import Table from 'rc-table';
import {
  useApiAddCharacteristicValueMutation,
  useApiAllLanguageQuery,
  useApiChangeCharacteristicValueMutation,
  useApiDeleteCharacteristicValueMutation,
  useApiAllCharacteristicValuesByCharacteristicQuery,
} from '../../api/generated/graphql';
import { ColumnsType } from 'rc-table/lib/interface';
import { toast } from 'react-toastify';
import { SlideOver } from '../SlideOver';
import { ImageSelect } from '../ImageSelect';
import { CharacteristicGroupSelect } from './CharacteristicGroupSelect';
import { withFallbackName } from '../../utils';
import { CharacteristicSelect } from './CharacteristicSelect';
import { WidgetType, widgetTypeFromString } from '../../model';
import { NamesEdit } from '../NamesEdit';
import { LanguagesPreview } from '../LanguagesPreview';
import Select from 'react-select';

interface Props {}

interface TableRow {
  key: string;
  id: number;
  characteristicId: number;
  name: string;
  names: Record<string, string>;
  descriptions: Record<string, string>;
  ordernum: number;
  iconId: string;
  helpImageLargeId: string | undefined;
  helpImageSmallId: string | undefined;
  widgetType: WidgetType | undefined;
  widgetData: string | undefined;
  parent: number | undefined;
}

interface NewCharacteristicValue {
  id: number | undefined;
  characteristicId: number | undefined;
  names: Record<string, string>;
  descriptions: Record<string, string>;
  ordernum: number;
  iconId: string | undefined;
  helpImageLargeId: string | undefined;
  helpImageSmallId: string | undefined;
  widgetType: WidgetType | undefined;
  widgetData: string | undefined;
  parent: number | undefined;
}

export const CharacteristicValues: FC<Props> = () => {
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>(undefined);
  const [selectedCharacteristic, setSelectedCharacteristic] = useState<number | undefined>(undefined);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [newCharValue, setNewCharValue] = useState<NewCharacteristicValue>({
    descriptions: {},
    characteristicId: undefined,
    helpImageLargeId: undefined,
    helpImageSmallId: undefined,
    iconId: undefined,
    id: undefined,
    names: {},
    ordernum: 0,
    widgetType: undefined,
    widgetData: undefined,
    parent: undefined,
  });

  const { data, loading, refetch } = useApiAllCharacteristicValuesByCharacteristicQuery({
    variables: { characteristicId: selectedCharacteristic! },
    skip: selectedCharacteristic === undefined,
  });

  const languagesQuery = useApiAllLanguageQuery();
  const languages = useMemo(() => languagesQuery.data?.language ?? [], [languagesQuery.data]);

  const [add, addStatus] = useApiAddCharacteristicValueMutation();
  const [change, changeStatus] = useApiChangeCharacteristicValueMutation();
  const [delete_, deleteStatus] = useApiDeleteCharacteristicValueMutation();

  const tableData: TableRow[] = useMemo(
    () =>
      data?.characteristic_value.map((y) => {
        return {
          key: y.id.toString(),
          id: y.id,
          characteristicId: y.characteristic_id,
          name: withFallbackName(y),
          names: y.names,
          descriptions: y.descriptions,
          ordernum: y.ordernum,
          iconId: y.icon_id,
          helpImageLargeId: y.help_image_large_id ?? undefined,
          helpImageSmallId: y.help_image_small_id ?? undefined,
          widgetType: y.characteristic.widget_type ? widgetTypeFromString(y.characteristic.widget_type) : undefined,
          widgetData: y.widget_data,
          parent: y.parent ?? undefined,
        };
      }) ?? [],
    [data?.characteristic_value]
  );

  const activeParentValue = useMemo(() => {
    const chosen = tableData.find((x) => x.id === newCharValue.parent);
    if (chosen) {
      return { value: chosen.id, label: chosen.name };
    } else {
      return undefined;
    }
  }, [tableData, newCharValue.parent]);

  const parentDisabled = useMemo(
    () =>
      newCharValue.id !== undefined &&
      newCharValue.parent === undefined &&
      tableData.some((x) => x.parent === newCharValue.id),
    [newCharValue, tableData]
  );

  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns: ColumnsType<TableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 30,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Name translations',
      dataIndex: '',
      key: 'nameTranslations',
      width: 200,
      render: (_value, row, _index) => <LanguagesPreview translations={row.names} />,
    },
    {
      title: 'Order',
      dataIndex: 'ordernum',
      key: 'ordernum',
      width: 60,
    },
    {
      title: 'Operations',
      dataIndex: '',
      key: 'operations',
      render: (value, row, index) => (
        <button
          onClick={() => {
            setNewCharValue({ ...row });
            setSlideOverOpen(true);
          }}
          className="lp-button-link"
        >
          Edit
        </button>
      ),
    },
  ];

  const handleCloseSlideOver = () => {
    setSlideOverOpen(false);
    setNewCharValue({
      id: undefined,
      characteristicId: undefined,
      names: {},
      descriptions: {},
      ordernum: 0,
      iconId: undefined,
      helpImageLargeId: undefined,
      helpImageSmallId: undefined,
      widgetType: undefined,
      widgetData: undefined,
      parent: undefined,
    });
  };

  const handleAdd = async () => {
    if (newCharValue.characteristicId === undefined || newCharValue.iconId === undefined) return;
    try {
      await add({
        variables: {
          descriptions: newCharValue.descriptions,
          characteristicId: newCharValue.characteristicId,
          helpImageLargeId: newCharValue.helpImageLargeId,
          helpImageSmallId: newCharValue.helpImageSmallId,
          iconId: newCharValue.iconId,
          names: newCharValue.names,
          ordernum: newCharValue.ordernum,
          widgetData: newCharValue.widgetData,
          parent: newCharValue.parent,
        },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Add successful!');
    } catch {
      toast.error('Add failed!');
    }
  };

  const handleUpdate = async () => {
    const { id, characteristicId, iconId } = newCharValue;
    if (id === undefined || characteristicId === undefined || iconId === undefined) return;
    try {
      await change({
        variables: {
          descriptions: newCharValue.descriptions,
          characteristicId: characteristicId,
          helpImageLargeId: newCharValue.helpImageLargeId,
          helpImageSmallId: newCharValue.helpImageSmallId,
          iconId: iconId,
          id: id,
          names: newCharValue.names,
          ordernum: newCharValue.ordernum,
          widgetData: newCharValue.widgetData,
          parent: newCharValue.parent,
        },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Save successful!');
    } catch {
      toast.error('Save failed!');
    }
  };

  const handleDelete = async () => {
    if (newCharValue.id === undefined) {
      handleCloseSlideOver();
    } else {
      if (!window.confirm('Delete characteristic value?')) return;
      try {
        await delete_({ variables: { id: newCharValue.id } });
        await refetch();
        handleCloseSlideOver();
        toast.success('Delete successful!');
      } catch {
        toast.error('Delete failed!');
      }
    }
  };

  return (
    <>
      <div className="w-full max-w-sm mb-4">
        <label className="lp-input-text-label" htmlFor="select-characteristic-group">
          1. Select group
        </label>
        <CharacteristicGroupSelect
          inputId="select-characteristic-group"
          value={selectedGroup}
          onChange={(value) => {
            setSelectedGroup(value);
            setSelectedCharacteristic(undefined);
          }}
        />
      </div>

      <div className="w-full max-w-sm mb-4">
        <label className="lp-input-text-label" htmlFor="select-characteristic">
          2. Select characteristic
        </label>
        <CharacteristicSelect
          inputId="select-characteristic"
          value={selectedCharacteristic}
          groupId={selectedGroup}
          onChange={(value) => setSelectedCharacteristic(value)}
        />
      </div>

      <button
        className="lp-button mb-4"
        type="button"
        disabled={selectedGroup === undefined}
        onClick={() => {
          setSlideOverOpen(true);
          setNewCharValue({ ...newCharValue, characteristicId: selectedCharacteristic });
        }}
      >
        Add new characteristic value
      </button>

      {data && !loading && tableData.length > 0 && <Table className="lp-table" columns={columns} data={tableData} />}

      <SlideOver
        show={slideOverOpen}
        title={newCharValue.id === undefined ? 'New characteristic value' : 'Edit characteristic value'}
        saving={addStatus.loading || changeStatus.loading}
        deleting={deleteStatus.loading}
        onCancelClick={handleCloseSlideOver}
        onDeleteClick={handleDelete}
        onSaveClick={() => {
          if (newCharValue.id === undefined) {
            handleAdd();
          } else {
            handleUpdate();
          }
        }}
      >
        <div className="space-y-6 pt-6 pb-5">
          <div>
            <h3>General</h3>
            <div>
              <label className="lp-input-text-label" htmlFor="characteristic">
                Characteristic *
              </label>
              <CharacteristicSelect
                inputId="characteristic"
                value={newCharValue.characteristicId}
                groupId={selectedGroup}
                onChange={(value) => setNewCharValue({ ...newCharValue, characteristicId: value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="icon">
                Icon *
              </label>
              <ImageSelect
                inputId="icon"
                value={newCharValue.iconId}
                onChange={(value) => setNewCharValue({ ...newCharValue, iconId: value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="helpImageSmall">
                Help image small
              </label>
              <ImageSelect
                inputId="helpImageSmall"
                value={newCharValue.helpImageSmallId}
                onChange={(value) => setNewCharValue({ ...newCharValue, helpImageSmallId: value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="helpImageLarge">
                Help image large
              </label>
              <ImageSelect
                inputId="helpImageLarge"
                value={newCharValue.helpImageLargeId}
                onChange={(value) => setNewCharValue({ ...newCharValue, helpImageLargeId: value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="ordernum">
                Order *
              </label>
              <input
                id="ordernum"
                type="number"
                className="lp-input-text mt-1 w-full"
                value={newCharValue?.ordernum ?? 0}
                onChange={(e) => setNewCharValue({ ...newCharValue, ordernum: parseInt(e.target.value) })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="parent">
                Parent value{parentDisabled && <> (Disabled - already has children set)</>}
              </label>
              <Select
                inputId="parent"
                value={activeParentValue}
                isClearable={true}
                isDisabled={parentDisabled}
                options={tableData
                  .filter((x) => x.id !== newCharValue.id && x.parent === undefined)
                  .map((x) => ({ label: x.name, value: x.id }))}
                isLoading={loading}
                onChange={(dropdownValue) => {
                  if (dropdownValue) {
                    setNewCharValue({ ...newCharValue, parent: dropdownValue.value });
                  } else {
                    setNewCharValue({ ...newCharValue, parent: undefined });
                  }
                }}
              />
            </div>
            {newCharValue.widgetType && (
              <div>
                <label className="lp-input-text-label" htmlFor="widget">
                  Characteristic widget data ({newCharValue.widgetType})
                </label>
                <input
                  id="widget"
                  type="text"
                  className="lp-input-text mt-1 w-full"
                  value={newCharValue?.widgetData ?? ''}
                  onChange={(e) => setNewCharValue({ ...newCharValue, widgetData: e.target.value })}
                />
              </div>
            )}
          </div>
          <NamesEdit value={newCharValue.names} onChange={(names) => setNewCharValue({ ...newCharValue, names })} />
          <div className="space-y-2">
            <h3>Descriptions</h3>
            {languages.map((language) => (
              <div key={language.key}>
                <label className="lp-input-text-label" htmlFor={`${language.key}-description`}>
                  {language.name}
                </label>
                <textarea
                  id={`${language.key}-description`}
                  className="lp-textarea h-32"
                  value={newCharValue.descriptions[language.key] ?? ''}
                  onChange={(e) =>
                    setNewCharValue({
                      ...newCharValue,
                      descriptions: { ...newCharValue.descriptions, [language.key]: e.target.value },
                    })
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </SlideOver>
    </>
  );
};
