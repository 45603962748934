import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ApiSingleTaxonomyDocument,
  ApiSingleTaxonomyQuery,
  useApiAllLanguageQuery,
  useApiAllVariationsQuery,
  useApiChangeTaxonomyImageMutation,
} from '../api/generated/graphql';
import Table from 'rc-table';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { toGraphqlArray } from '../api/utils';

type TaxonomyImage = NonNullable<ApiSingleTaxonomyQuery['taxonomy_by_pk']>['taxonomy_images'][0];

export interface Props {
  taxonomyId: number;
  image: TaxonomyImage;
  deleting: boolean;
  handleDelete: () => Promise<void>;
}

export const ImageDetails: FC<Props> = ({ taxonomyId, image, deleting, handleDelete }) => {
  const [copyright, setCopyright] = useState<string>('');
  const [texts, setTexts] = useState<{ language: string; caption: string; alt: string }[]>([]);
  const [activeIdentification, setActiveIdentification] = useState<boolean>(false);
  const [activeVariations, setActiveVariations] = useState<number[]>([]);

  const allLanguages = useApiAllLanguageQuery();
  const [changeDetails] = useApiChangeTaxonomyImageMutation();
  const allVariations = useApiAllVariationsQuery();

  useEffect(() => {
    if (allLanguages.data) {
      const newTexts = allLanguages.data.language.map((lang) => {
        const caption = image.image.captions[lang.key] ?? '';
        const alt = image.image.alts[lang.key] ?? '';
        return {
          language: lang.key,
          image_id: image.image_id,
          caption: caption,
          alt: alt,
        };
      });
      setTexts(newTexts);
      setCopyright(image.image.copyright ?? '');
      setActiveIdentification(image.identification);
      setActiveVariations(image.variations);
    }
  }, [image, allLanguages]);

  const setTextValue = useCallback(
    (language, type, value) => {
      setTexts(
        texts.map((x) => {
          if (x.language !== language) {
            return x;
          }
          return { ...x, [type]: value };
        })
      );
    },
    [texts, setTexts]
  );

  const updateDetails = useCallback(async () => {
    const captions = texts.reduce((acc, x) => ({ ...acc, [x.language]: x.caption }), {});
    const alts = texts.reduce((acc, x) => ({ ...acc, [x.language]: x.alt }), {});
    try {
      await changeDetails({
        variables: {
          imageId: image.image_id,
          taxonomyId: image.taxonomy_id,
          copyright: copyright.length > 0 ? copyright : null,
          captions: captions,
          alts: alts,
          identification: activeIdentification,
          variations: toGraphqlArray(activeVariations),
        },
        refetchQueries: [{ query: ApiSingleTaxonomyDocument, variables: { id: taxonomyId } }],
      });
      toast.success('Update success!');
    } catch {
      toast.error('Update failed!');
    }
  }, [
    image.image_id,
    image.taxonomy_id,
    changeDetails,
    copyright,
    texts,
    activeIdentification,
    activeVariations,
    taxonomyId,
  ]);

  const activeVariationsAsValue = useMemo(
    () =>
      activeVariations.map((x) => {
        const found = allVariations?.data?.variation?.find((y) => y.id === x);
        if (found) {
          return { label: `${found.characteristic_section.name}: ${found.name} `, value: found.id };
        } else {
          return { label: x.toString(), value: x };
        }
      }),
    [activeVariations, allVariations]
  );

  return (
    <div className="mt-12">
      <h2>Image details</h2>
      <div className="grid grid-cols-2 gap-x-4 gap-y-2 mb-8">
        <div className="col-span-2">
          <label className="lp-input-text-label block mb-2" htmlFor="imageid">
            ID
          </label>
          <input
            id="imageid"
            type="text"
            readOnly={true}
            className="lp-input-text w-full"
            value={image.image.id ?? ''}
          />
        </div>
        <div className="col-span-2">
          <label className="lp-input-text-label block mb-2" htmlFor="filename">
            Filename
          </label>
          <input
            id="filename"
            type="text"
            readOnly={true}
            className="lp-input-text  w-full"
            value={image.image.filename ?? ''}
          />
        </div>
        <div>
          <label className="lp-input-text-label block mb-2" htmlFor="extension">
            File extension
          </label>
          <input
            id="extension"
            type="text"
            readOnly={true}
            className="lp-input-text  w-full"
            value={image.image.file_extension ?? ''}
          />
        </div>
        <div>
          <label className="lp-input-text-label block mb-2" htmlFor="outputformat">
            Output format
          </label>
          <input
            id="outputformat"
            type="text"
            readOnly={true}
            className="lp-input-text  w-full"
            value={image.image.output_format ?? ''}
          />
        </div>
        <div>
          <label className="lp-input-text-label block mb-2" htmlFor="copyright">
            Copyright
          </label>
          <input
            id="copyright"
            type="text"
            placeholder="Etunimi Sukunimi"
            className="lp-input-text w-full"
            value={copyright}
            onChange={(e) => setCopyright(e.target.value)}
          />
        </div>
        <div className="flex items-center">
          <input
            id="identification"
            name="identification"
            type="checkbox"
            className="lp-input-checkbox"
            checked={activeIdentification}
            onChange={(e) => setActiveIdentification(e.target.checked)}
          />
          <label className="font-sm ml-2" htmlFor="identification">
            Identification image
          </label>
        </div>
        <div>
          <label className="lp-input-text-label block mb-2" htmlFor="width">
            Width
          </label>
          <input
            id="width"
            type="number"
            readOnly={true}
            className="lp-input-text  w-full"
            value={image.image.width ?? ''}
          />
        </div>
        <div>
          <label className="lp-input-text-label block mb-2" htmlFor="height">
            Height
          </label>
          <input
            id="height"
            type="number"
            readOnly={true}
            className="lp-input-text w-full"
            value={image.image.height ?? ''}
          />
        </div>
        <div>
          <label className="lp-input-text-label block mb-2" htmlFor="variations">
            Variations
          </label>
          <Select
            inputId="variations"
            value={activeVariationsAsValue}
            isMulti={true}
            onChange={(dropdownValue) => {
              setActiveVariations(dropdownValue?.map((y) => y.value) ?? []);
            }}
            options={[
              {
                label: 'Variations',
                value: '',
                options:
                  allVariations.data?.variation.map((x) => ({
                    label: `${x.characteristic_section.name}: ${x.name}`,
                    value: x.id,
                  })) ?? [],
              },
            ]}
          />
        </div>
      </div>

      <Table
        className="my-4 lp-table"
        columns={[
          { title: 'Lang', dataIndex: 'language', render: (v) => v.toUpperCase() },
          {
            title: 'Caption',
            dataIndex: 'caption',
            width: 300,
            render: (_value, _row) => (
              <input
                type="text"
                className="lp-input-text w-full px-1"
                value={_value}
                onChange={(e) => setTextValue(_row.language, 'caption', e.target.value)}
              />
            ),
          },
          {
            title: 'Alt',
            dataIndex: 'alt',
            width: 300,
            render: (_value, _row) => (
              <input
                type="text"
                className="lp-input-text w-full px-1"
                value={_value}
                onChange={(e) => setTextValue(_row.language, 'alt', e.target.value)}
              />
            ),
          },
        ]}
        data={texts.map((text) => ({ ...text, key: text.language }))}
      />
      <button className="lp-button" onClick={() => updateDetails()}>
        Save
      </button>
      <button className="lp-button -danger ml-4" onClick={handleDelete} disabled={deleting}>
        Delete
      </button>
    </div>
  );
};
