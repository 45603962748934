import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import './styles/components.scss';
import './styles/typography.scss';
import './styles/utils.scss';
import AppWithAuth from './AppWithAuth';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<div>Loading</div>}>
      <AppWithAuth />
      <ToastContainer />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
