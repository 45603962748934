import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { TLink } from './TLink';

interface Props {
  links: {
    link: string;
    title: string;
  }[];
}

const tabActive = 'bg-gray-200 text-gray-800 px-3 py-2 font-medium text-sm rounded-md';
const tabStyle = 'text-gray-600 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md';

export function Tablinks({ links }: Props) {
  const history = useHistory();
  const { i18n } = useTranslation();
  return (
    <div className="tablinks">
      {links.map((link) => {
        const linkclass =
          history.location.pathname === `/${i18n.language}${link.link ? '/' + link.link : ''}` ? tabActive : tabStyle;
        return (
          <TLink className={linkclass} to={link.link} key={link.link}>
            {link.title}
          </TLink>
        );
      })}
    </div>
  );
}
