import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Characteristics } from '../components/Identification/Characteristics';
import { CharacteristicGroups } from '../components/Identification/CharacteristicGroups';
import { CharacteristicSections } from '../components/Identification/CharacteristicSections';
import { Tablinks } from '../components/Tablinks';
import { CharacteristicValues } from '../components/Identification/CharacteristicValues';
import { Variations } from '../components/Identification/Variations';

const links = [
  {
    link: 'identification/sections',
    title: 'Sections',
  },
  {
    link: 'identification/groups',
    title: 'Groups',
  },
  {
    link: 'identification/characteristics',
    title: 'Characteristics',
  },
  {
    link: 'identification/characteristic_values',
    title: 'Characteristic values',
  },
  {
    link: 'identification/variations',
    title: 'Variations',
  },
];

export function Identification() {
  const match = useRouteMatch();

  const linkPath = useMemo(() => match.path.replace('/*', ''), [match]);

  return (
    <div className="lp-page">
      <Tablinks links={links} />
      <Switch>
        <Route exact path={[`${linkPath}/sections`]}>
          <CharacteristicSections />
        </Route>
        <Route path={`${linkPath}/groups`}>
          <CharacteristicGroups />
        </Route>
        <Route path={`${linkPath}/characteristics`}>
          <Characteristics />
        </Route>
        <Route path={`${linkPath}/characteristic_values`}>
          <CharacteristicValues />
        </Route>
        <Route path={`${linkPath}/variations`}>
          <Variations />
        </Route>
      </Switch>
    </div>
  );
}
