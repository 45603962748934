import { FC, useEffect, useState, useCallback, useMemo } from 'react';
import Table from 'rc-table';
import {
  ApiImagesQuery,
  useApiSingleLargeImageQuery,
  useApiAllLanguageQuery,
  useApiAllImageTagsQuery,
  useApiChangeImageDetailsMutation,
  useApiDeleteImageMutation,
  Image,
  useApiReplaceImageTagsMutation,
} from '../api/generated/graphql';
import { toast } from 'react-toastify';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { SlideOver } from './SlideOver';
import { ImageListItem } from './ImageListItem';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { s3bucket, s3client } from '../AppWithAuth';
import { DeleteObjectCommand, GetObjectCommand } from '@aws-sdk/client-s3';
import { Loader } from 'react-feather';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { TLink } from './TLink';

interface Props {
  data?: ApiImagesQuery;
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<ApiImagesQuery>>;
  handleSearch: (e: string) => void;
  searchValue: string;
  handleTagSearch: (e: any) => void;
  tagValue: any;
}
interface LanguageTexts {
  language: string;
  selectedImageId: string | undefined;
  caption: any;
  alt: any;
}

function download(href: string) {
  const element = document.createElement('a');
  element.setAttribute('href', href);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

export const ImageList: FC<Props> = ({
  data,
  loading,
  refetch,
  handleSearch,
  searchValue,
  handleTagSearch,
  tagValue,
}) => {
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState<string | undefined>(undefined);
  const [copyright, setCopyright] = useState<string | undefined>();
  const [outputFormat, setOutputFormat] = useState<string | undefined>(undefined);
  const [texts, setTexts] = useState<LanguageTexts[]>([]);
  const [tags, setTags] = useState<{ label: string; value: string }[]>([]);
  const allLanguages = useApiAllLanguageQuery();
  const allTags = useApiAllImageTagsQuery();
  const { data: singleImageData } = useApiSingleLargeImageQuery({
    variables: { id: selectedImageId! },
    skip: !selectedImageId,
    onCompleted: async () => {
      if (!singleImageData?.image_by_pk) {
        setSlideOverOpen(false);
        await refetch();
        toast.warning('The image no longer exists');
      }
    },
  });
  const [updateDetails, updateStatus] = useApiChangeImageDetailsMutation();
  const [replaceTags] = useApiReplaceImageTagsMutation();
  const [deleteImage, deleteStatus] = useApiDeleteImageMutation();
  const availableOutputFormats = useMemo(() => {
    const exts = new Set(['jpeg']); // conversion to jpeg is always possible
    const fileExtension = singleImageData?.image_by_pk?.file_extension;
    if (fileExtension) {
      exts.add(fileExtension);
    }
    const outputFormat = singleImageData?.image_by_pk?.output_format;
    if (outputFormat) {
      exts.add(outputFormat);
    }
    return Array.from(exts).sort();
  }, [singleImageData]);
  useEffect(() => {
    if (allLanguages.data && singleImageData) {
      const newTexts: LanguageTexts[] = allLanguages.data.language.map((lang) => {
        const caption = singleImageData?.image_by_pk?.captions[lang.key] ?? '';
        const alt = singleImageData?.image_by_pk?.alts[lang.key] ?? '';
        return {
          language: lang.key,
          selectedImageId,
          caption: caption,
          alt: alt,
        };
      });
      setTexts(newTexts);
      setCopyright(singleImageData?.image_by_pk?.copyright ?? '');
      setTags(singleImageData?.image_by_pk?.image_tags.map((x) => ({ label: x.tag, value: x.tag })) ?? []);
      setOutputFormat(singleImageData?.image_by_pk?.output_format ?? undefined);
    }
    if (!selectedImageId) {
      setTexts([]);
      setCopyright('');
      setTags([]);
      setOutputFormat(undefined);
    }
  }, [allLanguages, selectedImageId, singleImageData]);

  const handleCloseSlideOver = async () => {
    setSlideOverOpen(false);
    setSelectedImageId(undefined);
    await allTags.refetch();
    await refetch();
  };
  const handleDelete = async () => {
    if (selectedImageId === undefined) {
      await handleCloseSlideOver();
      toast.error('No image selected');
    } else {
      if (!window.confirm('Delete image?')) return;
      try {
        await deleteImage({ variables: { id: selectedImageId } });
        await s3client.send(
          new DeleteObjectCommand({
            Bucket: s3bucket,
            Key: selectedImageId,
          })
        );
        await handleCloseSlideOver();
        toast.success('Delete successful!');
      } catch {
        toast.error('Delete failed!');
      }
    }
  };

  const handleUpdate = async () => {
    if (selectedImageId === undefined) {
      await handleCloseSlideOver();
      toast.error('No image selected');
    } else {
      const captions = texts.reduce((acc, x) => ({ ...acc, [x.language]: x.caption }), {});
      const alts = texts.reduce((acc, x) => ({ ...acc, [x.language]: x.alt }), {});
      try {
        await updateDetails({
          variables: {
            id: selectedImageId,
            copyright,
            output_format: outputFormat ?? null,
            captions: captions,
            alts: alts,
          },
        });
        await replaceTags({
          variables: {
            imageId: selectedImageId,
            tags: tags.map((x) => ({
              tag: x.value,
              image_id: selectedImageId,
            })),
          },
        });
        await handleCloseSlideOver();
        toast.success('Update success!');
      } catch {
        toast.error('Update failed!');
      }
    }
  };
  const setTextValue = useCallback(
    (language, type, value) => {
      setTexts(
        texts.map((x) => {
          if (x.language !== language) {
            return x;
          }
          return { ...x, [type]: value };
        })
      );
    },
    [texts, setTexts]
  );

  const allTagOptions = allTags.data?.image_tag.map((x) => ({ label: x.tag, value: x.tag })) ?? [];

  const handleCreateTag = async (inputValue: string) => {
    if (selectedImageId === undefined) {
      await handleCloseSlideOver();
      toast.error('No image selected');
    } else {
      const newTag = inputValue.toLowerCase();
      setTags([...tags, { label: newTag, value: newTag }]);
    }
  };

  const handleTagChange = async (newValue: any) => {
    if (selectedImageId === undefined) {
      handleCloseSlideOver();
      toast.error('No image selected');
    } else {
      setTags(newValue);
    }
  };

  return (
    <>
      <div className="images">
        <h2>Images</h2>
        <div className="mb-6">
          <label htmlFor="search" className="lp-input-text-label">
            Search
          </label>
          <input
            name="search"
            className="lp-input-text w-1/2 max-w-sm"
            type="text"
            placeholder="Search by filename or id..."
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
          />
          <label htmlFor="searchbytag" className="lp-input-text-label">
            Tags
          </label>
          <Select
            isMulti
            id="searchbytag"
            options={allTagOptions}
            className="text-sm max-w-sm"
            value={tagValue.map((x: any) => ({ label: x, value: x }))}
            onChange={(e) => handleTagSearch(e)}
          />
        </div>
        <div className="imagegrid max-w-4xl">
          {loading ? (
            <Loader className="animate-spin" />
          ) : (
            data?.image.map((x) => {
              return (
                <ImageListItem
                  key={x.id}
                  image={x as Image}
                  onClick={() => {
                    setSelectedImageId(x.id);
                    setSlideOverOpen(true);
                  }}
                />
              );
            })
          )}
        </div>
        <SlideOver
          show={slideOverOpen}
          title={singleImageData?.image_by_pk?.filename ?? ''}
          containerClass="max-w-4xl"
          saving={updateStatus.loading}
          deleting={deleteStatus.loading}
          onSaveClick={() => {
            handleUpdate();
          }}
          onCancelClick={() => {
            setSlideOverOpen(false);
            setSelectedImageId(undefined);
          }}
          onDeleteClick={() => {
            handleDelete();
          }}
        >
          {singleImageData && (
            <div className="space-y-2 pt-6 pb-5">
              <button
                className="lp-button -default mb-4"
                onClick={async () => {
                  const img = singleImageData.image_by_pk;
                  if (!img) return;
                  const filename = img.filename ?? `${img.id}${img.file_extension ? `.${img.file_extension}` : ''}`;
                  const cmd = new GetObjectCommand({
                    Key: img.id,
                    Bucket: s3bucket,
                    ResponseContentDisposition: `attachment; filename ="${filename}"`,
                  });
                  const downloadLink = await getSignedUrl(s3client, cmd);
                  download(downloadLink);
                }}
              >
                Download
              </button>
              <TLink className="ml-2 lp-button -default mb-4" to={`imagepreview/${singleImageData.image_by_pk?.id}`}>
                Preview
              </TLink>
              {singleImageData?.image_by_pk?.urls && (
                <a href={singleImageData?.image_by_pk?.urls[0].url} target="_blank" rel="noreferrer">
                  <img
                    className="w-full max-h-80 object-contain"
                    src={singleImageData?.image_by_pk?.urls[0].url}
                    alt={singleImageData?.image_by_pk?.alt ?? 'alt text'}
                  />
                </a>
              )}
              <label className="lp-input-text-label" htmlFor="imageid">
                ID
              </label>
              <input
                id="imageid"
                type="text"
                readOnly={true}
                className="lp-input-text mt-1 w-full"
                value={singleImageData?.image_by_pk?.id ?? '0'}
              />
              <label className="lp-input-text-label" htmlFor="filename">
                Filename
              </label>
              <input
                id="filename"
                type="text"
                readOnly={true}
                className="lp-input-text mt-1 w-full"
                value={singleImageData?.image_by_pk?.filename ?? ''}
              />
              <label className="lp-input-text-label" htmlFor="extension">
                File extension
              </label>
              <input
                id="extension"
                type="text"
                readOnly={true}
                className="lp-input-text mt-1 w-full"
                value={singleImageData?.image_by_pk?.file_extension ?? ''}
              />
              <label className="lp-input-text-label" htmlFor="outputformat">
                Output format{' '}
                <span className="text-xs text-gray-500">(choose jpeg unless you know what you are doing)</span>
              </label>
              <Select
                inputId="outputformat"
                className="lp-input-text mt-1 w-full"
                options={availableOutputFormats.map((y) => ({ label: y, value: y })) ?? []}
                value={outputFormat ? { label: outputFormat, value: outputFormat } : undefined}
                isClearable={true}
                onChange={(e) => {
                  if (e) {
                    setOutputFormat(e.value);
                  } else {
                    setOutputFormat(undefined);
                  }
                }}
              />
              <label className="lp-input-text-label" htmlFor="width">
                Width
              </label>
              <input
                id="width"
                type="text"
                readOnly={true}
                className="lp-input-text mt-1 w-full"
                value={singleImageData?.image_by_pk?.width ?? ''}
              />
              <label className="lp-input-text-label" htmlFor="height">
                Height
              </label>
              <input
                id="height"
                type="text"
                readOnly={true}
                className="lp-input-text mt-1 w-full"
                value={singleImageData?.image_by_pk?.height ?? ''}
              />
              <label className="lp-input-text-label" htmlFor="created_at">
                Created at
              </label>
              <input
                id="created_at"
                type="text"
                readOnly={true}
                className="lp-input-text mt-1 w-full"
                value={
                  singleImageData?.image_by_pk?.created_at
                    ? new Date(Date.parse(singleImageData?.image_by_pk?.created_at)).toLocaleString()
                    : ''
                }
              />
              <label className="lp-input-text-label" htmlFor="copyright">
                Copyright
              </label>
              <input
                id="copyright"
                type="text"
                placeholder="Etunimi Sukunimi"
                className="lp-input-text mt-1 w-full"
                value={copyright ?? ''}
                onChange={(e) => {
                  setCopyright(e.target.value);
                }}
              />
              <label className="lp-input-text-label" htmlFor="tags">
                Tags
              </label>
              <CreatableSelect
                isMulti
                isClearable={false}
                id="tags"
                className="lp-input-text mt-1 w-full"
                classNamePrefix="selectmulti"
                value={tags}
                options={allTagOptions}
                onCreateOption={handleCreateTag}
                onChange={handleTagChange}
              />
              <label className="lp-input-text-label" htmlFor="taxonomies">
                Taxonomy usages
              </label>
              <ul id="taxonomies">
                {singleImageData?.image_by_pk?.taxonomy_images?.length === 0 && (
                  <span className="text-sm">Not used in any taxonomies</span>
                )}
                {singleImageData?.image_by_pk?.taxonomy_images?.map((y) => (
                  <li key={y.taxonomy.id}>
                    <TLink className="text-lp-green text-sm" target="_blank" to={`taxonomy/${y.taxonomy.id}/images`}>
                      {y.taxonomy.common_name ? `${y.taxonomy.common_name} - ` : ''}
                      {y.taxonomy.scientific_name}
                    </TLink>
                  </li>
                ))}
              </ul>

              <Table
                className="my-4 lp-table pt-6"
                columns={[
                  { title: 'Lang', dataIndex: 'language', render: (v) => v.toUpperCase() },
                  {
                    title: 'Caption',
                    dataIndex: 'caption',
                    width: '50%',
                    render: (_value, _row) => (
                      <input
                        type="text"
                        className="lp-input-text w-full px-1"
                        value={_value}
                        onChange={(e) => setTextValue(_row.language, 'caption', e.target.value)}
                      />
                    ),
                  },
                  {
                    title: 'Alt',
                    dataIndex: 'alt',
                    width: '50%',
                    render: (_value, _row) => (
                      <input
                        type="text"
                        className="lp-input-text w-full px-1"
                        value={_value}
                        onChange={(e) => setTextValue(_row.language, 'alt', e.target.value)}
                      />
                    ),
                  },
                ]}
                data={texts.map((text) => ({ ...text, key: text.language }))}
              />
            </div>
          )}
        </SlideOver>
      </div>
    </>
  );
};
