export type TaxonomyWidgetUnknown = { widget_type_id: string; widget_data: unknown };

export const TAXONOMY_WIDGET_LINK_KASVIATLAS = 'link_kasviatlas';
export const TAXONOMY_WIDGET_LINK_GBIF = 'link_gbif';
export const TAXONOMY_WIDGET_LINK_KALAHAVAINNOT = 'link_kalahavainnot';
export const TAXONOMY_WIDGET_LINK_LINTUATLAS = 'link_lintuatlas';
export const TAXONOMY_WIDGET_FUNGUS_EDIBILITY = 'fungus_edibility';
export const TAXONOMY_WIDGET_FUNGUS_COLORING = 'fungus_coloring';
export const TAXONOMY_WIDGET_FISH_LENGTH = 'fish_length';

type TaxonomyWidgetLinkKasviatlas = {
  widget_type_id: typeof TAXONOMY_WIDGET_LINK_KASVIATLAS;
  widget_data: string;
};

type TaxonomyWidgetLinkGbif = {
  widget_type_id: typeof TAXONOMY_WIDGET_LINK_GBIF;
  widget_data: string;
};

type TaxonomyWidgetLinkKalahavainnot = {
  widget_type_id: typeof TAXONOMY_WIDGET_LINK_KALAHAVAINNOT;
  widget_data: string;
};

type TaxonomyWidgetLinkLintuatlas = {
  widget_type_id: typeof TAXONOMY_WIDGET_LINK_LINTUATLAS;
  widget_data: { [key: string]: string };
};

type TaxonomyWidgetFungusEdibility = {
  widget_type_id: typeof TAXONOMY_WIDGET_FUNGUS_EDIBILITY;
  widget_data: number;
};

type TaxonomyWidgetFungusColoring = {
  widget_type_id: typeof TAXONOMY_WIDGET_FUNGUS_COLORING;
  widget_data: boolean;
};

type TaxonomyWidgetFishLength = {
  widget_type_id: typeof TAXONOMY_WIDGET_FISH_LENGTH;
  widget_data: 1 | 2 | 3 | 4 | 5;
};

export type TaxonomyWidget =
  | TaxonomyWidgetLinkKasviatlas
  | TaxonomyWidgetLinkGbif
  | TaxonomyWidgetLinkKalahavainnot
  | TaxonomyWidgetLinkLintuatlas
  | TaxonomyWidgetFungusEdibility
  | TaxonomyWidgetFungusColoring
  | TaxonomyWidgetFishLength;

export const WIDGET_TYPES = [
  TAXONOMY_WIDGET_LINK_KASVIATLAS,
  TAXONOMY_WIDGET_LINK_GBIF,
  TAXONOMY_WIDGET_LINK_KALAHAVAINNOT,
  TAXONOMY_WIDGET_LINK_LINTUATLAS,
  TAXONOMY_WIDGET_FUNGUS_EDIBILITY,
  TAXONOMY_WIDGET_FUNGUS_COLORING,
  TAXONOMY_WIDGET_FISH_LENGTH,
];

export function isTaxonomyWidget(x: TaxonomyWidgetUnknown): x is TaxonomyWidget {
  return WIDGET_TYPES.some((y) => y === x.widget_type_id);
}

export enum FungusEdibility {
  EXCELLENT = 1,
  GOOD = 2,
  OKAY = 3,
  EXCELLENT_AFTER_PARBOILING = 4,
  GOOD_AFTER_PARBOILING = 5,
  OKAY_AFTER_PARBOILING = 6,
  MILDLY_POISONOUS = 8,
  POISONOUS = 9,
  DEADLY_POISONOOUS = 10,
  USELESS = 7,
  DEADLY_POISONOOUS_EDIBLE_IF_PROCESSED = 11,
}

export function fungusEdibilityToString(edibility: number): string | undefined {
  switch (edibility) {
    case FungusEdibility.EXCELLENT:
      return 'Excellent';
    case FungusEdibility.GOOD:
      return 'Good';
    case FungusEdibility.OKAY:
      return 'Okay';
    case FungusEdibility.EXCELLENT_AFTER_PARBOILING:
      return 'Excellent after parboiling';
    case FungusEdibility.GOOD_AFTER_PARBOILING:
      return 'Good after parboiling';
    case FungusEdibility.OKAY_AFTER_PARBOILING:
      return 'Okay after parboiling';
    case FungusEdibility.MILDLY_POISONOUS:
      return 'Mildly poisonous';
    case FungusEdibility.POISONOUS:
      return 'Poisonous';
    case FungusEdibility.DEADLY_POISONOOUS:
      return 'Deadly poisonous';
    case FungusEdibility.USELESS:
      return 'Useless';
    case FungusEdibility.DEADLY_POISONOOUS_EDIBLE_IF_PROCESSED:
      return 'Deadly poisonous, edible if processed';
    default:
      return undefined;
  }
}

export function widgetTypeToString(widgetType: string) {
  switch (widgetType) {
    case TAXONOMY_WIDGET_LINK_KASVIATLAS:
      return 'Link (kasviatlas)';
    case TAXONOMY_WIDGET_LINK_GBIF:
      return 'Link (GBIF)';
    case TAXONOMY_WIDGET_LINK_KALAHAVAINNOT:
      return 'Link (kalahavainnot)';
    case TAXONOMY_WIDGET_LINK_LINTUATLAS:
      return 'Link (lintuatlas)';
    case TAXONOMY_WIDGET_FUNGUS_EDIBILITY:
      return 'Fungus edibility';
    case TAXONOMY_WIDGET_FUNGUS_COLORING:
      return 'Fungus coloring';
    case TAXONOMY_WIDGET_FISH_LENGTH:
      return 'Fish length';
    default:
      return `Unknown widget type (${widgetType})`;
  }
}
