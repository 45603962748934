import { FC } from 'react';
import Select from 'react-select';
import { useApiAllCharacteristicSectionsQuery } from '../../api/generated/graphql';
import { withFallbackName } from '../../utils';

interface Props {
  value: number | undefined;
  onChange: (sectionId: number | undefined) => void;
  filterOption?: (sectionId: number) => boolean;
  inputId: string;
  isClearable?: boolean;
}
export const CharacteristicSectionSelect: FC<Props> = ({ value, onChange, filterOption, inputId, isClearable }) => {
  const { data, loading } = useApiAllCharacteristicSectionsQuery();

  const options =
    data?.characteristic_section
      .map((x) => ({ label: withFallbackName(x), value: x.id }))
      .filter((y) => filterOption === undefined || filterOption(y.value)) ?? [];

  return (
    <Select
      inputId={inputId}
      isSearchable={true}
      isClearable={isClearable}
      value={options?.find((x) => x.value === value)}
      options={options}
      isLoading={loading}
      onChange={(dropdownValue) => {
        onChange(dropdownValue?.value ?? undefined);
      }}
    />
  );
};
