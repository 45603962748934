/* eslint-disable no-restricted-globals */
import { FixedSizeNodeData, FixedSizeTree as Tree, TreeWalker } from 'react-vtree';
import { CSSProperties, useCallback, useMemo, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  useApiAllTaxonomyQuery,
  useApiAddTaxonomyMutation,
  useApiChangeTaxonomyPathMutation,
  useApiFnMoveTaxonomyMutation,
  Taxonomy,
} from '../api/generated/graphql';
import { TLink } from '../components/TLink';
import Fuse from 'fuse.js';
import { debounce } from 'lodash';
import { ReactComponent as PencilIcon } from '../assets/pencil.svg';
import { ReactComponent as MoveIcon } from '../assets/move.svg';
import Table from 'rc-table';
import { ColumnsType } from 'rc-table/lib/interface';
import { Tablinks } from '../components/Tablinks';
import { Route, Switch } from 'react-router-dom';
import { SlideOver } from '../components/SlideOver';
import { WarningBox, WarningTitle, WarningBody, WarningButton } from '../components/WarningBox';
import { CharacteristicSectionSelect } from '../components/Identification/CharacteristicSectionSelect';
import { RankSelect } from '../components/RankSelect';
import { TaxonomySelect } from '../components/TaxonomySelect';
import { toast } from 'react-toastify';
import { Loader } from 'react-feather';

type TaxonomyNode = Pick<Taxonomy, 'id' | 'scientific_name' | 'common_name' | 'rank_id' | 'path'>;

type Node = {
  id: number;
  scientificName?: string | undefined | null;
  commonName?: string | undefined | null;
  rankId: string;
  path: string;
  nestingLevel: number;
  children: { [key: string]: Node };
};

const getName = (node: Node) => node.commonName ?? node.scientificName ?? 'Unnamed';

type NodeMovement = {
  source?: Node | undefined;
  setSource: (node: Node | undefined) => void;
  target?: Node | undefined;
  setTarget: (node: Node | undefined) => void;
};

const toNodeTree = (taxonomies: TaxonomyNode[]): Node[] => {
  const root: Node = {
    id: 0,
    scientificName: '',
    rankId: '',
    path: '0',
    commonName: '',
    nestingLevel: 0,
    children: {},
  };

  for (let taxonomy of taxonomies) {
    const segments = taxonomy.path.split('.');
    let current = root;
    let nestingLevel = 1;

    for (let i = 0; i < segments.length; ++i) {
      const segment = segments[i];
      if (current.children[segment] === undefined || i === segments.length - 1) {
        const id = parseInt(segment, 10);
        current.children[segment] = {
          id,
          scientificName: i === segments.length - 1 ? taxonomy.scientific_name ?? '' : '',
          rankId: i === segments.length - 1 ? taxonomy.rank_id : '',
          commonName: i === segments.length - 1 ? taxonomy.common_name ?? '' : '',
          path: segments.slice(0, i + 1).join('.'),
          nestingLevel,
          children: current.children[segment]?.children ?? {},
        };
      }
      current = current.children[segment];
      nestingLevel++;
    }
  }

  return Object.values(root.children);
};

const getNodeData = (node: Node, nestingLevel: number, movement: NodeMovement) => ({
  data: {
    node,
    id: node.id.toString(),
    isOpenByDefault: false,
    isLeaf: Object.keys(node.children).length === 0,
    nestingLevel,
    movement,
  },
  nestingLevel,
  node,
});

type TreeData = FixedSizeNodeData & { node: Node } & {
  isOpenByDefault: boolean;
  isLeaf: boolean;
  nestingLevel: number;
  movement: NodeMovement;
};

type NodeMeta = Readonly<{
  nestingLevel: number;
  node: Node;
}>;

// The `treeWalker` function runs only on tree re-build which is performed
// whenever the `treeWalker` prop is changed.
function buildTreeWalker(nodes: Node[], movement: NodeMovement) {
  function* treeWalker(): ReturnType<TreeWalker<TreeData, NodeMeta>> {
    for (let i = 0; i < nodes.length; i++) {
      yield getNodeData(nodes[i], 0, movement);
    }
    while (true) {
      const parent: NodeMeta = yield;
      const childrenIds = Object.keys(parent.node.children);
      for (let i = 0; i < childrenIds.length; i++) {
        yield getNodeData(parent.node.children[childrenIds[i]], parent.nestingLevel + 1, movement);
      }
    }
  }
  return treeWalker;
}

const capitalize = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

const NodeComponent = ({
  data: { isLeaf, node, nestingLevel, id, movement },
  isOpen,
  style,
  setOpen,
}: {
  data: TreeData;
  isOpen: boolean;
  style: CSSProperties;
  setOpen: (o: boolean) => void;
}) => {
  const source = movement.source?.id;
  const target = movement.target?.id;

  const ComponentText = () => (
    <>
      {node.commonName ?? 'Unnamed'}
      <span className="ml-1 text-gray-600 text-xs">
        ({capitalize(node.rankId)} {node.scientificName})
      </span>
    </>
  );

  const ComponentCore = () => {
    if (!source) {
      return (
        <>
          <ComponentText />
          <TLink to={`taxonomy/${id}`}>
            <PencilIcon className="ml-2 inline-block w-6 h-6 p-1" />
          </TLink>
          {nestingLevel > 0 && (
            <button onClick={() => movement.setSource(node)}>
              <MoveIcon className="w-4 h-4" />
            </button>
          )}
        </>
      );
    }
    if (!target) {
      return (
        <button onClick={() => movement.setTarget(node)}>
          <ComponentText />
        </button>
      );
    }
    return <ComponentText />;
  };

  return (
    <div style={{ ...style }} className="px-4">
      <div
        className={`flex items-center w-auto${
          source === node.id || target === node.id ? ' rounded-md bg-yellow-50' : ''
        }`}
        style={{ marginLeft: nestingLevel * 30 }}
      >
        {isLeaf || (
          <button type="button" className="bg-gray-100 px-3 py-1" onClick={() => setOpen(!isOpen)}>
            {isOpen ? '-' : '+'}
          </button>
        )}
        <div className={`text-base flex flex-row items-center ml-8${isLeaf ? '' : ' ml-8'}`}>
          <ComponentCore />
        </div>
      </div>
    </div>
  );
};

interface TableRow {
  key: string;
  id: number;
  name: string;
  commonName: string;
}

interface NewTaxonomy {
  name: string;
  sectionId: number | undefined;
  rankId: string | undefined;
  parent: { id: number; path: string } | undefined;
  showInListing: boolean;
}

export const TaxonomyTree = () => {
  const { data, loading, refetch } = useApiAllTaxonomyQuery({ fetchPolicy: 'cache-and-network' });
  const [moveTaxonomy] = useApiFnMoveTaxonomyMutation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [newTaxonomy, setNewTaxonomy] = useState<NewTaxonomy>({
    name: '',
    sectionId: undefined,
    rankId: 'species',
    parent: undefined,
    showInListing: true,
  });
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [confirmMove, setConfirmMove] = useState(false);
  const [moveSourceNode, setMoveSourceNode] = useState<Node | undefined>();
  const [moveTargetNode, setMoveTargetNode] = useState<Node | undefined>();

  const handleSearchUpdate = debounce((e) => setSearchTerm(e), 300, {
    leading: false,
    trailing: true,
  });

  const [add, addStatus] = useApiAddTaxonomyMutation();
  const [change, changeStatus] = useApiChangeTaxonomyPathMutation();

  const handleCloseSlideOver = () => {
    setSlideOverOpen(false);
    setNewTaxonomy({ name: '', sectionId: undefined, rankId: 'species', parent: undefined, showInListing: true });
  };

  const handleAdd = async () => {
    const { name, parent, rankId, sectionId, showInListing } = newTaxonomy;
    if (name.length === 0 || parent === undefined || rankId === undefined) return;

    let saved = false;
    try {
      const created = await add({
        variables: { name, rankId, path: `${parent.path}.0`, sectionId, showInListing },
      });
      const taxonomyId = created.data!.insert_taxonomy_one!.id!;
      await change({ variables: { id: taxonomyId, path: `${parent.path}.${taxonomyId}` } });
      saved = true;
      toast.success('Save successful!');
      handleCloseSlideOver();
    } catch {
      toast.error('Save failed!');
    }

    if (saved) {
      refetch();
    }
  };

  const columns: ColumnsType<TableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 30,
    },
    {
      title: 'Rank',
      dataIndex: 'rankId',
      key: 'rank',
      width: 100,
    },
    {
      title: 'Scientific name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: 'Common name',
      dataIndex: 'commonName',
      key: 'commonName',
      width: 100,
    },
    {
      title: 'Operations',
      dataIndex: '',
      key: 'operations',
      width: 50,
      render: (value, row, index) => (
        <TLink to={`taxonomy/${row.id}`} className="text-base">
          <button onClick={() => {}} className="lp-button-link">
            Edit
          </button>
        </TLink>
      ),
    },
  ];

  const options = {
    includeScore: true,
    minMatchCharLength: 2,
    // Search in `author` and in `tags` array
    keys: ['scientific_name', 'common_name'],
    threshold: 0.3,
  };

  const fuse = new Fuse(data?.taxonomy as TaxonomyNode[], options);
  const result = searchTerm
    ? fuse
        .search(searchTerm)
        .map((x) => x.item)
        .map((x) => ({
          key: x.id.toString(),
          id: x.id,
          name: x.scientific_name ?? '',
          commonName: x.common_name ?? '',
          rankId: x.rank_id,
        }))
    : [];

  const confirmNodeMove = useCallback(
    async (confirmation: boolean) => {
      if (confirmation && moveSourceNode && moveTargetNode && moveTargetNode.id !== moveSourceNode.id) {
        try {
          await moveTaxonomy({ variables: { destinationId: moveTargetNode.id, sourceId: moveSourceNode.id } });
          await refetch();
          window.location.reload();
          toast.success('Moved successfully!');
        } catch (e) {
          console.error(e);
          toast.error('Failed to move taxonomy');
        }
      }
      setMoveSourceNode(undefined);
      setMoveTargetNode(undefined);
      setConfirmMove(false);
    },
    [moveSourceNode, moveTargetNode, moveTaxonomy, refetch]
  );

  const treeWalkerMemoized = useMemo(() => {
    const movement: NodeMovement = {
      source: moveSourceNode,
      setSource: setMoveSourceNode,
      target: moveTargetNode,
      setTarget: setMoveTargetNode,
    };
    return data ? buildTreeWalker(toNodeTree(data?.taxonomy), movement) : undefined;
  }, [data, moveSourceNode, moveTargetNode]);

  return (
    <div className="h-screen">
      <Tablinks
        links={[
          { link: `taxonomy/list`, title: 'List' },
          { link: `taxonomy/tree`, title: 'Tree' },
        ]}
      />
      <h1 className="text-2xl mb-4">Taxonomy</h1>
      <button type="button" className="lp-button mb-4" onClick={() => setSlideOverOpen(true)}>
        Add new taxonomy
      </button>

      {moveSourceNode && !moveTargetNode && (
        <WarningBox>
          <WarningTitle>You have selected to move an entire taxonomy branch!</WarningTitle>
          <WarningBody>
            You can now select a new parent for {getName(moveSourceNode)} and all of its child taxonomies.
          </WarningBody>
          <WarningButton onClick={() => setMoveSourceNode(undefined)}>Cancel</WarningButton>
        </WarningBox>
      )}
      {moveSourceNode && moveTargetNode && (
        <WarningBox>
          <WarningTitle>Warning! You are about to move an entire taxonomy branch!</WarningTitle>
          <WarningBody>
            Confirm movement of {getName(moveSourceNode)} into {getName(moveTargetNode)}?
          </WarningBody>
          <WarningButton
            onClick={() => {
              if (confirmMove) {
                confirmNodeMove(true);
              } else {
                setConfirmMove(true);
              }
            }}
          >
            {confirmMove ? 'Are you sure?' : 'Confirm'}
          </WarningButton>
          <WarningButton onClick={() => confirmNodeMove(false)}>Cancel</WarningButton>
        </WarningBox>
      )}

      <Switch>
        <Route path="*/taxonomy/list">
          <div>
            <div className="mb-4">
              <label htmlFor="search" className="lp-input-text-label">
                Search
              </label>
              <input
                name="search"
                className="lp-input-text max-w-sm"
                type="text"
                onChange={(e) => handleSearchUpdate(e.target.value)}
              />
            </div>
            <Table className="lp-table" columns={columns} data={result} />
          </div>
        </Route>
        <Route path="*/taxonomy/tree">
          <div className="h-full">
            {loading && (
              <div className="m-4">
                <Loader className="animate-spin" />
              </div>
            )}
            {treeWalkerMemoized && (
              <div className="h-full">
                <AutoSizer disableWidth>
                  {({ height }) => (
                    <Tree treeWalker={treeWalkerMemoized} itemSize={50} height={height} width="100%" async={true}>
                      {NodeComponent as any}
                    </Tree>
                  )}
                </AutoSizer>
              </div>
            )}
          </div>
        </Route>
      </Switch>
      <SlideOver
        show={slideOverOpen}
        title="New taxonomy"
        saving={addStatus.loading || changeStatus.loading}
        hideDeleteButton={true}
        onCancelClick={handleCloseSlideOver}
        onSaveClick={handleAdd}
      >
        <div className="space-y-6 pt-6 pb-5">
          <div>
            <h3>General</h3>
            <div>
              <label className="lp-input-text-label" htmlFor="name">
                Scientific name *
              </label>
              <input
                id="name"
                type="text"
                className="lp-input-text mt-1 w-full"
                value={newTaxonomy.name ?? ''}
                onChange={(e) => setNewTaxonomy({ ...newTaxonomy, name: e.target.value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="rank">
                Rank *
              </label>
              <RankSelect
                inputId="rank"
                value={newTaxonomy.rankId}
                onChange={(rankId) => {
                  setNewTaxonomy({ ...newTaxonomy, rankId });
                }}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="parent">
                Parent taxonomy *
              </label>
              <TaxonomySelect
                inputId="parent"
                value={newTaxonomy.parent?.id}
                onChange={(parent) => setNewTaxonomy({ ...newTaxonomy, parent })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="section">
                Section
              </label>
              <CharacteristicSectionSelect
                inputId="section"
                value={newTaxonomy.sectionId}
                onChange={(value) => setNewTaxonomy({ ...newTaxonomy, sectionId: value })}
              />
              <label className="flex flex-row items-center p-1">
                <input
                  type="checkbox"
                  checked={newTaxonomy.showInListing}
                  onChange={(e) => setNewTaxonomy({ ...newTaxonomy, showInListing: e.target.checked })}
                />
                <span className="mx-1">Show in section listing</span>
              </label>
            </div>
          </div>
        </div>
      </SlideOver>
    </div>
  );
};
