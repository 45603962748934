/* eslint-disable jsx-a11y/no-onchange */
import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import {
  ApiSinglePageQuery,
  useApiAddPageContentMutation,
  useApiAllLanguageQuery,
  useApiAllPageContentTypesQuery,
  useApiDeletePageContentMutation,
  useApiSinglePageQuery,
  useApiSpeciesListQuery,
  useApiUpdatePageContentMutation,
} from '../../api/generated/graphql';
import { orderBy } from 'lodash';
import { ColumnsType } from 'rc-table/lib/interface';
import Table from 'rc-table';
import { SlideOver } from '../SlideOver';
import { toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import { PluginManager } from 'tinymce';
import { ImageSelect } from '../ImageSelect';
import { useMemo } from 'react';
import { TLink } from '../TLink';

function HtmlEncode(s: string) {
  var el = document.createElement('div');
  el.innerHTML = s;
  return el.innerText;
}

interface TableRow {
  id: number;
  ordernum: number;
  typeId: string;
  preview: string;
  language: string | undefined;
}

function toPreviewString(row: NonNullable<ApiSinglePageQuery['page_by_pk']>['page_contents'][0]): string {
  switch (row.page_content_type_id) {
    case 'text':
    case 'article':
      return HtmlEncode(row.contents).replace(/(<([^>]+)>)/gi, '');
    case 'bigheroimage':
      return row.image?.filename ?? '-';
    case 'pagecard':
      return row.contents?.title ?? row.contents?.body ?? '-';
    default:
      return `Preview not available for ${row.page_content_type_id}`;
  }
}

function toDefaultContent(pageContentTypeId: string): unknown {
  switch (pageContentTypeId) {
    case 'text':
      return '';
    case 'article':
      return '';
    case 'bigheroimage':
      return {};
    case 'pagecard':
      return {};
    default:
      return {};
  }
}

const SHOW_EVERYTHING = 'EVERYTHING';

export const SingleContentPage = () => {
  const match = useRouteMatch<{ id: string }>();
  const [tableData, settableData] = useState<TableRow[]>([]);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [selectedPageContent, setSelectedPageContent] = useState<any>(null);
  const { data, loading, refetch } = useApiSinglePageQuery({ variables: { id: Number(match.params.id) } });
  const [contentType, setContentType] = useState<string>('');
  const [specieslist, setspecieslist] = useState<any>(null);
  const [selectedLanguage, setSelectedLanguage] = useState<string>('fi');

  const languagesQuery = useApiAllLanguageQuery();
  const languages = languagesQuery.data?.language ?? [];

  const pageContentTypesQuery = useApiAllPageContentTypesQuery();
  const contentTypes = useMemo(() => pageContentTypesQuery.data?.page_content_type ?? [], [pageContentTypesQuery.data]);
  useEffect(() => {
    setContentType(contentTypes && contentTypes[0] ? contentTypes[0].id : '');
  }, [contentTypes]);

  const [addPageContent, addMutationStatus] = useApiAddPageContentMutation();
  const [deletePageContent, deleteMutationStatus] = useApiDeletePageContentMutation();
  const [updatePageContent, updateMutationStatus] = useApiUpdatePageContentMutation();

  const filteredTableData = useMemo(
    () =>
      tableData
        ? tableData.filter(
            (x) => selectedLanguage === SHOW_EVERYTHING || !x.language || x.language === selectedLanguage
          )
        : [],
    [tableData, selectedLanguage]
  );

  useEffect(() => {
    if (!data || !data.page_by_pk) {
      return;
    }
    settableData(
      orderBy(data.page_by_pk.page_contents, ['ordernum', 'language']).map((x) => {
        return {
          key: x.id.toString(),
          id: x.id,
          ordernum: x.ordernum,
          typeId: x.page_content_type_id,
          preview: toPreviewString(x),
          language: x.language ?? undefined,
        };
      })
    );
  }, [data]);
  const columns: ColumnsType<TableRow> = [
    {
      title: 'Ordernum',
      dataIndex: 'ordernum',
      key: 'ordernum',
    },
    {
      title: 'Type',
      dataIndex: 'typeId',
      key: 'typeId',
    },
    {
      title: 'Language',
      dataIndex: 'language',
      key: 'language',
      render: (value) => (value ? value : 'All languages'),
    },
    {
      title: 'Preview',
      dataIndex: 'preview',
      key: 'preview',
      render: (_value) => {
        return <div className="previewcell">{_value}</div>;
      },
    },
    {
      title: 'Operations',
      dataIndex: '',
      key: 'operations',
      render: (_value, _row, index) => (
        <div className="flex">
          <button
            type="button"
            className="h-8 w-8 flex items-center mr-4"
            onClick={() => {
              setSelectedPageContent(data?.page_by_pk?.page_contents.find((x) => x.id === _row.id));
              setSlideOverOpen(true);
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const handlePageContentAdd = async () => {
    const ordernums = filteredTableData?.map((x) => x.ordernum) ?? [];
    const ordernum = Math.max(-1, ...ordernums) + 1;
    try {
      await addPageContent({
        variables: {
          pageId: Number(match.params.id),
          type: contentType,
          contents: toDefaultContent(contentType),
          ordernum: ordernum,
          language: selectedLanguage === SHOW_EVERYTHING ? null : selectedLanguage,
        },
      });
      await refetch();
      toast.success('Update successful!');
    } catch {
      toast.error('Update failed!');
    }
  };

  const handlePageContentDelete = async () => {
    if (!selectedPageContent?.id) {
      toast.error('Update failed!');
      return;
    }
    try {
      await deletePageContent({
        variables: {
          id: selectedPageContent?.id,
        },
      });
      await refetch();
      toast.success('Update successful!');
      setSlideOverOpen(false);
    } catch {
      toast.error('Update failed!');
    }
  };

  const handlePageContentUpdate = async () => {
    if (!selectedPageContent) {
      toast.error('Update failed!');
      return;
    }
    try {
      await updatePageContent({
        variables: {
          id: selectedPageContent.id,
          contents: selectedPageContent.contents,
          ordernum: selectedPageContent.ordernum,
          image_id: selectedPageContent.image_id ?? null,
        },
      });
      await refetch();
      toast.success('Update successful!');
      setSlideOverOpen(false);
      setSelectedPageContent(null);
    } catch {
      toast.error('Update failed!');
    }
  };

  const allSpecies = useApiSpeciesListQuery({
    variables: { lang: selectedLanguage !== SHOW_EVERYTHING ? selectedLanguage : 'en' },
  });
  const allSpeciesData = allSpecies.data;

  useEffect(() => {
    if (allSpeciesData) {
      setspecieslist(
        allSpeciesData.taxonomy.map((x) => {
          return {
            value: x.id.toString(),
            text: x.taxonomy_common_names.find(Boolean)?.name,
          };
        })
      );
    }
  }, [allSpeciesData]);

  const setup = (editor: any) => {
    const editorManager: any = editor.editorManager;
    const pluginManager: PluginManager = editorManager.PluginManager as PluginManager;
    pluginManager.add('lplink', function (editor) {
      /*
            Use to store the instance of the Dialog
             */
      var _dialog: any = false;

      var _speciesOptions = specieslist;

      var _searchValue = '';
      var _titleValue = '';

      function _getDialogConfig() {
        return {
          title: 'Link to a species',
          body: {
            type: 'panel',
            items: [
              {
                type: 'input',
                name: 'title',
                label: 'Title',
              },
              {
                type: 'input',
                name: 'search',
                label: 'Search',
              },
              {
                type: 'listbox',
                name: 'id',
                label: 'Id',
                items: _speciesOptions,
              },
              {
                type: 'input',
                name: 'fragment',
                label: 'Fragment (hash)',
              },
            ],
          },
          buttons: [
            {
              type: 'cancel',
              text: 'Close',
            },
            {
              type: 'submit',
              text: 'Save',
              primary: true,
            },
          ],
          initialData: {
            title: _titleValue,
            search: _searchValue,
            id: _speciesOptions[0] ? _speciesOptions[0].value : '',
          },
          onChange: (api: any, changedComponent: any) => {
            var data: any = api.getData();
            if (changedComponent.name === 'search' && data) {
              _speciesOptions = specieslist.filter(function (x: any) {
                return x.text.toLowerCase().startsWith(data.search.toLowerCase());
              });
              _searchValue = data.search;
              _dialog.redial(_getDialogConfig());
              _dialog.focus('search');
            } else if (changedComponent.name === 'title' && data) {
              _titleValue = data.title;
            }
          },
          onSubmit: (api: any) => {
            var data: any = api.getData();
            /* Insert content when the window form is submitted */
            var fragmentAttr = data.fragment && data.fragment.length > 0 ? ` data-fragment=${data.fragment}` : '';
            editor.insertContent(
              `<lp-taxonomy-link data-id=${data.id}${fragmentAttr}>${data.title}</lp-taxonomy-link>`
            );
            api.close();
          },
        };
      }

      function _onAction() {
        // Open a Dialog, and update the dialog instance var
        _dialog = editor.windowManager.open(_getDialogConfig() as any);
      }

      // Define the Toolbar button
      editor.ui.registry.addButton('lplink', {
        text: 'Specieslink',
        onAction: _onAction,
      });
    });
  };

  const handlePageCardInputChange = (e: any) => {
    setSelectedPageContent({
      ...selectedPageContent,
      contents: {
        ...selectedPageContent.contents,
        [e.target.id]: e.target.value,
      },
    });
  };

  const tabActive = 'bg-gray-200 text-gray-800 px-3 py-2 font-medium text-sm rounded-md';
  const tabStyle = 'text-gray-600 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md';

  if (!data && loading) {
    return null;
  }

  return (
    <div className="singlecontentpage">
      <TLink to="contentpages">Back to the list</TLink>
      <div className="mt-4 flex flex-col">
        <h2>{data?.page_by_pk?.admin_description}</h2>
      </div>
      <div className="mt-6 max-w-2xl flex flex-row items-center mb-4">
        <select
          className="lp-input-text mr-2"
          value={selectedLanguage}
          onChange={(e) => setSelectedLanguage(e.target.value)}
        >
          <option value={SHOW_EVERYTHING}>Show all</option>
          {languages.map((x) => {
            return (
              <option key={x.key} value={x.key}>
                {x.key} - {x.name}
              </option>
            );
          })}
        </select>

        <span className="pageaddheader mr-2">Add new page content</span>
        <select className="lp-input-text mr-2" value={contentType} onChange={(e) => setContentType(e.target.value)}>
          {contentTypes.map((x) => {
            return (
              <option key={x.id} value={x.id}>
                {x.id}
              </option>
            );
          })}
        </select>
        <button
          className="lp-button"
          type="button"
          onClick={() => handlePageContentAdd()}
          disabled={!contentType || addMutationStatus.loading}
        >
          Add
        </button>
      </div>
      <div className="lp-table-container">
        {filteredTableData && !loading && filteredTableData.length > 0 && (
          <Table className="lp-table" columns={columns} data={filteredTableData} />
        )}
      </div>
      <SlideOver
        containerClass="max-w-3xl"
        show={slideOverOpen}
        title={selectedPageContent?.page_content_type_id ?? ''}
        saving={updateMutationStatus.loading}
        deleting={deleteMutationStatus.loading}
        onCancelClick={() => {
          setSelectedPageContent(null);
          return setSlideOverOpen(false);
        }}
        onDeleteClick={() => {
          // eslint-disable-next-line no-restricted-globals
          if (confirm('Are you sure you want to delete the page content?')) {
            handlePageContentDelete();
          } else {
            return;
          }
        }}
        onSaveClick={() => handlePageContentUpdate()}
      >
        {selectedPageContent && (
          <div className="space-y-6 pt-6 pb-5">
            <div className="space-y-3">
              <label className="lp-input-text-label" htmlFor="ordernum">
                Ordernum
              </label>
              <input
                id="ordernum"
                name="ordernum"
                type="number"
                className="lp-input-text mt-1 w-full"
                value={selectedPageContent?.ordernum ?? 0}
                onChange={(e) => {
                  setSelectedPageContent({ ...selectedPageContent, ordernum: Number(e.target.value) });
                }}
              />
              <div className="space-y-3">
                {selectedPageContent.page_content_type_id !== 'bigheroimage' &&
                  selectedPageContent.page_content_type_id !== 'pagecard' && (
                    <Editor
                      apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                      value={selectedPageContent?.contents ?? ''}
                      init={{
                        height: 700,
                        menubar: true,
                        entity_encoding: 'raw',
                        plugins: [
                          'advlist lists link image preview',
                          'searchreplace visualblocks',
                          'insertdatetime paste lplink',
                        ],
                        toolbar:
                          // eslint-disable-next-line no-multi-str
                          'undo redo | formatselect | bold italic backcolor | \
                    bullist numlist | lplink link image',
                        extended_valid_elements: 'lp-taxonomy-link[data-id|data-fragment]',
                        custom_elements: '~lp-taxonomy-link',
                        setup,
                        content_css: '/editor.css',
                      }}
                      onEditorChange={(changed) => {
                        setSelectedPageContent({
                          ...selectedPageContent,
                          contents: changed,
                        });
                      }}
                    />
                  )}
                {selectedPageContent.page_content_type_id === 'pagecard' && (
                  <div className="space-y-2">
                    <label className="lp-input-text-label" htmlFor="title">
                      Title
                    </label>
                    <input
                      id="title"
                      type="text"
                      className="lp-input-text mt-1 w-full"
                      value={selectedPageContent?.contents?.title ?? ''}
                      onChange={(e) => handlePageCardInputChange(e)}
                    />
                    <label className="lp-input-text-label" htmlFor="body">
                      Body
                    </label>
                    <textarea
                      id="body"
                      className="lp-input-text mt-1 w-full"
                      value={selectedPageContent?.contents?.body ?? ''}
                      onChange={(e) => handlePageCardInputChange(e)}
                    />
                    <label className="lp-input-text-label" htmlFor="buttonText">
                      Button text
                    </label>
                    <input
                      id="buttonText"
                      type="text"
                      className="lp-input-text mt-1 w-full"
                      value={selectedPageContent?.contents?.buttonText ?? ''}
                      onChange={(e) => handlePageCardInputChange(e)}
                    />
                    <label className="lp-input-text-label" htmlFor="buttonLink">
                      Button link
                    </label>
                    <input
                      id="buttonLink"
                      type="text"
                      placeholder="http://example.com"
                      className="lp-input-text mt-1 w-full"
                      value={selectedPageContent?.contents?.buttonLink ?? ''}
                      onChange={(e) => handlePageCardInputChange(e)}
                    />
                  </div>
                )}
              </div>
              {selectedPageContent.page_content_type_id !== 'text' && (
                <div className="space-y-3 z-50">
                  <label className="lp-input-text-label" htmlFor="image">
                    Image
                  </label>
                  <ImageSelect
                    inputId="image"
                    value={selectedPageContent.image_id}
                    onChange={(value) => setSelectedPageContent({ ...selectedPageContent, image_id: value })}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </SlideOver>
    </div>
  );
};
