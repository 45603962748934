import { FC, useEffect, useMemo } from 'react';
import { useApiAllLanguageQuery } from '../api/generated/graphql';

interface Props {
  current?: string;
  onChange: (language: string) => any;
  languages?: string[];
}

export const LanguageTabs: FC<Props> = ({ current, onChange, languages }) => {
  const tabActive = 'bg-gray-200 text-gray-800 px-3 py-2 font-medium text-sm rounded-md';
  const tabStyle = 'text-gray-600 hover:text-gray-800 px-3 py-2 font-medium text-sm rounded-md';

  // TODO: Set active language based on first article language variant if current UI language variant is not found.
  const allLanguages = useApiAllLanguageQuery();

  const langs = useMemo(() => {
    return languages ?? allLanguages?.data?.language.map((lang) => lang.key).sort() ?? [];
  }, [allLanguages, languages]);

  useEffect(() => {
    if (!current && langs.length > 0) {
      if (langs.includes('fi')) {
        onChange('fi');
      } else if (langs.includes('en')) {
        onChange('en');
      } else {
        onChange(langs[0]);
      }
    }
  }, [current, onChange, langs]);

  const langOptions = useMemo(() => {
    return langs.map((lang) => {
      return (
        <option value={lang} key={`option_${lang}`}>
          {lang.toUpperCase()}
        </option>
      );
    });
  }, [langs]);

  const langTabs = useMemo(() => {
    return langs.map((lang) => {
      if (lang === current) {
        return (
          <button key={lang} type="button" className={tabActive} aria-current="page">
            {lang.toUpperCase()}
          </button>
        );
      }
      return (
        <button key={lang} type="button" className={tabStyle} onClick={() => onChange(lang)}>
          {lang.toUpperCase()}
        </button>
      );
    });
  }, [current, langs, onChange]);

  return (
    <>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md p-2"
          onBlur={(e) => onChange(e.target.value)}
        >
          {langOptions}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {langTabs}
        </nav>
      </div>
    </>
  );
};
