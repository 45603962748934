import React, { FC, useCallback, useState } from 'react';
import { DropTargetMonitor } from 'react-dnd';
import { toast } from 'react-toastify';
import { ApiImagesQuery, useApiAddImageMutation, useApiAllImageExtensionsQuery } from '../api/generated/graphql';
import { v4 as uuidv4 } from 'uuid';
import { FileuploadBox } from './FileuploadBox';
import { UploadImage, fileListToUploadImages } from './ImageUploadUtils';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { s3bucket, s3client } from '../AppWithAuth';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import { fileExtensionToMimeType, isDefined, mimeTypeToDefaultOutputExtension } from '../utils';

interface Props {
  refetch: () => Promise<ApolloQueryResult<ApiImagesQuery>>;
}

export const ImageUpload: FC<Props> = ({ refetch }) => {
  const [uploadImages, setUploadImages] = useState<UploadImage[]>([]);
  const [addImage] = useApiAddImageMutation();
  const { data } = useApiAllImageExtensionsQuery();
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const toastId = React.useRef<any>(null);

  const handleFileDrop = useCallback(async (monitor: DropTargetMonitor) => {
    if (monitor) {
      const files = monitor.getItem().files;
      if (files) {
        setUploadImages(await fileListToUploadImages(files));
      }
    }
  }, []);

  const handleFileChoose = useCallback(async (files: FileList | null) => {
    setUploadImages(await fileListToUploadImages(files));
  }, []);

  const handleFileUpload = async () => {
    // TODO: progressbar for multiupload
    //
    // progressCallback(progress: { loaded: number; total: number }) {
    //   const progressbar = progress.loaded / progress.total;
    //   if (toastId.current === null) {
    //     toastId.current = toast('Upload in progress....', {
    //       progress: progressbar,
    //     });
    //   } else {
    //     toast.update(toastId.current, {
    //       progress: progressbar,
    //     });
    //   }
    // },
    setIsUpload(true);
    for (const { file, dataUrl } of uploadImages) {
      const imageId = uuidv4();
      const imageFile = new Image();
      imageFile.src = dataUrl;
      const fileMimeType = file.type;
      const validExtensions = data?.image_file_extension
        ?.map((y) => {
          const mimeType = fileExtensionToMimeType(y.extension);
          return mimeType ? { extension: y.extension, mimeType } : undefined;
        })
        ?.filter(isDefined);
      const mimeMatch = validExtensions?.find((y) => y.mimeType === fileMimeType);
      if (mimeMatch) {
        try {
          await imageFile.decode();
          const uploadParams = {
            Bucket: s3bucket,
            Key: imageId,
            Body: file,
          };
          await s3client.send(new PutObjectCommand(uploadParams));

          await addImage({
            variables: {
              id: imageId,
              filename: file.name,
              file_extension: mimeMatch.extension,
              width: imageFile.width,
              height: imageFile.height,
              output_format: mimeTypeToDefaultOutputExtension(mimeMatch.mimeType),
            },
          });
          toast.success('File upload successful!');
          await refetch();
        } catch {
          toast.error('File upload failed');
        } finally {
          if (toastId.current !== null) toast.done(toastId.current);
          toastId.current = null;
        }
      } else {
        toast.error('File extension not supported');
      }
    }
    setUploadImages([]);
    setIsUpload(false);
  };

  return (
    <div>
      <div className="uploadform">
        <h2>Upload images</h2>
        <form>
          <div className="pb-4">
            <div>
              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                {uploadImages.length > 0 ? (
                  uploadImages.map(({ file, dataUrl }, ind) => {
                    return (
                      <div key={`${file.name}${file.size}${ind}`}>
                        {dataUrl && <img className="h-20 w-20" src={dataUrl} alt="uploaded file" />}
                        <span>{file.name}</span>
                      </div>
                    );
                  })
                ) : (
                  <div className="sm:col-span-6">
                    <FileuploadBox onDrop={handleFileDrop} onSelect={handleFileChoose} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-2">
            <div className="flex justify-start">
              {isUpload ? (
                <div className="flex justify-center items-center animate-slowfade">
                  <div className="animate-spin rounded-full h-6 w-6 border-r-2 border-gray-900" />
                </div>
              ) : (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      handleFileUpload();
                    }}
                    className="lp-button"
                    disabled={isUpload || !uploadImages.length}
                  >
                    Save
                  </button>
                </>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
