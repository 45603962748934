/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FC } from 'react';
import { Image } from '../api/generated/graphql';

interface ImageListItemProps {
  image: Image;
  onClick: () => void;
}

export const ImageListItem: FC<ImageListItemProps> = ({ image, onClick }) => {
  return (
    <div>
      <a
        role="button"
        tabIndex={0}
        onClick={onClick}
        className="flex h-full flex-col sm:flex-row sm:space-x-4 lg:flex-col items-center justify-between bg-lp-white rounded shadow overflow-hidden focus:outline-none focus:ring focus:ring-red-500 hover:opacity-75 hover:underline"
      >
        <div className="w-full">
          {image.urls && (
            <img className="w-full max-h-36 object-contain" src={image.urls[0].url} alt={image.alt ?? 'alt text'} />
          )}
        </div>
        <div className="w-full flex flex-col p-1 lg:py-2">
          <h4 className="mb-0 leading-none">{image.filename}</h4>
          <em className="text-xs">
            {image.image_tags.length > 0
              ? image.image_tags
                  .map((x) => x.tag)
                  .sort()
                  .join(', ')
              : ''}
          </em>
        </div>
      </a>
    </div>
  );
};
