import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  _int4: any;
  _text: any;
  bigint: number;
  jsonb: any;
  lquery: any;
  ltree: string;
  ltxtquery: any;
  timestamptz: string;
  uuid: string;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type _Int4_Comparison_Exp = {
  _eq?: Maybe<Scalars['_int4']>;
  _gt?: Maybe<Scalars['_int4']>;
  _gte?: Maybe<Scalars['_int4']>;
  _in?: Maybe<Array<Scalars['_int4']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_int4']>;
  _lte?: Maybe<Scalars['_int4']>;
  _neq?: Maybe<Scalars['_int4']>;
  _nin?: Maybe<Array<Scalars['_int4']>>;
};


/** columns and relationships of "audio" */
export type Audio = {
  __typename?: 'audio';
  copyright?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** A computed field, executes function "computed_audio_url" */
  url?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "audio". All fields are combined with a logical 'AND'. */
export type Audio_Bool_Exp = {
  _and?: Maybe<Array<Audio_Bool_Exp>>;
  _not?: Maybe<Audio_Bool_Exp>;
  _or?: Maybe<Array<Audio_Bool_Exp>>;
  copyright?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "audio" */
export enum Audio_Constraint {
  /** unique or primary key constraint */
  AudioPkey = 'audio_pkey'
}

/** input type for inserting data into table "audio" */
export type Audio_Insert_Input = {
  copyright?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "audio" */
export type Audio_Mutation_Response = {
  __typename?: 'audio_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audio>;
};

/** input type for inserting object relation for remote table "audio" */
export type Audio_Obj_Rel_Insert_Input = {
  data: Audio_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Audio_On_Conflict>;
};

/** on conflict condition type for table "audio" */
export type Audio_On_Conflict = {
  constraint: Audio_Constraint;
  update_columns?: Array<Audio_Update_Column>;
  where?: Maybe<Audio_Bool_Exp>;
};

/** Ordering options when selecting data from "audio". */
export type Audio_Order_By = {
  copyright?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: audio */
export type Audio_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "audio" */
export enum Audio_Select_Column {
  /** column name */
  Copyright = 'copyright',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "audio" */
export type Audio_Set_Input = {
  copyright?: Maybe<Scalars['String']>;
};

/** update columns of table "audio" */
export enum Audio_Update_Column {
  /** column name */
  Copyright = 'copyright'
}


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "characteristic" */
export type Characteristic = {
  __typename?: 'characteristic';
  /** An object relationship */
  characteristic_group?: Maybe<Characteristic_Group>;
  characteristic_group_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  characteristic_values: Array<Characteristic_Value>;
  /** A computed field, executes function "computed_characteristic_description" */
  description?: Maybe<Scalars['String']>;
  descriptions: Scalars['jsonb'];
  /** An object relationship */
  help_icon?: Maybe<Image>;
  help_icon_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  icon?: Maybe<Image>;
  icon_id?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  legacy_help_icon?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "computed_characteristic_name" */
  name?: Maybe<Scalars['String']>;
  names: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  widget_data?: Maybe<Scalars['jsonb']>;
  widget_type?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic" */
export type CharacteristicCharacteristic_ValuesArgs = {
  distinct_on?: Maybe<Array<Characteristic_Value_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Value_Order_By>>;
  where?: Maybe<Characteristic_Value_Bool_Exp>;
};


/** columns and relationships of "characteristic" */
export type CharacteristicDescriptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic" */
export type CharacteristicNamesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic" */
export type CharacteristicWidget_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "characteristic" */
export type Characteristic_Aggregate_Order_By = {
  avg?: Maybe<Characteristic_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Characteristic_Max_Order_By>;
  min?: Maybe<Characteristic_Min_Order_By>;
  stddev?: Maybe<Characteristic_Stddev_Order_By>;
  stddev_pop?: Maybe<Characteristic_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Characteristic_Stddev_Samp_Order_By>;
  sum?: Maybe<Characteristic_Sum_Order_By>;
  var_pop?: Maybe<Characteristic_Var_Pop_Order_By>;
  var_samp?: Maybe<Characteristic_Var_Samp_Order_By>;
  variance?: Maybe<Characteristic_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Characteristic_Append_Input = {
  descriptions?: Maybe<Scalars['jsonb']>;
  names?: Maybe<Scalars['jsonb']>;
  widget_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "characteristic" */
export type Characteristic_Arr_Rel_Insert_Input = {
  data: Array<Characteristic_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Characteristic_On_Conflict>;
};

/** order by avg() on columns of table "characteristic" */
export type Characteristic_Avg_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "characteristic". All fields are combined with a logical 'AND'. */
export type Characteristic_Bool_Exp = {
  _and?: Maybe<Array<Characteristic_Bool_Exp>>;
  _not?: Maybe<Characteristic_Bool_Exp>;
  _or?: Maybe<Array<Characteristic_Bool_Exp>>;
  characteristic_group?: Maybe<Characteristic_Group_Bool_Exp>;
  characteristic_group_id?: Maybe<Int_Comparison_Exp>;
  characteristic_values?: Maybe<Characteristic_Value_Bool_Exp>;
  descriptions?: Maybe<Jsonb_Comparison_Exp>;
  help_icon?: Maybe<Image_Bool_Exp>;
  help_icon_id?: Maybe<Uuid_Comparison_Exp>;
  icon?: Maybe<Image_Bool_Exp>;
  icon_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  legacy_help_icon?: Maybe<Int_Comparison_Exp>;
  legacy_icon?: Maybe<Int_Comparison_Exp>;
  names?: Maybe<Jsonb_Comparison_Exp>;
  ordernum?: Maybe<Int_Comparison_Exp>;
  widget_data?: Maybe<Jsonb_Comparison_Exp>;
  widget_type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "characteristic" */
export enum Characteristic_Constraint {
  /** unique or primary key constraint */
  CharacteristicPkey = 'characteristic_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Characteristic_Delete_At_Path_Input = {
  descriptions?: Maybe<Array<Scalars['String']>>;
  names?: Maybe<Array<Scalars['String']>>;
  widget_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Characteristic_Delete_Elem_Input = {
  descriptions?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['Int']>;
  widget_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Characteristic_Delete_Key_Input = {
  descriptions?: Maybe<Scalars['String']>;
  names?: Maybe<Scalars['String']>;
  widget_data?: Maybe<Scalars['String']>;
};

/** columns and relationships of "characteristic_group" */
export type Characteristic_Group = {
  __typename?: 'characteristic_group';
  /** An object relationship */
  characteristic_section: Characteristic_Section;
  characteristic_section_id: Scalars['Int'];
  /** An array relationship */
  characteristics: Array<Characteristic>;
  /** An object relationship */
  icon: Image;
  icon_id: Scalars['uuid'];
  id: Scalars['Int'];
  legacy_icon: Scalars['Int'];
  /** A computed field, executes function "computed_characteristic_group_name" */
  name?: Maybe<Scalars['String']>;
  names: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  widget_type?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_group" */
export type Characteristic_GroupCharacteristicsArgs = {
  distinct_on?: Maybe<Array<Characteristic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Order_By>>;
  where?: Maybe<Characteristic_Bool_Exp>;
};


/** columns and relationships of "characteristic_group" */
export type Characteristic_GroupNamesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "characteristic_group" */
export type Characteristic_Group_Aggregate_Order_By = {
  avg?: Maybe<Characteristic_Group_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Characteristic_Group_Max_Order_By>;
  min?: Maybe<Characteristic_Group_Min_Order_By>;
  stddev?: Maybe<Characteristic_Group_Stddev_Order_By>;
  stddev_pop?: Maybe<Characteristic_Group_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Characteristic_Group_Stddev_Samp_Order_By>;
  sum?: Maybe<Characteristic_Group_Sum_Order_By>;
  var_pop?: Maybe<Characteristic_Group_Var_Pop_Order_By>;
  var_samp?: Maybe<Characteristic_Group_Var_Samp_Order_By>;
  variance?: Maybe<Characteristic_Group_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Characteristic_Group_Append_Input = {
  names?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "characteristic_group" */
export type Characteristic_Group_Arr_Rel_Insert_Input = {
  data: Array<Characteristic_Group_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Characteristic_Group_On_Conflict>;
};

/** order by avg() on columns of table "characteristic_group" */
export type Characteristic_Group_Avg_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "characteristic_group". All fields are combined with a logical 'AND'. */
export type Characteristic_Group_Bool_Exp = {
  _and?: Maybe<Array<Characteristic_Group_Bool_Exp>>;
  _not?: Maybe<Characteristic_Group_Bool_Exp>;
  _or?: Maybe<Array<Characteristic_Group_Bool_Exp>>;
  characteristic_section?: Maybe<Characteristic_Section_Bool_Exp>;
  characteristic_section_id?: Maybe<Int_Comparison_Exp>;
  characteristics?: Maybe<Characteristic_Bool_Exp>;
  icon?: Maybe<Image_Bool_Exp>;
  icon_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  legacy_icon?: Maybe<Int_Comparison_Exp>;
  names?: Maybe<Jsonb_Comparison_Exp>;
  ordernum?: Maybe<Int_Comparison_Exp>;
  widget_type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "characteristic_group" */
export enum Characteristic_Group_Constraint {
  /** unique or primary key constraint */
  CharacteristicGroupPkey = 'characteristic_group_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Characteristic_Group_Delete_At_Path_Input = {
  names?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Characteristic_Group_Delete_Elem_Input = {
  names?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Characteristic_Group_Delete_Key_Input = {
  names?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "characteristic_group" */
export type Characteristic_Group_Inc_Input = {
  characteristic_section_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  ordernum?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "characteristic_group" */
export type Characteristic_Group_Insert_Input = {
  characteristic_section?: Maybe<Characteristic_Section_Obj_Rel_Insert_Input>;
  characteristic_section_id?: Maybe<Scalars['Int']>;
  characteristics?: Maybe<Characteristic_Arr_Rel_Insert_Input>;
  icon?: Maybe<Image_Obj_Rel_Insert_Input>;
  icon_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['jsonb']>;
  ordernum?: Maybe<Scalars['Int']>;
  widget_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "characteristic_group" */
export type Characteristic_Group_Max_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  widget_type?: Maybe<Order_By>;
};

/** order by min() on columns of table "characteristic_group" */
export type Characteristic_Group_Min_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  widget_type?: Maybe<Order_By>;
};

/** response of any mutation on the table "characteristic_group" */
export type Characteristic_Group_Mutation_Response = {
  __typename?: 'characteristic_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Characteristic_Group>;
};

/** input type for inserting object relation for remote table "characteristic_group" */
export type Characteristic_Group_Obj_Rel_Insert_Input = {
  data: Characteristic_Group_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Characteristic_Group_On_Conflict>;
};

/** on conflict condition type for table "characteristic_group" */
export type Characteristic_Group_On_Conflict = {
  constraint: Characteristic_Group_Constraint;
  update_columns?: Array<Characteristic_Group_Update_Column>;
  where?: Maybe<Characteristic_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "characteristic_group". */
export type Characteristic_Group_Order_By = {
  characteristic_section?: Maybe<Characteristic_Section_Order_By>;
  characteristic_section_id?: Maybe<Order_By>;
  characteristics_aggregate?: Maybe<Characteristic_Aggregate_Order_By>;
  icon?: Maybe<Image_Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  names?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  widget_type?: Maybe<Order_By>;
};

/** primary key columns input for table: characteristic_group */
export type Characteristic_Group_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Characteristic_Group_Prepend_Input = {
  names?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "characteristic_group" */
export enum Characteristic_Group_Select_Column {
  /** column name */
  CharacteristicSectionId = 'characteristic_section_id',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyIcon = 'legacy_icon',
  /** column name */
  Names = 'names',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  WidgetType = 'widget_type'
}

/** input type for updating data in table "characteristic_group" */
export type Characteristic_Group_Set_Input = {
  characteristic_section_id?: Maybe<Scalars['Int']>;
  icon_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['jsonb']>;
  ordernum?: Maybe<Scalars['Int']>;
  widget_type?: Maybe<Scalars['String']>;
};

/** order by stddev() on columns of table "characteristic_group" */
export type Characteristic_Group_Stddev_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "characteristic_group" */
export type Characteristic_Group_Stddev_Pop_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "characteristic_group" */
export type Characteristic_Group_Stddev_Samp_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by sum() on columns of table "characteristic_group" */
export type Characteristic_Group_Sum_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** update columns of table "characteristic_group" */
export enum Characteristic_Group_Update_Column {
  /** column name */
  CharacteristicSectionId = 'characteristic_section_id',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyIcon = 'legacy_icon',
  /** column name */
  Names = 'names',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  WidgetType = 'widget_type'
}

/** order by var_pop() on columns of table "characteristic_group" */
export type Characteristic_Group_Var_Pop_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "characteristic_group" */
export type Characteristic_Group_Var_Samp_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by variance() on columns of table "characteristic_group" */
export type Characteristic_Group_Variance_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "characteristic" */
export type Characteristic_Inc_Input = {
  characteristic_group_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  legacy_help_icon?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  ordernum?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "characteristic" */
export type Characteristic_Insert_Input = {
  characteristic_group?: Maybe<Characteristic_Group_Obj_Rel_Insert_Input>;
  characteristic_group_id?: Maybe<Scalars['Int']>;
  characteristic_values?: Maybe<Characteristic_Value_Arr_Rel_Insert_Input>;
  descriptions?: Maybe<Scalars['jsonb']>;
  help_icon?: Maybe<Image_Obj_Rel_Insert_Input>;
  help_icon_id?: Maybe<Scalars['uuid']>;
  icon?: Maybe<Image_Obj_Rel_Insert_Input>;
  icon_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  legacy_help_icon?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['jsonb']>;
  ordernum?: Maybe<Scalars['Int']>;
  widget_data?: Maybe<Scalars['jsonb']>;
  widget_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "characteristic" */
export type Characteristic_Max_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  help_icon_id?: Maybe<Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  widget_type?: Maybe<Order_By>;
};

/** order by min() on columns of table "characteristic" */
export type Characteristic_Min_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  help_icon_id?: Maybe<Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  widget_type?: Maybe<Order_By>;
};

/** response of any mutation on the table "characteristic" */
export type Characteristic_Mutation_Response = {
  __typename?: 'characteristic_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Characteristic>;
};

/** input type for inserting object relation for remote table "characteristic" */
export type Characteristic_Obj_Rel_Insert_Input = {
  data: Characteristic_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Characteristic_On_Conflict>;
};

/** on conflict condition type for table "characteristic" */
export type Characteristic_On_Conflict = {
  constraint: Characteristic_Constraint;
  update_columns?: Array<Characteristic_Update_Column>;
  where?: Maybe<Characteristic_Bool_Exp>;
};

/** Ordering options when selecting data from "characteristic". */
export type Characteristic_Order_By = {
  characteristic_group?: Maybe<Characteristic_Group_Order_By>;
  characteristic_group_id?: Maybe<Order_By>;
  characteristic_values_aggregate?: Maybe<Characteristic_Value_Aggregate_Order_By>;
  descriptions?: Maybe<Order_By>;
  help_icon?: Maybe<Image_Order_By>;
  help_icon_id?: Maybe<Order_By>;
  icon?: Maybe<Image_Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  names?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  widget_data?: Maybe<Order_By>;
  widget_type?: Maybe<Order_By>;
};

/** primary key columns input for table: characteristic */
export type Characteristic_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Characteristic_Prepend_Input = {
  descriptions?: Maybe<Scalars['jsonb']>;
  names?: Maybe<Scalars['jsonb']>;
  widget_data?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "characteristic_section" */
export type Characteristic_Section = {
  __typename?: 'characteristic_section';
  /** An array relationship */
  characteristic_groups: Array<Characteristic_Group>;
  /** A computed field, executes function "computed_characteristic_section_description" */
  description?: Maybe<Scalars['String']>;
  descriptions: Scalars['jsonb'];
  enabled_languages: Scalars['jsonb'];
  /** An object relationship */
  icon?: Maybe<Image>;
  icon_id?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  identification_enabled_languages: Scalars['jsonb'];
  /** An object relationship */
  image?: Maybe<Image>;
  image_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "computed_characteristic_section_long_description" */
  long_description?: Maybe<Scalars['String']>;
  long_descriptions: Scalars['jsonb'];
  /** A computed field, executes function "computed_characteristic_section_metadata_description" */
  metadata_description?: Maybe<Scalars['String']>;
  metadata_descriptions: Scalars['jsonb'];
  /** A computed field, executes function "computed_characteristic_section_name" */
  name?: Maybe<Scalars['String']>;
  names: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  url_slugs: Scalars['jsonb'];
  /** An array relationship */
  variations: Array<Variation>;
};


/** columns and relationships of "characteristic_section" */
export type Characteristic_SectionCharacteristic_GroupsArgs = {
  distinct_on?: Maybe<Array<Characteristic_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Group_Order_By>>;
  where?: Maybe<Characteristic_Group_Bool_Exp>;
};


/** columns and relationships of "characteristic_section" */
export type Characteristic_SectionDescriptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_section" */
export type Characteristic_SectionEnabled_LanguagesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_section" */
export type Characteristic_SectionIdentification_Enabled_LanguagesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_section" */
export type Characteristic_SectionLong_DescriptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_section" */
export type Characteristic_SectionMetadata_DescriptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_section" */
export type Characteristic_SectionNamesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_section" */
export type Characteristic_SectionUrl_SlugsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_section" */
export type Characteristic_SectionVariationsArgs = {
  distinct_on?: Maybe<Array<Variation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Variation_Order_By>>;
  where?: Maybe<Variation_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Characteristic_Section_Append_Input = {
  descriptions?: Maybe<Scalars['jsonb']>;
  enabled_languages?: Maybe<Scalars['jsonb']>;
  identification_enabled_languages?: Maybe<Scalars['jsonb']>;
  long_descriptions?: Maybe<Scalars['jsonb']>;
  metadata_descriptions?: Maybe<Scalars['jsonb']>;
  names?: Maybe<Scalars['jsonb']>;
  url_slugs?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "characteristic_section". All fields are combined with a logical 'AND'. */
export type Characteristic_Section_Bool_Exp = {
  _and?: Maybe<Array<Characteristic_Section_Bool_Exp>>;
  _not?: Maybe<Characteristic_Section_Bool_Exp>;
  _or?: Maybe<Array<Characteristic_Section_Bool_Exp>>;
  characteristic_groups?: Maybe<Characteristic_Group_Bool_Exp>;
  descriptions?: Maybe<Jsonb_Comparison_Exp>;
  enabled_languages?: Maybe<Jsonb_Comparison_Exp>;
  icon?: Maybe<Image_Bool_Exp>;
  icon_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  identification_enabled_languages?: Maybe<Jsonb_Comparison_Exp>;
  image?: Maybe<Image_Bool_Exp>;
  image_id?: Maybe<Uuid_Comparison_Exp>;
  long_descriptions?: Maybe<Jsonb_Comparison_Exp>;
  metadata_descriptions?: Maybe<Jsonb_Comparison_Exp>;
  names?: Maybe<Jsonb_Comparison_Exp>;
  ordernum?: Maybe<Int_Comparison_Exp>;
  url_slugs?: Maybe<Jsonb_Comparison_Exp>;
  variations?: Maybe<Variation_Bool_Exp>;
};

/** unique or primary key constraints on table "characteristic_section" */
export enum Characteristic_Section_Constraint {
  /** unique or primary key constraint */
  CharacteristicSectionPkey = 'characteristic_section_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Characteristic_Section_Delete_At_Path_Input = {
  descriptions?: Maybe<Array<Scalars['String']>>;
  enabled_languages?: Maybe<Array<Scalars['String']>>;
  identification_enabled_languages?: Maybe<Array<Scalars['String']>>;
  long_descriptions?: Maybe<Array<Scalars['String']>>;
  metadata_descriptions?: Maybe<Array<Scalars['String']>>;
  names?: Maybe<Array<Scalars['String']>>;
  url_slugs?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Characteristic_Section_Delete_Elem_Input = {
  descriptions?: Maybe<Scalars['Int']>;
  enabled_languages?: Maybe<Scalars['Int']>;
  identification_enabled_languages?: Maybe<Scalars['Int']>;
  long_descriptions?: Maybe<Scalars['Int']>;
  metadata_descriptions?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['Int']>;
  url_slugs?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Characteristic_Section_Delete_Key_Input = {
  descriptions?: Maybe<Scalars['String']>;
  enabled_languages?: Maybe<Scalars['String']>;
  identification_enabled_languages?: Maybe<Scalars['String']>;
  long_descriptions?: Maybe<Scalars['String']>;
  metadata_descriptions?: Maybe<Scalars['String']>;
  names?: Maybe<Scalars['String']>;
  url_slugs?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "characteristic_section" */
export type Characteristic_Section_Inc_Input = {
  ordernum?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "characteristic_section" */
export type Characteristic_Section_Insert_Input = {
  characteristic_groups?: Maybe<Characteristic_Group_Arr_Rel_Insert_Input>;
  descriptions?: Maybe<Scalars['jsonb']>;
  enabled_languages?: Maybe<Scalars['jsonb']>;
  icon?: Maybe<Image_Obj_Rel_Insert_Input>;
  icon_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  identification_enabled_languages?: Maybe<Scalars['jsonb']>;
  image?: Maybe<Image_Obj_Rel_Insert_Input>;
  image_id?: Maybe<Scalars['uuid']>;
  long_descriptions?: Maybe<Scalars['jsonb']>;
  metadata_descriptions?: Maybe<Scalars['jsonb']>;
  names?: Maybe<Scalars['jsonb']>;
  ordernum?: Maybe<Scalars['Int']>;
  url_slugs?: Maybe<Scalars['jsonb']>;
  variations?: Maybe<Variation_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "characteristic_section" */
export type Characteristic_Section_Mutation_Response = {
  __typename?: 'characteristic_section_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Characteristic_Section>;
};

/** input type for inserting object relation for remote table "characteristic_section" */
export type Characteristic_Section_Obj_Rel_Insert_Input = {
  data: Characteristic_Section_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Characteristic_Section_On_Conflict>;
};

/** on conflict condition type for table "characteristic_section" */
export type Characteristic_Section_On_Conflict = {
  constraint: Characteristic_Section_Constraint;
  update_columns?: Array<Characteristic_Section_Update_Column>;
  where?: Maybe<Characteristic_Section_Bool_Exp>;
};

/** Ordering options when selecting data from "characteristic_section". */
export type Characteristic_Section_Order_By = {
  characteristic_groups_aggregate?: Maybe<Characteristic_Group_Aggregate_Order_By>;
  descriptions?: Maybe<Order_By>;
  enabled_languages?: Maybe<Order_By>;
  icon?: Maybe<Image_Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  identification_enabled_languages?: Maybe<Order_By>;
  image?: Maybe<Image_Order_By>;
  image_id?: Maybe<Order_By>;
  long_descriptions?: Maybe<Order_By>;
  metadata_descriptions?: Maybe<Order_By>;
  names?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  url_slugs?: Maybe<Order_By>;
  variations_aggregate?: Maybe<Variation_Aggregate_Order_By>;
};

/** primary key columns input for table: characteristic_section */
export type Characteristic_Section_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Characteristic_Section_Prepend_Input = {
  descriptions?: Maybe<Scalars['jsonb']>;
  enabled_languages?: Maybe<Scalars['jsonb']>;
  identification_enabled_languages?: Maybe<Scalars['jsonb']>;
  long_descriptions?: Maybe<Scalars['jsonb']>;
  metadata_descriptions?: Maybe<Scalars['jsonb']>;
  names?: Maybe<Scalars['jsonb']>;
  url_slugs?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "characteristic_section" */
export enum Characteristic_Section_Select_Column {
  /** column name */
  Descriptions = 'descriptions',
  /** column name */
  EnabledLanguages = 'enabled_languages',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationEnabledLanguages = 'identification_enabled_languages',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  LongDescriptions = 'long_descriptions',
  /** column name */
  MetadataDescriptions = 'metadata_descriptions',
  /** column name */
  Names = 'names',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  UrlSlugs = 'url_slugs'
}

/** input type for updating data in table "characteristic_section" */
export type Characteristic_Section_Set_Input = {
  descriptions?: Maybe<Scalars['jsonb']>;
  enabled_languages?: Maybe<Scalars['jsonb']>;
  icon_id?: Maybe<Scalars['uuid']>;
  identification_enabled_languages?: Maybe<Scalars['jsonb']>;
  image_id?: Maybe<Scalars['uuid']>;
  long_descriptions?: Maybe<Scalars['jsonb']>;
  metadata_descriptions?: Maybe<Scalars['jsonb']>;
  names?: Maybe<Scalars['jsonb']>;
  ordernum?: Maybe<Scalars['Int']>;
  url_slugs?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "characteristic_section" */
export enum Characteristic_Section_Update_Column {
  /** column name */
  Descriptions = 'descriptions',
  /** column name */
  EnabledLanguages = 'enabled_languages',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  IdentificationEnabledLanguages = 'identification_enabled_languages',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  LongDescriptions = 'long_descriptions',
  /** column name */
  MetadataDescriptions = 'metadata_descriptions',
  /** column name */
  Names = 'names',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  UrlSlugs = 'url_slugs'
}

/** select columns of table "characteristic" */
export enum Characteristic_Select_Column {
  /** column name */
  CharacteristicGroupId = 'characteristic_group_id',
  /** column name */
  Descriptions = 'descriptions',
  /** column name */
  HelpIconId = 'help_icon_id',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyHelpIcon = 'legacy_help_icon',
  /** column name */
  LegacyIcon = 'legacy_icon',
  /** column name */
  Names = 'names',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  WidgetData = 'widget_data',
  /** column name */
  WidgetType = 'widget_type'
}

/** input type for updating data in table "characteristic" */
export type Characteristic_Set_Input = {
  characteristic_group_id?: Maybe<Scalars['Int']>;
  descriptions?: Maybe<Scalars['jsonb']>;
  help_icon_id?: Maybe<Scalars['uuid']>;
  icon_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  legacy_help_icon?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['jsonb']>;
  ordernum?: Maybe<Scalars['Int']>;
  widget_data?: Maybe<Scalars['jsonb']>;
  widget_type?: Maybe<Scalars['String']>;
};

/** order by stddev() on columns of table "characteristic" */
export type Characteristic_Stddev_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "characteristic" */
export type Characteristic_Stddev_Pop_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "characteristic" */
export type Characteristic_Stddev_Samp_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by sum() on columns of table "characteristic" */
export type Characteristic_Sum_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** update columns of table "characteristic" */
export enum Characteristic_Update_Column {
  /** column name */
  CharacteristicGroupId = 'characteristic_group_id',
  /** column name */
  Descriptions = 'descriptions',
  /** column name */
  HelpIconId = 'help_icon_id',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyHelpIcon = 'legacy_help_icon',
  /** column name */
  LegacyIcon = 'legacy_icon',
  /** column name */
  Names = 'names',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  WidgetData = 'widget_data',
  /** column name */
  WidgetType = 'widget_type'
}

/** columns and relationships of "characteristic_value" */
export type Characteristic_Value = {
  __typename?: 'characteristic_value';
  /** An object relationship */
  characteristic: Characteristic;
  characteristic_id: Scalars['Int'];
  /** An array relationship */
  children: Array<Characteristic_Value>;
  /** A computed field, executes function "computed_characteristic_value_description" */
  description?: Maybe<Scalars['String']>;
  descriptions: Scalars['jsonb'];
  /** An object relationship */
  help_image_large?: Maybe<Image>;
  help_image_large_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  help_image_small?: Maybe<Image>;
  help_image_small_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  icon: Image;
  icon_id: Scalars['uuid'];
  id: Scalars['Int'];
  legacy_icon: Scalars['Int'];
  /** A computed field, executes function "computed_characteristic_value_name" */
  name?: Maybe<Scalars['String']>;
  names: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  parent?: Maybe<Scalars['Int']>;
  /** An array relationship */
  taxonomy_characteristic_values: Array<Taxonomy_Characteristic_Value>;
  widget_data?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "characteristic_value" */
export type Characteristic_ValueChildrenArgs = {
  distinct_on?: Maybe<Array<Characteristic_Value_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Value_Order_By>>;
  where?: Maybe<Characteristic_Value_Bool_Exp>;
};


/** columns and relationships of "characteristic_value" */
export type Characteristic_ValueDescriptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_value" */
export type Characteristic_ValueNamesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "characteristic_value" */
export type Characteristic_ValueTaxonomy_Characteristic_ValuesArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Characteristic_Value_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Characteristic_Value_Order_By>>;
  where?: Maybe<Taxonomy_Characteristic_Value_Bool_Exp>;
};


/** columns and relationships of "characteristic_value" */
export type Characteristic_ValueWidget_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "characteristic_value" */
export type Characteristic_Value_Aggregate_Order_By = {
  avg?: Maybe<Characteristic_Value_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Characteristic_Value_Max_Order_By>;
  min?: Maybe<Characteristic_Value_Min_Order_By>;
  stddev?: Maybe<Characteristic_Value_Stddev_Order_By>;
  stddev_pop?: Maybe<Characteristic_Value_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Characteristic_Value_Stddev_Samp_Order_By>;
  sum?: Maybe<Characteristic_Value_Sum_Order_By>;
  var_pop?: Maybe<Characteristic_Value_Var_Pop_Order_By>;
  var_samp?: Maybe<Characteristic_Value_Var_Samp_Order_By>;
  variance?: Maybe<Characteristic_Value_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Characteristic_Value_Append_Input = {
  descriptions?: Maybe<Scalars['jsonb']>;
  names?: Maybe<Scalars['jsonb']>;
  widget_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "characteristic_value" */
export type Characteristic_Value_Arr_Rel_Insert_Input = {
  data: Array<Characteristic_Value_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Characteristic_Value_On_Conflict>;
};

/** order by avg() on columns of table "characteristic_value" */
export type Characteristic_Value_Avg_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "characteristic_value". All fields are combined with a logical 'AND'. */
export type Characteristic_Value_Bool_Exp = {
  _and?: Maybe<Array<Characteristic_Value_Bool_Exp>>;
  _not?: Maybe<Characteristic_Value_Bool_Exp>;
  _or?: Maybe<Array<Characteristic_Value_Bool_Exp>>;
  characteristic?: Maybe<Characteristic_Bool_Exp>;
  characteristic_id?: Maybe<Int_Comparison_Exp>;
  children?: Maybe<Characteristic_Value_Bool_Exp>;
  descriptions?: Maybe<Jsonb_Comparison_Exp>;
  help_image_large?: Maybe<Image_Bool_Exp>;
  help_image_large_id?: Maybe<Uuid_Comparison_Exp>;
  help_image_small?: Maybe<Image_Bool_Exp>;
  help_image_small_id?: Maybe<Uuid_Comparison_Exp>;
  icon?: Maybe<Image_Bool_Exp>;
  icon_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  legacy_icon?: Maybe<Int_Comparison_Exp>;
  names?: Maybe<Jsonb_Comparison_Exp>;
  ordernum?: Maybe<Int_Comparison_Exp>;
  parent?: Maybe<Int_Comparison_Exp>;
  taxonomy_characteristic_values?: Maybe<Taxonomy_Characteristic_Value_Bool_Exp>;
  widget_data?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "characteristic_value" */
export enum Characteristic_Value_Constraint {
  /** unique or primary key constraint */
  CharacteristicValuePkey = 'characteristic_value_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Characteristic_Value_Delete_At_Path_Input = {
  descriptions?: Maybe<Array<Scalars['String']>>;
  names?: Maybe<Array<Scalars['String']>>;
  widget_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Characteristic_Value_Delete_Elem_Input = {
  descriptions?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['Int']>;
  widget_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Characteristic_Value_Delete_Key_Input = {
  descriptions?: Maybe<Scalars['String']>;
  names?: Maybe<Scalars['String']>;
  widget_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "characteristic_value" */
export type Characteristic_Value_Inc_Input = {
  characteristic_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  ordernum?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "characteristic_value" */
export type Characteristic_Value_Insert_Input = {
  characteristic?: Maybe<Characteristic_Obj_Rel_Insert_Input>;
  characteristic_id?: Maybe<Scalars['Int']>;
  children?: Maybe<Characteristic_Value_Arr_Rel_Insert_Input>;
  descriptions?: Maybe<Scalars['jsonb']>;
  help_image_large?: Maybe<Image_Obj_Rel_Insert_Input>;
  help_image_large_id?: Maybe<Scalars['uuid']>;
  help_image_small?: Maybe<Image_Obj_Rel_Insert_Input>;
  help_image_small_id?: Maybe<Scalars['uuid']>;
  icon?: Maybe<Image_Obj_Rel_Insert_Input>;
  icon_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['jsonb']>;
  ordernum?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['Int']>;
  taxonomy_characteristic_values?: Maybe<Taxonomy_Characteristic_Value_Arr_Rel_Insert_Input>;
  widget_data?: Maybe<Scalars['jsonb']>;
};

/** order by max() on columns of table "characteristic_value" */
export type Characteristic_Value_Max_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  help_image_large_id?: Maybe<Order_By>;
  help_image_small_id?: Maybe<Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** order by min() on columns of table "characteristic_value" */
export type Characteristic_Value_Min_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  help_image_large_id?: Maybe<Order_By>;
  help_image_small_id?: Maybe<Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** response of any mutation on the table "characteristic_value" */
export type Characteristic_Value_Mutation_Response = {
  __typename?: 'characteristic_value_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Characteristic_Value>;
};

/** input type for inserting object relation for remote table "characteristic_value" */
export type Characteristic_Value_Obj_Rel_Insert_Input = {
  data: Characteristic_Value_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Characteristic_Value_On_Conflict>;
};

/** on conflict condition type for table "characteristic_value" */
export type Characteristic_Value_On_Conflict = {
  constraint: Characteristic_Value_Constraint;
  update_columns?: Array<Characteristic_Value_Update_Column>;
  where?: Maybe<Characteristic_Value_Bool_Exp>;
};

/** Ordering options when selecting data from "characteristic_value". */
export type Characteristic_Value_Order_By = {
  characteristic?: Maybe<Characteristic_Order_By>;
  characteristic_id?: Maybe<Order_By>;
  children_aggregate?: Maybe<Characteristic_Value_Aggregate_Order_By>;
  descriptions?: Maybe<Order_By>;
  help_image_large?: Maybe<Image_Order_By>;
  help_image_large_id?: Maybe<Order_By>;
  help_image_small?: Maybe<Image_Order_By>;
  help_image_small_id?: Maybe<Order_By>;
  icon?: Maybe<Image_Order_By>;
  icon_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  names?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  taxonomy_characteristic_values_aggregate?: Maybe<Taxonomy_Characteristic_Value_Aggregate_Order_By>;
  widget_data?: Maybe<Order_By>;
};

/** primary key columns input for table: characteristic_value */
export type Characteristic_Value_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Characteristic_Value_Prepend_Input = {
  descriptions?: Maybe<Scalars['jsonb']>;
  names?: Maybe<Scalars['jsonb']>;
  widget_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "characteristic_value" */
export enum Characteristic_Value_Select_Column {
  /** column name */
  CharacteristicId = 'characteristic_id',
  /** column name */
  Descriptions = 'descriptions',
  /** column name */
  HelpImageLargeId = 'help_image_large_id',
  /** column name */
  HelpImageSmallId = 'help_image_small_id',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyIcon = 'legacy_icon',
  /** column name */
  Names = 'names',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  Parent = 'parent',
  /** column name */
  WidgetData = 'widget_data'
}

/** input type for updating data in table "characteristic_value" */
export type Characteristic_Value_Set_Input = {
  characteristic_id?: Maybe<Scalars['Int']>;
  descriptions?: Maybe<Scalars['jsonb']>;
  help_image_large_id?: Maybe<Scalars['uuid']>;
  help_image_small_id?: Maybe<Scalars['uuid']>;
  icon_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['Int']>;
  legacy_icon?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['jsonb']>;
  ordernum?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['Int']>;
  widget_data?: Maybe<Scalars['jsonb']>;
};

/** order by stddev() on columns of table "characteristic_value" */
export type Characteristic_Value_Stddev_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "characteristic_value" */
export type Characteristic_Value_Stddev_Pop_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "characteristic_value" */
export type Characteristic_Value_Stddev_Samp_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** order by sum() on columns of table "characteristic_value" */
export type Characteristic_Value_Sum_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** update columns of table "characteristic_value" */
export enum Characteristic_Value_Update_Column {
  /** column name */
  CharacteristicId = 'characteristic_id',
  /** column name */
  Descriptions = 'descriptions',
  /** column name */
  HelpImageLargeId = 'help_image_large_id',
  /** column name */
  HelpImageSmallId = 'help_image_small_id',
  /** column name */
  IconId = 'icon_id',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyIcon = 'legacy_icon',
  /** column name */
  Names = 'names',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  Parent = 'parent',
  /** column name */
  WidgetData = 'widget_data'
}

/** order by var_pop() on columns of table "characteristic_value" */
export type Characteristic_Value_Var_Pop_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "characteristic_value" */
export type Characteristic_Value_Var_Samp_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** order by variance() on columns of table "characteristic_value" */
export type Characteristic_Value_Variance_Order_By = {
  characteristic_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "characteristic" */
export type Characteristic_Var_Pop_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "characteristic" */
export type Characteristic_Var_Samp_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** order by variance() on columns of table "characteristic" */
export type Characteristic_Variance_Order_By = {
  characteristic_group_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  legacy_help_icon?: Maybe<Order_By>;
  legacy_icon?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** columns and relationships of "feedback" */
export type Feedback = {
  __typename?: 'feedback';
  created_at: Scalars['timestamptz'];
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  feedback_comments: Array<Feedback_Comment>;
  /** An array relationship */
  feedback_taxonomy_references: Array<Feedback_Taxonomy_Reference>;
  id: Scalars['bigint'];
  message: Scalars['String'];
  metadata: Scalars['jsonb'];
  status: Scalars['String'];
};


/** columns and relationships of "feedback" */
export type FeedbackFeedback_CommentsArgs = {
  distinct_on?: Maybe<Array<Feedback_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Comment_Order_By>>;
  where?: Maybe<Feedback_Comment_Bool_Exp>;
};


/** columns and relationships of "feedback" */
export type FeedbackFeedback_Taxonomy_ReferencesArgs = {
  distinct_on?: Maybe<Array<Feedback_Taxonomy_Reference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Taxonomy_Reference_Order_By>>;
  where?: Maybe<Feedback_Taxonomy_Reference_Bool_Exp>;
};


/** columns and relationships of "feedback" */
export type FeedbackMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "feedback" */
export type Feedback_Aggregate = {
  __typename?: 'feedback_aggregate';
  aggregate?: Maybe<Feedback_Aggregate_Fields>;
  nodes: Array<Feedback>;
};

/** aggregate fields of "feedback" */
export type Feedback_Aggregate_Fields = {
  __typename?: 'feedback_aggregate_fields';
  avg?: Maybe<Feedback_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Feedback_Max_Fields>;
  min?: Maybe<Feedback_Min_Fields>;
  stddev?: Maybe<Feedback_Stddev_Fields>;
  stddev_pop?: Maybe<Feedback_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Feedback_Stddev_Samp_Fields>;
  sum?: Maybe<Feedback_Sum_Fields>;
  var_pop?: Maybe<Feedback_Var_Pop_Fields>;
  var_samp?: Maybe<Feedback_Var_Samp_Fields>;
  variance?: Maybe<Feedback_Variance_Fields>;
};


/** aggregate fields of "feedback" */
export type Feedback_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Feedback_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Feedback_Avg_Fields = {
  __typename?: 'feedback_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "feedback". All fields are combined with a logical 'AND'. */
export type Feedback_Bool_Exp = {
  _and?: Maybe<Array<Feedback_Bool_Exp>>;
  _not?: Maybe<Feedback_Bool_Exp>;
  _or?: Maybe<Array<Feedback_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  feedback_comments?: Maybe<Feedback_Comment_Bool_Exp>;
  feedback_taxonomy_references?: Maybe<Feedback_Taxonomy_Reference_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** columns and relationships of "feedback_comment" */
export type Feedback_Comment = {
  __typename?: 'feedback_comment';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  created_by: User_Account;
  created_by_id: Scalars['uuid'];
  feedback_id: Scalars['bigint'];
  id: Scalars['bigint'];
  message: Scalars['String'];
};

/** order by aggregate values of table "feedback_comment" */
export type Feedback_Comment_Aggregate_Order_By = {
  avg?: Maybe<Feedback_Comment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Feedback_Comment_Max_Order_By>;
  min?: Maybe<Feedback_Comment_Min_Order_By>;
  stddev?: Maybe<Feedback_Comment_Stddev_Order_By>;
  stddev_pop?: Maybe<Feedback_Comment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Feedback_Comment_Stddev_Samp_Order_By>;
  sum?: Maybe<Feedback_Comment_Sum_Order_By>;
  var_pop?: Maybe<Feedback_Comment_Var_Pop_Order_By>;
  var_samp?: Maybe<Feedback_Comment_Var_Samp_Order_By>;
  variance?: Maybe<Feedback_Comment_Variance_Order_By>;
};

/** order by avg() on columns of table "feedback_comment" */
export type Feedback_Comment_Avg_Order_By = {
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "feedback_comment". All fields are combined with a logical 'AND'. */
export type Feedback_Comment_Bool_Exp = {
  _and?: Maybe<Array<Feedback_Comment_Bool_Exp>>;
  _not?: Maybe<Feedback_Comment_Bool_Exp>;
  _or?: Maybe<Array<Feedback_Comment_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<User_Account_Bool_Exp>;
  created_by_id?: Maybe<Uuid_Comparison_Exp>;
  feedback_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "feedback_comment" */
export type Feedback_Comment_Insert_Input = {
  feedback_id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "feedback_comment" */
export type Feedback_Comment_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
};

/** order by min() on columns of table "feedback_comment" */
export type Feedback_Comment_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by_id?: Maybe<Order_By>;
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
};

/** response of any mutation on the table "feedback_comment" */
export type Feedback_Comment_Mutation_Response = {
  __typename?: 'feedback_comment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feedback_Comment>;
};

/** Ordering options when selecting data from "feedback_comment". */
export type Feedback_Comment_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<User_Account_Order_By>;
  created_by_id?: Maybe<Order_By>;
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
};

/** select columns of table "feedback_comment" */
export enum Feedback_Comment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedById = 'created_by_id',
  /** column name */
  FeedbackId = 'feedback_id',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message'
}

/** order by stddev() on columns of table "feedback_comment" */
export type Feedback_Comment_Stddev_Order_By = {
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "feedback_comment" */
export type Feedback_Comment_Stddev_Pop_Order_By = {
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "feedback_comment" */
export type Feedback_Comment_Stddev_Samp_Order_By = {
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "feedback_comment" */
export type Feedback_Comment_Sum_Order_By = {
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "feedback_comment" */
export type Feedback_Comment_Var_Pop_Order_By = {
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "feedback_comment" */
export type Feedback_Comment_Var_Samp_Order_By = {
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "feedback_comment" */
export type Feedback_Comment_Variance_Order_By = {
  feedback_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Feedback_Max_Fields = {
  __typename?: 'feedback_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Feedback_Min_Fields = {
  __typename?: 'feedback_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "feedback" */
export type Feedback_Mutation_Response = {
  __typename?: 'feedback_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feedback>;
};

/** Ordering options when selecting data from "feedback". */
export type Feedback_Order_By = {
  created_at?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  feedback_comments_aggregate?: Maybe<Feedback_Comment_Aggregate_Order_By>;
  feedback_taxonomy_references_aggregate?: Maybe<Feedback_Taxonomy_Reference_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: feedback */
export type Feedback_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "feedback" */
export enum Feedback_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "feedback" */
export type Feedback_Set_Input = {
  status?: Maybe<Scalars['String']>;
};

/** columns and relationships of "feedback_status" */
export type Feedback_Status = {
  __typename?: 'feedback_status';
  status: Scalars['String'];
};

/** Boolean expression to filter rows from the table "feedback_status". All fields are combined with a logical 'AND'. */
export type Feedback_Status_Bool_Exp = {
  _and?: Maybe<Array<Feedback_Status_Bool_Exp>>;
  _not?: Maybe<Feedback_Status_Bool_Exp>;
  _or?: Maybe<Array<Feedback_Status_Bool_Exp>>;
  status?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "feedback_status". */
export type Feedback_Status_Order_By = {
  status?: Maybe<Order_By>;
};

/** select columns of table "feedback_status" */
export enum Feedback_Status_Select_Column {
  /** column name */
  Status = 'status'
}

/** aggregate stddev on columns */
export type Feedback_Stddev_Fields = {
  __typename?: 'feedback_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Feedback_Stddev_Pop_Fields = {
  __typename?: 'feedback_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Feedback_Stddev_Samp_Fields = {
  __typename?: 'feedback_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Feedback_Sum_Fields = {
  __typename?: 'feedback_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference = {
  __typename?: 'feedback_taxonomy_reference';
  /** An object relationship */
  feedback: Feedback;
  feedback_id: Scalars['bigint'];
  /** An object relationship */
  taxonomy: Taxonomy;
  taxonomy_id: Scalars['bigint'];
};

/** order by aggregate values of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Aggregate_Order_By = {
  avg?: Maybe<Feedback_Taxonomy_Reference_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Feedback_Taxonomy_Reference_Max_Order_By>;
  min?: Maybe<Feedback_Taxonomy_Reference_Min_Order_By>;
  stddev?: Maybe<Feedback_Taxonomy_Reference_Stddev_Order_By>;
  stddev_pop?: Maybe<Feedback_Taxonomy_Reference_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Feedback_Taxonomy_Reference_Stddev_Samp_Order_By>;
  sum?: Maybe<Feedback_Taxonomy_Reference_Sum_Order_By>;
  var_pop?: Maybe<Feedback_Taxonomy_Reference_Var_Pop_Order_By>;
  var_samp?: Maybe<Feedback_Taxonomy_Reference_Var_Samp_Order_By>;
  variance?: Maybe<Feedback_Taxonomy_Reference_Variance_Order_By>;
};

/** order by avg() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Avg_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "feedback_taxonomy_reference". All fields are combined with a logical 'AND'. */
export type Feedback_Taxonomy_Reference_Bool_Exp = {
  _and?: Maybe<Array<Feedback_Taxonomy_Reference_Bool_Exp>>;
  _not?: Maybe<Feedback_Taxonomy_Reference_Bool_Exp>;
  _or?: Maybe<Array<Feedback_Taxonomy_Reference_Bool_Exp>>;
  feedback?: Maybe<Feedback_Bool_Exp>;
  feedback_id?: Maybe<Bigint_Comparison_Exp>;
  taxonomy?: Maybe<Taxonomy_Bool_Exp>;
  taxonomy_id?: Maybe<Bigint_Comparison_Exp>;
};

/** order by max() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Max_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Min_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** Ordering options when selecting data from "feedback_taxonomy_reference". */
export type Feedback_Taxonomy_Reference_Order_By = {
  feedback?: Maybe<Feedback_Order_By>;
  feedback_id?: Maybe<Order_By>;
  taxonomy?: Maybe<Taxonomy_Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** select columns of table "feedback_taxonomy_reference" */
export enum Feedback_Taxonomy_Reference_Select_Column {
  /** column name */
  FeedbackId = 'feedback_id',
  /** column name */
  TaxonomyId = 'taxonomy_id'
}

/** order by stddev() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Stddev_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Stddev_Pop_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Stddev_Samp_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Sum_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Var_Pop_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Var_Samp_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "feedback_taxonomy_reference" */
export type Feedback_Taxonomy_Reference_Variance_Order_By = {
  feedback_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Feedback_Var_Pop_Fields = {
  __typename?: 'feedback_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Feedback_Var_Samp_Fields = {
  __typename?: 'feedback_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Feedback_Variance_Fields = {
  __typename?: 'feedback_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

export type Fn_Move_Taxonomy_Args = {
  destination?: Maybe<Scalars['bigint']>;
  source?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "image" */
export type Image = {
  __typename?: 'image';
  /** A computed field, executes function "computed_image_alt" */
  alt?: Maybe<Scalars['String']>;
  alts: Scalars['jsonb'];
  /** A computed field, executes function "computed_image_caption" */
  caption?: Maybe<Scalars['String']>;
  captions: Scalars['jsonb'];
  copyright?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  file_extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  /** An array relationship */
  image_tags: Array<Image_Tag>;
  legacy_alt?: Maybe<Scalars['String']>;
  legacy_caption?: Maybe<Scalars['String']>;
  legacy_category?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['Int']>;
  output_format?: Maybe<Scalars['String']>;
  /** An array relationship */
  taxonomy_images: Array<Taxonomy_Image>;
  /** An aggregate relationship */
  taxonomy_images_aggregate: Taxonomy_Image_Aggregate;
  uploaded_by?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "computed_image_urls" */
  urls?: Maybe<Array<Imgproxy_Urls>>;
  width?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "image" */
export type ImageAltsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "image" */
export type ImageCaptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "image" */
export type ImageImage_TagsArgs = {
  distinct_on?: Maybe<Array<Image_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Tag_Order_By>>;
  where?: Maybe<Image_Tag_Bool_Exp>;
};


/** columns and relationships of "image" */
export type ImageTaxonomy_ImagesArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Image_Order_By>>;
  where?: Maybe<Taxonomy_Image_Bool_Exp>;
};


/** columns and relationships of "image" */
export type ImageTaxonomy_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Image_Order_By>>;
  where?: Maybe<Taxonomy_Image_Bool_Exp>;
};


/** columns and relationships of "image" */
export type ImageUrlsArgs = {
  args: Urls_Image_Args;
  distinct_on?: Maybe<Array<Imgproxy_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Imgproxy_Urls_Order_By>>;
  where?: Maybe<Imgproxy_Urls_Bool_Exp>;
};

/** aggregated selection of "image" */
export type Image_Aggregate = {
  __typename?: 'image_aggregate';
  aggregate?: Maybe<Image_Aggregate_Fields>;
  nodes: Array<Image>;
};

/** aggregate fields of "image" */
export type Image_Aggregate_Fields = {
  __typename?: 'image_aggregate_fields';
  avg?: Maybe<Image_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Image_Max_Fields>;
  min?: Maybe<Image_Min_Fields>;
  stddev?: Maybe<Image_Stddev_Fields>;
  stddev_pop?: Maybe<Image_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Image_Stddev_Samp_Fields>;
  sum?: Maybe<Image_Sum_Fields>;
  var_pop?: Maybe<Image_Var_Pop_Fields>;
  var_samp?: Maybe<Image_Var_Samp_Fields>;
  variance?: Maybe<Image_Variance_Fields>;
};


/** aggregate fields of "image" */
export type Image_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Image_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Image_Append_Input = {
  alts?: Maybe<Scalars['jsonb']>;
  captions?: Maybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Image_Avg_Fields = {
  __typename?: 'image_avg_fields';
  height?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "image". All fields are combined with a logical 'AND'. */
export type Image_Bool_Exp = {
  _and?: Maybe<Array<Image_Bool_Exp>>;
  _not?: Maybe<Image_Bool_Exp>;
  _or?: Maybe<Array<Image_Bool_Exp>>;
  alts?: Maybe<Jsonb_Comparison_Exp>;
  captions?: Maybe<Jsonb_Comparison_Exp>;
  copyright?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_extension?: Maybe<String_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  height?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image_tags?: Maybe<Image_Tag_Bool_Exp>;
  legacy_alt?: Maybe<String_Comparison_Exp>;
  legacy_caption?: Maybe<String_Comparison_Exp>;
  legacy_category?: Maybe<String_Comparison_Exp>;
  legacy_id?: Maybe<Int_Comparison_Exp>;
  output_format?: Maybe<String_Comparison_Exp>;
  taxonomy_images?: Maybe<Taxonomy_Image_Bool_Exp>;
  uploaded_by?: Maybe<Uuid_Comparison_Exp>;
  width?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "image" */
export enum Image_Constraint {
  /** unique or primary key constraint */
  ImagePkey = 'image_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Image_Delete_At_Path_Input = {
  alts?: Maybe<Array<Scalars['String']>>;
  captions?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Image_Delete_Elem_Input = {
  alts?: Maybe<Scalars['Int']>;
  captions?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Image_Delete_Key_Input = {
  alts?: Maybe<Scalars['String']>;
  captions?: Maybe<Scalars['String']>;
};

/** columns and relationships of "image_file_extension" */
export type Image_File_Extension = {
  __typename?: 'image_file_extension';
  extension: Scalars['String'];
};

/** Boolean expression to filter rows from the table "image_file_extension". All fields are combined with a logical 'AND'. */
export type Image_File_Extension_Bool_Exp = {
  _and?: Maybe<Array<Image_File_Extension_Bool_Exp>>;
  _not?: Maybe<Image_File_Extension_Bool_Exp>;
  _or?: Maybe<Array<Image_File_Extension_Bool_Exp>>;
  extension?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "image_file_extension". */
export type Image_File_Extension_Order_By = {
  extension?: Maybe<Order_By>;
};

/** select columns of table "image_file_extension" */
export enum Image_File_Extension_Select_Column {
  /** column name */
  Extension = 'extension'
}

/** input type for incrementing numeric columns in table "image" */
export type Image_Inc_Input = {
  height?: Maybe<Scalars['Int']>;
  legacy_id?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "image" */
export type Image_Insert_Input = {
  alts?: Maybe<Scalars['jsonb']>;
  captions?: Maybe<Scalars['jsonb']>;
  copyright?: Maybe<Scalars['String']>;
  file_extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  image_tags?: Maybe<Image_Tag_Arr_Rel_Insert_Input>;
  legacy_alt?: Maybe<Scalars['String']>;
  legacy_caption?: Maybe<Scalars['String']>;
  legacy_category?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['Int']>;
  output_format?: Maybe<Scalars['String']>;
  taxonomy_images?: Maybe<Taxonomy_Image_Arr_Rel_Insert_Input>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Image_Max_Fields = {
  __typename?: 'image_max_fields';
  copyright?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  legacy_alt?: Maybe<Scalars['String']>;
  legacy_caption?: Maybe<Scalars['String']>;
  legacy_category?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['Int']>;
  output_format?: Maybe<Scalars['String']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Image_Min_Fields = {
  __typename?: 'image_min_fields';
  copyright?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  legacy_alt?: Maybe<Scalars['String']>;
  legacy_caption?: Maybe<Scalars['String']>;
  legacy_category?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['Int']>;
  output_format?: Maybe<Scalars['String']>;
  uploaded_by?: Maybe<Scalars['uuid']>;
  width?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "image" */
export type Image_Mutation_Response = {
  __typename?: 'image_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Image>;
};

/** input type for inserting object relation for remote table "image" */
export type Image_Obj_Rel_Insert_Input = {
  data: Image_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Image_On_Conflict>;
};

/** on conflict condition type for table "image" */
export type Image_On_Conflict = {
  constraint: Image_Constraint;
  update_columns?: Array<Image_Update_Column>;
  where?: Maybe<Image_Bool_Exp>;
};

/** Ordering options when selecting data from "image". */
export type Image_Order_By = {
  alts?: Maybe<Order_By>;
  captions?: Maybe<Order_By>;
  copyright?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_extension?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  height?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_tags_aggregate?: Maybe<Image_Tag_Aggregate_Order_By>;
  legacy_alt?: Maybe<Order_By>;
  legacy_caption?: Maybe<Order_By>;
  legacy_category?: Maybe<Order_By>;
  legacy_id?: Maybe<Order_By>;
  output_format?: Maybe<Order_By>;
  taxonomy_images_aggregate?: Maybe<Taxonomy_Image_Aggregate_Order_By>;
  uploaded_by?: Maybe<Order_By>;
  width?: Maybe<Order_By>;
};

/** primary key columns input for table: image */
export type Image_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Image_Prepend_Input = {
  alts?: Maybe<Scalars['jsonb']>;
  captions?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "image" */
export enum Image_Select_Column {
  /** column name */
  Alts = 'alts',
  /** column name */
  Captions = 'captions',
  /** column name */
  Copyright = 'copyright',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileExtension = 'file_extension',
  /** column name */
  Filename = 'filename',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyAlt = 'legacy_alt',
  /** column name */
  LegacyCaption = 'legacy_caption',
  /** column name */
  LegacyCategory = 'legacy_category',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  OutputFormat = 'output_format',
  /** column name */
  UploadedBy = 'uploaded_by',
  /** column name */
  Width = 'width'
}

/** input type for updating data in table "image" */
export type Image_Set_Input = {
  alts?: Maybe<Scalars['jsonb']>;
  captions?: Maybe<Scalars['jsonb']>;
  copyright?: Maybe<Scalars['String']>;
  file_extension?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  legacy_alt?: Maybe<Scalars['String']>;
  legacy_caption?: Maybe<Scalars['String']>;
  legacy_category?: Maybe<Scalars['String']>;
  legacy_id?: Maybe<Scalars['Int']>;
  output_format?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Image_Stddev_Fields = {
  __typename?: 'image_stddev_fields';
  height?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Image_Stddev_Pop_Fields = {
  __typename?: 'image_stddev_pop_fields';
  height?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Image_Stddev_Samp_Fields = {
  __typename?: 'image_stddev_samp_fields';
  height?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Image_Sum_Fields = {
  __typename?: 'image_sum_fields';
  height?: Maybe<Scalars['Int']>;
  legacy_id?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "image_tag" */
export type Image_Tag = {
  __typename?: 'image_tag';
  image_id: Scalars['uuid'];
  tag: Scalars['String'];
};

/** order by aggregate values of table "image_tag" */
export type Image_Tag_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Image_Tag_Max_Order_By>;
  min?: Maybe<Image_Tag_Min_Order_By>;
};

/** input type for inserting array relation for remote table "image_tag" */
export type Image_Tag_Arr_Rel_Insert_Input = {
  data: Array<Image_Tag_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Image_Tag_On_Conflict>;
};

/** Boolean expression to filter rows from the table "image_tag". All fields are combined with a logical 'AND'. */
export type Image_Tag_Bool_Exp = {
  _and?: Maybe<Array<Image_Tag_Bool_Exp>>;
  _not?: Maybe<Image_Tag_Bool_Exp>;
  _or?: Maybe<Array<Image_Tag_Bool_Exp>>;
  image_id?: Maybe<Uuid_Comparison_Exp>;
  tag?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "image_tag" */
export enum Image_Tag_Constraint {
  /** unique or primary key constraint */
  ImageTagPkey = 'image_tag_pkey'
}

/** input type for inserting data into table "image_tag" */
export type Image_Tag_Insert_Input = {
  image_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "image_tag" */
export type Image_Tag_Max_Order_By = {
  image_id?: Maybe<Order_By>;
  tag?: Maybe<Order_By>;
};

/** order by min() on columns of table "image_tag" */
export type Image_Tag_Min_Order_By = {
  image_id?: Maybe<Order_By>;
  tag?: Maybe<Order_By>;
};

/** response of any mutation on the table "image_tag" */
export type Image_Tag_Mutation_Response = {
  __typename?: 'image_tag_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Image_Tag>;
};

/** on conflict condition type for table "image_tag" */
export type Image_Tag_On_Conflict = {
  constraint: Image_Tag_Constraint;
  update_columns?: Array<Image_Tag_Update_Column>;
  where?: Maybe<Image_Tag_Bool_Exp>;
};

/** Ordering options when selecting data from "image_tag". */
export type Image_Tag_Order_By = {
  image_id?: Maybe<Order_By>;
  tag?: Maybe<Order_By>;
};

/** primary key columns input for table: image_tag */
export type Image_Tag_Pk_Columns_Input = {
  image_id: Scalars['uuid'];
  tag: Scalars['String'];
};

/** select columns of table "image_tag" */
export enum Image_Tag_Select_Column {
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Tag = 'tag'
}

/** input type for updating data in table "image_tag" */
export type Image_Tag_Set_Input = {
  image_id?: Maybe<Scalars['uuid']>;
  tag?: Maybe<Scalars['String']>;
};

/** update columns of table "image_tag" */
export enum Image_Tag_Update_Column {
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Tag = 'tag'
}

/** update columns of table "image" */
export enum Image_Update_Column {
  /** column name */
  Alts = 'alts',
  /** column name */
  Captions = 'captions',
  /** column name */
  Copyright = 'copyright',
  /** column name */
  FileExtension = 'file_extension',
  /** column name */
  Filename = 'filename',
  /** column name */
  Height = 'height',
  /** column name */
  Id = 'id',
  /** column name */
  LegacyAlt = 'legacy_alt',
  /** column name */
  LegacyCaption = 'legacy_caption',
  /** column name */
  LegacyCategory = 'legacy_category',
  /** column name */
  LegacyId = 'legacy_id',
  /** column name */
  OutputFormat = 'output_format',
  /** column name */
  Width = 'width'
}

/** aggregate var_pop on columns */
export type Image_Var_Pop_Fields = {
  __typename?: 'image_var_pop_fields';
  height?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Image_Var_Samp_Fields = {
  __typename?: 'image_var_samp_fields';
  height?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Image_Variance_Fields = {
  __typename?: 'image_variance_fields';
  height?: Maybe<Scalars['Float']>;
  legacy_id?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "imgproxy_preset" */
export type Imgproxy_Preset = {
  __typename?: 'imgproxy_preset';
  name: Scalars['String'];
  processing_opts: Scalars['String'];
};

/** Boolean expression to filter rows from the table "imgproxy_preset". All fields are combined with a logical 'AND'. */
export type Imgproxy_Preset_Bool_Exp = {
  _and?: Maybe<Array<Imgproxy_Preset_Bool_Exp>>;
  _not?: Maybe<Imgproxy_Preset_Bool_Exp>;
  _or?: Maybe<Array<Imgproxy_Preset_Bool_Exp>>;
  name?: Maybe<String_Comparison_Exp>;
  processing_opts?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "imgproxy_preset". */
export type Imgproxy_Preset_Order_By = {
  name?: Maybe<Order_By>;
  processing_opts?: Maybe<Order_By>;
};

/** select columns of table "imgproxy_preset" */
export enum Imgproxy_Preset_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  ProcessingOpts = 'processing_opts'
}

/** columns and relationships of "imgproxy_urls" */
export type Imgproxy_Urls = {
  __typename?: 'imgproxy_urls';
  preset: Scalars['String'];
  url: Scalars['String'];
};

/** Boolean expression to filter rows from the table "imgproxy_urls". All fields are combined with a logical 'AND'. */
export type Imgproxy_Urls_Bool_Exp = {
  _and?: Maybe<Array<Imgproxy_Urls_Bool_Exp>>;
  _not?: Maybe<Imgproxy_Urls_Bool_Exp>;
  _or?: Maybe<Array<Imgproxy_Urls_Bool_Exp>>;
  preset?: Maybe<String_Comparison_Exp>;
  url?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "imgproxy_urls". */
export type Imgproxy_Urls_Order_By = {
  preset?: Maybe<Order_By>;
  url?: Maybe<Order_By>;
};

/** select columns of table "imgproxy_urls" */
export enum Imgproxy_Urls_Select_Column {
  /** column name */
  Preset = 'preset',
  /** column name */
  Url = 'url'
}


/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "language" */
export type Language = {
  __typename?: 'language';
  key: Scalars['String'];
  /** A computed field, executes function "computed_language_name" */
  name?: Maybe<Scalars['String']>;
  names: Scalars['jsonb'];
  published?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "language" */
export type LanguageNamesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Language_Append_Input = {
  names?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "language". All fields are combined with a logical 'AND'. */
export type Language_Bool_Exp = {
  _and?: Maybe<Array<Language_Bool_Exp>>;
  _not?: Maybe<Language_Bool_Exp>;
  _or?: Maybe<Array<Language_Bool_Exp>>;
  key?: Maybe<String_Comparison_Exp>;
  names?: Maybe<Jsonb_Comparison_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Language_Delete_At_Path_Input = {
  names?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Language_Delete_Elem_Input = {
  names?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Language_Delete_Key_Input = {
  names?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "language" */
export type Language_Mutation_Response = {
  __typename?: 'language_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Language>;
};

/** Ordering options when selecting data from "language". */
export type Language_Order_By = {
  key?: Maybe<Order_By>;
  names?: Maybe<Order_By>;
  published?: Maybe<Order_By>;
};

/** primary key columns input for table: language */
export type Language_Pk_Columns_Input = {
  key: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Language_Prepend_Input = {
  names?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "language" */
export enum Language_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  Names = 'names',
  /** column name */
  Published = 'published'
}

/** input type for updating data in table "language" */
export type Language_Set_Input = {
  names?: Maybe<Scalars['jsonb']>;
  published?: Maybe<Scalars['Boolean']>;
};



/** Boolean expression to compare columns of type "ltree". All fields are combined with logical 'AND'. */
export type Ltree_Comparison_Exp = {
  /** is the left argument an ancestor of right (or equal)? */
  _ancestor?: Maybe<Scalars['ltree']>;
  /** does array contain an ancestor of `ltree`? */
  _ancestor_any?: Maybe<Array<Scalars['ltree']>>;
  /** is the left argument a descendant of right (or equal)? */
  _descendant?: Maybe<Scalars['ltree']>;
  /** does array contain a descendant of `ltree`? */
  _descendant_any?: Maybe<Array<Scalars['ltree']>>;
  _eq?: Maybe<Scalars['ltree']>;
  _gt?: Maybe<Scalars['ltree']>;
  _gte?: Maybe<Scalars['ltree']>;
  _in?: Maybe<Array<Scalars['ltree']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['ltree']>;
  _lte?: Maybe<Scalars['ltree']>;
  /** does `ltree` match `lquery`? */
  _matches?: Maybe<Scalars['lquery']>;
  /** does `ltree` match any `lquery` in array? */
  _matches_any?: Maybe<Array<Scalars['String']>>;
  /** does `ltree` match `ltxtquery`? */
  _matches_fulltext?: Maybe<Scalars['ltxtquery']>;
  _neq?: Maybe<Scalars['ltree']>;
  _nin?: Maybe<Array<Scalars['ltree']>>;
};


/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "audio" */
  delete_audio?: Maybe<Audio_Mutation_Response>;
  /** delete single row from the table: "audio" */
  delete_audio_by_pk?: Maybe<Audio>;
  /** delete data from the table: "characteristic" */
  delete_characteristic?: Maybe<Characteristic_Mutation_Response>;
  /** delete single row from the table: "characteristic" */
  delete_characteristic_by_pk?: Maybe<Characteristic>;
  /** delete data from the table: "characteristic_group" */
  delete_characteristic_group?: Maybe<Characteristic_Group_Mutation_Response>;
  /** delete single row from the table: "characteristic_group" */
  delete_characteristic_group_by_pk?: Maybe<Characteristic_Group>;
  /** delete data from the table: "characteristic_section" */
  delete_characteristic_section?: Maybe<Characteristic_Section_Mutation_Response>;
  /** delete single row from the table: "characteristic_section" */
  delete_characteristic_section_by_pk?: Maybe<Characteristic_Section>;
  /** delete data from the table: "characteristic_value" */
  delete_characteristic_value?: Maybe<Characteristic_Value_Mutation_Response>;
  /** delete single row from the table: "characteristic_value" */
  delete_characteristic_value_by_pk?: Maybe<Characteristic_Value>;
  /** delete data from the table: "feedback_comment" */
  delete_feedback_comment?: Maybe<Feedback_Comment_Mutation_Response>;
  /** delete single row from the table: "feedback_comment" */
  delete_feedback_comment_by_pk?: Maybe<Feedback_Comment>;
  /** delete data from the table: "image" */
  delete_image?: Maybe<Image_Mutation_Response>;
  /** delete single row from the table: "image" */
  delete_image_by_pk?: Maybe<Image>;
  /** delete data from the table: "image_tag" */
  delete_image_tag?: Maybe<Image_Tag_Mutation_Response>;
  /** delete single row from the table: "image_tag" */
  delete_image_tag_by_pk?: Maybe<Image_Tag>;
  /** delete data from the table: "page_content" */
  delete_page_content?: Maybe<Page_Content_Mutation_Response>;
  /** delete single row from the table: "page_content" */
  delete_page_content_by_pk?: Maybe<Page_Content>;
  /** delete data from the table: "taxonomy" */
  delete_taxonomy?: Maybe<Taxonomy_Mutation_Response>;
  /** delete data from the table: "taxonomy_article" */
  delete_taxonomy_article?: Maybe<Taxonomy_Article_Mutation_Response>;
  /** delete data from the table: "taxonomy_audio" */
  delete_taxonomy_audio?: Maybe<Taxonomy_Audio_Mutation_Response>;
  /** delete single row from the table: "taxonomy_audio" */
  delete_taxonomy_audio_by_pk?: Maybe<Taxonomy_Audio>;
  /** delete single row from the table: "taxonomy" */
  delete_taxonomy_by_pk?: Maybe<Taxonomy>;
  /** delete data from the table: "taxonomy_characteristic_section" */
  delete_taxonomy_characteristic_section?: Maybe<Taxonomy_Characteristic_Section_Mutation_Response>;
  /** delete single row from the table: "taxonomy_characteristic_section" */
  delete_taxonomy_characteristic_section_by_pk?: Maybe<Taxonomy_Characteristic_Section>;
  /** delete data from the table: "taxonomy_characteristic_value" */
  delete_taxonomy_characteristic_value?: Maybe<Taxonomy_Characteristic_Value_Mutation_Response>;
  /** delete single row from the table: "taxonomy_characteristic_value" */
  delete_taxonomy_characteristic_value_by_pk?: Maybe<Taxonomy_Characteristic_Value>;
  /** delete data from the table: "taxonomy_common_name" */
  delete_taxonomy_common_name?: Maybe<Taxonomy_Common_Name_Mutation_Response>;
  /** delete data from the table: "taxonomy_image" */
  delete_taxonomy_image?: Maybe<Taxonomy_Image_Mutation_Response>;
  /** delete data from the table: "taxonomy_scientific_name" */
  delete_taxonomy_scientific_name?: Maybe<Taxonomy_Scientific_Name_Mutation_Response>;
  /** delete data from the table: "taxonomy_widget" */
  delete_taxonomy_widget?: Maybe<Taxonomy_Widget_Mutation_Response>;
  /** delete data from the table: "variation" */
  delete_variation?: Maybe<Variation_Mutation_Response>;
  /** delete single row from the table: "variation" */
  delete_variation_by_pk?: Maybe<Variation>;
  /** execute VOLATILE function "fn_move_taxonomy" which returns "taxonomy" */
  fn_move_taxonomy: Array<Taxonomy>;
  /** insert data into the table: "audio" */
  insert_audio?: Maybe<Audio_Mutation_Response>;
  /** insert a single row into the table: "audio" */
  insert_audio_one?: Maybe<Audio>;
  /** insert data into the table: "characteristic" */
  insert_characteristic?: Maybe<Characteristic_Mutation_Response>;
  /** insert data into the table: "characteristic_group" */
  insert_characteristic_group?: Maybe<Characteristic_Group_Mutation_Response>;
  /** insert a single row into the table: "characteristic_group" */
  insert_characteristic_group_one?: Maybe<Characteristic_Group>;
  /** insert a single row into the table: "characteristic" */
  insert_characteristic_one?: Maybe<Characteristic>;
  /** insert data into the table: "characteristic_section" */
  insert_characteristic_section?: Maybe<Characteristic_Section_Mutation_Response>;
  /** insert a single row into the table: "characteristic_section" */
  insert_characteristic_section_one?: Maybe<Characteristic_Section>;
  /** insert data into the table: "characteristic_value" */
  insert_characteristic_value?: Maybe<Characteristic_Value_Mutation_Response>;
  /** insert a single row into the table: "characteristic_value" */
  insert_characteristic_value_one?: Maybe<Characteristic_Value>;
  /** insert data into the table: "feedback_comment" */
  insert_feedback_comment?: Maybe<Feedback_Comment_Mutation_Response>;
  /** insert a single row into the table: "feedback_comment" */
  insert_feedback_comment_one?: Maybe<Feedback_Comment>;
  /** insert data into the table: "image" */
  insert_image?: Maybe<Image_Mutation_Response>;
  /** insert a single row into the table: "image" */
  insert_image_one?: Maybe<Image>;
  /** insert data into the table: "image_tag" */
  insert_image_tag?: Maybe<Image_Tag_Mutation_Response>;
  /** insert a single row into the table: "image_tag" */
  insert_image_tag_one?: Maybe<Image_Tag>;
  /** insert data into the table: "page" */
  insert_page?: Maybe<Page_Mutation_Response>;
  /** insert data into the table: "page_content" */
  insert_page_content?: Maybe<Page_Content_Mutation_Response>;
  /** insert a single row into the table: "page_content" */
  insert_page_content_one?: Maybe<Page_Content>;
  /** insert a single row into the table: "page" */
  insert_page_one?: Maybe<Page>;
  /** insert data into the table: "taxonomy" */
  insert_taxonomy?: Maybe<Taxonomy_Mutation_Response>;
  /** insert data into the table: "taxonomy_article" */
  insert_taxonomy_article?: Maybe<Taxonomy_Article_Mutation_Response>;
  /** insert a single row into the table: "taxonomy_article" */
  insert_taxonomy_article_one?: Maybe<Taxonomy_Article>;
  /** insert data into the table: "taxonomy_audio" */
  insert_taxonomy_audio?: Maybe<Taxonomy_Audio_Mutation_Response>;
  /** insert a single row into the table: "taxonomy_audio" */
  insert_taxonomy_audio_one?: Maybe<Taxonomy_Audio>;
  /** insert data into the table: "taxonomy_characteristic_section" */
  insert_taxonomy_characteristic_section?: Maybe<Taxonomy_Characteristic_Section_Mutation_Response>;
  /** insert a single row into the table: "taxonomy_characteristic_section" */
  insert_taxonomy_characteristic_section_one?: Maybe<Taxonomy_Characteristic_Section>;
  /** insert data into the table: "taxonomy_characteristic_value" */
  insert_taxonomy_characteristic_value?: Maybe<Taxonomy_Characteristic_Value_Mutation_Response>;
  /** insert a single row into the table: "taxonomy_characteristic_value" */
  insert_taxonomy_characteristic_value_one?: Maybe<Taxonomy_Characteristic_Value>;
  /** insert data into the table: "taxonomy_common_name" */
  insert_taxonomy_common_name?: Maybe<Taxonomy_Common_Name_Mutation_Response>;
  /** insert a single row into the table: "taxonomy_common_name" */
  insert_taxonomy_common_name_one?: Maybe<Taxonomy_Common_Name>;
  /** insert data into the table: "taxonomy_image" */
  insert_taxonomy_image?: Maybe<Taxonomy_Image_Mutation_Response>;
  /** insert a single row into the table: "taxonomy_image" */
  insert_taxonomy_image_one?: Maybe<Taxonomy_Image>;
  /** insert a single row into the table: "taxonomy" */
  insert_taxonomy_one?: Maybe<Taxonomy>;
  /** insert data into the table: "taxonomy_scientific_name" */
  insert_taxonomy_scientific_name?: Maybe<Taxonomy_Scientific_Name_Mutation_Response>;
  /** insert a single row into the table: "taxonomy_scientific_name" */
  insert_taxonomy_scientific_name_one?: Maybe<Taxonomy_Scientific_Name>;
  /** insert data into the table: "taxonomy_widget" */
  insert_taxonomy_widget?: Maybe<Taxonomy_Widget_Mutation_Response>;
  /** insert a single row into the table: "taxonomy_widget" */
  insert_taxonomy_widget_one?: Maybe<Taxonomy_Widget>;
  /** insert data into the table: "variation" */
  insert_variation?: Maybe<Variation_Mutation_Response>;
  /** insert a single row into the table: "variation" */
  insert_variation_one?: Maybe<Variation>;
  /** update data of the table: "audio" */
  update_audio?: Maybe<Audio_Mutation_Response>;
  /** update single row of the table: "audio" */
  update_audio_by_pk?: Maybe<Audio>;
  /** update data of the table: "characteristic" */
  update_characteristic?: Maybe<Characteristic_Mutation_Response>;
  /** update single row of the table: "characteristic" */
  update_characteristic_by_pk?: Maybe<Characteristic>;
  /** update data of the table: "characteristic_group" */
  update_characteristic_group?: Maybe<Characteristic_Group_Mutation_Response>;
  /** update single row of the table: "characteristic_group" */
  update_characteristic_group_by_pk?: Maybe<Characteristic_Group>;
  /** update data of the table: "characteristic_section" */
  update_characteristic_section?: Maybe<Characteristic_Section_Mutation_Response>;
  /** update single row of the table: "characteristic_section" */
  update_characteristic_section_by_pk?: Maybe<Characteristic_Section>;
  /** update data of the table: "characteristic_value" */
  update_characteristic_value?: Maybe<Characteristic_Value_Mutation_Response>;
  /** update single row of the table: "characteristic_value" */
  update_characteristic_value_by_pk?: Maybe<Characteristic_Value>;
  /** update data of the table: "feedback" */
  update_feedback?: Maybe<Feedback_Mutation_Response>;
  /** update single row of the table: "feedback" */
  update_feedback_by_pk?: Maybe<Feedback>;
  /** update data of the table: "image" */
  update_image?: Maybe<Image_Mutation_Response>;
  /** update single row of the table: "image" */
  update_image_by_pk?: Maybe<Image>;
  /** update data of the table: "image_tag" */
  update_image_tag?: Maybe<Image_Tag_Mutation_Response>;
  /** update single row of the table: "image_tag" */
  update_image_tag_by_pk?: Maybe<Image_Tag>;
  /** update data of the table: "language" */
  update_language?: Maybe<Language_Mutation_Response>;
  /** update single row of the table: "language" */
  update_language_by_pk?: Maybe<Language>;
  /** update data of the table: "page" */
  update_page?: Maybe<Page_Mutation_Response>;
  /** update single row of the table: "page" */
  update_page_by_pk?: Maybe<Page>;
  /** update data of the table: "page_content" */
  update_page_content?: Maybe<Page_Content_Mutation_Response>;
  /** update single row of the table: "page_content" */
  update_page_content_by_pk?: Maybe<Page_Content>;
  /** update data of the table: "rank" */
  update_rank?: Maybe<Rank_Mutation_Response>;
  /** update single row of the table: "rank" */
  update_rank_by_pk?: Maybe<Rank>;
  /** update data of the table: "taxonomy" */
  update_taxonomy?: Maybe<Taxonomy_Mutation_Response>;
  /** update data of the table: "taxonomy_article" */
  update_taxonomy_article?: Maybe<Taxonomy_Article_Mutation_Response>;
  /** update data of the table: "taxonomy_audio" */
  update_taxonomy_audio?: Maybe<Taxonomy_Audio_Mutation_Response>;
  /** update single row of the table: "taxonomy_audio" */
  update_taxonomy_audio_by_pk?: Maybe<Taxonomy_Audio>;
  /** update single row of the table: "taxonomy" */
  update_taxonomy_by_pk?: Maybe<Taxonomy>;
  /** update data of the table: "taxonomy_characteristic_value" */
  update_taxonomy_characteristic_value?: Maybe<Taxonomy_Characteristic_Value_Mutation_Response>;
  /** update single row of the table: "taxonomy_characteristic_value" */
  update_taxonomy_characteristic_value_by_pk?: Maybe<Taxonomy_Characteristic_Value>;
  /** update data of the table: "taxonomy_common_name" */
  update_taxonomy_common_name?: Maybe<Taxonomy_Common_Name_Mutation_Response>;
  /** update data of the table: "taxonomy_image" */
  update_taxonomy_image?: Maybe<Taxonomy_Image_Mutation_Response>;
  /** update data of the table: "taxonomy_scientific_name" */
  update_taxonomy_scientific_name?: Maybe<Taxonomy_Scientific_Name_Mutation_Response>;
  /** update data of the table: "taxonomy_widget" */
  update_taxonomy_widget?: Maybe<Taxonomy_Widget_Mutation_Response>;
  /** update data of the table: "variation" */
  update_variation?: Maybe<Variation_Mutation_Response>;
  /** update single row of the table: "variation" */
  update_variation_by_pk?: Maybe<Variation>;
};


/** mutation root */
export type Mutation_RootDelete_AudioArgs = {
  where: Audio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audio_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_CharacteristicArgs = {
  where: Characteristic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Characteristic_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Characteristic_GroupArgs = {
  where: Characteristic_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Characteristic_Group_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Characteristic_SectionArgs = {
  where: Characteristic_Section_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Characteristic_Section_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Characteristic_ValueArgs = {
  where: Characteristic_Value_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Characteristic_Value_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Feedback_CommentArgs = {
  where: Feedback_Comment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Feedback_Comment_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_ImageArgs = {
  where: Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Image_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Image_TagArgs = {
  where: Image_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Image_Tag_By_PkArgs = {
  image_id: Scalars['uuid'];
  tag: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Page_ContentArgs = {
  where: Page_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Page_Content_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_TaxonomyArgs = {
  where: Taxonomy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_ArticleArgs = {
  where: Taxonomy_Article_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_AudioArgs = {
  where: Taxonomy_Audio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_Audio_By_PkArgs = {
  audio_id: Scalars['String'];
  taxonomy_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_Characteristic_SectionArgs = {
  where: Taxonomy_Characteristic_Section_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_Characteristic_Section_By_PkArgs = {
  characteristic_section_id: Scalars['Int'];
  taxonomy_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_Characteristic_ValueArgs = {
  where: Taxonomy_Characteristic_Value_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_Characteristic_Value_By_PkArgs = {
  characteristic_value_id: Scalars['Int'];
  taxonomy_id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_Common_NameArgs = {
  where: Taxonomy_Common_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_ImageArgs = {
  where: Taxonomy_Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_Scientific_NameArgs = {
  where: Taxonomy_Scientific_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Taxonomy_WidgetArgs = {
  where: Taxonomy_Widget_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_VariationArgs = {
  where: Variation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Variation_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootFn_Move_TaxonomyArgs = {
  args: Fn_Move_Taxonomy_Args;
  distinct_on?: Maybe<Array<Taxonomy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Order_By>>;
  where?: Maybe<Taxonomy_Bool_Exp>;
};


/** mutation root */
export type Mutation_RootInsert_AudioArgs = {
  objects: Array<Audio_Insert_Input>;
  on_conflict?: Maybe<Audio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audio_OneArgs = {
  object: Audio_Insert_Input;
  on_conflict?: Maybe<Audio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CharacteristicArgs = {
  objects: Array<Characteristic_Insert_Input>;
  on_conflict?: Maybe<Characteristic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Characteristic_GroupArgs = {
  objects: Array<Characteristic_Group_Insert_Input>;
  on_conflict?: Maybe<Characteristic_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Characteristic_Group_OneArgs = {
  object: Characteristic_Group_Insert_Input;
  on_conflict?: Maybe<Characteristic_Group_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Characteristic_OneArgs = {
  object: Characteristic_Insert_Input;
  on_conflict?: Maybe<Characteristic_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Characteristic_SectionArgs = {
  objects: Array<Characteristic_Section_Insert_Input>;
  on_conflict?: Maybe<Characteristic_Section_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Characteristic_Section_OneArgs = {
  object: Characteristic_Section_Insert_Input;
  on_conflict?: Maybe<Characteristic_Section_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Characteristic_ValueArgs = {
  objects: Array<Characteristic_Value_Insert_Input>;
  on_conflict?: Maybe<Characteristic_Value_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Characteristic_Value_OneArgs = {
  object: Characteristic_Value_Insert_Input;
  on_conflict?: Maybe<Characteristic_Value_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Feedback_CommentArgs = {
  objects: Array<Feedback_Comment_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Feedback_Comment_OneArgs = {
  object: Feedback_Comment_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ImageArgs = {
  objects: Array<Image_Insert_Input>;
  on_conflict?: Maybe<Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_OneArgs = {
  object: Image_Insert_Input;
  on_conflict?: Maybe<Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_TagArgs = {
  objects: Array<Image_Tag_Insert_Input>;
  on_conflict?: Maybe<Image_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_Tag_OneArgs = {
  object: Image_Tag_Insert_Input;
  on_conflict?: Maybe<Image_Tag_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PageArgs = {
  objects: Array<Page_Insert_Input>;
  on_conflict?: Maybe<Page_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Page_ContentArgs = {
  objects: Array<Page_Content_Insert_Input>;
  on_conflict?: Maybe<Page_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Page_Content_OneArgs = {
  object: Page_Content_Insert_Input;
  on_conflict?: Maybe<Page_Content_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Page_OneArgs = {
  object: Page_Insert_Input;
  on_conflict?: Maybe<Page_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaxonomyArgs = {
  objects: Array<Taxonomy_Insert_Input>;
  on_conflict?: Maybe<Taxonomy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_ArticleArgs = {
  objects: Array<Taxonomy_Article_Insert_Input>;
  on_conflict?: Maybe<Taxonomy_Article_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Article_OneArgs = {
  object: Taxonomy_Article_Insert_Input;
  on_conflict?: Maybe<Taxonomy_Article_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_AudioArgs = {
  objects: Array<Taxonomy_Audio_Insert_Input>;
  on_conflict?: Maybe<Taxonomy_Audio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Audio_OneArgs = {
  object: Taxonomy_Audio_Insert_Input;
  on_conflict?: Maybe<Taxonomy_Audio_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Characteristic_SectionArgs = {
  objects: Array<Taxonomy_Characteristic_Section_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Characteristic_Section_OneArgs = {
  object: Taxonomy_Characteristic_Section_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Characteristic_ValueArgs = {
  objects: Array<Taxonomy_Characteristic_Value_Insert_Input>;
  on_conflict?: Maybe<Taxonomy_Characteristic_Value_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Characteristic_Value_OneArgs = {
  object: Taxonomy_Characteristic_Value_Insert_Input;
  on_conflict?: Maybe<Taxonomy_Characteristic_Value_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Common_NameArgs = {
  objects: Array<Taxonomy_Common_Name_Insert_Input>;
  on_conflict?: Maybe<Taxonomy_Common_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Common_Name_OneArgs = {
  object: Taxonomy_Common_Name_Insert_Input;
  on_conflict?: Maybe<Taxonomy_Common_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_ImageArgs = {
  objects: Array<Taxonomy_Image_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Image_OneArgs = {
  object: Taxonomy_Image_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_OneArgs = {
  object: Taxonomy_Insert_Input;
  on_conflict?: Maybe<Taxonomy_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Scientific_NameArgs = {
  objects: Array<Taxonomy_Scientific_Name_Insert_Input>;
  on_conflict?: Maybe<Taxonomy_Scientific_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Scientific_Name_OneArgs = {
  object: Taxonomy_Scientific_Name_Insert_Input;
  on_conflict?: Maybe<Taxonomy_Scientific_Name_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_WidgetArgs = {
  objects: Array<Taxonomy_Widget_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Taxonomy_Widget_OneArgs = {
  object: Taxonomy_Widget_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_VariationArgs = {
  objects: Array<Variation_Insert_Input>;
  on_conflict?: Maybe<Variation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Variation_OneArgs = {
  object: Variation_Insert_Input;
  on_conflict?: Maybe<Variation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AudioArgs = {
  _set?: Maybe<Audio_Set_Input>;
  where: Audio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audio_By_PkArgs = {
  _set?: Maybe<Audio_Set_Input>;
  pk_columns: Audio_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_CharacteristicArgs = {
  _append?: Maybe<Characteristic_Append_Input>;
  _delete_at_path?: Maybe<Characteristic_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Characteristic_Delete_Elem_Input>;
  _delete_key?: Maybe<Characteristic_Delete_Key_Input>;
  _inc?: Maybe<Characteristic_Inc_Input>;
  _prepend?: Maybe<Characteristic_Prepend_Input>;
  _set?: Maybe<Characteristic_Set_Input>;
  where: Characteristic_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Characteristic_By_PkArgs = {
  _append?: Maybe<Characteristic_Append_Input>;
  _delete_at_path?: Maybe<Characteristic_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Characteristic_Delete_Elem_Input>;
  _delete_key?: Maybe<Characteristic_Delete_Key_Input>;
  _inc?: Maybe<Characteristic_Inc_Input>;
  _prepend?: Maybe<Characteristic_Prepend_Input>;
  _set?: Maybe<Characteristic_Set_Input>;
  pk_columns: Characteristic_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Characteristic_GroupArgs = {
  _append?: Maybe<Characteristic_Group_Append_Input>;
  _delete_at_path?: Maybe<Characteristic_Group_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Characteristic_Group_Delete_Elem_Input>;
  _delete_key?: Maybe<Characteristic_Group_Delete_Key_Input>;
  _inc?: Maybe<Characteristic_Group_Inc_Input>;
  _prepend?: Maybe<Characteristic_Group_Prepend_Input>;
  _set?: Maybe<Characteristic_Group_Set_Input>;
  where: Characteristic_Group_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Characteristic_Group_By_PkArgs = {
  _append?: Maybe<Characteristic_Group_Append_Input>;
  _delete_at_path?: Maybe<Characteristic_Group_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Characteristic_Group_Delete_Elem_Input>;
  _delete_key?: Maybe<Characteristic_Group_Delete_Key_Input>;
  _inc?: Maybe<Characteristic_Group_Inc_Input>;
  _prepend?: Maybe<Characteristic_Group_Prepend_Input>;
  _set?: Maybe<Characteristic_Group_Set_Input>;
  pk_columns: Characteristic_Group_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Characteristic_SectionArgs = {
  _append?: Maybe<Characteristic_Section_Append_Input>;
  _delete_at_path?: Maybe<Characteristic_Section_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Characteristic_Section_Delete_Elem_Input>;
  _delete_key?: Maybe<Characteristic_Section_Delete_Key_Input>;
  _inc?: Maybe<Characteristic_Section_Inc_Input>;
  _prepend?: Maybe<Characteristic_Section_Prepend_Input>;
  _set?: Maybe<Characteristic_Section_Set_Input>;
  where: Characteristic_Section_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Characteristic_Section_By_PkArgs = {
  _append?: Maybe<Characteristic_Section_Append_Input>;
  _delete_at_path?: Maybe<Characteristic_Section_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Characteristic_Section_Delete_Elem_Input>;
  _delete_key?: Maybe<Characteristic_Section_Delete_Key_Input>;
  _inc?: Maybe<Characteristic_Section_Inc_Input>;
  _prepend?: Maybe<Characteristic_Section_Prepend_Input>;
  _set?: Maybe<Characteristic_Section_Set_Input>;
  pk_columns: Characteristic_Section_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Characteristic_ValueArgs = {
  _append?: Maybe<Characteristic_Value_Append_Input>;
  _delete_at_path?: Maybe<Characteristic_Value_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Characteristic_Value_Delete_Elem_Input>;
  _delete_key?: Maybe<Characteristic_Value_Delete_Key_Input>;
  _inc?: Maybe<Characteristic_Value_Inc_Input>;
  _prepend?: Maybe<Characteristic_Value_Prepend_Input>;
  _set?: Maybe<Characteristic_Value_Set_Input>;
  where: Characteristic_Value_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Characteristic_Value_By_PkArgs = {
  _append?: Maybe<Characteristic_Value_Append_Input>;
  _delete_at_path?: Maybe<Characteristic_Value_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Characteristic_Value_Delete_Elem_Input>;
  _delete_key?: Maybe<Characteristic_Value_Delete_Key_Input>;
  _inc?: Maybe<Characteristic_Value_Inc_Input>;
  _prepend?: Maybe<Characteristic_Value_Prepend_Input>;
  _set?: Maybe<Characteristic_Value_Set_Input>;
  pk_columns: Characteristic_Value_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FeedbackArgs = {
  _set?: Maybe<Feedback_Set_Input>;
  where: Feedback_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Feedback_By_PkArgs = {
  _set?: Maybe<Feedback_Set_Input>;
  pk_columns: Feedback_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ImageArgs = {
  _append?: Maybe<Image_Append_Input>;
  _delete_at_path?: Maybe<Image_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Image_Delete_Elem_Input>;
  _delete_key?: Maybe<Image_Delete_Key_Input>;
  _inc?: Maybe<Image_Inc_Input>;
  _prepend?: Maybe<Image_Prepend_Input>;
  _set?: Maybe<Image_Set_Input>;
  where: Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Image_By_PkArgs = {
  _append?: Maybe<Image_Append_Input>;
  _delete_at_path?: Maybe<Image_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Image_Delete_Elem_Input>;
  _delete_key?: Maybe<Image_Delete_Key_Input>;
  _inc?: Maybe<Image_Inc_Input>;
  _prepend?: Maybe<Image_Prepend_Input>;
  _set?: Maybe<Image_Set_Input>;
  pk_columns: Image_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Image_TagArgs = {
  _set?: Maybe<Image_Tag_Set_Input>;
  where: Image_Tag_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Image_Tag_By_PkArgs = {
  _set?: Maybe<Image_Tag_Set_Input>;
  pk_columns: Image_Tag_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LanguageArgs = {
  _append?: Maybe<Language_Append_Input>;
  _delete_at_path?: Maybe<Language_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Language_Delete_Elem_Input>;
  _delete_key?: Maybe<Language_Delete_Key_Input>;
  _prepend?: Maybe<Language_Prepend_Input>;
  _set?: Maybe<Language_Set_Input>;
  where: Language_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Language_By_PkArgs = {
  _append?: Maybe<Language_Append_Input>;
  _delete_at_path?: Maybe<Language_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Language_Delete_Elem_Input>;
  _delete_key?: Maybe<Language_Delete_Key_Input>;
  _prepend?: Maybe<Language_Prepend_Input>;
  _set?: Maybe<Language_Set_Input>;
  pk_columns: Language_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PageArgs = {
  _append?: Maybe<Page_Append_Input>;
  _delete_at_path?: Maybe<Page_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Page_Delete_Elem_Input>;
  _delete_key?: Maybe<Page_Delete_Key_Input>;
  _prepend?: Maybe<Page_Prepend_Input>;
  _set?: Maybe<Page_Set_Input>;
  where: Page_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Page_By_PkArgs = {
  _append?: Maybe<Page_Append_Input>;
  _delete_at_path?: Maybe<Page_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Page_Delete_Elem_Input>;
  _delete_key?: Maybe<Page_Delete_Key_Input>;
  _prepend?: Maybe<Page_Prepend_Input>;
  _set?: Maybe<Page_Set_Input>;
  pk_columns: Page_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Page_ContentArgs = {
  _append?: Maybe<Page_Content_Append_Input>;
  _delete_at_path?: Maybe<Page_Content_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Page_Content_Delete_Elem_Input>;
  _delete_key?: Maybe<Page_Content_Delete_Key_Input>;
  _inc?: Maybe<Page_Content_Inc_Input>;
  _prepend?: Maybe<Page_Content_Prepend_Input>;
  _set?: Maybe<Page_Content_Set_Input>;
  where: Page_Content_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Page_Content_By_PkArgs = {
  _append?: Maybe<Page_Content_Append_Input>;
  _delete_at_path?: Maybe<Page_Content_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Page_Content_Delete_Elem_Input>;
  _delete_key?: Maybe<Page_Content_Delete_Key_Input>;
  _inc?: Maybe<Page_Content_Inc_Input>;
  _prepend?: Maybe<Page_Content_Prepend_Input>;
  _set?: Maybe<Page_Content_Set_Input>;
  pk_columns: Page_Content_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_RankArgs = {
  _append?: Maybe<Rank_Append_Input>;
  _delete_at_path?: Maybe<Rank_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Rank_Delete_Elem_Input>;
  _delete_key?: Maybe<Rank_Delete_Key_Input>;
  _prepend?: Maybe<Rank_Prepend_Input>;
  _set?: Maybe<Rank_Set_Input>;
  where: Rank_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Rank_By_PkArgs = {
  _append?: Maybe<Rank_Append_Input>;
  _delete_at_path?: Maybe<Rank_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Rank_Delete_Elem_Input>;
  _delete_key?: Maybe<Rank_Delete_Key_Input>;
  _prepend?: Maybe<Rank_Prepend_Input>;
  _set?: Maybe<Rank_Set_Input>;
  pk_columns: Rank_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TaxonomyArgs = {
  _inc?: Maybe<Taxonomy_Inc_Input>;
  _set?: Maybe<Taxonomy_Set_Input>;
  where: Taxonomy_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_ArticleArgs = {
  _inc?: Maybe<Taxonomy_Article_Inc_Input>;
  _set?: Maybe<Taxonomy_Article_Set_Input>;
  where: Taxonomy_Article_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_AudioArgs = {
  _inc?: Maybe<Taxonomy_Audio_Inc_Input>;
  _set?: Maybe<Taxonomy_Audio_Set_Input>;
  where: Taxonomy_Audio_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_Audio_By_PkArgs = {
  _inc?: Maybe<Taxonomy_Audio_Inc_Input>;
  _set?: Maybe<Taxonomy_Audio_Set_Input>;
  pk_columns: Taxonomy_Audio_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_By_PkArgs = {
  _inc?: Maybe<Taxonomy_Inc_Input>;
  _set?: Maybe<Taxonomy_Set_Input>;
  pk_columns: Taxonomy_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_Characteristic_ValueArgs = {
  _inc?: Maybe<Taxonomy_Characteristic_Value_Inc_Input>;
  _set?: Maybe<Taxonomy_Characteristic_Value_Set_Input>;
  where: Taxonomy_Characteristic_Value_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_Characteristic_Value_By_PkArgs = {
  _inc?: Maybe<Taxonomy_Characteristic_Value_Inc_Input>;
  _set?: Maybe<Taxonomy_Characteristic_Value_Set_Input>;
  pk_columns: Taxonomy_Characteristic_Value_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_Common_NameArgs = {
  _inc?: Maybe<Taxonomy_Common_Name_Inc_Input>;
  _set?: Maybe<Taxonomy_Common_Name_Set_Input>;
  where: Taxonomy_Common_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_ImageArgs = {
  _inc?: Maybe<Taxonomy_Image_Inc_Input>;
  _set?: Maybe<Taxonomy_Image_Set_Input>;
  where: Taxonomy_Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_Scientific_NameArgs = {
  _set?: Maybe<Taxonomy_Scientific_Name_Set_Input>;
  where: Taxonomy_Scientific_Name_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taxonomy_WidgetArgs = {
  _append?: Maybe<Taxonomy_Widget_Append_Input>;
  _delete_at_path?: Maybe<Taxonomy_Widget_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Taxonomy_Widget_Delete_Elem_Input>;
  _delete_key?: Maybe<Taxonomy_Widget_Delete_Key_Input>;
  _prepend?: Maybe<Taxonomy_Widget_Prepend_Input>;
  _set?: Maybe<Taxonomy_Widget_Set_Input>;
  where: Taxonomy_Widget_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_VariationArgs = {
  _append?: Maybe<Variation_Append_Input>;
  _delete_at_path?: Maybe<Variation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Variation_Delete_Elem_Input>;
  _delete_key?: Maybe<Variation_Delete_Key_Input>;
  _inc?: Maybe<Variation_Inc_Input>;
  _prepend?: Maybe<Variation_Prepend_Input>;
  _set?: Maybe<Variation_Set_Input>;
  where: Variation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Variation_By_PkArgs = {
  _append?: Maybe<Variation_Append_Input>;
  _delete_at_path?: Maybe<Variation_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Variation_Delete_Elem_Input>;
  _delete_key?: Maybe<Variation_Delete_Key_Input>;
  _inc?: Maybe<Variation_Inc_Input>;
  _prepend?: Maybe<Variation_Prepend_Input>;
  _set?: Maybe<Variation_Set_Input>;
  pk_columns: Variation_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "page" */
export type Page = {
  __typename?: 'page';
  admin_description?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted: Scalars['Boolean'];
  id: Scalars['Int'];
  /** A computed field, executes function "computed_page_metadata_description" */
  metadata_description?: Maybe<Scalars['String']>;
  metadata_descriptions: Scalars['jsonb'];
  name: Scalars['String'];
  /** An array relationship */
  page_contents: Array<Page_Content>;
  published: Scalars['Boolean'];
  titles: Scalars['jsonb'];
  url_slugs: Scalars['jsonb'];
};


/** columns and relationships of "page" */
export type PageMetadata_DescriptionsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "page" */
export type PagePage_ContentsArgs = {
  distinct_on?: Maybe<Array<Page_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Page_Content_Order_By>>;
  where?: Maybe<Page_Content_Bool_Exp>;
};


/** columns and relationships of "page" */
export type PageTitlesArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "page" */
export type PageUrl_SlugsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Page_Append_Input = {
  metadata_descriptions?: Maybe<Scalars['jsonb']>;
  titles?: Maybe<Scalars['jsonb']>;
  url_slugs?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "page". All fields are combined with a logical 'AND'. */
export type Page_Bool_Exp = {
  _and?: Maybe<Array<Page_Bool_Exp>>;
  _not?: Maybe<Page_Bool_Exp>;
  _or?: Maybe<Array<Page_Bool_Exp>>;
  admin_description?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  deleted?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  metadata_descriptions?: Maybe<Jsonb_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  page_contents?: Maybe<Page_Content_Bool_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
  titles?: Maybe<Jsonb_Comparison_Exp>;
  url_slugs?: Maybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "page" */
export enum Page_Constraint {
  /** unique or primary key constraint */
  PageNameKey = 'page_name_key',
  /** unique or primary key constraint */
  PagePkey = 'page_pkey'
}

/** columns and relationships of "page_content" */
export type Page_Content = {
  __typename?: 'page_content';
  contents: Scalars['jsonb'];
  id: Scalars['Int'];
  /** An object relationship */
  image?: Maybe<Image>;
  image_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  ordernum: Scalars['Int'];
  /** An object relationship */
  page: Page;
  page_content_type_id: Scalars['String'];
  page_id: Scalars['Int'];
};


/** columns and relationships of "page_content" */
export type Page_ContentContentsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "page_content" */
export type Page_Content_Aggregate_Order_By = {
  avg?: Maybe<Page_Content_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Page_Content_Max_Order_By>;
  min?: Maybe<Page_Content_Min_Order_By>;
  stddev?: Maybe<Page_Content_Stddev_Order_By>;
  stddev_pop?: Maybe<Page_Content_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Page_Content_Stddev_Samp_Order_By>;
  sum?: Maybe<Page_Content_Sum_Order_By>;
  var_pop?: Maybe<Page_Content_Var_Pop_Order_By>;
  var_samp?: Maybe<Page_Content_Var_Samp_Order_By>;
  variance?: Maybe<Page_Content_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Page_Content_Append_Input = {
  contents?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "page_content" */
export type Page_Content_Arr_Rel_Insert_Input = {
  data: Array<Page_Content_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Page_Content_On_Conflict>;
};

/** order by avg() on columns of table "page_content" */
export type Page_Content_Avg_Order_By = {
  id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "page_content". All fields are combined with a logical 'AND'. */
export type Page_Content_Bool_Exp = {
  _and?: Maybe<Array<Page_Content_Bool_Exp>>;
  _not?: Maybe<Page_Content_Bool_Exp>;
  _or?: Maybe<Array<Page_Content_Bool_Exp>>;
  contents?: Maybe<Jsonb_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image?: Maybe<Image_Bool_Exp>;
  image_id?: Maybe<Uuid_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  ordernum?: Maybe<Int_Comparison_Exp>;
  page?: Maybe<Page_Bool_Exp>;
  page_content_type_id?: Maybe<String_Comparison_Exp>;
  page_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "page_content" */
export enum Page_Content_Constraint {
  /** unique or primary key constraint */
  PageContentPkey = 'page_content_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Page_Content_Delete_At_Path_Input = {
  contents?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Page_Content_Delete_Elem_Input = {
  contents?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Page_Content_Delete_Key_Input = {
  contents?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "page_content" */
export type Page_Content_Inc_Input = {
  ordernum?: Maybe<Scalars['Int']>;
  page_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "page_content" */
export type Page_Content_Insert_Input = {
  contents?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Image_Obj_Rel_Insert_Input>;
  image_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  ordernum?: Maybe<Scalars['Int']>;
  page?: Maybe<Page_Obj_Rel_Insert_Input>;
  page_content_type_id?: Maybe<Scalars['String']>;
  page_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "page_content" */
export type Page_Content_Max_Order_By = {
  id?: Maybe<Order_By>;
  image_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_content_type_id?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "page_content" */
export type Page_Content_Min_Order_By = {
  id?: Maybe<Order_By>;
  image_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_content_type_id?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "page_content" */
export type Page_Content_Mutation_Response = {
  __typename?: 'page_content_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Page_Content>;
};

/** on conflict condition type for table "page_content" */
export type Page_Content_On_Conflict = {
  constraint: Page_Content_Constraint;
  update_columns?: Array<Page_Content_Update_Column>;
  where?: Maybe<Page_Content_Bool_Exp>;
};

/** Ordering options when selecting data from "page_content". */
export type Page_Content_Order_By = {
  contents?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Image_Order_By>;
  image_id?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page?: Maybe<Page_Order_By>;
  page_content_type_id?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** primary key columns input for table: page_content */
export type Page_Content_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Page_Content_Prepend_Input = {
  contents?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "page_content" */
export enum Page_Content_Select_Column {
  /** column name */
  Contents = 'contents',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Language = 'language',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  PageContentTypeId = 'page_content_type_id',
  /** column name */
  PageId = 'page_id'
}

/** input type for updating data in table "page_content" */
export type Page_Content_Set_Input = {
  contents?: Maybe<Scalars['jsonb']>;
  image_id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  ordernum?: Maybe<Scalars['Int']>;
  page_content_type_id?: Maybe<Scalars['String']>;
  page_id?: Maybe<Scalars['Int']>;
};

/** order by stddev() on columns of table "page_content" */
export type Page_Content_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "page_content" */
export type Page_Content_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "page_content" */
export type Page_Content_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "page_content" */
export type Page_Content_Sum_Order_By = {
  id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** columns and relationships of "page_content_type" */
export type Page_Content_Type = {
  __typename?: 'page_content_type';
  id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "page_content_type". All fields are combined with a logical 'AND'. */
export type Page_Content_Type_Bool_Exp = {
  _and?: Maybe<Array<Page_Content_Type_Bool_Exp>>;
  _not?: Maybe<Page_Content_Type_Bool_Exp>;
  _or?: Maybe<Array<Page_Content_Type_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "page_content_type". */
export type Page_Content_Type_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "page_content_type" */
export enum Page_Content_Type_Select_Column {
  /** column name */
  Id = 'id'
}

/** update columns of table "page_content" */
export enum Page_Content_Update_Column {
  /** column name */
  Contents = 'contents',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Language = 'language',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  PageContentTypeId = 'page_content_type_id',
  /** column name */
  PageId = 'page_id'
}

/** order by var_pop() on columns of table "page_content" */
export type Page_Content_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "page_content" */
export type Page_Content_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "page_content" */
export type Page_Content_Variance_Order_By = {
  id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  page_id?: Maybe<Order_By>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Page_Delete_At_Path_Input = {
  metadata_descriptions?: Maybe<Array<Scalars['String']>>;
  titles?: Maybe<Array<Scalars['String']>>;
  url_slugs?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Page_Delete_Elem_Input = {
  metadata_descriptions?: Maybe<Scalars['Int']>;
  titles?: Maybe<Scalars['Int']>;
  url_slugs?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Page_Delete_Key_Input = {
  metadata_descriptions?: Maybe<Scalars['String']>;
  titles?: Maybe<Scalars['String']>;
  url_slugs?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "page" */
export type Page_Insert_Input = {
  admin_description?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  metadata_descriptions?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  page_contents?: Maybe<Page_Content_Arr_Rel_Insert_Input>;
  published?: Maybe<Scalars['Boolean']>;
  titles?: Maybe<Scalars['jsonb']>;
  url_slugs?: Maybe<Scalars['jsonb']>;
};

/** response of any mutation on the table "page" */
export type Page_Mutation_Response = {
  __typename?: 'page_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Page>;
};

/** input type for inserting object relation for remote table "page" */
export type Page_Obj_Rel_Insert_Input = {
  data: Page_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Page_On_Conflict>;
};

/** on conflict condition type for table "page" */
export type Page_On_Conflict = {
  constraint: Page_Constraint;
  update_columns?: Array<Page_Update_Column>;
  where?: Maybe<Page_Bool_Exp>;
};

/** Ordering options when selecting data from "page". */
export type Page_Order_By = {
  admin_description?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata_descriptions?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  page_contents_aggregate?: Maybe<Page_Content_Aggregate_Order_By>;
  published?: Maybe<Order_By>;
  titles?: Maybe<Order_By>;
  url_slugs?: Maybe<Order_By>;
};

/** primary key columns input for table: page */
export type Page_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Page_Prepend_Input = {
  metadata_descriptions?: Maybe<Scalars['jsonb']>;
  titles?: Maybe<Scalars['jsonb']>;
  url_slugs?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "page" */
export enum Page_Select_Column {
  /** column name */
  AdminDescription = 'admin_description',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  Id = 'id',
  /** column name */
  MetadataDescriptions = 'metadata_descriptions',
  /** column name */
  Name = 'name',
  /** column name */
  Published = 'published',
  /** column name */
  Titles = 'titles',
  /** column name */
  UrlSlugs = 'url_slugs'
}

/** input type for updating data in table "page" */
export type Page_Set_Input = {
  admin_description?: Maybe<Scalars['String']>;
  deleted?: Maybe<Scalars['Boolean']>;
  metadata_descriptions?: Maybe<Scalars['jsonb']>;
  name?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  titles?: Maybe<Scalars['jsonb']>;
  url_slugs?: Maybe<Scalars['jsonb']>;
};

/** update columns of table "page" */
export enum Page_Update_Column {
  /** column name */
  AdminDescription = 'admin_description',
  /** column name */
  Deleted = 'deleted',
  /** column name */
  MetadataDescriptions = 'metadata_descriptions',
  /** column name */
  Name = 'name',
  /** column name */
  Published = 'published',
  /** column name */
  Titles = 'titles',
  /** column name */
  UrlSlugs = 'url_slugs'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "audio" */
  audio: Array<Audio>;
  /** fetch data from the table: "audio" using primary key columns */
  audio_by_pk?: Maybe<Audio>;
  /** fetch data from the table: "characteristic" */
  characteristic: Array<Characteristic>;
  /** fetch data from the table: "characteristic" using primary key columns */
  characteristic_by_pk?: Maybe<Characteristic>;
  /** fetch data from the table: "characteristic_group" */
  characteristic_group: Array<Characteristic_Group>;
  /** fetch data from the table: "characteristic_group" using primary key columns */
  characteristic_group_by_pk?: Maybe<Characteristic_Group>;
  /** fetch data from the table: "characteristic_section" */
  characteristic_section: Array<Characteristic_Section>;
  /** fetch data from the table: "characteristic_section" using primary key columns */
  characteristic_section_by_pk?: Maybe<Characteristic_Section>;
  /** fetch data from the table: "characteristic_value" */
  characteristic_value: Array<Characteristic_Value>;
  /** fetch data from the table: "characteristic_value" using primary key columns */
  characteristic_value_by_pk?: Maybe<Characteristic_Value>;
  /** fetch data from the table: "feedback" */
  feedback: Array<Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table: "feedback_comment" */
  feedback_comment: Array<Feedback_Comment>;
  /** fetch data from the table: "feedback_comment" using primary key columns */
  feedback_comment_by_pk?: Maybe<Feedback_Comment>;
  /** fetch data from the table: "feedback_status" */
  feedback_status: Array<Feedback_Status>;
  /** fetch data from the table: "feedback_status" using primary key columns */
  feedback_status_by_pk?: Maybe<Feedback_Status>;
  /** fetch data from the table: "feedback_taxonomy_reference" */
  feedback_taxonomy_reference: Array<Feedback_Taxonomy_Reference>;
  /** fetch data from the table: "feedback_taxonomy_reference" using primary key columns */
  feedback_taxonomy_reference_by_pk?: Maybe<Feedback_Taxonomy_Reference>;
  /** fetch data from the table: "image" */
  image: Array<Image>;
  /** fetch aggregated fields from the table: "image" */
  image_aggregate: Image_Aggregate;
  /** fetch data from the table: "image" using primary key columns */
  image_by_pk?: Maybe<Image>;
  /** fetch data from the table: "image_file_extension" */
  image_file_extension: Array<Image_File_Extension>;
  /** fetch data from the table: "image_file_extension" using primary key columns */
  image_file_extension_by_pk?: Maybe<Image_File_Extension>;
  /** fetch data from the table: "image_tag" */
  image_tag: Array<Image_Tag>;
  /** fetch data from the table: "image_tag" using primary key columns */
  image_tag_by_pk?: Maybe<Image_Tag>;
  /** fetch data from the table: "imgproxy_preset" */
  imgproxy_preset: Array<Imgproxy_Preset>;
  /** fetch data from the table: "imgproxy_preset" using primary key columns */
  imgproxy_preset_by_pk?: Maybe<Imgproxy_Preset>;
  /** fetch data from the table: "imgproxy_urls" */
  imgproxy_urls: Array<Imgproxy_Urls>;
  /** fetch data from the table: "imgproxy_urls" using primary key columns */
  imgproxy_urls_by_pk?: Maybe<Imgproxy_Urls>;
  /** fetch data from the table: "language" */
  language: Array<Language>;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<Language>;
  /** fetch data from the table: "page" */
  page: Array<Page>;
  /** fetch data from the table: "page" using primary key columns */
  page_by_pk?: Maybe<Page>;
  /** fetch data from the table: "page_content" */
  page_content: Array<Page_Content>;
  /** fetch data from the table: "page_content" using primary key columns */
  page_content_by_pk?: Maybe<Page_Content>;
  /** fetch data from the table: "page_content_type" */
  page_content_type: Array<Page_Content_Type>;
  /** fetch data from the table: "page_content_type" using primary key columns */
  page_content_type_by_pk?: Maybe<Page_Content_Type>;
  /** fetch data from the table: "rank" */
  rank: Array<Rank>;
  /** fetch data from the table: "rank" using primary key columns */
  rank_by_pk?: Maybe<Rank>;
  /** fetch data from the table: "taxonomy" */
  taxonomy: Array<Taxonomy>;
  /** fetch aggregated fields from the table: "taxonomy" */
  taxonomy_aggregate: Taxonomy_Aggregate;
  /** fetch data from the table: "taxonomy_article" */
  taxonomy_article: Array<Taxonomy_Article>;
  /** fetch aggregated fields from the table: "taxonomy_article" */
  taxonomy_article_aggregate: Taxonomy_Article_Aggregate;
  /** fetch data from the table: "taxonomy_audio" */
  taxonomy_audio: Array<Taxonomy_Audio>;
  /** fetch data from the table: "taxonomy_audio" using primary key columns */
  taxonomy_audio_by_pk?: Maybe<Taxonomy_Audio>;
  /** fetch data from the table: "taxonomy" using primary key columns */
  taxonomy_by_pk?: Maybe<Taxonomy>;
  /** fetch data from the table: "taxonomy_characteristic_section" */
  taxonomy_characteristic_section: Array<Taxonomy_Characteristic_Section>;
  /** fetch data from the table: "taxonomy_characteristic_section" using primary key columns */
  taxonomy_characteristic_section_by_pk?: Maybe<Taxonomy_Characteristic_Section>;
  /** fetch data from the table: "taxonomy_characteristic_value" */
  taxonomy_characteristic_value: Array<Taxonomy_Characteristic_Value>;
  /** fetch data from the table: "taxonomy_characteristic_value" using primary key columns */
  taxonomy_characteristic_value_by_pk?: Maybe<Taxonomy_Characteristic_Value>;
  /** fetch data from the table: "taxonomy_common_name" */
  taxonomy_common_name: Array<Taxonomy_Common_Name>;
  /** fetch aggregated fields from the table: "taxonomy_common_name" */
  taxonomy_common_name_aggregate: Taxonomy_Common_Name_Aggregate;
  /** execute function "taxonomy_find" which returns "taxonomy_find_result" */
  taxonomy_find: Array<Taxonomy_Find_Result>;
  /** execute function "taxonomy_find_by_characteristic_values" which returns "taxonomy_find_by_characteristic_values_result" */
  taxonomy_find_by_characteristic_values: Array<Taxonomy_Find_By_Characteristic_Values_Result>;
  /** execute function "taxonomy_find_by_characteristic_values" and query aggregates on result of table type "taxonomy_find_by_characteristic_values_result" */
  taxonomy_find_by_characteristic_values_aggregate: Taxonomy_Find_By_Characteristic_Values_Result_Aggregate;
  /** fetch data from the table: "taxonomy_find_by_characteristic_values_result" */
  taxonomy_find_by_characteristic_values_result: Array<Taxonomy_Find_By_Characteristic_Values_Result>;
  /** fetch aggregated fields from the table: "taxonomy_find_by_characteristic_values_result" */
  taxonomy_find_by_characteristic_values_result_aggregate: Taxonomy_Find_By_Characteristic_Values_Result_Aggregate;
  /** fetch data from the table: "taxonomy_find_result" */
  taxonomy_find_result: Array<Taxonomy_Find_Result>;
  /** fetch data from the table: "taxonomy_image" */
  taxonomy_image: Array<Taxonomy_Image>;
  /** fetch aggregated fields from the table: "taxonomy_image" */
  taxonomy_image_aggregate: Taxonomy_Image_Aggregate;
  /** execute function "taxonomy_list" which returns "taxonomy_list_result" */
  taxonomy_list: Array<Taxonomy_List_Result>;
  /** fetch data from the table: "taxonomy_list_result" */
  taxonomy_list_result: Array<Taxonomy_List_Result>;
  /** fetch data from the table: "taxonomy_scientific_name" */
  taxonomy_scientific_name: Array<Taxonomy_Scientific_Name>;
  /** fetch aggregated fields from the table: "taxonomy_scientific_name" */
  taxonomy_scientific_name_aggregate: Taxonomy_Scientific_Name_Aggregate;
  /** fetch data from the table: "taxonomy_widget" */
  taxonomy_widget: Array<Taxonomy_Widget>;
  /** fetch data from the table: "taxonomy_widget_type" */
  taxonomy_widget_type: Array<Taxonomy_Widget_Type>;
  /** fetch data from the table: "taxonomy_widget_type" using primary key columns */
  taxonomy_widget_type_by_pk?: Maybe<Taxonomy_Widget_Type>;
  /** fetch data from the table: "user_account" */
  user_account: Array<User_Account>;
  /** fetch data from the table: "user_account" using primary key columns */
  user_account_by_pk?: Maybe<User_Account>;
  /** fetch data from the table: "variation" */
  variation: Array<Variation>;
  /** fetch data from the table: "variation" using primary key columns */
  variation_by_pk?: Maybe<Variation>;
};


export type Query_RootAudioArgs = {
  distinct_on?: Maybe<Array<Audio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Order_By>>;
  where?: Maybe<Audio_Bool_Exp>;
};


export type Query_RootAudio_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootCharacteristicArgs = {
  distinct_on?: Maybe<Array<Characteristic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Order_By>>;
  where?: Maybe<Characteristic_Bool_Exp>;
};


export type Query_RootCharacteristic_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCharacteristic_GroupArgs = {
  distinct_on?: Maybe<Array<Characteristic_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Group_Order_By>>;
  where?: Maybe<Characteristic_Group_Bool_Exp>;
};


export type Query_RootCharacteristic_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCharacteristic_SectionArgs = {
  distinct_on?: Maybe<Array<Characteristic_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Section_Order_By>>;
  where?: Maybe<Characteristic_Section_Bool_Exp>;
};


export type Query_RootCharacteristic_Section_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCharacteristic_ValueArgs = {
  distinct_on?: Maybe<Array<Characteristic_Value_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Value_Order_By>>;
  where?: Maybe<Characteristic_Value_Bool_Exp>;
};


export type Query_RootCharacteristic_Value_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


export type Query_RootFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


export type Query_RootFeedback_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFeedback_CommentArgs = {
  distinct_on?: Maybe<Array<Feedback_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Comment_Order_By>>;
  where?: Maybe<Feedback_Comment_Bool_Exp>;
};


export type Query_RootFeedback_Comment_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootFeedback_StatusArgs = {
  distinct_on?: Maybe<Array<Feedback_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Status_Order_By>>;
  where?: Maybe<Feedback_Status_Bool_Exp>;
};


export type Query_RootFeedback_Status_By_PkArgs = {
  status: Scalars['String'];
};


export type Query_RootFeedback_Taxonomy_ReferenceArgs = {
  distinct_on?: Maybe<Array<Feedback_Taxonomy_Reference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Taxonomy_Reference_Order_By>>;
  where?: Maybe<Feedback_Taxonomy_Reference_Bool_Exp>;
};


export type Query_RootFeedback_Taxonomy_Reference_By_PkArgs = {
  feedback_id: Scalars['bigint'];
  taxonomy_id: Scalars['bigint'];
};


export type Query_RootImageArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};


export type Query_RootImage_AggregateArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};


export type Query_RootImage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootImage_File_ExtensionArgs = {
  distinct_on?: Maybe<Array<Image_File_Extension_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_File_Extension_Order_By>>;
  where?: Maybe<Image_File_Extension_Bool_Exp>;
};


export type Query_RootImage_File_Extension_By_PkArgs = {
  extension: Scalars['String'];
};


export type Query_RootImage_TagArgs = {
  distinct_on?: Maybe<Array<Image_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Tag_Order_By>>;
  where?: Maybe<Image_Tag_Bool_Exp>;
};


export type Query_RootImage_Tag_By_PkArgs = {
  image_id: Scalars['uuid'];
  tag: Scalars['String'];
};


export type Query_RootImgproxy_PresetArgs = {
  distinct_on?: Maybe<Array<Imgproxy_Preset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Imgproxy_Preset_Order_By>>;
  where?: Maybe<Imgproxy_Preset_Bool_Exp>;
};


export type Query_RootImgproxy_Preset_By_PkArgs = {
  name: Scalars['String'];
};


export type Query_RootImgproxy_UrlsArgs = {
  distinct_on?: Maybe<Array<Imgproxy_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Imgproxy_Urls_Order_By>>;
  where?: Maybe<Imgproxy_Urls_Bool_Exp>;
};


export type Query_RootImgproxy_Urls_By_PkArgs = {
  preset: Scalars['String'];
};


export type Query_RootLanguageArgs = {
  distinct_on?: Maybe<Array<Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Language_Order_By>>;
  where?: Maybe<Language_Bool_Exp>;
};


export type Query_RootLanguage_By_PkArgs = {
  key: Scalars['String'];
};


export type Query_RootPageArgs = {
  distinct_on?: Maybe<Array<Page_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Page_Order_By>>;
  where?: Maybe<Page_Bool_Exp>;
};


export type Query_RootPage_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPage_ContentArgs = {
  distinct_on?: Maybe<Array<Page_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Page_Content_Order_By>>;
  where?: Maybe<Page_Content_Bool_Exp>;
};


export type Query_RootPage_Content_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPage_Content_TypeArgs = {
  distinct_on?: Maybe<Array<Page_Content_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Page_Content_Type_Order_By>>;
  where?: Maybe<Page_Content_Type_Bool_Exp>;
};


export type Query_RootPage_Content_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootRankArgs = {
  distinct_on?: Maybe<Array<Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rank_Order_By>>;
  where?: Maybe<Rank_Bool_Exp>;
};


export type Query_RootRank_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootTaxonomyArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Order_By>>;
  where?: Maybe<Taxonomy_Bool_Exp>;
};


export type Query_RootTaxonomy_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Order_By>>;
  where?: Maybe<Taxonomy_Bool_Exp>;
};


export type Query_RootTaxonomy_ArticleArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Article_Order_By>>;
  where?: Maybe<Taxonomy_Article_Bool_Exp>;
};


export type Query_RootTaxonomy_Article_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Article_Order_By>>;
  where?: Maybe<Taxonomy_Article_Bool_Exp>;
};


export type Query_RootTaxonomy_AudioArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Audio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Audio_Order_By>>;
  where?: Maybe<Taxonomy_Audio_Bool_Exp>;
};


export type Query_RootTaxonomy_Audio_By_PkArgs = {
  audio_id: Scalars['String'];
  taxonomy_id: Scalars['bigint'];
};


export type Query_RootTaxonomy_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Query_RootTaxonomy_Characteristic_SectionArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Characteristic_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Characteristic_Section_Order_By>>;
  where?: Maybe<Taxonomy_Characteristic_Section_Bool_Exp>;
};


export type Query_RootTaxonomy_Characteristic_Section_By_PkArgs = {
  characteristic_section_id: Scalars['Int'];
  taxonomy_id: Scalars['bigint'];
};


export type Query_RootTaxonomy_Characteristic_ValueArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Characteristic_Value_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Characteristic_Value_Order_By>>;
  where?: Maybe<Taxonomy_Characteristic_Value_Bool_Exp>;
};


export type Query_RootTaxonomy_Characteristic_Value_By_PkArgs = {
  characteristic_value_id: Scalars['Int'];
  taxonomy_id: Scalars['bigint'];
};


export type Query_RootTaxonomy_Common_NameArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Common_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Common_Name_Order_By>>;
  where?: Maybe<Taxonomy_Common_Name_Bool_Exp>;
};


export type Query_RootTaxonomy_Common_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Common_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Common_Name_Order_By>>;
  where?: Maybe<Taxonomy_Common_Name_Bool_Exp>;
};


export type Query_RootTaxonomy_FindArgs = {
  args: Taxonomy_Find_Args;
  distinct_on?: Maybe<Array<Taxonomy_Find_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_Result_Bool_Exp>;
};


export type Query_RootTaxonomy_Find_By_Characteristic_ValuesArgs = {
  args: Taxonomy_Find_By_Characteristic_Values_Args;
  distinct_on?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>;
};


export type Query_RootTaxonomy_Find_By_Characteristic_Values_AggregateArgs = {
  args: Taxonomy_Find_By_Characteristic_Values_Args;
  distinct_on?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>;
};


export type Query_RootTaxonomy_Find_By_Characteristic_Values_ResultArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>;
};


export type Query_RootTaxonomy_Find_By_Characteristic_Values_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>;
};


export type Query_RootTaxonomy_Find_ResultArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Find_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_Result_Bool_Exp>;
};


export type Query_RootTaxonomy_ImageArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Image_Order_By>>;
  where?: Maybe<Taxonomy_Image_Bool_Exp>;
};


export type Query_RootTaxonomy_Image_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Image_Order_By>>;
  where?: Maybe<Taxonomy_Image_Bool_Exp>;
};


export type Query_RootTaxonomy_ListArgs = {
  args: Taxonomy_List_Args;
  distinct_on?: Maybe<Array<Taxonomy_List_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_List_Result_Order_By>>;
  where?: Maybe<Taxonomy_List_Result_Bool_Exp>;
};


export type Query_RootTaxonomy_List_ResultArgs = {
  distinct_on?: Maybe<Array<Taxonomy_List_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_List_Result_Order_By>>;
  where?: Maybe<Taxonomy_List_Result_Bool_Exp>;
};


export type Query_RootTaxonomy_Scientific_NameArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Scientific_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Scientific_Name_Order_By>>;
  where?: Maybe<Taxonomy_Scientific_Name_Bool_Exp>;
};


export type Query_RootTaxonomy_Scientific_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Scientific_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Scientific_Name_Order_By>>;
  where?: Maybe<Taxonomy_Scientific_Name_Bool_Exp>;
};


export type Query_RootTaxonomy_WidgetArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Widget_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Widget_Order_By>>;
  where?: Maybe<Taxonomy_Widget_Bool_Exp>;
};


export type Query_RootTaxonomy_Widget_TypeArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Widget_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Widget_Type_Order_By>>;
  where?: Maybe<Taxonomy_Widget_Type_Bool_Exp>;
};


export type Query_RootTaxonomy_Widget_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootUser_AccountArgs = {
  distinct_on?: Maybe<Array<User_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Account_Order_By>>;
  where?: Maybe<User_Account_Bool_Exp>;
};


export type Query_RootUser_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVariationArgs = {
  distinct_on?: Maybe<Array<Variation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Variation_Order_By>>;
  where?: Maybe<Variation_Bool_Exp>;
};


export type Query_RootVariation_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "rank" */
export type Rank = {
  __typename?: 'rank';
  id: Scalars['String'];
  /** A computed field, executes function "computed_rank_name" */
  name?: Maybe<Scalars['String']>;
  names: Scalars['jsonb'];
  ordernum: Scalars['Int'];
};


/** columns and relationships of "rank" */
export type RankNamesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Rank_Append_Input = {
  names?: Maybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "rank". All fields are combined with a logical 'AND'. */
export type Rank_Bool_Exp = {
  _and?: Maybe<Array<Rank_Bool_Exp>>;
  _not?: Maybe<Rank_Bool_Exp>;
  _or?: Maybe<Array<Rank_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
  names?: Maybe<Jsonb_Comparison_Exp>;
  ordernum?: Maybe<Int_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Rank_Delete_At_Path_Input = {
  names?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Rank_Delete_Elem_Input = {
  names?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Rank_Delete_Key_Input = {
  names?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "rank" */
export type Rank_Mutation_Response = {
  __typename?: 'rank_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Rank>;
};

/** Ordering options when selecting data from "rank". */
export type Rank_Order_By = {
  id?: Maybe<Order_By>;
  names?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
};

/** primary key columns input for table: rank */
export type Rank_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Rank_Prepend_Input = {
  names?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "rank" */
export enum Rank_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Names = 'names',
  /** column name */
  Ordernum = 'ordernum'
}

/** input type for updating data in table "rank" */
export type Rank_Set_Input = {
  names?: Maybe<Scalars['jsonb']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "audio" */
  audio: Array<Audio>;
  /** fetch data from the table: "audio" using primary key columns */
  audio_by_pk?: Maybe<Audio>;
  /** fetch data from the table: "characteristic" */
  characteristic: Array<Characteristic>;
  /** fetch data from the table: "characteristic" using primary key columns */
  characteristic_by_pk?: Maybe<Characteristic>;
  /** fetch data from the table: "characteristic_group" */
  characteristic_group: Array<Characteristic_Group>;
  /** fetch data from the table: "characteristic_group" using primary key columns */
  characteristic_group_by_pk?: Maybe<Characteristic_Group>;
  /** fetch data from the table: "characteristic_section" */
  characteristic_section: Array<Characteristic_Section>;
  /** fetch data from the table: "characteristic_section" using primary key columns */
  characteristic_section_by_pk?: Maybe<Characteristic_Section>;
  /** fetch data from the table: "characteristic_value" */
  characteristic_value: Array<Characteristic_Value>;
  /** fetch data from the table: "characteristic_value" using primary key columns */
  characteristic_value_by_pk?: Maybe<Characteristic_Value>;
  /** fetch data from the table: "feedback" */
  feedback: Array<Feedback>;
  /** fetch aggregated fields from the table: "feedback" */
  feedback_aggregate: Feedback_Aggregate;
  /** fetch data from the table: "feedback" using primary key columns */
  feedback_by_pk?: Maybe<Feedback>;
  /** fetch data from the table: "feedback_comment" */
  feedback_comment: Array<Feedback_Comment>;
  /** fetch data from the table: "feedback_comment" using primary key columns */
  feedback_comment_by_pk?: Maybe<Feedback_Comment>;
  /** fetch data from the table: "feedback_status" */
  feedback_status: Array<Feedback_Status>;
  /** fetch data from the table: "feedback_status" using primary key columns */
  feedback_status_by_pk?: Maybe<Feedback_Status>;
  /** fetch data from the table: "feedback_taxonomy_reference" */
  feedback_taxonomy_reference: Array<Feedback_Taxonomy_Reference>;
  /** fetch data from the table: "feedback_taxonomy_reference" using primary key columns */
  feedback_taxonomy_reference_by_pk?: Maybe<Feedback_Taxonomy_Reference>;
  /** fetch data from the table: "image" */
  image: Array<Image>;
  /** fetch aggregated fields from the table: "image" */
  image_aggregate: Image_Aggregate;
  /** fetch data from the table: "image" using primary key columns */
  image_by_pk?: Maybe<Image>;
  /** fetch data from the table: "image_file_extension" */
  image_file_extension: Array<Image_File_Extension>;
  /** fetch data from the table: "image_file_extension" using primary key columns */
  image_file_extension_by_pk?: Maybe<Image_File_Extension>;
  /** fetch data from the table: "image_tag" */
  image_tag: Array<Image_Tag>;
  /** fetch data from the table: "image_tag" using primary key columns */
  image_tag_by_pk?: Maybe<Image_Tag>;
  /** fetch data from the table: "imgproxy_preset" */
  imgproxy_preset: Array<Imgproxy_Preset>;
  /** fetch data from the table: "imgproxy_preset" using primary key columns */
  imgproxy_preset_by_pk?: Maybe<Imgproxy_Preset>;
  /** fetch data from the table: "imgproxy_urls" */
  imgproxy_urls: Array<Imgproxy_Urls>;
  /** fetch data from the table: "imgproxy_urls" using primary key columns */
  imgproxy_urls_by_pk?: Maybe<Imgproxy_Urls>;
  /** fetch data from the table: "language" */
  language: Array<Language>;
  /** fetch data from the table: "language" using primary key columns */
  language_by_pk?: Maybe<Language>;
  /** fetch data from the table: "page" */
  page: Array<Page>;
  /** fetch data from the table: "page" using primary key columns */
  page_by_pk?: Maybe<Page>;
  /** fetch data from the table: "page_content" */
  page_content: Array<Page_Content>;
  /** fetch data from the table: "page_content" using primary key columns */
  page_content_by_pk?: Maybe<Page_Content>;
  /** fetch data from the table: "page_content_type" */
  page_content_type: Array<Page_Content_Type>;
  /** fetch data from the table: "page_content_type" using primary key columns */
  page_content_type_by_pk?: Maybe<Page_Content_Type>;
  /** fetch data from the table: "rank" */
  rank: Array<Rank>;
  /** fetch data from the table: "rank" using primary key columns */
  rank_by_pk?: Maybe<Rank>;
  /** fetch data from the table: "taxonomy" */
  taxonomy: Array<Taxonomy>;
  /** fetch aggregated fields from the table: "taxonomy" */
  taxonomy_aggregate: Taxonomy_Aggregate;
  /** fetch data from the table: "taxonomy_article" */
  taxonomy_article: Array<Taxonomy_Article>;
  /** fetch aggregated fields from the table: "taxonomy_article" */
  taxonomy_article_aggregate: Taxonomy_Article_Aggregate;
  /** fetch data from the table: "taxonomy_audio" */
  taxonomy_audio: Array<Taxonomy_Audio>;
  /** fetch data from the table: "taxonomy_audio" using primary key columns */
  taxonomy_audio_by_pk?: Maybe<Taxonomy_Audio>;
  /** fetch data from the table: "taxonomy" using primary key columns */
  taxonomy_by_pk?: Maybe<Taxonomy>;
  /** fetch data from the table: "taxonomy_characteristic_section" */
  taxonomy_characteristic_section: Array<Taxonomy_Characteristic_Section>;
  /** fetch data from the table: "taxonomy_characteristic_section" using primary key columns */
  taxonomy_characteristic_section_by_pk?: Maybe<Taxonomy_Characteristic_Section>;
  /** fetch data from the table: "taxonomy_characteristic_value" */
  taxonomy_characteristic_value: Array<Taxonomy_Characteristic_Value>;
  /** fetch data from the table: "taxonomy_characteristic_value" using primary key columns */
  taxonomy_characteristic_value_by_pk?: Maybe<Taxonomy_Characteristic_Value>;
  /** fetch data from the table: "taxonomy_common_name" */
  taxonomy_common_name: Array<Taxonomy_Common_Name>;
  /** fetch aggregated fields from the table: "taxonomy_common_name" */
  taxonomy_common_name_aggregate: Taxonomy_Common_Name_Aggregate;
  /** execute function "taxonomy_find" which returns "taxonomy_find_result" */
  taxonomy_find: Array<Taxonomy_Find_Result>;
  /** execute function "taxonomy_find_by_characteristic_values" which returns "taxonomy_find_by_characteristic_values_result" */
  taxonomy_find_by_characteristic_values: Array<Taxonomy_Find_By_Characteristic_Values_Result>;
  /** execute function "taxonomy_find_by_characteristic_values" and query aggregates on result of table type "taxonomy_find_by_characteristic_values_result" */
  taxonomy_find_by_characteristic_values_aggregate: Taxonomy_Find_By_Characteristic_Values_Result_Aggregate;
  /** fetch data from the table: "taxonomy_find_by_characteristic_values_result" */
  taxonomy_find_by_characteristic_values_result: Array<Taxonomy_Find_By_Characteristic_Values_Result>;
  /** fetch aggregated fields from the table: "taxonomy_find_by_characteristic_values_result" */
  taxonomy_find_by_characteristic_values_result_aggregate: Taxonomy_Find_By_Characteristic_Values_Result_Aggregate;
  /** fetch data from the table: "taxonomy_find_result" */
  taxonomy_find_result: Array<Taxonomy_Find_Result>;
  /** fetch data from the table: "taxonomy_image" */
  taxonomy_image: Array<Taxonomy_Image>;
  /** fetch aggregated fields from the table: "taxonomy_image" */
  taxonomy_image_aggregate: Taxonomy_Image_Aggregate;
  /** execute function "taxonomy_list" which returns "taxonomy_list_result" */
  taxonomy_list: Array<Taxonomy_List_Result>;
  /** fetch data from the table: "taxonomy_list_result" */
  taxonomy_list_result: Array<Taxonomy_List_Result>;
  /** fetch data from the table: "taxonomy_scientific_name" */
  taxonomy_scientific_name: Array<Taxonomy_Scientific_Name>;
  /** fetch aggregated fields from the table: "taxonomy_scientific_name" */
  taxonomy_scientific_name_aggregate: Taxonomy_Scientific_Name_Aggregate;
  /** fetch data from the table: "taxonomy_widget" */
  taxonomy_widget: Array<Taxonomy_Widget>;
  /** fetch data from the table: "taxonomy_widget_type" */
  taxonomy_widget_type: Array<Taxonomy_Widget_Type>;
  /** fetch data from the table: "taxonomy_widget_type" using primary key columns */
  taxonomy_widget_type_by_pk?: Maybe<Taxonomy_Widget_Type>;
  /** fetch data from the table: "user_account" */
  user_account: Array<User_Account>;
  /** fetch data from the table: "user_account" using primary key columns */
  user_account_by_pk?: Maybe<User_Account>;
  /** fetch data from the table: "variation" */
  variation: Array<Variation>;
  /** fetch data from the table: "variation" using primary key columns */
  variation_by_pk?: Maybe<Variation>;
};


export type Subscription_RootAudioArgs = {
  distinct_on?: Maybe<Array<Audio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Audio_Order_By>>;
  where?: Maybe<Audio_Bool_Exp>;
};


export type Subscription_RootAudio_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootCharacteristicArgs = {
  distinct_on?: Maybe<Array<Characteristic_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Order_By>>;
  where?: Maybe<Characteristic_Bool_Exp>;
};


export type Subscription_RootCharacteristic_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCharacteristic_GroupArgs = {
  distinct_on?: Maybe<Array<Characteristic_Group_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Group_Order_By>>;
  where?: Maybe<Characteristic_Group_Bool_Exp>;
};


export type Subscription_RootCharacteristic_Group_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCharacteristic_SectionArgs = {
  distinct_on?: Maybe<Array<Characteristic_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Section_Order_By>>;
  where?: Maybe<Characteristic_Section_Bool_Exp>;
};


export type Subscription_RootCharacteristic_Section_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootCharacteristic_ValueArgs = {
  distinct_on?: Maybe<Array<Characteristic_Value_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Characteristic_Value_Order_By>>;
  where?: Maybe<Characteristic_Value_Bool_Exp>;
};


export type Subscription_RootCharacteristic_Value_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFeedbackArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedback_AggregateArgs = {
  distinct_on?: Maybe<Array<Feedback_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Order_By>>;
  where?: Maybe<Feedback_Bool_Exp>;
};


export type Subscription_RootFeedback_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFeedback_CommentArgs = {
  distinct_on?: Maybe<Array<Feedback_Comment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Comment_Order_By>>;
  where?: Maybe<Feedback_Comment_Bool_Exp>;
};


export type Subscription_RootFeedback_Comment_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootFeedback_StatusArgs = {
  distinct_on?: Maybe<Array<Feedback_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Status_Order_By>>;
  where?: Maybe<Feedback_Status_Bool_Exp>;
};


export type Subscription_RootFeedback_Status_By_PkArgs = {
  status: Scalars['String'];
};


export type Subscription_RootFeedback_Taxonomy_ReferenceArgs = {
  distinct_on?: Maybe<Array<Feedback_Taxonomy_Reference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Taxonomy_Reference_Order_By>>;
  where?: Maybe<Feedback_Taxonomy_Reference_Bool_Exp>;
};


export type Subscription_RootFeedback_Taxonomy_Reference_By_PkArgs = {
  feedback_id: Scalars['bigint'];
  taxonomy_id: Scalars['bigint'];
};


export type Subscription_RootImageArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};


export type Subscription_RootImage_AggregateArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};


export type Subscription_RootImage_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootImage_File_ExtensionArgs = {
  distinct_on?: Maybe<Array<Image_File_Extension_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_File_Extension_Order_By>>;
  where?: Maybe<Image_File_Extension_Bool_Exp>;
};


export type Subscription_RootImage_File_Extension_By_PkArgs = {
  extension: Scalars['String'];
};


export type Subscription_RootImage_TagArgs = {
  distinct_on?: Maybe<Array<Image_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Tag_Order_By>>;
  where?: Maybe<Image_Tag_Bool_Exp>;
};


export type Subscription_RootImage_Tag_By_PkArgs = {
  image_id: Scalars['uuid'];
  tag: Scalars['String'];
};


export type Subscription_RootImgproxy_PresetArgs = {
  distinct_on?: Maybe<Array<Imgproxy_Preset_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Imgproxy_Preset_Order_By>>;
  where?: Maybe<Imgproxy_Preset_Bool_Exp>;
};


export type Subscription_RootImgproxy_Preset_By_PkArgs = {
  name: Scalars['String'];
};


export type Subscription_RootImgproxy_UrlsArgs = {
  distinct_on?: Maybe<Array<Imgproxy_Urls_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Imgproxy_Urls_Order_By>>;
  where?: Maybe<Imgproxy_Urls_Bool_Exp>;
};


export type Subscription_RootImgproxy_Urls_By_PkArgs = {
  preset: Scalars['String'];
};


export type Subscription_RootLanguageArgs = {
  distinct_on?: Maybe<Array<Language_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Language_Order_By>>;
  where?: Maybe<Language_Bool_Exp>;
};


export type Subscription_RootLanguage_By_PkArgs = {
  key: Scalars['String'];
};


export type Subscription_RootPageArgs = {
  distinct_on?: Maybe<Array<Page_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Page_Order_By>>;
  where?: Maybe<Page_Bool_Exp>;
};


export type Subscription_RootPage_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPage_ContentArgs = {
  distinct_on?: Maybe<Array<Page_Content_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Page_Content_Order_By>>;
  where?: Maybe<Page_Content_Bool_Exp>;
};


export type Subscription_RootPage_Content_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPage_Content_TypeArgs = {
  distinct_on?: Maybe<Array<Page_Content_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Page_Content_Type_Order_By>>;
  where?: Maybe<Page_Content_Type_Bool_Exp>;
};


export type Subscription_RootPage_Content_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootRankArgs = {
  distinct_on?: Maybe<Array<Rank_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Rank_Order_By>>;
  where?: Maybe<Rank_Bool_Exp>;
};


export type Subscription_RootRank_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootTaxonomyArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Order_By>>;
  where?: Maybe<Taxonomy_Bool_Exp>;
};


export type Subscription_RootTaxonomy_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Order_By>>;
  where?: Maybe<Taxonomy_Bool_Exp>;
};


export type Subscription_RootTaxonomy_ArticleArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Article_Order_By>>;
  where?: Maybe<Taxonomy_Article_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Article_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Article_Order_By>>;
  where?: Maybe<Taxonomy_Article_Bool_Exp>;
};


export type Subscription_RootTaxonomy_AudioArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Audio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Audio_Order_By>>;
  where?: Maybe<Taxonomy_Audio_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Audio_By_PkArgs = {
  audio_id: Scalars['String'];
  taxonomy_id: Scalars['bigint'];
};


export type Subscription_RootTaxonomy_By_PkArgs = {
  id: Scalars['bigint'];
};


export type Subscription_RootTaxonomy_Characteristic_SectionArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Characteristic_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Characteristic_Section_Order_By>>;
  where?: Maybe<Taxonomy_Characteristic_Section_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Characteristic_Section_By_PkArgs = {
  characteristic_section_id: Scalars['Int'];
  taxonomy_id: Scalars['bigint'];
};


export type Subscription_RootTaxonomy_Characteristic_ValueArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Characteristic_Value_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Characteristic_Value_Order_By>>;
  where?: Maybe<Taxonomy_Characteristic_Value_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Characteristic_Value_By_PkArgs = {
  characteristic_value_id: Scalars['Int'];
  taxonomy_id: Scalars['bigint'];
};


export type Subscription_RootTaxonomy_Common_NameArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Common_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Common_Name_Order_By>>;
  where?: Maybe<Taxonomy_Common_Name_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Common_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Common_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Common_Name_Order_By>>;
  where?: Maybe<Taxonomy_Common_Name_Bool_Exp>;
};


export type Subscription_RootTaxonomy_FindArgs = {
  args: Taxonomy_Find_Args;
  distinct_on?: Maybe<Array<Taxonomy_Find_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_Result_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Find_By_Characteristic_ValuesArgs = {
  args: Taxonomy_Find_By_Characteristic_Values_Args;
  distinct_on?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Find_By_Characteristic_Values_AggregateArgs = {
  args: Taxonomy_Find_By_Characteristic_Values_Args;
  distinct_on?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Find_By_Characteristic_Values_ResultArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Find_By_Characteristic_Values_Result_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Find_ResultArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Find_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Find_Result_Order_By>>;
  where?: Maybe<Taxonomy_Find_Result_Bool_Exp>;
};


export type Subscription_RootTaxonomy_ImageArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Image_Order_By>>;
  where?: Maybe<Taxonomy_Image_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Image_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Image_Order_By>>;
  where?: Maybe<Taxonomy_Image_Bool_Exp>;
};


export type Subscription_RootTaxonomy_ListArgs = {
  args: Taxonomy_List_Args;
  distinct_on?: Maybe<Array<Taxonomy_List_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_List_Result_Order_By>>;
  where?: Maybe<Taxonomy_List_Result_Bool_Exp>;
};


export type Subscription_RootTaxonomy_List_ResultArgs = {
  distinct_on?: Maybe<Array<Taxonomy_List_Result_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_List_Result_Order_By>>;
  where?: Maybe<Taxonomy_List_Result_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Scientific_NameArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Scientific_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Scientific_Name_Order_By>>;
  where?: Maybe<Taxonomy_Scientific_Name_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Scientific_Name_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Scientific_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Scientific_Name_Order_By>>;
  where?: Maybe<Taxonomy_Scientific_Name_Bool_Exp>;
};


export type Subscription_RootTaxonomy_WidgetArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Widget_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Widget_Order_By>>;
  where?: Maybe<Taxonomy_Widget_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Widget_TypeArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Widget_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Widget_Type_Order_By>>;
  where?: Maybe<Taxonomy_Widget_Type_Bool_Exp>;
};


export type Subscription_RootTaxonomy_Widget_Type_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootUser_AccountArgs = {
  distinct_on?: Maybe<Array<User_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Account_Order_By>>;
  where?: Maybe<User_Account_Bool_Exp>;
};


export type Subscription_RootUser_Account_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVariationArgs = {
  distinct_on?: Maybe<Array<Variation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Variation_Order_By>>;
  where?: Maybe<Variation_Bool_Exp>;
};


export type Subscription_RootVariation_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "taxonomy" */
export type Taxonomy = {
  __typename?: 'taxonomy';
  /** A computed field, executes function "computed_taxonomy_ancestors" */
  ancestors?: Maybe<Array<Taxonomy>>;
  /** A computed field, executes function "computed_taxonomy_article" */
  article?: Maybe<Array<Taxonomy_Article>>;
  /** A computed field, executes function "computed_taxonomy_children" */
  children?: Maybe<Array<Taxonomy>>;
  /** A computed field, executes function "computed_taxonomy_children_deep" */
  children_deep?: Maybe<Array<Taxonomy>>;
  /** A computed field, executes function "computed_taxonomy_preferred_common_name" */
  common_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  feedback_taxonomy_references: Array<Feedback_Taxonomy_Reference>;
  id: Scalars['bigint'];
  identification_published: Scalars['Boolean'];
  path: Scalars['ltree'];
  published: Scalars['Boolean'];
  /** An object relationship */
  rank: Rank;
  rank_id: Scalars['String'];
  /** A computed field, executes function "computed_taxonomy_current_scientific_name" */
  scientific_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  taxonomy_articles: Array<Taxonomy_Article>;
  /** An aggregate relationship */
  taxonomy_articles_aggregate: Taxonomy_Article_Aggregate;
  /** An array relationship */
  taxonomy_audios: Array<Taxonomy_Audio>;
  /** An array relationship */
  taxonomy_characteristic_sections: Array<Taxonomy_Characteristic_Section>;
  /** An array relationship */
  taxonomy_characteristic_values: Array<Taxonomy_Characteristic_Value>;
  /** An array relationship */
  taxonomy_common_names: Array<Taxonomy_Common_Name>;
  /** An aggregate relationship */
  taxonomy_common_names_aggregate: Taxonomy_Common_Name_Aggregate;
  /** An array relationship */
  taxonomy_images: Array<Taxonomy_Image>;
  /** An aggregate relationship */
  taxonomy_images_aggregate: Taxonomy_Image_Aggregate;
  /** An array relationship */
  taxonomy_scientific_names: Array<Taxonomy_Scientific_Name>;
  /** An aggregate relationship */
  taxonomy_scientific_names_aggregate: Taxonomy_Scientific_Name_Aggregate;
  /** An array relationship */
  taxonomy_widgets: Array<Taxonomy_Widget>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyAncestorsArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Order_By>>;
  where?: Maybe<Taxonomy_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyArticleArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Article_Order_By>>;
  where?: Maybe<Taxonomy_Article_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyChildrenArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Order_By>>;
  where?: Maybe<Taxonomy_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyChildren_DeepArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Order_By>>;
  where?: Maybe<Taxonomy_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyFeedback_Taxonomy_ReferencesArgs = {
  distinct_on?: Maybe<Array<Feedback_Taxonomy_Reference_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Feedback_Taxonomy_Reference_Order_By>>;
  where?: Maybe<Feedback_Taxonomy_Reference_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_ArticlesArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Article_Order_By>>;
  where?: Maybe<Taxonomy_Article_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_Articles_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Article_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Article_Order_By>>;
  where?: Maybe<Taxonomy_Article_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_AudiosArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Audio_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Audio_Order_By>>;
  where?: Maybe<Taxonomy_Audio_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_Characteristic_SectionsArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Characteristic_Section_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Characteristic_Section_Order_By>>;
  where?: Maybe<Taxonomy_Characteristic_Section_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_Characteristic_ValuesArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Characteristic_Value_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Characteristic_Value_Order_By>>;
  where?: Maybe<Taxonomy_Characteristic_Value_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_Common_NamesArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Common_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Common_Name_Order_By>>;
  where?: Maybe<Taxonomy_Common_Name_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_Common_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Common_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Common_Name_Order_By>>;
  where?: Maybe<Taxonomy_Common_Name_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_ImagesArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Image_Order_By>>;
  where?: Maybe<Taxonomy_Image_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_Images_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Image_Order_By>>;
  where?: Maybe<Taxonomy_Image_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_Scientific_NamesArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Scientific_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Scientific_Name_Order_By>>;
  where?: Maybe<Taxonomy_Scientific_Name_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_Scientific_Names_AggregateArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Scientific_Name_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Scientific_Name_Order_By>>;
  where?: Maybe<Taxonomy_Scientific_Name_Bool_Exp>;
};


/** columns and relationships of "taxonomy" */
export type TaxonomyTaxonomy_WidgetsArgs = {
  distinct_on?: Maybe<Array<Taxonomy_Widget_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Taxonomy_Widget_Order_By>>;
  where?: Maybe<Taxonomy_Widget_Bool_Exp>;
};

/** aggregated selection of "taxonomy" */
export type Taxonomy_Aggregate = {
  __typename?: 'taxonomy_aggregate';
  aggregate?: Maybe<Taxonomy_Aggregate_Fields>;
  nodes: Array<Taxonomy>;
};

/** aggregate fields of "taxonomy" */
export type Taxonomy_Aggregate_Fields = {
  __typename?: 'taxonomy_aggregate_fields';
  avg?: Maybe<Taxonomy_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Taxonomy_Max_Fields>;
  min?: Maybe<Taxonomy_Min_Fields>;
  stddev?: Maybe<Taxonomy_Stddev_Fields>;
  stddev_pop?: Maybe<Taxonomy_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Taxonomy_Stddev_Samp_Fields>;
  sum?: Maybe<Taxonomy_Sum_Fields>;
  var_pop?: Maybe<Taxonomy_Var_Pop_Fields>;
  var_samp?: Maybe<Taxonomy_Var_Samp_Fields>;
  variance?: Maybe<Taxonomy_Variance_Fields>;
};


/** aggregate fields of "taxonomy" */
export type Taxonomy_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Taxonomy_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "taxonomy_article" */
export type Taxonomy_Article = {
  __typename?: 'taxonomy_article';
  article: Scalars['String'];
  information_strike?: Maybe<Scalars['String']>;
  language: Scalars['String'];
  legacy_copyright?: Maybe<Scalars['String']>;
  published: Scalars['Boolean'];
  section: Scalars['String'];
  /** An object relationship */
  taxonomy: Taxonomy;
  taxonomy_id: Scalars['bigint'];
  url_slug: Scalars['String'];
};

/** aggregated selection of "taxonomy_article" */
export type Taxonomy_Article_Aggregate = {
  __typename?: 'taxonomy_article_aggregate';
  aggregate?: Maybe<Taxonomy_Article_Aggregate_Fields>;
  nodes: Array<Taxonomy_Article>;
};

/** aggregate fields of "taxonomy_article" */
export type Taxonomy_Article_Aggregate_Fields = {
  __typename?: 'taxonomy_article_aggregate_fields';
  avg?: Maybe<Taxonomy_Article_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Taxonomy_Article_Max_Fields>;
  min?: Maybe<Taxonomy_Article_Min_Fields>;
  stddev?: Maybe<Taxonomy_Article_Stddev_Fields>;
  stddev_pop?: Maybe<Taxonomy_Article_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Taxonomy_Article_Stddev_Samp_Fields>;
  sum?: Maybe<Taxonomy_Article_Sum_Fields>;
  var_pop?: Maybe<Taxonomy_Article_Var_Pop_Fields>;
  var_samp?: Maybe<Taxonomy_Article_Var_Samp_Fields>;
  variance?: Maybe<Taxonomy_Article_Variance_Fields>;
};


/** aggregate fields of "taxonomy_article" */
export type Taxonomy_Article_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Taxonomy_Article_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "taxonomy_article" */
export type Taxonomy_Article_Aggregate_Order_By = {
  avg?: Maybe<Taxonomy_Article_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Taxonomy_Article_Max_Order_By>;
  min?: Maybe<Taxonomy_Article_Min_Order_By>;
  stddev?: Maybe<Taxonomy_Article_Stddev_Order_By>;
  stddev_pop?: Maybe<Taxonomy_Article_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Taxonomy_Article_Stddev_Samp_Order_By>;
  sum?: Maybe<Taxonomy_Article_Sum_Order_By>;
  var_pop?: Maybe<Taxonomy_Article_Var_Pop_Order_By>;
  var_samp?: Maybe<Taxonomy_Article_Var_Samp_Order_By>;
  variance?: Maybe<Taxonomy_Article_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "taxonomy_article" */
export type Taxonomy_Article_Arr_Rel_Insert_Input = {
  data: Array<Taxonomy_Article_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Taxonomy_Article_On_Conflict>;
};

/** aggregate avg on columns */
export type Taxonomy_Article_Avg_Fields = {
  __typename?: 'taxonomy_article_avg_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Avg_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taxonomy_article". All fields are combined with a logical 'AND'. */
export type Taxonomy_Article_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Article_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Article_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Article_Bool_Exp>>;
  article?: Maybe<String_Comparison_Exp>;
  information_strike?: Maybe<String_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  legacy_copyright?: Maybe<String_Comparison_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
  section?: Maybe<String_Comparison_Exp>;
  taxonomy?: Maybe<Taxonomy_Bool_Exp>;
  taxonomy_id?: Maybe<Bigint_Comparison_Exp>;
  url_slug?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "taxonomy_article" */
export enum Taxonomy_Article_Constraint {
  /** unique or primary key constraint */
  TaxonomyArticleTaxonomyIdLanguageUniqIdx = 'taxonomy_article_taxonomy_id_language_uniq_idx'
}

/** input type for incrementing numeric columns in table "taxonomy_article" */
export type Taxonomy_Article_Inc_Input = {
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "taxonomy_article" */
export type Taxonomy_Article_Insert_Input = {
  article?: Maybe<Scalars['String']>;
  information_strike?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  legacy_copyright?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Scalars['String']>;
  taxonomy?: Maybe<Taxonomy_Obj_Rel_Insert_Input>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
  url_slug?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Taxonomy_Article_Max_Fields = {
  __typename?: 'taxonomy_article_max_fields';
  article?: Maybe<Scalars['String']>;
  information_strike?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  legacy_copyright?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
  url_slug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Max_Order_By = {
  article?: Maybe<Order_By>;
  information_strike?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  legacy_copyright?: Maybe<Order_By>;
  section?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
  url_slug?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Taxonomy_Article_Min_Fields = {
  __typename?: 'taxonomy_article_min_fields';
  article?: Maybe<Scalars['String']>;
  information_strike?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  legacy_copyright?: Maybe<Scalars['String']>;
  section?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
  url_slug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Min_Order_By = {
  article?: Maybe<Order_By>;
  information_strike?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  legacy_copyright?: Maybe<Order_By>;
  section?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
  url_slug?: Maybe<Order_By>;
};

/** response of any mutation on the table "taxonomy_article" */
export type Taxonomy_Article_Mutation_Response = {
  __typename?: 'taxonomy_article_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Article>;
};

/** on conflict condition type for table "taxonomy_article" */
export type Taxonomy_Article_On_Conflict = {
  constraint: Taxonomy_Article_Constraint;
  update_columns?: Array<Taxonomy_Article_Update_Column>;
  where?: Maybe<Taxonomy_Article_Bool_Exp>;
};

/** Ordering options when selecting data from "taxonomy_article". */
export type Taxonomy_Article_Order_By = {
  article?: Maybe<Order_By>;
  information_strike?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  legacy_copyright?: Maybe<Order_By>;
  published?: Maybe<Order_By>;
  section?: Maybe<Order_By>;
  taxonomy?: Maybe<Taxonomy_Order_By>;
  taxonomy_id?: Maybe<Order_By>;
  url_slug?: Maybe<Order_By>;
};

/** select columns of table "taxonomy_article" */
export enum Taxonomy_Article_Select_Column {
  /** column name */
  Article = 'article',
  /** column name */
  InformationStrike = 'information_strike',
  /** column name */
  Language = 'language',
  /** column name */
  LegacyCopyright = 'legacy_copyright',
  /** column name */
  Published = 'published',
  /** column name */
  Section = 'section',
  /** column name */
  TaxonomyId = 'taxonomy_id',
  /** column name */
  UrlSlug = 'url_slug'
}

/** input type for updating data in table "taxonomy_article" */
export type Taxonomy_Article_Set_Input = {
  article?: Maybe<Scalars['String']>;
  information_strike?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  legacy_copyright?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  section?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
  url_slug?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Taxonomy_Article_Stddev_Fields = {
  __typename?: 'taxonomy_article_stddev_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Stddev_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Taxonomy_Article_Stddev_Pop_Fields = {
  __typename?: 'taxonomy_article_stddev_pop_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Stddev_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Taxonomy_Article_Stddev_Samp_Fields = {
  __typename?: 'taxonomy_article_stddev_samp_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Stddev_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Taxonomy_Article_Sum_Fields = {
  __typename?: 'taxonomy_article_sum_fields';
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Sum_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** update columns of table "taxonomy_article" */
export enum Taxonomy_Article_Update_Column {
  /** column name */
  Article = 'article',
  /** column name */
  InformationStrike = 'information_strike',
  /** column name */
  Language = 'language',
  /** column name */
  LegacyCopyright = 'legacy_copyright',
  /** column name */
  Published = 'published',
  /** column name */
  Section = 'section',
  /** column name */
  TaxonomyId = 'taxonomy_id',
  /** column name */
  UrlSlug = 'url_slug'
}

/** aggregate var_pop on columns */
export type Taxonomy_Article_Var_Pop_Fields = {
  __typename?: 'taxonomy_article_var_pop_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Var_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Taxonomy_Article_Var_Samp_Fields = {
  __typename?: 'taxonomy_article_var_samp_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Var_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Taxonomy_Article_Variance_Fields = {
  __typename?: 'taxonomy_article_variance_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "taxonomy_article" */
export type Taxonomy_Article_Variance_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** columns and relationships of "taxonomy_audio" */
export type Taxonomy_Audio = {
  __typename?: 'taxonomy_audio';
  /** An object relationship */
  audio: Audio;
  audio_id: Scalars['String'];
  taxonomy_id: Scalars['bigint'];
};

/** order by aggregate values of table "taxonomy_audio" */
export type Taxonomy_Audio_Aggregate_Order_By = {
  avg?: Maybe<Taxonomy_Audio_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Taxonomy_Audio_Max_Order_By>;
  min?: Maybe<Taxonomy_Audio_Min_Order_By>;
  stddev?: Maybe<Taxonomy_Audio_Stddev_Order_By>;
  stddev_pop?: Maybe<Taxonomy_Audio_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Taxonomy_Audio_Stddev_Samp_Order_By>;
  sum?: Maybe<Taxonomy_Audio_Sum_Order_By>;
  var_pop?: Maybe<Taxonomy_Audio_Var_Pop_Order_By>;
  var_samp?: Maybe<Taxonomy_Audio_Var_Samp_Order_By>;
  variance?: Maybe<Taxonomy_Audio_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "taxonomy_audio" */
export type Taxonomy_Audio_Arr_Rel_Insert_Input = {
  data: Array<Taxonomy_Audio_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Taxonomy_Audio_On_Conflict>;
};

/** order by avg() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Avg_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taxonomy_audio". All fields are combined with a logical 'AND'. */
export type Taxonomy_Audio_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Audio_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Audio_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Audio_Bool_Exp>>;
  audio?: Maybe<Audio_Bool_Exp>;
  audio_id?: Maybe<String_Comparison_Exp>;
  taxonomy_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "taxonomy_audio" */
export enum Taxonomy_Audio_Constraint {
  /** unique or primary key constraint */
  TaxonomyAudioPkey = 'taxonomy_audio_pkey'
}

/** input type for incrementing numeric columns in table "taxonomy_audio" */
export type Taxonomy_Audio_Inc_Input = {
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "taxonomy_audio" */
export type Taxonomy_Audio_Insert_Input = {
  audio?: Maybe<Audio_Obj_Rel_Insert_Input>;
  audio_id?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Max_Order_By = {
  audio_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Min_Order_By = {
  audio_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "taxonomy_audio" */
export type Taxonomy_Audio_Mutation_Response = {
  __typename?: 'taxonomy_audio_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Audio>;
};

/** on conflict condition type for table "taxonomy_audio" */
export type Taxonomy_Audio_On_Conflict = {
  constraint: Taxonomy_Audio_Constraint;
  update_columns?: Array<Taxonomy_Audio_Update_Column>;
  where?: Maybe<Taxonomy_Audio_Bool_Exp>;
};

/** Ordering options when selecting data from "taxonomy_audio". */
export type Taxonomy_Audio_Order_By = {
  audio?: Maybe<Audio_Order_By>;
  audio_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** primary key columns input for table: taxonomy_audio */
export type Taxonomy_Audio_Pk_Columns_Input = {
  audio_id: Scalars['String'];
  taxonomy_id: Scalars['bigint'];
};

/** select columns of table "taxonomy_audio" */
export enum Taxonomy_Audio_Select_Column {
  /** column name */
  AudioId = 'audio_id',
  /** column name */
  TaxonomyId = 'taxonomy_id'
}

/** input type for updating data in table "taxonomy_audio" */
export type Taxonomy_Audio_Set_Input = {
  audio_id?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by stddev() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Stddev_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Stddev_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Stddev_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Sum_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** update columns of table "taxonomy_audio" */
export enum Taxonomy_Audio_Update_Column {
  /** column name */
  AudioId = 'audio_id',
  /** column name */
  TaxonomyId = 'taxonomy_id'
}

/** order by var_pop() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Var_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Var_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "taxonomy_audio" */
export type Taxonomy_Audio_Variance_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate avg on columns */
export type Taxonomy_Avg_Fields = {
  __typename?: 'taxonomy_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "taxonomy". All fields are combined with a logical 'AND'. */
export type Taxonomy_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Bool_Exp>>;
  feedback_taxonomy_references?: Maybe<Feedback_Taxonomy_Reference_Bool_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  identification_published?: Maybe<Boolean_Comparison_Exp>;
  path?: Maybe<Ltree_Comparison_Exp>;
  published?: Maybe<Boolean_Comparison_Exp>;
  rank?: Maybe<Rank_Bool_Exp>;
  rank_id?: Maybe<String_Comparison_Exp>;
  taxonomy_articles?: Maybe<Taxonomy_Article_Bool_Exp>;
  taxonomy_audios?: Maybe<Taxonomy_Audio_Bool_Exp>;
  taxonomy_characteristic_sections?: Maybe<Taxonomy_Characteristic_Section_Bool_Exp>;
  taxonomy_characteristic_values?: Maybe<Taxonomy_Characteristic_Value_Bool_Exp>;
  taxonomy_common_names?: Maybe<Taxonomy_Common_Name_Bool_Exp>;
  taxonomy_images?: Maybe<Taxonomy_Image_Bool_Exp>;
  taxonomy_scientific_names?: Maybe<Taxonomy_Scientific_Name_Bool_Exp>;
  taxonomy_widgets?: Maybe<Taxonomy_Widget_Bool_Exp>;
};

/** columns and relationships of "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section = {
  __typename?: 'taxonomy_characteristic_section';
  /** An object relationship */
  characteristic_section: Characteristic_Section;
  characteristic_section_id: Scalars['Int'];
  show_in_section_listing: Scalars['Boolean'];
  taxonomy_id: Scalars['bigint'];
};

/** order by aggregate values of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Aggregate_Order_By = {
  avg?: Maybe<Taxonomy_Characteristic_Section_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Taxonomy_Characteristic_Section_Max_Order_By>;
  min?: Maybe<Taxonomy_Characteristic_Section_Min_Order_By>;
  stddev?: Maybe<Taxonomy_Characteristic_Section_Stddev_Order_By>;
  stddev_pop?: Maybe<Taxonomy_Characteristic_Section_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Taxonomy_Characteristic_Section_Stddev_Samp_Order_By>;
  sum?: Maybe<Taxonomy_Characteristic_Section_Sum_Order_By>;
  var_pop?: Maybe<Taxonomy_Characteristic_Section_Var_Pop_Order_By>;
  var_samp?: Maybe<Taxonomy_Characteristic_Section_Var_Samp_Order_By>;
  variance?: Maybe<Taxonomy_Characteristic_Section_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Arr_Rel_Insert_Input = {
  data: Array<Taxonomy_Characteristic_Section_Insert_Input>;
};

/** order by avg() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Avg_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taxonomy_characteristic_section". All fields are combined with a logical 'AND'. */
export type Taxonomy_Characteristic_Section_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Characteristic_Section_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Characteristic_Section_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Characteristic_Section_Bool_Exp>>;
  characteristic_section?: Maybe<Characteristic_Section_Bool_Exp>;
  characteristic_section_id?: Maybe<Int_Comparison_Exp>;
  show_in_section_listing?: Maybe<Boolean_Comparison_Exp>;
  taxonomy_id?: Maybe<Bigint_Comparison_Exp>;
};

/** input type for inserting data into table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Insert_Input = {
  characteristic_section?: Maybe<Characteristic_Section_Obj_Rel_Insert_Input>;
  characteristic_section_id?: Maybe<Scalars['Int']>;
  show_in_section_listing?: Maybe<Scalars['Boolean']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Max_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Min_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Mutation_Response = {
  __typename?: 'taxonomy_characteristic_section_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Characteristic_Section>;
};

/** Ordering options when selecting data from "taxonomy_characteristic_section". */
export type Taxonomy_Characteristic_Section_Order_By = {
  characteristic_section?: Maybe<Characteristic_Section_Order_By>;
  characteristic_section_id?: Maybe<Order_By>;
  show_in_section_listing?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** select columns of table "taxonomy_characteristic_section" */
export enum Taxonomy_Characteristic_Section_Select_Column {
  /** column name */
  CharacteristicSectionId = 'characteristic_section_id',
  /** column name */
  ShowInSectionListing = 'show_in_section_listing',
  /** column name */
  TaxonomyId = 'taxonomy_id'
}

/** order by stddev() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Stddev_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Stddev_Pop_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Stddev_Samp_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Sum_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by var_pop() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Var_Pop_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Var_Samp_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "taxonomy_characteristic_section" */
export type Taxonomy_Characteristic_Section_Variance_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** columns and relationships of "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value = {
  __typename?: 'taxonomy_characteristic_value';
  /** An object relationship */
  characteristic_value: Characteristic_Value;
  characteristic_value_id: Scalars['Int'];
  frequency?: Maybe<Scalars['String']>;
  /** An object relationship */
  taxonomy: Taxonomy;
  taxonomy_id: Scalars['bigint'];
  variations?: Maybe<Scalars['_int4']>;
};

/** order by aggregate values of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Aggregate_Order_By = {
  avg?: Maybe<Taxonomy_Characteristic_Value_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Taxonomy_Characteristic_Value_Max_Order_By>;
  min?: Maybe<Taxonomy_Characteristic_Value_Min_Order_By>;
  stddev?: Maybe<Taxonomy_Characteristic_Value_Stddev_Order_By>;
  stddev_pop?: Maybe<Taxonomy_Characteristic_Value_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Taxonomy_Characteristic_Value_Stddev_Samp_Order_By>;
  sum?: Maybe<Taxonomy_Characteristic_Value_Sum_Order_By>;
  var_pop?: Maybe<Taxonomy_Characteristic_Value_Var_Pop_Order_By>;
  var_samp?: Maybe<Taxonomy_Characteristic_Value_Var_Samp_Order_By>;
  variance?: Maybe<Taxonomy_Characteristic_Value_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Arr_Rel_Insert_Input = {
  data: Array<Taxonomy_Characteristic_Value_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Taxonomy_Characteristic_Value_On_Conflict>;
};

/** order by avg() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Avg_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taxonomy_characteristic_value". All fields are combined with a logical 'AND'. */
export type Taxonomy_Characteristic_Value_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Characteristic_Value_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Characteristic_Value_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Characteristic_Value_Bool_Exp>>;
  characteristic_value?: Maybe<Characteristic_Value_Bool_Exp>;
  characteristic_value_id?: Maybe<Int_Comparison_Exp>;
  frequency?: Maybe<String_Comparison_Exp>;
  taxonomy?: Maybe<Taxonomy_Bool_Exp>;
  taxonomy_id?: Maybe<Bigint_Comparison_Exp>;
  variations?: Maybe<_Int4_Comparison_Exp>;
};

/** unique or primary key constraints on table "taxonomy_characteristic_value" */
export enum Taxonomy_Characteristic_Value_Constraint {
  /** unique or primary key constraint */
  TaxonomyCharacteristicValuePkey = 'taxonomy_characteristic_value_pkey'
}

/** input type for incrementing numeric columns in table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Inc_Input = {
  characteristic_value_id?: Maybe<Scalars['Int']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Insert_Input = {
  characteristic_value?: Maybe<Characteristic_Value_Obj_Rel_Insert_Input>;
  characteristic_value_id?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  taxonomy?: Maybe<Taxonomy_Obj_Rel_Insert_Input>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
  variations?: Maybe<Scalars['_int4']>;
};

/** order by max() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Max_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  frequency?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Min_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  frequency?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Mutation_Response = {
  __typename?: 'taxonomy_characteristic_value_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Characteristic_Value>;
};

/** on conflict condition type for table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_On_Conflict = {
  constraint: Taxonomy_Characteristic_Value_Constraint;
  update_columns?: Array<Taxonomy_Characteristic_Value_Update_Column>;
  where?: Maybe<Taxonomy_Characteristic_Value_Bool_Exp>;
};

/** Ordering options when selecting data from "taxonomy_characteristic_value". */
export type Taxonomy_Characteristic_Value_Order_By = {
  characteristic_value?: Maybe<Characteristic_Value_Order_By>;
  characteristic_value_id?: Maybe<Order_By>;
  frequency?: Maybe<Order_By>;
  taxonomy?: Maybe<Taxonomy_Order_By>;
  taxonomy_id?: Maybe<Order_By>;
  variations?: Maybe<Order_By>;
};

/** primary key columns input for table: taxonomy_characteristic_value */
export type Taxonomy_Characteristic_Value_Pk_Columns_Input = {
  characteristic_value_id: Scalars['Int'];
  taxonomy_id: Scalars['bigint'];
};

/** select columns of table "taxonomy_characteristic_value" */
export enum Taxonomy_Characteristic_Value_Select_Column {
  /** column name */
  CharacteristicValueId = 'characteristic_value_id',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  TaxonomyId = 'taxonomy_id',
  /** column name */
  Variations = 'variations'
}

/** input type for updating data in table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Set_Input = {
  characteristic_value_id?: Maybe<Scalars['Int']>;
  frequency?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
  variations?: Maybe<Scalars['_int4']>;
};

/** order by stddev() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Stddev_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Stddev_Pop_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Stddev_Samp_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Sum_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** update columns of table "taxonomy_characteristic_value" */
export enum Taxonomy_Characteristic_Value_Update_Column {
  /** column name */
  CharacteristicValueId = 'characteristic_value_id',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  TaxonomyId = 'taxonomy_id',
  /** column name */
  Variations = 'variations'
}

/** order by var_pop() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Var_Pop_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Var_Samp_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "taxonomy_characteristic_value" */
export type Taxonomy_Characteristic_Value_Variance_Order_By = {
  characteristic_value_id?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** columns and relationships of "taxonomy_common_name" */
export type Taxonomy_Common_Name = {
  __typename?: 'taxonomy_common_name';
  language: Scalars['String'];
  name: Scalars['String'];
  preferred: Scalars['Boolean'];
  /** An object relationship */
  taxonomy: Taxonomy;
  taxonomy_id: Scalars['bigint'];
};

/** aggregated selection of "taxonomy_common_name" */
export type Taxonomy_Common_Name_Aggregate = {
  __typename?: 'taxonomy_common_name_aggregate';
  aggregate?: Maybe<Taxonomy_Common_Name_Aggregate_Fields>;
  nodes: Array<Taxonomy_Common_Name>;
};

/** aggregate fields of "taxonomy_common_name" */
export type Taxonomy_Common_Name_Aggregate_Fields = {
  __typename?: 'taxonomy_common_name_aggregate_fields';
  avg?: Maybe<Taxonomy_Common_Name_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Taxonomy_Common_Name_Max_Fields>;
  min?: Maybe<Taxonomy_Common_Name_Min_Fields>;
  stddev?: Maybe<Taxonomy_Common_Name_Stddev_Fields>;
  stddev_pop?: Maybe<Taxonomy_Common_Name_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Taxonomy_Common_Name_Stddev_Samp_Fields>;
  sum?: Maybe<Taxonomy_Common_Name_Sum_Fields>;
  var_pop?: Maybe<Taxonomy_Common_Name_Var_Pop_Fields>;
  var_samp?: Maybe<Taxonomy_Common_Name_Var_Samp_Fields>;
  variance?: Maybe<Taxonomy_Common_Name_Variance_Fields>;
};


/** aggregate fields of "taxonomy_common_name" */
export type Taxonomy_Common_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Taxonomy_Common_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Aggregate_Order_By = {
  avg?: Maybe<Taxonomy_Common_Name_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Taxonomy_Common_Name_Max_Order_By>;
  min?: Maybe<Taxonomy_Common_Name_Min_Order_By>;
  stddev?: Maybe<Taxonomy_Common_Name_Stddev_Order_By>;
  stddev_pop?: Maybe<Taxonomy_Common_Name_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Taxonomy_Common_Name_Stddev_Samp_Order_By>;
  sum?: Maybe<Taxonomy_Common_Name_Sum_Order_By>;
  var_pop?: Maybe<Taxonomy_Common_Name_Var_Pop_Order_By>;
  var_samp?: Maybe<Taxonomy_Common_Name_Var_Samp_Order_By>;
  variance?: Maybe<Taxonomy_Common_Name_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Arr_Rel_Insert_Input = {
  data: Array<Taxonomy_Common_Name_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Taxonomy_Common_Name_On_Conflict>;
};

/** aggregate avg on columns */
export type Taxonomy_Common_Name_Avg_Fields = {
  __typename?: 'taxonomy_common_name_avg_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Avg_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taxonomy_common_name". All fields are combined with a logical 'AND'. */
export type Taxonomy_Common_Name_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Common_Name_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Common_Name_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Common_Name_Bool_Exp>>;
  language?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  preferred?: Maybe<Boolean_Comparison_Exp>;
  taxonomy?: Maybe<Taxonomy_Bool_Exp>;
  taxonomy_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "taxonomy_common_name" */
export enum Taxonomy_Common_Name_Constraint {
  /** unique or primary key constraint */
  TaxonomyCommonNameIdLanguageNameUniqIdx = 'taxonomy_common_name_id_language_name_uniq_idx',
  /** unique or primary key constraint */
  TaxonomyCommonNamePreferredUniqIdx = 'taxonomy_common_name_preferred_uniq_idx'
}

/** input type for incrementing numeric columns in table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Inc_Input = {
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Insert_Input = {
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  preferred?: Maybe<Scalars['Boolean']>;
  taxonomy?: Maybe<Taxonomy_Obj_Rel_Insert_Input>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Taxonomy_Common_Name_Max_Fields = {
  __typename?: 'taxonomy_common_name_max_fields';
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Max_Order_By = {
  language?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Taxonomy_Common_Name_Min_Fields = {
  __typename?: 'taxonomy_common_name_min_fields';
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Min_Order_By = {
  language?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Mutation_Response = {
  __typename?: 'taxonomy_common_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Common_Name>;
};

/** on conflict condition type for table "taxonomy_common_name" */
export type Taxonomy_Common_Name_On_Conflict = {
  constraint: Taxonomy_Common_Name_Constraint;
  update_columns?: Array<Taxonomy_Common_Name_Update_Column>;
  where?: Maybe<Taxonomy_Common_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "taxonomy_common_name". */
export type Taxonomy_Common_Name_Order_By = {
  language?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  preferred?: Maybe<Order_By>;
  taxonomy?: Maybe<Taxonomy_Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** select columns of table "taxonomy_common_name" */
export enum Taxonomy_Common_Name_Select_Column {
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  Preferred = 'preferred',
  /** column name */
  TaxonomyId = 'taxonomy_id'
}

/** input type for updating data in table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Set_Input = {
  language?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  preferred?: Maybe<Scalars['Boolean']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Taxonomy_Common_Name_Stddev_Fields = {
  __typename?: 'taxonomy_common_name_stddev_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Stddev_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Taxonomy_Common_Name_Stddev_Pop_Fields = {
  __typename?: 'taxonomy_common_name_stddev_pop_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Stddev_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Taxonomy_Common_Name_Stddev_Samp_Fields = {
  __typename?: 'taxonomy_common_name_stddev_samp_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Stddev_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Taxonomy_Common_Name_Sum_Fields = {
  __typename?: 'taxonomy_common_name_sum_fields';
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Sum_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** update columns of table "taxonomy_common_name" */
export enum Taxonomy_Common_Name_Update_Column {
  /** column name */
  Language = 'language',
  /** column name */
  Name = 'name',
  /** column name */
  Preferred = 'preferred',
  /** column name */
  TaxonomyId = 'taxonomy_id'
}

/** aggregate var_pop on columns */
export type Taxonomy_Common_Name_Var_Pop_Fields = {
  __typename?: 'taxonomy_common_name_var_pop_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Var_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Taxonomy_Common_Name_Var_Samp_Fields = {
  __typename?: 'taxonomy_common_name_var_samp_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Var_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Taxonomy_Common_Name_Variance_Fields = {
  __typename?: 'taxonomy_common_name_variance_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "taxonomy_common_name" */
export type Taxonomy_Common_Name_Variance_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** unique or primary key constraints on table "taxonomy" */
export enum Taxonomy_Constraint {
  /** unique or primary key constraint */
  TaxonomyPkey = 'taxonomy_pkey'
}

export type Taxonomy_Find_Args = {
  match_types?: Maybe<Scalars['_text']>;
  search_term?: Maybe<Scalars['String']>;
};

export type Taxonomy_Find_By_Characteristic_Values_Args = {
  characteristic_values?: Maybe<Scalars['_int4']>;
};

/** columns and relationships of "taxonomy_find_by_characteristic_values_result" */
export type Taxonomy_Find_By_Characteristic_Values_Result = {
  __typename?: 'taxonomy_find_by_characteristic_values_result';
  common_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An object relationship */
  image: Image;
  image_id: Scalars['uuid'];
  scientific_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  taxonomy: Taxonomy;
  variations?: Maybe<Scalars['jsonb']>;
};


/** columns and relationships of "taxonomy_find_by_characteristic_values_result" */
export type Taxonomy_Find_By_Characteristic_Values_ResultVariationsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "taxonomy_find_by_characteristic_values_result" */
export type Taxonomy_Find_By_Characteristic_Values_Result_Aggregate = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_aggregate';
  aggregate?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Aggregate_Fields>;
  nodes: Array<Taxonomy_Find_By_Characteristic_Values_Result>;
};

/** aggregate fields of "taxonomy_find_by_characteristic_values_result" */
export type Taxonomy_Find_By_Characteristic_Values_Result_Aggregate_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_aggregate_fields';
  avg?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Max_Fields>;
  min?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Min_Fields>;
  stddev?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Stddev_Fields>;
  stddev_pop?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Stddev_Samp_Fields>;
  sum?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Sum_Fields>;
  var_pop?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Var_Pop_Fields>;
  var_samp?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Var_Samp_Fields>;
  variance?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Variance_Fields>;
};


/** aggregate fields of "taxonomy_find_by_characteristic_values_result" */
export type Taxonomy_Find_By_Characteristic_Values_Result_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Avg_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "taxonomy_find_by_characteristic_values_result". All fields are combined with a logical 'AND'. */
export type Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Find_By_Characteristic_Values_Result_Bool_Exp>>;
  common_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  image?: Maybe<Image_Bool_Exp>;
  image_id?: Maybe<Uuid_Comparison_Exp>;
  scientific_name?: Maybe<String_Comparison_Exp>;
  taxonomy?: Maybe<Taxonomy_Bool_Exp>;
  variations?: Maybe<Jsonb_Comparison_Exp>;
};

/** aggregate max on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Max_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_max_fields';
  common_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  image_id?: Maybe<Scalars['uuid']>;
  scientific_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Min_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_min_fields';
  common_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  image_id?: Maybe<Scalars['uuid']>;
  scientific_name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "taxonomy_find_by_characteristic_values_result". */
export type Taxonomy_Find_By_Characteristic_Values_Result_Order_By = {
  common_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image?: Maybe<Image_Order_By>;
  image_id?: Maybe<Order_By>;
  scientific_name?: Maybe<Order_By>;
  taxonomy?: Maybe<Taxonomy_Order_By>;
  variations?: Maybe<Order_By>;
};

/** select columns of table "taxonomy_find_by_characteristic_values_result" */
export enum Taxonomy_Find_By_Characteristic_Values_Result_Select_Column {
  /** column name */
  CommonName = 'common_name',
  /** column name */
  Id = 'id',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  ScientificName = 'scientific_name',
  /** column name */
  Variations = 'variations'
}

/** aggregate stddev on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Stddev_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Stddev_Pop_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Stddev_Samp_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Sum_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Var_Pop_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Var_Samp_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Taxonomy_Find_By_Characteristic_Values_Result_Variance_Fields = {
  __typename?: 'taxonomy_find_by_characteristic_values_result_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "taxonomy_find_result" */
export type Taxonomy_Find_Result = {
  __typename?: 'taxonomy_find_result';
  id: Scalars['bigint'];
  match: Scalars['String'];
  match_type: Scalars['String'];
  /** An object relationship */
  taxonomy: Taxonomy;
};

/** Boolean expression to filter rows from the table "taxonomy_find_result". All fields are combined with a logical 'AND'. */
export type Taxonomy_Find_Result_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Find_Result_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Find_Result_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Find_Result_Bool_Exp>>;
  id?: Maybe<Bigint_Comparison_Exp>;
  match?: Maybe<String_Comparison_Exp>;
  match_type?: Maybe<String_Comparison_Exp>;
  taxonomy?: Maybe<Taxonomy_Bool_Exp>;
};

/** Ordering options when selecting data from "taxonomy_find_result". */
export type Taxonomy_Find_Result_Order_By = {
  id?: Maybe<Order_By>;
  match?: Maybe<Order_By>;
  match_type?: Maybe<Order_By>;
  taxonomy?: Maybe<Taxonomy_Order_By>;
};

/** select columns of table "taxonomy_find_result" */
export enum Taxonomy_Find_Result_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Match = 'match',
  /** column name */
  MatchType = 'match_type'
}

/** columns and relationships of "taxonomy_image" */
export type Taxonomy_Image = {
  __typename?: 'taxonomy_image';
  identification: Scalars['Boolean'];
  /** An object relationship */
  image: Image;
  image_id: Scalars['uuid'];
  ordernum?: Maybe<Scalars['Int']>;
  /** An object relationship */
  taxonomy: Taxonomy;
  taxonomy_id: Scalars['bigint'];
  variations: Scalars['_int4'];
};

/** aggregated selection of "taxonomy_image" */
export type Taxonomy_Image_Aggregate = {
  __typename?: 'taxonomy_image_aggregate';
  aggregate?: Maybe<Taxonomy_Image_Aggregate_Fields>;
  nodes: Array<Taxonomy_Image>;
};

/** aggregate fields of "taxonomy_image" */
export type Taxonomy_Image_Aggregate_Fields = {
  __typename?: 'taxonomy_image_aggregate_fields';
  avg?: Maybe<Taxonomy_Image_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Taxonomy_Image_Max_Fields>;
  min?: Maybe<Taxonomy_Image_Min_Fields>;
  stddev?: Maybe<Taxonomy_Image_Stddev_Fields>;
  stddev_pop?: Maybe<Taxonomy_Image_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Taxonomy_Image_Stddev_Samp_Fields>;
  sum?: Maybe<Taxonomy_Image_Sum_Fields>;
  var_pop?: Maybe<Taxonomy_Image_Var_Pop_Fields>;
  var_samp?: Maybe<Taxonomy_Image_Var_Samp_Fields>;
  variance?: Maybe<Taxonomy_Image_Variance_Fields>;
};


/** aggregate fields of "taxonomy_image" */
export type Taxonomy_Image_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Taxonomy_Image_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "taxonomy_image" */
export type Taxonomy_Image_Aggregate_Order_By = {
  avg?: Maybe<Taxonomy_Image_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Taxonomy_Image_Max_Order_By>;
  min?: Maybe<Taxonomy_Image_Min_Order_By>;
  stddev?: Maybe<Taxonomy_Image_Stddev_Order_By>;
  stddev_pop?: Maybe<Taxonomy_Image_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Taxonomy_Image_Stddev_Samp_Order_By>;
  sum?: Maybe<Taxonomy_Image_Sum_Order_By>;
  var_pop?: Maybe<Taxonomy_Image_Var_Pop_Order_By>;
  var_samp?: Maybe<Taxonomy_Image_Var_Samp_Order_By>;
  variance?: Maybe<Taxonomy_Image_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "taxonomy_image" */
export type Taxonomy_Image_Arr_Rel_Insert_Input = {
  data: Array<Taxonomy_Image_Insert_Input>;
};

/** aggregate avg on columns */
export type Taxonomy_Image_Avg_Fields = {
  __typename?: 'taxonomy_image_avg_fields';
  ordernum?: Maybe<Scalars['Float']>;
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Avg_Order_By = {
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taxonomy_image". All fields are combined with a logical 'AND'. */
export type Taxonomy_Image_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Image_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Image_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Image_Bool_Exp>>;
  identification?: Maybe<Boolean_Comparison_Exp>;
  image?: Maybe<Image_Bool_Exp>;
  image_id?: Maybe<Uuid_Comparison_Exp>;
  ordernum?: Maybe<Int_Comparison_Exp>;
  taxonomy?: Maybe<Taxonomy_Bool_Exp>;
  taxonomy_id?: Maybe<Bigint_Comparison_Exp>;
  variations?: Maybe<_Int4_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "taxonomy_image" */
export type Taxonomy_Image_Inc_Input = {
  ordernum?: Maybe<Scalars['Int']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "taxonomy_image" */
export type Taxonomy_Image_Insert_Input = {
  identification?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Image_Obj_Rel_Insert_Input>;
  image_id?: Maybe<Scalars['uuid']>;
  ordernum?: Maybe<Scalars['Int']>;
  taxonomy?: Maybe<Taxonomy_Obj_Rel_Insert_Input>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
  variations?: Maybe<Scalars['_int4']>;
};

/** aggregate max on columns */
export type Taxonomy_Image_Max_Fields = {
  __typename?: 'taxonomy_image_max_fields';
  image_id?: Maybe<Scalars['uuid']>;
  ordernum?: Maybe<Scalars['Int']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Max_Order_By = {
  image_id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Taxonomy_Image_Min_Fields = {
  __typename?: 'taxonomy_image_min_fields';
  image_id?: Maybe<Scalars['uuid']>;
  ordernum?: Maybe<Scalars['Int']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Min_Order_By = {
  image_id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "taxonomy_image" */
export type Taxonomy_Image_Mutation_Response = {
  __typename?: 'taxonomy_image_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Image>;
};

/** Ordering options when selecting data from "taxonomy_image". */
export type Taxonomy_Image_Order_By = {
  identification?: Maybe<Order_By>;
  image?: Maybe<Image_Order_By>;
  image_id?: Maybe<Order_By>;
  ordernum?: Maybe<Order_By>;
  taxonomy?: Maybe<Taxonomy_Order_By>;
  taxonomy_id?: Maybe<Order_By>;
  variations?: Maybe<Order_By>;
};

/** select columns of table "taxonomy_image" */
export enum Taxonomy_Image_Select_Column {
  /** column name */
  Identification = 'identification',
  /** column name */
  ImageId = 'image_id',
  /** column name */
  Ordernum = 'ordernum',
  /** column name */
  TaxonomyId = 'taxonomy_id',
  /** column name */
  Variations = 'variations'
}

/** input type for updating data in table "taxonomy_image" */
export type Taxonomy_Image_Set_Input = {
  identification?: Maybe<Scalars['Boolean']>;
  image_id?: Maybe<Scalars['uuid']>;
  ordernum?: Maybe<Scalars['Int']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
  variations?: Maybe<Scalars['_int4']>;
};

/** aggregate stddev on columns */
export type Taxonomy_Image_Stddev_Fields = {
  __typename?: 'taxonomy_image_stddev_fields';
  ordernum?: Maybe<Scalars['Float']>;
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Stddev_Order_By = {
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Taxonomy_Image_Stddev_Pop_Fields = {
  __typename?: 'taxonomy_image_stddev_pop_fields';
  ordernum?: Maybe<Scalars['Float']>;
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Stddev_Pop_Order_By = {
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Taxonomy_Image_Stddev_Samp_Fields = {
  __typename?: 'taxonomy_image_stddev_samp_fields';
  ordernum?: Maybe<Scalars['Float']>;
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Stddev_Samp_Order_By = {
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Taxonomy_Image_Sum_Fields = {
  __typename?: 'taxonomy_image_sum_fields';
  ordernum?: Maybe<Scalars['Int']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Sum_Order_By = {
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Taxonomy_Image_Var_Pop_Fields = {
  __typename?: 'taxonomy_image_var_pop_fields';
  ordernum?: Maybe<Scalars['Float']>;
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Var_Pop_Order_By = {
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Taxonomy_Image_Var_Samp_Fields = {
  __typename?: 'taxonomy_image_var_samp_fields';
  ordernum?: Maybe<Scalars['Float']>;
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Var_Samp_Order_By = {
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Taxonomy_Image_Variance_Fields = {
  __typename?: 'taxonomy_image_variance_fields';
  ordernum?: Maybe<Scalars['Float']>;
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "taxonomy_image" */
export type Taxonomy_Image_Variance_Order_By = {
  ordernum?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "taxonomy" */
export type Taxonomy_Inc_Input = {
  id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "taxonomy" */
export type Taxonomy_Insert_Input = {
  id?: Maybe<Scalars['bigint']>;
  identification_published?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Scalars['ltree']>;
  published?: Maybe<Scalars['Boolean']>;
  rank_id?: Maybe<Scalars['String']>;
  taxonomy_articles?: Maybe<Taxonomy_Article_Arr_Rel_Insert_Input>;
  taxonomy_audios?: Maybe<Taxonomy_Audio_Arr_Rel_Insert_Input>;
  taxonomy_characteristic_sections?: Maybe<Taxonomy_Characteristic_Section_Arr_Rel_Insert_Input>;
  taxonomy_characteristic_values?: Maybe<Taxonomy_Characteristic_Value_Arr_Rel_Insert_Input>;
  taxonomy_common_names?: Maybe<Taxonomy_Common_Name_Arr_Rel_Insert_Input>;
  taxonomy_images?: Maybe<Taxonomy_Image_Arr_Rel_Insert_Input>;
  taxonomy_scientific_names?: Maybe<Taxonomy_Scientific_Name_Arr_Rel_Insert_Input>;
  taxonomy_widgets?: Maybe<Taxonomy_Widget_Arr_Rel_Insert_Input>;
};

export type Taxonomy_List_Args = {
  order_by?: Maybe<Scalars['String']>;
};

/** columns and relationships of "taxonomy_list_result" */
export type Taxonomy_List_Result = {
  __typename?: 'taxonomy_list_result';
  common_name?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  row_number: Scalars['bigint'];
  scientific_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  taxonomy: Taxonomy;
};

/** Boolean expression to filter rows from the table "taxonomy_list_result". All fields are combined with a logical 'AND'. */
export type Taxonomy_List_Result_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_List_Result_Bool_Exp>>;
  _not?: Maybe<Taxonomy_List_Result_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_List_Result_Bool_Exp>>;
  common_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Bigint_Comparison_Exp>;
  row_number?: Maybe<Bigint_Comparison_Exp>;
  scientific_name?: Maybe<String_Comparison_Exp>;
  taxonomy?: Maybe<Taxonomy_Bool_Exp>;
};

/** Ordering options when selecting data from "taxonomy_list_result". */
export type Taxonomy_List_Result_Order_By = {
  common_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  scientific_name?: Maybe<Order_By>;
  taxonomy?: Maybe<Taxonomy_Order_By>;
};

/** select columns of table "taxonomy_list_result" */
export enum Taxonomy_List_Result_Select_Column {
  /** column name */
  CommonName = 'common_name',
  /** column name */
  Id = 'id',
  /** column name */
  RowNumber = 'row_number',
  /** column name */
  ScientificName = 'scientific_name'
}

/** aggregate max on columns */
export type Taxonomy_Max_Fields = {
  __typename?: 'taxonomy_max_fields';
  id?: Maybe<Scalars['bigint']>;
  path?: Maybe<Scalars['ltree']>;
  rank_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Taxonomy_Min_Fields = {
  __typename?: 'taxonomy_min_fields';
  id?: Maybe<Scalars['bigint']>;
  path?: Maybe<Scalars['ltree']>;
  rank_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "taxonomy" */
export type Taxonomy_Mutation_Response = {
  __typename?: 'taxonomy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy>;
};

/** input type for inserting object relation for remote table "taxonomy" */
export type Taxonomy_Obj_Rel_Insert_Input = {
  data: Taxonomy_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Taxonomy_On_Conflict>;
};

/** on conflict condition type for table "taxonomy" */
export type Taxonomy_On_Conflict = {
  constraint: Taxonomy_Constraint;
  update_columns?: Array<Taxonomy_Update_Column>;
  where?: Maybe<Taxonomy_Bool_Exp>;
};

/** Ordering options when selecting data from "taxonomy". */
export type Taxonomy_Order_By = {
  feedback_taxonomy_references_aggregate?: Maybe<Feedback_Taxonomy_Reference_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  identification_published?: Maybe<Order_By>;
  path?: Maybe<Order_By>;
  published?: Maybe<Order_By>;
  rank?: Maybe<Rank_Order_By>;
  rank_id?: Maybe<Order_By>;
  taxonomy_articles_aggregate?: Maybe<Taxonomy_Article_Aggregate_Order_By>;
  taxonomy_audios_aggregate?: Maybe<Taxonomy_Audio_Aggregate_Order_By>;
  taxonomy_characteristic_sections_aggregate?: Maybe<Taxonomy_Characteristic_Section_Aggregate_Order_By>;
  taxonomy_characteristic_values_aggregate?: Maybe<Taxonomy_Characteristic_Value_Aggregate_Order_By>;
  taxonomy_common_names_aggregate?: Maybe<Taxonomy_Common_Name_Aggregate_Order_By>;
  taxonomy_images_aggregate?: Maybe<Taxonomy_Image_Aggregate_Order_By>;
  taxonomy_scientific_names_aggregate?: Maybe<Taxonomy_Scientific_Name_Aggregate_Order_By>;
  taxonomy_widgets_aggregate?: Maybe<Taxonomy_Widget_Aggregate_Order_By>;
};

/** primary key columns input for table: taxonomy */
export type Taxonomy_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** columns and relationships of "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name = {
  __typename?: 'taxonomy_scientific_name';
  current: Scalars['Boolean'];
  name: Scalars['String'];
  taxonomy_id: Scalars['bigint'];
};

/** aggregated selection of "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Aggregate = {
  __typename?: 'taxonomy_scientific_name_aggregate';
  aggregate?: Maybe<Taxonomy_Scientific_Name_Aggregate_Fields>;
  nodes: Array<Taxonomy_Scientific_Name>;
};

/** aggregate fields of "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Aggregate_Fields = {
  __typename?: 'taxonomy_scientific_name_aggregate_fields';
  avg?: Maybe<Taxonomy_Scientific_Name_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Taxonomy_Scientific_Name_Max_Fields>;
  min?: Maybe<Taxonomy_Scientific_Name_Min_Fields>;
  stddev?: Maybe<Taxonomy_Scientific_Name_Stddev_Fields>;
  stddev_pop?: Maybe<Taxonomy_Scientific_Name_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Taxonomy_Scientific_Name_Stddev_Samp_Fields>;
  sum?: Maybe<Taxonomy_Scientific_Name_Sum_Fields>;
  var_pop?: Maybe<Taxonomy_Scientific_Name_Var_Pop_Fields>;
  var_samp?: Maybe<Taxonomy_Scientific_Name_Var_Samp_Fields>;
  variance?: Maybe<Taxonomy_Scientific_Name_Variance_Fields>;
};


/** aggregate fields of "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Taxonomy_Scientific_Name_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Aggregate_Order_By = {
  avg?: Maybe<Taxonomy_Scientific_Name_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Taxonomy_Scientific_Name_Max_Order_By>;
  min?: Maybe<Taxonomy_Scientific_Name_Min_Order_By>;
  stddev?: Maybe<Taxonomy_Scientific_Name_Stddev_Order_By>;
  stddev_pop?: Maybe<Taxonomy_Scientific_Name_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Taxonomy_Scientific_Name_Stddev_Samp_Order_By>;
  sum?: Maybe<Taxonomy_Scientific_Name_Sum_Order_By>;
  var_pop?: Maybe<Taxonomy_Scientific_Name_Var_Pop_Order_By>;
  var_samp?: Maybe<Taxonomy_Scientific_Name_Var_Samp_Order_By>;
  variance?: Maybe<Taxonomy_Scientific_Name_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Arr_Rel_Insert_Input = {
  data: Array<Taxonomy_Scientific_Name_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Taxonomy_Scientific_Name_On_Conflict>;
};

/** aggregate avg on columns */
export type Taxonomy_Scientific_Name_Avg_Fields = {
  __typename?: 'taxonomy_scientific_name_avg_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Avg_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taxonomy_scientific_name". All fields are combined with a logical 'AND'. */
export type Taxonomy_Scientific_Name_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Scientific_Name_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Scientific_Name_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Scientific_Name_Bool_Exp>>;
  current?: Maybe<Boolean_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  taxonomy_id?: Maybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "taxonomy_scientific_name" */
export enum Taxonomy_Scientific_Name_Constraint {
  /** unique or primary key constraint */
  TaxonomyScientificNameCurrentUniqIdx = 'taxonomy_scientific_name_current_uniq_idx',
  /** unique or primary key constraint */
  TaxonomyScientificNameIdNameUniqIdx = 'taxonomy_scientific_name_id_name_uniq_idx'
}

/** input type for inserting data into table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Insert_Input = {
  current?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Taxonomy_Scientific_Name_Max_Fields = {
  __typename?: 'taxonomy_scientific_name_max_fields';
  name?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Max_Order_By = {
  name?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Taxonomy_Scientific_Name_Min_Fields = {
  __typename?: 'taxonomy_scientific_name_min_fields';
  name?: Maybe<Scalars['String']>;
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Min_Order_By = {
  name?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Mutation_Response = {
  __typename?: 'taxonomy_scientific_name_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Scientific_Name>;
};

/** on conflict condition type for table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_On_Conflict = {
  constraint: Taxonomy_Scientific_Name_Constraint;
  update_columns?: Array<Taxonomy_Scientific_Name_Update_Column>;
  where?: Maybe<Taxonomy_Scientific_Name_Bool_Exp>;
};

/** Ordering options when selecting data from "taxonomy_scientific_name". */
export type Taxonomy_Scientific_Name_Order_By = {
  current?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  taxonomy_id?: Maybe<Order_By>;
};

/** select columns of table "taxonomy_scientific_name" */
export enum Taxonomy_Scientific_Name_Select_Column {
  /** column name */
  Current = 'current',
  /** column name */
  Name = 'name',
  /** column name */
  TaxonomyId = 'taxonomy_id'
}

/** input type for updating data in table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Set_Input = {
  current?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Taxonomy_Scientific_Name_Stddev_Fields = {
  __typename?: 'taxonomy_scientific_name_stddev_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Stddev_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Taxonomy_Scientific_Name_Stddev_Pop_Fields = {
  __typename?: 'taxonomy_scientific_name_stddev_pop_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Stddev_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Taxonomy_Scientific_Name_Stddev_Samp_Fields = {
  __typename?: 'taxonomy_scientific_name_stddev_samp_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Stddev_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Taxonomy_Scientific_Name_Sum_Fields = {
  __typename?: 'taxonomy_scientific_name_sum_fields';
  taxonomy_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Sum_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** update columns of table "taxonomy_scientific_name" */
export enum Taxonomy_Scientific_Name_Update_Column {
  /** column name */
  Current = 'current',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Taxonomy_Scientific_Name_Var_Pop_Fields = {
  __typename?: 'taxonomy_scientific_name_var_pop_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Var_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Taxonomy_Scientific_Name_Var_Samp_Fields = {
  __typename?: 'taxonomy_scientific_name_var_samp_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Var_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Taxonomy_Scientific_Name_Variance_Fields = {
  __typename?: 'taxonomy_scientific_name_variance_fields';
  taxonomy_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "taxonomy_scientific_name" */
export type Taxonomy_Scientific_Name_Variance_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** select columns of table "taxonomy" */
export enum Taxonomy_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationPublished = 'identification_published',
  /** column name */
  Path = 'path',
  /** column name */
  Published = 'published',
  /** column name */
  RankId = 'rank_id'
}

/** input type for updating data in table "taxonomy" */
export type Taxonomy_Set_Input = {
  id?: Maybe<Scalars['bigint']>;
  identification_published?: Maybe<Scalars['Boolean']>;
  path?: Maybe<Scalars['ltree']>;
  published?: Maybe<Scalars['Boolean']>;
  rank_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Taxonomy_Stddev_Fields = {
  __typename?: 'taxonomy_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Taxonomy_Stddev_Pop_Fields = {
  __typename?: 'taxonomy_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Taxonomy_Stddev_Samp_Fields = {
  __typename?: 'taxonomy_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Taxonomy_Sum_Fields = {
  __typename?: 'taxonomy_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "taxonomy" */
export enum Taxonomy_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IdentificationPublished = 'identification_published',
  /** column name */
  Path = 'path',
  /** column name */
  Published = 'published',
  /** column name */
  RankId = 'rank_id'
}

/** aggregate var_pop on columns */
export type Taxonomy_Var_Pop_Fields = {
  __typename?: 'taxonomy_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Taxonomy_Var_Samp_Fields = {
  __typename?: 'taxonomy_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Taxonomy_Variance_Fields = {
  __typename?: 'taxonomy_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "taxonomy_widget" */
export type Taxonomy_Widget = {
  __typename?: 'taxonomy_widget';
  taxonomy_id: Scalars['bigint'];
  /** An object relationship */
  taxonomy_widget_type: Taxonomy_Widget_Type;
  widget_data: Scalars['jsonb'];
  widget_type_id: Scalars['String'];
};


/** columns and relationships of "taxonomy_widget" */
export type Taxonomy_WidgetWidget_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "taxonomy_widget" */
export type Taxonomy_Widget_Aggregate_Order_By = {
  avg?: Maybe<Taxonomy_Widget_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Taxonomy_Widget_Max_Order_By>;
  min?: Maybe<Taxonomy_Widget_Min_Order_By>;
  stddev?: Maybe<Taxonomy_Widget_Stddev_Order_By>;
  stddev_pop?: Maybe<Taxonomy_Widget_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Taxonomy_Widget_Stddev_Samp_Order_By>;
  sum?: Maybe<Taxonomy_Widget_Sum_Order_By>;
  var_pop?: Maybe<Taxonomy_Widget_Var_Pop_Order_By>;
  var_samp?: Maybe<Taxonomy_Widget_Var_Samp_Order_By>;
  variance?: Maybe<Taxonomy_Widget_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Taxonomy_Widget_Append_Input = {
  widget_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "taxonomy_widget" */
export type Taxonomy_Widget_Arr_Rel_Insert_Input = {
  data: Array<Taxonomy_Widget_Insert_Input>;
};

/** order by avg() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Avg_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taxonomy_widget". All fields are combined with a logical 'AND'. */
export type Taxonomy_Widget_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Widget_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Widget_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Widget_Bool_Exp>>;
  taxonomy_id?: Maybe<Bigint_Comparison_Exp>;
  taxonomy_widget_type?: Maybe<Taxonomy_Widget_Type_Bool_Exp>;
  widget_data?: Maybe<Jsonb_Comparison_Exp>;
  widget_type_id?: Maybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Taxonomy_Widget_Delete_At_Path_Input = {
  widget_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Taxonomy_Widget_Delete_Elem_Input = {
  widget_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Taxonomy_Widget_Delete_Key_Input = {
  widget_data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "taxonomy_widget" */
export type Taxonomy_Widget_Insert_Input = {
  taxonomy_id?: Maybe<Scalars['bigint']>;
  widget_data?: Maybe<Scalars['jsonb']>;
  widget_type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Max_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
  widget_type_id?: Maybe<Order_By>;
};

/** order by min() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Min_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
  widget_type_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "taxonomy_widget" */
export type Taxonomy_Widget_Mutation_Response = {
  __typename?: 'taxonomy_widget_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Taxonomy_Widget>;
};

/** Ordering options when selecting data from "taxonomy_widget". */
export type Taxonomy_Widget_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
  taxonomy_widget_type?: Maybe<Taxonomy_Widget_Type_Order_By>;
  widget_data?: Maybe<Order_By>;
  widget_type_id?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Taxonomy_Widget_Prepend_Input = {
  widget_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "taxonomy_widget" */
export enum Taxonomy_Widget_Select_Column {
  /** column name */
  TaxonomyId = 'taxonomy_id',
  /** column name */
  WidgetData = 'widget_data',
  /** column name */
  WidgetTypeId = 'widget_type_id'
}

/** input type for updating data in table "taxonomy_widget" */
export type Taxonomy_Widget_Set_Input = {
  widget_data?: Maybe<Scalars['jsonb']>;
};

/** order by stddev() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Stddev_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Stddev_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Stddev_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Sum_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** columns and relationships of "taxonomy_widget_type" */
export type Taxonomy_Widget_Type = {
  __typename?: 'taxonomy_widget_type';
  id: Scalars['String'];
};

/** Boolean expression to filter rows from the table "taxonomy_widget_type". All fields are combined with a logical 'AND'. */
export type Taxonomy_Widget_Type_Bool_Exp = {
  _and?: Maybe<Array<Taxonomy_Widget_Type_Bool_Exp>>;
  _not?: Maybe<Taxonomy_Widget_Type_Bool_Exp>;
  _or?: Maybe<Array<Taxonomy_Widget_Type_Bool_Exp>>;
  id?: Maybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "taxonomy_widget_type". */
export type Taxonomy_Widget_Type_Order_By = {
  id?: Maybe<Order_By>;
};

/** select columns of table "taxonomy_widget_type" */
export enum Taxonomy_Widget_Type_Select_Column {
  /** column name */
  Id = 'id'
}

/** order by var_pop() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Var_Pop_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Var_Samp_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "taxonomy_widget" */
export type Taxonomy_Widget_Variance_Order_By = {
  taxonomy_id?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type Urls_Image_Args = {
  presets?: Maybe<Scalars['_text']>;
};

/** columns and relationships of "user_account" */
export type User_Account = {
  __typename?: 'user_account';
  active: Scalars['Boolean'];
  email: Scalars['String'];
  email_confirmed: Scalars['Boolean'];
  id: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "user_account". All fields are combined with a logical 'AND'. */
export type User_Account_Bool_Exp = {
  _and?: Maybe<Array<User_Account_Bool_Exp>>;
  _not?: Maybe<User_Account_Bool_Exp>;
  _or?: Maybe<Array<User_Account_Bool_Exp>>;
  active?: Maybe<Boolean_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  email_confirmed?: Maybe<Boolean_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "user_account". */
export type User_Account_Order_By = {
  active?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  email_confirmed?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** select columns of table "user_account" */
export enum User_Account_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Email = 'email',
  /** column name */
  EmailConfirmed = 'email_confirmed',
  /** column name */
  Id = 'id'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "variation" */
export type Variation = {
  __typename?: 'variation';
  /** An object relationship */
  characteristic_section: Characteristic_Section;
  characteristic_section_id: Scalars['bigint'];
  id: Scalars['Int'];
  /** A computed field, executes function "computed_variation_name" */
  name?: Maybe<Scalars['String']>;
  names: Scalars['jsonb'];
  show: Scalars['Boolean'];
};


/** columns and relationships of "variation" */
export type VariationNamesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "variation" */
export type Variation_Aggregate_Order_By = {
  avg?: Maybe<Variation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Variation_Max_Order_By>;
  min?: Maybe<Variation_Min_Order_By>;
  stddev?: Maybe<Variation_Stddev_Order_By>;
  stddev_pop?: Maybe<Variation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Variation_Stddev_Samp_Order_By>;
  sum?: Maybe<Variation_Sum_Order_By>;
  var_pop?: Maybe<Variation_Var_Pop_Order_By>;
  var_samp?: Maybe<Variation_Var_Samp_Order_By>;
  variance?: Maybe<Variation_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Variation_Append_Input = {
  names?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "variation" */
export type Variation_Arr_Rel_Insert_Input = {
  data: Array<Variation_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Variation_On_Conflict>;
};

/** order by avg() on columns of table "variation" */
export type Variation_Avg_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "variation". All fields are combined with a logical 'AND'. */
export type Variation_Bool_Exp = {
  _and?: Maybe<Array<Variation_Bool_Exp>>;
  _not?: Maybe<Variation_Bool_Exp>;
  _or?: Maybe<Array<Variation_Bool_Exp>>;
  characteristic_section?: Maybe<Characteristic_Section_Bool_Exp>;
  characteristic_section_id?: Maybe<Bigint_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  names?: Maybe<Jsonb_Comparison_Exp>;
  show?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "variation" */
export enum Variation_Constraint {
  /** unique or primary key constraint */
  VariationPkey = 'variation_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Variation_Delete_At_Path_Input = {
  names?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Variation_Delete_Elem_Input = {
  names?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Variation_Delete_Key_Input = {
  names?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "variation" */
export type Variation_Inc_Input = {
  characteristic_section_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "variation" */
export type Variation_Insert_Input = {
  characteristic_section?: Maybe<Characteristic_Section_Obj_Rel_Insert_Input>;
  characteristic_section_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['jsonb']>;
  show?: Maybe<Scalars['Boolean']>;
};

/** order by max() on columns of table "variation" */
export type Variation_Max_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by min() on columns of table "variation" */
export type Variation_Min_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** response of any mutation on the table "variation" */
export type Variation_Mutation_Response = {
  __typename?: 'variation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Variation>;
};

/** on conflict condition type for table "variation" */
export type Variation_On_Conflict = {
  constraint: Variation_Constraint;
  update_columns?: Array<Variation_Update_Column>;
  where?: Maybe<Variation_Bool_Exp>;
};

/** Ordering options when selecting data from "variation". */
export type Variation_Order_By = {
  characteristic_section?: Maybe<Characteristic_Section_Order_By>;
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  names?: Maybe<Order_By>;
  show?: Maybe<Order_By>;
};

/** primary key columns input for table: variation */
export type Variation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Variation_Prepend_Input = {
  names?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "variation" */
export enum Variation_Select_Column {
  /** column name */
  CharacteristicSectionId = 'characteristic_section_id',
  /** column name */
  Id = 'id',
  /** column name */
  Names = 'names',
  /** column name */
  Show = 'show'
}

/** input type for updating data in table "variation" */
export type Variation_Set_Input = {
  characteristic_section_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['Int']>;
  names?: Maybe<Scalars['jsonb']>;
  show?: Maybe<Scalars['Boolean']>;
};

/** order by stddev() on columns of table "variation" */
export type Variation_Stddev_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_pop() on columns of table "variation" */
export type Variation_Stddev_Pop_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by stddev_samp() on columns of table "variation" */
export type Variation_Stddev_Samp_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by sum() on columns of table "variation" */
export type Variation_Sum_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "variation" */
export enum Variation_Update_Column {
  /** column name */
  CharacteristicSectionId = 'characteristic_section_id',
  /** column name */
  Id = 'id',
  /** column name */
  Names = 'names',
  /** column name */
  Show = 'show'
}

/** order by var_pop() on columns of table "variation" */
export type Variation_Var_Pop_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by var_samp() on columns of table "variation" */
export type Variation_Var_Samp_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** order by variance() on columns of table "variation" */
export type Variation_Variance_Order_By = {
  characteristic_section_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type ApiAllLanguageQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllLanguageQuery = (
  { __typename?: 'query_root' }
  & { language: Array<(
    { __typename?: 'language' }
    & Pick<Language, 'key' | 'name'>
  )> }
);

export type ApiAllRankQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllRankQuery = (
  { __typename?: 'query_root' }
  & { rank: Array<(
    { __typename?: 'rank' }
    & Pick<Rank, 'id' | 'name'>
  )> }
);

export type ApiAllTaxonomyQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllTaxonomyQuery = (
  { __typename?: 'query_root' }
  & { taxonomy: Array<(
    { __typename?: 'taxonomy' }
    & Pick<Taxonomy, 'id' | 'scientific_name' | 'common_name' | 'rank_id' | 'path'>
  )> }
);

export type ApiSingleTaxonomyQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type ApiSingleTaxonomyQuery = (
  { __typename?: 'query_root' }
  & { taxonomy_by_pk?: Maybe<(
    { __typename?: 'taxonomy' }
    & Pick<Taxonomy, 'scientific_name' | 'common_name' | 'id' | 'rank_id' | 'path' | 'published' | 'identification_published'>
    & { rank: (
      { __typename?: 'rank' }
      & Pick<Rank, 'id' | 'name'>
    ), taxonomy_scientific_names: Array<(
      { __typename?: 'taxonomy_scientific_name' }
      & Pick<Taxonomy_Scientific_Name, 'name' | 'current'>
    )>, taxonomy_characteristic_sections: Array<(
      { __typename?: 'taxonomy_characteristic_section' }
      & Pick<Taxonomy_Characteristic_Section, 'characteristic_section_id' | 'show_in_section_listing'>
      & { characteristic_section: (
        { __typename?: 'characteristic_section' }
        & Pick<Characteristic_Section, 'name' | 'names'>
      ) }
    )>, taxonomy_common_names: Array<(
      { __typename?: 'taxonomy_common_name' }
      & Pick<Taxonomy_Common_Name, 'name' | 'language' | 'preferred'>
    )>, taxonomy_articles: Array<(
      { __typename?: 'taxonomy_article' }
      & Pick<Taxonomy_Article, 'article' | 'information_strike' | 'language' | 'url_slug' | 'section' | 'published' | 'legacy_copyright'>
    )>, taxonomy_images: Array<(
      { __typename?: 'taxonomy_image' }
      & Pick<Taxonomy_Image, 'image_id' | 'taxonomy_id' | 'ordernum' | 'identification' | 'variations'>
      & { image: (
        { __typename?: 'image' }
        & Pick<Image, 'id' | 'copyright' | 'filename' | 'created_at' | 'alt' | 'caption' | 'captions' | 'alts' | 'width' | 'height' | 'file_extension' | 'output_format'>
        & { urls?: Maybe<Array<(
          { __typename?: 'imgproxy_urls' }
          & Pick<Imgproxy_Urls, 'preset' | 'url'>
        )>> }
      ) }
    )>, taxonomy_characteristic_values: Array<(
      { __typename?: 'taxonomy_characteristic_value' }
      & Pick<Taxonomy_Characteristic_Value, 'frequency' | 'variations'>
      & { characteristic_value: (
        { __typename?: 'characteristic_value' }
        & Pick<Characteristic_Value, 'id' | 'name'>
        & { characteristic: (
          { __typename?: 'characteristic' }
          & Pick<Characteristic, 'id' | 'name'>
          & { characteristic_group?: Maybe<(
            { __typename?: 'characteristic_group' }
            & Pick<Characteristic_Group, 'id' | 'name'>
          )> }
        ) }
      ) }
    )>, taxonomy_audios: Array<(
      { __typename?: 'taxonomy_audio' }
      & Pick<Taxonomy_Audio, 'audio_id'>
    )>, taxonomy_widgets: Array<(
      { __typename?: 'taxonomy_widget' }
      & Pick<Taxonomy_Widget, 'widget_type_id' | 'widget_data'>
    )>, ancestors?: Maybe<Array<(
      { __typename?: 'taxonomy' }
      & Pick<Taxonomy, 'id' | 'scientific_name' | 'common_name' | 'path'>
      & { rank: (
        { __typename?: 'rank' }
        & Pick<Rank, 'id' | 'name'>
      ) }
    )>>, feedback_taxonomy_references: Array<(
      { __typename?: 'feedback_taxonomy_reference' }
      & Pick<Feedback_Taxonomy_Reference, 'feedback_id'>
    )> }
  )> }
);

export type ApiAddTaxonomyMutationVariables = Exact<{
  name: Scalars['String'];
  path: Scalars['ltree'];
  rankId: Scalars['String'];
  sectionId?: Maybe<Scalars['Int']>;
  showInListing: Scalars['Boolean'];
}>;


export type ApiAddTaxonomyMutation = (
  { __typename?: 'mutation_root' }
  & { insert_taxonomy_one?: Maybe<(
    { __typename?: 'taxonomy' }
    & Pick<Taxonomy, 'id' | 'scientific_name' | 'rank_id' | 'path' | 'published' | 'identification_published'>
    & { taxonomy_characteristic_sections: Array<(
      { __typename?: 'taxonomy_characteristic_section' }
      & Pick<Taxonomy_Characteristic_Section, 'characteristic_section_id'>
    )> }
  )> }
);

export type ApiChangeTaxonomyPathMutationVariables = Exact<{
  id: Scalars['bigint'];
  path: Scalars['ltree'];
}>;


export type ApiChangeTaxonomyPathMutation = (
  { __typename?: 'mutation_root' }
  & { update_taxonomy_by_pk?: Maybe<(
    { __typename?: 'taxonomy' }
    & Pick<Taxonomy, 'id' | 'path'>
  )> }
);

export type ApiChangeTaxonomyMutationVariables = Exact<{
  id: Scalars['bigint'];
  published: Scalars['Boolean'];
  identificationPublished: Scalars['Boolean'];
  characteristicSections: Array<Taxonomy_Characteristic_Section_Insert_Input> | Taxonomy_Characteristic_Section_Insert_Input;
  audioIds: Array<Taxonomy_Audio_Insert_Input> | Taxonomy_Audio_Insert_Input;
  rankId: Scalars['String'];
}>;


export type ApiChangeTaxonomyMutation = (
  { __typename?: 'mutation_root' }
  & { update_taxonomy_by_pk?: Maybe<(
    { __typename?: 'taxonomy' }
    & Pick<Taxonomy, 'scientific_name' | 'id' | 'rank_id' | 'path' | 'published' | 'identification_published'>
  )>, delete_taxonomy_characteristic_section?: Maybe<(
    { __typename?: 'taxonomy_characteristic_section_mutation_response' }
    & Pick<Taxonomy_Characteristic_Section_Mutation_Response, 'affected_rows'>
  )>, insert_taxonomy_characteristic_section?: Maybe<(
    { __typename?: 'taxonomy_characteristic_section_mutation_response' }
    & Pick<Taxonomy_Characteristic_Section_Mutation_Response, 'affected_rows'>
  )>, delete_taxonomy_audio?: Maybe<(
    { __typename?: 'taxonomy_audio_mutation_response' }
    & Pick<Taxonomy_Audio_Mutation_Response, 'affected_rows'>
  )>, insert_taxonomy_audio?: Maybe<(
    { __typename?: 'taxonomy_audio_mutation_response' }
    & Pick<Taxonomy_Audio_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiChangeTaxonomyScientificNamesMutationVariables = Exact<{
  id: Scalars['bigint'];
  values: Array<Taxonomy_Scientific_Name_Insert_Input> | Taxonomy_Scientific_Name_Insert_Input;
}>;


export type ApiChangeTaxonomyScientificNamesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_taxonomy_scientific_name?: Maybe<(
    { __typename?: 'taxonomy_scientific_name_mutation_response' }
    & Pick<Taxonomy_Scientific_Name_Mutation_Response, 'affected_rows'>
  )>, insert_taxonomy_scientific_name?: Maybe<(
    { __typename?: 'taxonomy_scientific_name_mutation_response' }
    & Pick<Taxonomy_Scientific_Name_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiChangeTaxonomyCommonNamesMutationVariables = Exact<{
  id?: Maybe<Scalars['bigint']>;
  language?: Maybe<Scalars['String']>;
  values: Array<Taxonomy_Common_Name_Insert_Input> | Taxonomy_Common_Name_Insert_Input;
}>;


export type ApiChangeTaxonomyCommonNamesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_taxonomy_common_name?: Maybe<(
    { __typename?: 'taxonomy_common_name_mutation_response' }
    & Pick<Taxonomy_Common_Name_Mutation_Response, 'affected_rows'>
  )>, insert_taxonomy_common_name?: Maybe<(
    { __typename?: 'taxonomy_common_name_mutation_response' }
    & Pick<Taxonomy_Common_Name_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiAllAudioQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllAudioQuery = (
  { __typename?: 'query_root' }
  & { audio: Array<(
    { __typename?: 'audio' }
    & Pick<Audio, 'id'>
  )> }
);

export type ApiFindImageQueryVariables = Exact<{
  searchTerm: Scalars['String'];
}>;


export type ApiFindImageQuery = (
  { __typename?: 'query_root' }
  & { image: Array<(
    { __typename?: 'image' }
    & Pick<Image, 'id' | 'filename'>
    & { urls?: Maybe<Array<(
      { __typename?: 'imgproxy_urls' }
      & Pick<Imgproxy_Urls, 'url'>
    )>> }
  )> }
);

export type ApiSingleImageQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ApiSingleImageQuery = (
  { __typename?: 'query_root' }
  & { image_by_pk?: Maybe<(
    { __typename?: 'image' }
    & Pick<Image, 'id' | 'filename'>
    & { urls?: Maybe<Array<(
      { __typename?: 'imgproxy_urls' }
      & Pick<Imgproxy_Urls, 'url'>
    )>> }
  )> }
);

export type ApiAllCharacteristicSectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllCharacteristicSectionsQuery = (
  { __typename?: 'query_root' }
  & { characteristic_section: Array<(
    { __typename?: 'characteristic_section' }
    & Pick<Characteristic_Section, 'id' | 'name' | 'names' | 'descriptions' | 'metadata_descriptions' | 'enabled_languages' | 'identification_enabled_languages' | 'icon_id' | 'image_id' | 'url_slugs' | 'ordernum'>
  )> }
);

export type ApiSingleCharacteristicsSectionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiSingleCharacteristicsSectionQuery = (
  { __typename?: 'query_root' }
  & { characteristic_section_by_pk?: Maybe<(
    { __typename?: 'characteristic_section' }
    & Pick<Characteristic_Section, 'id' | 'name' | 'names' | 'descriptions' | 'enabled_languages' | 'identification_enabled_languages' | 'icon_id' | 'image_id' | 'url_slugs' | 'ordernum'>
    & { variations: Array<(
      { __typename?: 'variation' }
      & Pick<Variation, 'id' | 'name' | 'names'>
    )>, characteristic_groups: Array<(
      { __typename?: 'characteristic_group' }
      & Pick<Characteristic_Group, 'name' | 'id'>
      & { characteristics: Array<(
        { __typename?: 'characteristic' }
        & Pick<Characteristic, 'id' | 'name'>
        & { characteristic_values: Array<(
          { __typename?: 'characteristic_value' }
          & Pick<Characteristic_Value, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type ApiAddCharacteristicSectionMutationVariables = Exact<{
  names: Scalars['jsonb'];
  descriptions: Scalars['jsonb'];
  metadataDescriptions: Scalars['jsonb'];
  urlSlugs: Scalars['jsonb'];
  enabledLanguages: Scalars['jsonb'];
  identificationEnabledLanguages: Scalars['jsonb'];
  imageId?: Maybe<Scalars['uuid']>;
  iconId?: Maybe<Scalars['uuid']>;
  ordernum: Scalars['Int'];
}>;


export type ApiAddCharacteristicSectionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_characteristic_section_one?: Maybe<(
    { __typename?: 'characteristic_section' }
    & Pick<Characteristic_Section, 'id' | 'names' | 'descriptions' | 'metadata_descriptions' | 'enabled_languages' | 'identification_enabled_languages' | 'icon_id' | 'image_id' | 'url_slugs' | 'ordernum'>
  )> }
);

export type ApiChangeCharacteristicSectionMutationVariables = Exact<{
  id: Scalars['Int'];
  names: Scalars['jsonb'];
  descriptions: Scalars['jsonb'];
  metadataDescriptions: Scalars['jsonb'];
  urlSlugs: Scalars['jsonb'];
  enabledLanguages: Scalars['jsonb'];
  identificationEnabledLanguages: Scalars['jsonb'];
  imageId?: Maybe<Scalars['uuid']>;
  iconId?: Maybe<Scalars['uuid']>;
  ordernum: Scalars['Int'];
}>;


export type ApiChangeCharacteristicSectionMutation = (
  { __typename?: 'mutation_root' }
  & { update_characteristic_section_by_pk?: Maybe<(
    { __typename?: 'characteristic_section' }
    & Pick<Characteristic_Section, 'id' | 'names' | 'descriptions' | 'metadata_descriptions' | 'enabled_languages' | 'identification_enabled_languages' | 'icon_id' | 'image_id' | 'url_slugs' | 'ordernum'>
  )> }
);

export type ApiDeleteCharacteristicSectionMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiDeleteCharacteristicSectionMutation = (
  { __typename?: 'mutation_root' }
  & { delete_characteristic_section_by_pk?: Maybe<(
    { __typename?: 'characteristic_section' }
    & Pick<Characteristic_Section, 'id'>
  )> }
);

export type ApiAllCharacteristicGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllCharacteristicGroupsQuery = (
  { __typename?: 'query_root' }
  & { characteristic_group: Array<(
    { __typename?: 'characteristic_group' }
    & Pick<Characteristic_Group, 'id' | 'name' | 'names' | 'ordernum' | 'icon_id' | 'widget_type'>
    & { characteristic_section: (
      { __typename?: 'characteristic_section' }
      & Pick<Characteristic_Section, 'id' | 'name' | 'names'>
    ) }
  )> }
);

export type ApiAddCharacteristicGroupMutationVariables = Exact<{
  sectionId: Scalars['Int'];
  iconId: Scalars['uuid'];
  names: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  widgetType?: Maybe<Scalars['String']>;
}>;


export type ApiAddCharacteristicGroupMutation = (
  { __typename?: 'mutation_root' }
  & { insert_characteristic_group_one?: Maybe<(
    { __typename?: 'characteristic_group' }
    & Pick<Characteristic_Group, 'id' | 'characteristic_section_id' | 'name' | 'names' | 'ordernum' | 'icon_id' | 'widget_type'>
  )> }
);

export type ApiChangeCharacteristicGroupMutationVariables = Exact<{
  id: Scalars['Int'];
  sectionId: Scalars['Int'];
  iconId: Scalars['uuid'];
  names: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  widgetType?: Maybe<Scalars['String']>;
}>;


export type ApiChangeCharacteristicGroupMutation = (
  { __typename?: 'mutation_root' }
  & { update_characteristic_group_by_pk?: Maybe<(
    { __typename?: 'characteristic_group' }
    & Pick<Characteristic_Group, 'id' | 'characteristic_section_id' | 'name' | 'names' | 'ordernum' | 'icon_id' | 'widget_type'>
  )> }
);

export type ApiDeleteCharacteristicGroupMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiDeleteCharacteristicGroupMutation = (
  { __typename?: 'mutation_root' }
  & { delete_characteristic_group_by_pk?: Maybe<(
    { __typename?: 'characteristic_group' }
    & Pick<Characteristic_Group, 'id'>
  )> }
);

export type ApiAllCharacteristicsWithWhereQueryVariables = Exact<{
  where?: Maybe<Characteristic_Bool_Exp>;
}>;


export type ApiAllCharacteristicsWithWhereQuery = (
  { __typename?: 'query_root' }
  & { characteristic: Array<(
    { __typename?: 'characteristic' }
    & Pick<Characteristic, 'id' | 'name' | 'names' | 'description' | 'descriptions' | 'ordernum' | 'icon_id' | 'help_icon_id' | 'characteristic_group_id' | 'widget_type' | 'widget_data'>
    & { characteristic_group?: Maybe<(
      { __typename?: 'characteristic_group' }
      & Pick<Characteristic_Group, 'id' | 'name' | 'widget_type'>
    )> }
  )> }
);

export type ApiAddCharacteristicMutationVariables = Exact<{
  groupId?: Maybe<Scalars['Int']>;
  iconId?: Maybe<Scalars['uuid']>;
  helpIconId?: Maybe<Scalars['uuid']>;
  names: Scalars['jsonb'];
  descriptions: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  widgetType?: Maybe<Scalars['String']>;
  widgetData?: Maybe<Scalars['jsonb']>;
}>;


export type ApiAddCharacteristicMutation = (
  { __typename?: 'mutation_root' }
  & { insert_characteristic_one?: Maybe<(
    { __typename?: 'characteristic' }
    & Pick<Characteristic, 'id' | 'characteristic_group_id' | 'name' | 'names' | 'description' | 'descriptions' | 'ordernum' | 'icon_id' | 'help_icon_id' | 'widget_type' | 'widget_data'>
    & { characteristic_group?: Maybe<(
      { __typename?: 'characteristic_group' }
      & Pick<Characteristic_Group, 'id' | 'name' | 'widget_type'>
    )> }
  )> }
);

export type ApiChangeCharacteristicMutationVariables = Exact<{
  id: Scalars['Int'];
  groupId?: Maybe<Scalars['Int']>;
  iconId?: Maybe<Scalars['uuid']>;
  helpIconId?: Maybe<Scalars['uuid']>;
  names: Scalars['jsonb'];
  descriptions: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  widgetType?: Maybe<Scalars['String']>;
  widgetData?: Maybe<Scalars['jsonb']>;
}>;


export type ApiChangeCharacteristicMutation = (
  { __typename?: 'mutation_root' }
  & { update_characteristic_by_pk?: Maybe<(
    { __typename?: 'characteristic' }
    & Pick<Characteristic, 'id' | 'characteristic_group_id' | 'name' | 'names' | 'description' | 'descriptions' | 'ordernum' | 'icon_id' | 'help_icon_id' | 'widget_type' | 'widget_data'>
    & { characteristic_group?: Maybe<(
      { __typename?: 'characteristic_group' }
      & Pick<Characteristic_Group, 'id' | 'name' | 'widget_type'>
    )> }
  )> }
);

export type ApiDeleteCharacteristicMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiDeleteCharacteristicMutation = (
  { __typename?: 'mutation_root' }
  & { delete_characteristic_by_pk?: Maybe<(
    { __typename?: 'characteristic' }
    & Pick<Characteristic, 'id'>
  )> }
);

export type ApiAllCharacteristicValuesByCharacteristicQueryVariables = Exact<{
  characteristicId: Scalars['Int'];
}>;


export type ApiAllCharacteristicValuesByCharacteristicQuery = (
  { __typename?: 'query_root' }
  & { characteristic_value: Array<(
    { __typename?: 'characteristic_value' }
    & Pick<Characteristic_Value, 'id' | 'characteristic_id' | 'name' | 'names' | 'description' | 'descriptions' | 'ordernum' | 'icon_id' | 'help_image_small_id' | 'help_image_large_id' | 'widget_data' | 'parent'>
    & { characteristic: (
      { __typename?: 'characteristic' }
      & Pick<Characteristic, 'widget_type'>
    ) }
  )> }
);

export type ApiAllVariationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllVariationsQuery = (
  { __typename?: 'query_root' }
  & { variation: Array<(
    { __typename?: 'variation' }
    & Pick<Variation, 'id' | 'name' | 'names' | 'characteristic_section_id' | 'show'>
    & { characteristic_section: (
      { __typename?: 'characteristic_section' }
      & Pick<Characteristic_Section, 'name' | 'names'>
    ) }
  )> }
);

export type ApiAddVariationMutationVariables = Exact<{
  sectionId: Scalars['bigint'];
  names: Scalars['jsonb'];
  show: Scalars['Boolean'];
}>;


export type ApiAddVariationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_variation_one?: Maybe<(
    { __typename?: 'variation' }
    & Pick<Variation, 'id' | 'name' | 'names' | 'characteristic_section_id' | 'show'>
  )> }
);

export type ApiChangeVariationMutationVariables = Exact<{
  id: Scalars['Int'];
  sectionId: Scalars['bigint'];
  names: Scalars['jsonb'];
  show: Scalars['Boolean'];
}>;


export type ApiChangeVariationMutation = (
  { __typename?: 'mutation_root' }
  & { update_variation_by_pk?: Maybe<(
    { __typename?: 'variation' }
    & Pick<Variation, 'id' | 'name' | 'names' | 'characteristic_section_id' | 'show'>
  )> }
);

export type ApiDeleteVariationMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiDeleteVariationMutation = (
  { __typename?: 'mutation_root' }
  & { delete_variation_by_pk?: Maybe<(
    { __typename?: 'variation' }
    & Pick<Variation, 'id'>
  )> }
);

export type ApiAddCharacteristicValueMutationVariables = Exact<{
  characteristicId: Scalars['Int'];
  iconId: Scalars['uuid'];
  helpImageSmallId?: Maybe<Scalars['uuid']>;
  helpImageLargeId?: Maybe<Scalars['uuid']>;
  names: Scalars['jsonb'];
  descriptions: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  widgetData?: Maybe<Scalars['jsonb']>;
  parent?: Maybe<Scalars['Int']>;
}>;


export type ApiAddCharacteristicValueMutation = (
  { __typename?: 'mutation_root' }
  & { insert_characteristic_value_one?: Maybe<(
    { __typename?: 'characteristic_value' }
    & Pick<Characteristic_Value, 'id' | 'characteristic_id' | 'name' | 'names' | 'description' | 'descriptions' | 'ordernum' | 'icon_id' | 'help_image_small_id' | 'help_image_large_id' | 'widget_data' | 'parent'>
  )> }
);

export type ApiChangeCharacteristicValueMutationVariables = Exact<{
  id: Scalars['Int'];
  characteristicId: Scalars['Int'];
  iconId: Scalars['uuid'];
  helpImageSmallId?: Maybe<Scalars['uuid']>;
  helpImageLargeId?: Maybe<Scalars['uuid']>;
  names: Scalars['jsonb'];
  descriptions: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  widgetData?: Maybe<Scalars['jsonb']>;
  parent?: Maybe<Scalars['Int']>;
}>;


export type ApiChangeCharacteristicValueMutation = (
  { __typename?: 'mutation_root' }
  & { update_characteristic_value_by_pk?: Maybe<(
    { __typename?: 'characteristic_value' }
    & Pick<Characteristic_Value, 'id' | 'characteristic_id' | 'name' | 'names' | 'description' | 'descriptions' | 'ordernum' | 'icon_id' | 'help_image_small_id' | 'help_image_large_id' | 'widget_data' | 'parent'>
  )> }
);

export type ApiDeleteCharacteristicValueMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiDeleteCharacteristicValueMutation = (
  { __typename?: 'mutation_root' }
  & { delete_characteristic_value_by_pk?: Maybe<(
    { __typename?: 'characteristic_value' }
    & Pick<Characteristic_Value, 'id'>
  )> }
);

export type ApiChangeTaxonomyCharValuesMutationVariables = Exact<{
  taxonomyId: Scalars['bigint'];
  values: Array<Taxonomy_Characteristic_Value_Insert_Input> | Taxonomy_Characteristic_Value_Insert_Input;
}>;


export type ApiChangeTaxonomyCharValuesMutation = (
  { __typename?: 'mutation_root' }
  & { delete_taxonomy_characteristic_value?: Maybe<(
    { __typename?: 'taxonomy_characteristic_value_mutation_response' }
    & Pick<Taxonomy_Characteristic_Value_Mutation_Response, 'affected_rows'>
  )>, insert_taxonomy_characteristic_value?: Maybe<(
    { __typename?: 'taxonomy_characteristic_value_mutation_response' }
    & Pick<Taxonomy_Characteristic_Value_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiAddArticleTextMutationVariables = Exact<{
  taxonomyId: Scalars['bigint'];
  lang: Scalars['String'];
  article: Scalars['String'];
  published: Scalars['Boolean'];
  section: Scalars['String'];
  urlSlug: Scalars['String'];
  informationStrike?: Maybe<Scalars['String']>;
}>;


export type ApiAddArticleTextMutation = (
  { __typename?: 'mutation_root' }
  & { insert_taxonomy_article_one?: Maybe<(
    { __typename?: 'taxonomy_article' }
    & Pick<Taxonomy_Article, 'taxonomy_id' | 'language' | 'article' | 'published' | 'section' | 'url_slug' | 'legacy_copyright'>
  )> }
);

export type ApiChangeArticleTextMutationVariables = Exact<{
  taxonomyId: Scalars['bigint'];
  lang: Scalars['String'];
  article: Scalars['String'];
  published: Scalars['Boolean'];
  informationStrike?: Maybe<Scalars['String']>;
  urlSlug: Scalars['String'];
}>;


export type ApiChangeArticleTextMutation = (
  { __typename?: 'mutation_root' }
  & { update_taxonomy_article?: Maybe<(
    { __typename?: 'taxonomy_article_mutation_response' }
    & Pick<Taxonomy_Article_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiChangeImageDetailsMutationVariables = Exact<{
  id: Scalars['uuid'];
  copyright?: Maybe<Scalars['String']>;
  output_format?: Maybe<Scalars['String']>;
  captions: Scalars['jsonb'];
  alts: Scalars['jsonb'];
}>;


export type ApiChangeImageDetailsMutation = (
  { __typename?: 'mutation_root' }
  & { update_image_by_pk?: Maybe<(
    { __typename?: 'image' }
    & Pick<Image, 'id'>
  )> }
);

export type ApiChangeTaxonomyImageMutationVariables = Exact<{
  taxonomyId: Scalars['bigint'];
  imageId: Scalars['uuid'];
  copyright?: Maybe<Scalars['String']>;
  captions: Scalars['jsonb'];
  alts: Scalars['jsonb'];
  identification: Scalars['Boolean'];
  variations: Scalars['_int4'];
}>;


export type ApiChangeTaxonomyImageMutation = (
  { __typename?: 'mutation_root' }
  & { update_image_by_pk?: Maybe<(
    { __typename?: 'image' }
    & Pick<Image, 'id'>
  )>, update_taxonomy_image?: Maybe<(
    { __typename?: 'taxonomy_image_mutation_response' }
    & Pick<Taxonomy_Image_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiAllPagesQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllPagesQuery = (
  { __typename?: 'query_root' }
  & { page: Array<(
    { __typename?: 'page' }
    & Pick<Page, 'name' | 'admin_description' | 'metadata_descriptions' | 'id' | 'titles' | 'created_by' | 'created_at' | 'published' | 'url_slugs'>
  )> }
);

export type ApiAddPageMutationVariables = Exact<{
  name: Scalars['String'];
  admin_description: Scalars['String'];
}>;


export type ApiAddPageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_page_one?: Maybe<(
    { __typename?: 'page' }
    & Pick<Page, 'name' | 'admin_description' | 'metadata_descriptions' | 'id' | 'titles' | 'created_by' | 'created_at' | 'published' | 'url_slugs'>
  )> }
);

export type ApiDeletePageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiDeletePageMutation = (
  { __typename?: 'mutation_root' }
  & { update_page_by_pk?: Maybe<(
    { __typename?: 'page' }
    & Pick<Page, 'id'>
  )> }
);

export type ApiUpdatePageMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  admin_description: Scalars['String'];
  metadata_descriptions: Scalars['jsonb'];
  published: Scalars['Boolean'];
  titles: Scalars['jsonb'];
  url_slugs: Scalars['jsonb'];
}>;


export type ApiUpdatePageMutation = (
  { __typename?: 'mutation_root' }
  & { update_page_by_pk?: Maybe<(
    { __typename?: 'page' }
    & Pick<Page, 'name' | 'admin_description' | 'metadata_descriptions' | 'id' | 'titles' | 'created_by' | 'created_at' | 'published' | 'url_slugs'>
  )> }
);

export type ApiSinglePageQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiSinglePageQuery = (
  { __typename?: 'query_root' }
  & { page_by_pk?: Maybe<(
    { __typename?: 'page' }
    & Pick<Page, 'id' | 'admin_description' | 'titles' | 'url_slugs' | 'published'>
    & { page_contents: Array<(
      { __typename?: 'page_content' }
      & Pick<Page_Content, 'contents' | 'id' | 'image_id' | 'ordernum' | 'page_content_type_id' | 'language'>
      & { image?: Maybe<(
        { __typename?: 'image' }
        & Pick<Image, 'alt' | 'caption' | 'id' | 'filename'>
        & { urls?: Maybe<Array<(
          { __typename?: 'imgproxy_urls' }
          & Pick<Imgproxy_Urls, 'url'>
        )>> }
      )> }
    )> }
  )> }
);

export type ApiAllPageContentTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllPageContentTypesQuery = (
  { __typename?: 'query_root' }
  & { page_content_type: Array<(
    { __typename?: 'page_content_type' }
    & Pick<Page_Content_Type, 'id'>
  )> }
);

export type ApiAddPageContentMutationVariables = Exact<{
  pageId: Scalars['Int'];
  type: Scalars['String'];
  contents: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  language?: Maybe<Scalars['String']>;
}>;


export type ApiAddPageContentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_page_content_one?: Maybe<(
    { __typename?: 'page_content' }
    & Pick<Page_Content, 'id'>
  )> }
);

export type ApiDeletePageContentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ApiDeletePageContentMutation = (
  { __typename?: 'mutation_root' }
  & { delete_page_content_by_pk?: Maybe<(
    { __typename?: 'page_content' }
    & Pick<Page_Content, 'id'>
  )> }
);

export type ApiUpdatePageContentMutationVariables = Exact<{
  id: Scalars['Int'];
  contents: Scalars['jsonb'];
  ordernum: Scalars['Int'];
  image_id?: Maybe<Scalars['uuid']>;
}>;


export type ApiUpdatePageContentMutation = (
  { __typename?: 'mutation_root' }
  & { update_page_content_by_pk?: Maybe<(
    { __typename?: 'page_content' }
    & Pick<Page_Content, 'id'>
  )> }
);

export type ApiSpeciesListQueryVariables = Exact<{
  lang: Scalars['String'];
}>;


export type ApiSpeciesListQuery = (
  { __typename?: 'query_root' }
  & { taxonomy: Array<(
    { __typename?: 'taxonomy' }
    & Pick<Taxonomy, 'id' | 'scientific_name'>
    & { taxonomy_common_names: Array<(
      { __typename?: 'taxonomy_common_name' }
      & Pick<Taxonomy_Common_Name, 'name'>
    )> }
  )> }
);

export type ApiAddTaxonomyImageMutationVariables = Exact<{
  taxonomy_id: Scalars['bigint'];
  ordernum?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  filename?: Maybe<Scalars['String']>;
  file_extension?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  copyright?: Maybe<Scalars['String']>;
  output_format?: Maybe<Scalars['String']>;
}>;


export type ApiAddTaxonomyImageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_taxonomy_image_one?: Maybe<(
    { __typename?: 'taxonomy_image' }
    & Pick<Taxonomy_Image, 'image_id' | 'ordernum' | 'taxonomy_id'>
    & { image: (
      { __typename?: 'image' }
      & Pick<Image, 'id'>
    ) }
  )> }
);

export type ApiDeleteTaxonomyImageMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ApiDeleteTaxonomyImageMutation = (
  { __typename?: 'mutation_root' }
  & { delete_taxonomy_image?: Maybe<(
    { __typename?: 'taxonomy_image_mutation_response' }
    & Pick<Taxonomy_Image_Mutation_Response, 'affected_rows'>
  )>, delete_image_by_pk?: Maybe<(
    { __typename?: 'image' }
    & Pick<Image, 'id'>
  )> }
);

export type ApiImagesQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  where?: Maybe<Image_Bool_Exp>;
}>;


export type ApiImagesQuery = (
  { __typename?: 'query_root' }
  & { image: Array<(
    { __typename?: 'image' }
    & Pick<Image, 'id' | 'copyright' | 'filename' | 'file_extension' | 'created_at' | 'alt' | 'alts' | 'caption' | 'captions' | 'width' | 'height' | 'uploaded_by' | 'legacy_id' | 'output_format'>
    & { image_tags: Array<(
      { __typename?: 'image_tag' }
      & Pick<Image_Tag, 'tag'>
    )>, urls?: Maybe<Array<(
      { __typename?: 'imgproxy_urls' }
      & Pick<Imgproxy_Urls, 'preset' | 'url'>
    )>> }
  )> }
);

export type ApiSingleLargeImageQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ApiSingleLargeImageQuery = (
  { __typename?: 'query_root' }
  & { image_by_pk?: Maybe<(
    { __typename?: 'image' }
    & Pick<Image, 'id' | 'copyright' | 'filename' | 'file_extension' | 'created_at' | 'alt' | 'alts' | 'caption' | 'captions' | 'width' | 'height' | 'uploaded_by' | 'legacy_id' | 'output_format'>
    & { image_tags: Array<(
      { __typename?: 'image_tag' }
      & Pick<Image_Tag, 'tag'>
    )>, urls?: Maybe<Array<(
      { __typename?: 'imgproxy_urls' }
      & Pick<Imgproxy_Urls, 'preset' | 'url'>
    )>>, taxonomy_images: Array<(
      { __typename?: 'taxonomy_image' }
      & { taxonomy: (
        { __typename?: 'taxonomy' }
        & Pick<Taxonomy, 'id' | 'scientific_name' | 'common_name'>
      ) }
    )> }
  )> }
);

export type ApiSingleImageWithPresetsQueryVariables = Exact<{
  id: Scalars['uuid'];
  presets?: Maybe<Scalars['_text']>;
}>;


export type ApiSingleImageWithPresetsQuery = (
  { __typename?: 'query_root' }
  & { image_by_pk?: Maybe<(
    { __typename?: 'image' }
    & Pick<Image, 'id' | 'filename'>
    & { urls?: Maybe<Array<(
      { __typename?: 'imgproxy_urls' }
      & Pick<Imgproxy_Urls, 'preset' | 'url'>
    )>> }
  )> }
);

export type ApiDeleteImageMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type ApiDeleteImageMutation = (
  { __typename?: 'mutation_root' }
  & { delete_image_by_pk?: Maybe<(
    { __typename?: 'image' }
    & Pick<Image, 'id'>
  )> }
);

export type ApiAddImageMutationVariables = Exact<{
  id: Scalars['uuid'];
  filename?: Maybe<Scalars['String']>;
  file_extension?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  output_format?: Maybe<Scalars['String']>;
}>;


export type ApiAddImageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_image?: Maybe<(
    { __typename?: 'image_mutation_response' }
    & Pick<Image_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiFnMoveTaxonomyMutationVariables = Exact<{
  destinationId?: Maybe<Scalars['bigint']>;
  sourceId?: Maybe<Scalars['bigint']>;
}>;


export type ApiFnMoveTaxonomyMutation = (
  { __typename?: 'mutation_root' }
  & { fn_move_taxonomy: Array<(
    { __typename?: 'taxonomy' }
    & Pick<Taxonomy, 'id'>
  )> }
);

export type ApiAllImageTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllImageTagsQuery = (
  { __typename?: 'query_root' }
  & { image_tag: Array<(
    { __typename?: 'image_tag' }
    & Pick<Image_Tag, 'tag' | 'image_id'>
  )> }
);

export type ApiAddImageTagMutationVariables = Exact<{
  image_id: Scalars['uuid'];
  tag: Scalars['String'];
}>;


export type ApiAddImageTagMutation = (
  { __typename?: 'mutation_root' }
  & { insert_image_tag_one?: Maybe<(
    { __typename?: 'image_tag' }
    & Pick<Image_Tag, 'image_id' | 'tag'>
  )> }
);

export type ApiReplaceImageTagsMutationVariables = Exact<{
  imageId: Scalars['uuid'];
  tags: Array<Image_Tag_Insert_Input> | Image_Tag_Insert_Input;
}>;


export type ApiReplaceImageTagsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_image_tag?: Maybe<(
    { __typename?: 'image_tag_mutation_response' }
    & Pick<Image_Tag_Mutation_Response, 'affected_rows'>
  )>, insert_image_tag?: Maybe<(
    { __typename?: 'image_tag_mutation_response' }
    & Pick<Image_Tag_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiDeleteImageTagMutationVariables = Exact<{
  image_id: Scalars['uuid'];
  tag: Scalars['String'];
}>;


export type ApiDeleteImageTagMutation = (
  { __typename?: 'mutation_root' }
  & { delete_image_tag_by_pk?: Maybe<(
    { __typename?: 'image_tag' }
    & Pick<Image_Tag, 'image_id' | 'tag'>
  )> }
);

export type ApiAllImageExtensionsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllImageExtensionsQuery = (
  { __typename?: 'query_root' }
  & { image_file_extension: Array<(
    { __typename?: 'image_file_extension' }
    & Pick<Image_File_Extension, 'extension'>
  )> }
);

export type ApiAddTaxonomyWidgetMutationVariables = Exact<{
  taxonomy_id: Scalars['bigint'];
  widget_type_id: Scalars['String'];
  widget_data: Scalars['jsonb'];
}>;


export type ApiAddTaxonomyWidgetMutation = (
  { __typename?: 'mutation_root' }
  & { insert_taxonomy_widget_one?: Maybe<(
    { __typename?: 'taxonomy_widget' }
    & Pick<Taxonomy_Widget, 'taxonomy_id' | 'widget_type_id' | 'widget_data'>
  )> }
);

export type ApiDeleteTaxonomyWidgetMutationVariables = Exact<{
  taxonomy_id: Scalars['bigint'];
  widget_type_id: Scalars['String'];
}>;


export type ApiDeleteTaxonomyWidgetMutation = (
  { __typename?: 'mutation_root' }
  & { delete_taxonomy_widget?: Maybe<(
    { __typename?: 'taxonomy_widget_mutation_response' }
    & Pick<Taxonomy_Widget_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiChangeTaxonomyWidgetMutationVariables = Exact<{
  taxonomy_id: Scalars['bigint'];
  widget_type_id: Scalars['String'];
  widget_data: Scalars['jsonb'];
}>;


export type ApiChangeTaxonomyWidgetMutation = (
  { __typename?: 'mutation_root' }
  & { update_taxonomy_widget?: Maybe<(
    { __typename?: 'taxonomy_widget_mutation_response' }
    & Pick<Taxonomy_Widget_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiAllFeedbackQueryVariables = Exact<{
  where: Feedback_Bool_Exp;
  where_aggregate: Feedback_Bool_Exp;
  limit: Scalars['Int'];
}>;


export type ApiAllFeedbackQuery = (
  { __typename?: 'query_root' }
  & { feedback: Array<(
    { __typename?: 'feedback' }
    & Pick<Feedback, 'id' | 'status' | 'email' | 'message' | 'created_at'>
    & { feedback_taxonomy_references: Array<(
      { __typename?: 'feedback_taxonomy_reference' }
      & { taxonomy: (
        { __typename?: 'taxonomy' }
        & Pick<Taxonomy, 'id' | 'common_name' | 'scientific_name'>
      ) }
    )> }
  )>, feedback_aggregate: (
    { __typename?: 'feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ApiSingleFeedbackQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type ApiSingleFeedbackQuery = (
  { __typename?: 'query_root' }
  & { feedback_by_pk?: Maybe<(
    { __typename?: 'feedback' }
    & Pick<Feedback, 'id' | 'status' | 'email' | 'message' | 'created_at' | 'metadata'>
    & { feedback_taxonomy_references: Array<(
      { __typename?: 'feedback_taxonomy_reference' }
      & { taxonomy: (
        { __typename?: 'taxonomy' }
        & Pick<Taxonomy, 'id' | 'common_name' | 'scientific_name'>
      ) }
    )>, feedback_comments: Array<(
      { __typename?: 'feedback_comment' }
      & Pick<Feedback_Comment, 'id' | 'message' | 'created_at'>
      & { created_by: (
        { __typename?: 'user_account' }
        & Pick<User_Account, 'id' | 'email'>
      ) }
    )> }
  )> }
);

export type ApiChangeFeedbackStatusMutationVariables = Exact<{
  feedback_id: Scalars['bigint'];
  status: Scalars['String'];
}>;


export type ApiChangeFeedbackStatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_feedback_by_pk?: Maybe<(
    { __typename?: 'feedback' }
    & Pick<Feedback, 'id' | 'status'>
  )> }
);

export type ApiAddFeedbackCommentMutationVariables = Exact<{
  feedback_id: Scalars['bigint'];
  message: Scalars['String'];
}>;


export type ApiAddFeedbackCommentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_feedback_comment_one?: Maybe<(
    { __typename?: 'feedback_comment' }
    & Pick<Feedback_Comment, 'id' | 'feedback_id' | 'message' | 'created_at'>
    & { created_by: (
      { __typename?: 'user_account' }
      & Pick<User_Account, 'id' | 'email'>
    ) }
  )> }
);

export type ApiDeleteFeedbackCommentMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type ApiDeleteFeedbackCommentMutation = (
  { __typename?: 'mutation_root' }
  & { delete_feedback_comment?: Maybe<(
    { __typename?: 'feedback_comment_mutation_response' }
    & Pick<Feedback_Comment_Mutation_Response, 'affected_rows'>
  )> }
);

export type ApiHomeStatisticsQueryVariables = Exact<{
  time: Scalars['timestamptz'];
}>;


export type ApiHomeStatisticsQuery = (
  { __typename?: 'query_root' }
  & { feedback_open: (
    { __typename?: 'feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ), feedback_closed: (
    { __typename?: 'feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ), feedback_since: (
    { __typename?: 'feedback_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'feedback_aggregate_fields' }
      & Pick<Feedback_Aggregate_Fields, 'count'>
    )> }
  ), taxonomy_aggregate: (
    { __typename?: 'taxonomy_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'taxonomy_aggregate_fields' }
      & Pick<Taxonomy_Aggregate_Fields, 'count'>
    )> }
  ), taxonomy_article_aggregate: (
    { __typename?: 'taxonomy_article_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'taxonomy_article_aggregate_fields' }
      & Pick<Taxonomy_Article_Aggregate_Fields, 'count'>
    )> }
  ), image_aggregate: (
    { __typename?: 'image_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'image_aggregate_fields' }
      & Pick<Image_Aggregate_Fields, 'count'>
    )> }
  ), taxonomy_image_aggregate: (
    { __typename?: 'taxonomy_image_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'taxonomy_image_aggregate_fields' }
      & Pick<Taxonomy_Image_Aggregate_Fields, 'count'>
    )> }
  ), taxonomy_common_name_aggregate: (
    { __typename?: 'taxonomy_common_name_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'taxonomy_common_name_aggregate_fields' }
      & Pick<Taxonomy_Common_Name_Aggregate_Fields, 'count'>
    )> }
  ), taxonomy_scientific_name_aggregate: (
    { __typename?: 'taxonomy_scientific_name_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'taxonomy_scientific_name_aggregate_fields' }
      & Pick<Taxonomy_Scientific_Name_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ApiAllImgProxyPresetsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiAllImgProxyPresetsQuery = (
  { __typename?: 'query_root' }
  & { imgproxy_preset: Array<(
    { __typename?: 'imgproxy_preset' }
    & Pick<Imgproxy_Preset, 'name' | 'processing_opts'>
  )> }
);


export const ApiAllLanguageDocument = gql`
    query apiAllLanguage {
  language {
    key
    name
  }
}
    `;

/**
 * __useApiAllLanguageQuery__
 *
 * To run a query within a React component, call `useApiAllLanguageQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllLanguageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllLanguageQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllLanguageQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllLanguageQuery, ApiAllLanguageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllLanguageQuery, ApiAllLanguageQueryVariables>(ApiAllLanguageDocument, options);
      }
export function useApiAllLanguageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllLanguageQuery, ApiAllLanguageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllLanguageQuery, ApiAllLanguageQueryVariables>(ApiAllLanguageDocument, options);
        }
export type ApiAllLanguageQueryHookResult = ReturnType<typeof useApiAllLanguageQuery>;
export type ApiAllLanguageLazyQueryHookResult = ReturnType<typeof useApiAllLanguageLazyQuery>;
export type ApiAllLanguageQueryResult = Apollo.QueryResult<ApiAllLanguageQuery, ApiAllLanguageQueryVariables>;
export const ApiAllRankDocument = gql`
    query apiAllRank {
  rank {
    id
    name
  }
}
    `;

/**
 * __useApiAllRankQuery__
 *
 * To run a query within a React component, call `useApiAllRankQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllRankQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllRankQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllRankQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllRankQuery, ApiAllRankQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllRankQuery, ApiAllRankQueryVariables>(ApiAllRankDocument, options);
      }
export function useApiAllRankLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllRankQuery, ApiAllRankQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllRankQuery, ApiAllRankQueryVariables>(ApiAllRankDocument, options);
        }
export type ApiAllRankQueryHookResult = ReturnType<typeof useApiAllRankQuery>;
export type ApiAllRankLazyQueryHookResult = ReturnType<typeof useApiAllRankLazyQuery>;
export type ApiAllRankQueryResult = Apollo.QueryResult<ApiAllRankQuery, ApiAllRankQueryVariables>;
export const ApiAllTaxonomyDocument = gql`
    query apiAllTaxonomy {
  taxonomy {
    id
    scientific_name
    common_name
    rank_id
    path
  }
}
    `;

/**
 * __useApiAllTaxonomyQuery__
 *
 * To run a query within a React component, call `useApiAllTaxonomyQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllTaxonomyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllTaxonomyQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllTaxonomyQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllTaxonomyQuery, ApiAllTaxonomyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllTaxonomyQuery, ApiAllTaxonomyQueryVariables>(ApiAllTaxonomyDocument, options);
      }
export function useApiAllTaxonomyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllTaxonomyQuery, ApiAllTaxonomyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllTaxonomyQuery, ApiAllTaxonomyQueryVariables>(ApiAllTaxonomyDocument, options);
        }
export type ApiAllTaxonomyQueryHookResult = ReturnType<typeof useApiAllTaxonomyQuery>;
export type ApiAllTaxonomyLazyQueryHookResult = ReturnType<typeof useApiAllTaxonomyLazyQuery>;
export type ApiAllTaxonomyQueryResult = Apollo.QueryResult<ApiAllTaxonomyQuery, ApiAllTaxonomyQueryVariables>;
export const ApiSingleTaxonomyDocument = gql`
    query apiSingleTaxonomy($id: bigint!) {
  taxonomy_by_pk(id: $id) {
    scientific_name
    common_name
    id
    rank_id
    rank {
      id
      name
    }
    path
    taxonomy_scientific_names {
      name
      current
    }
    taxonomy_characteristic_sections {
      characteristic_section {
        name
        names
      }
      characteristic_section_id
      show_in_section_listing
    }
    published
    identification_published
    taxonomy_common_names {
      name
      language
      preferred
    }
    taxonomy_articles {
      article
      information_strike
      language
      url_slug
      section
      published
      legacy_copyright
    }
    taxonomy_images {
      image_id
      taxonomy_id
      ordernum
      identification
      variations
      image {
        id
        urls(args: {presets: "{large}"}) {
          preset
          url
        }
        copyright
        filename
        created_at
        alt
        caption
        captions
        alts
        width
        height
        file_extension
        output_format
      }
    }
    taxonomy_characteristic_values {
      frequency
      variations
      characteristic_value {
        id
        name
        characteristic {
          id
          name
          characteristic_group {
            id
            name
          }
        }
      }
    }
    taxonomy_audios {
      audio_id
    }
    taxonomy_widgets {
      widget_type_id
      widget_data
    }
    ancestors(where: {published: {_eq: true}}, order_by: {rank: {ordernum: asc}}) {
      id
      scientific_name
      common_name
      path
      rank {
        id
        name
      }
    }
    feedback_taxonomy_references(where: {feedback: {status: {_eq: "open"}}}) {
      feedback_id
    }
  }
}
    `;

/**
 * __useApiSingleTaxonomyQuery__
 *
 * To run a query within a React component, call `useApiSingleTaxonomyQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiSingleTaxonomyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiSingleTaxonomyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiSingleTaxonomyQuery(baseOptions: Apollo.QueryHookOptions<ApiSingleTaxonomyQuery, ApiSingleTaxonomyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSingleTaxonomyQuery, ApiSingleTaxonomyQueryVariables>(ApiSingleTaxonomyDocument, options);
      }
export function useApiSingleTaxonomyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSingleTaxonomyQuery, ApiSingleTaxonomyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSingleTaxonomyQuery, ApiSingleTaxonomyQueryVariables>(ApiSingleTaxonomyDocument, options);
        }
export type ApiSingleTaxonomyQueryHookResult = ReturnType<typeof useApiSingleTaxonomyQuery>;
export type ApiSingleTaxonomyLazyQueryHookResult = ReturnType<typeof useApiSingleTaxonomyLazyQuery>;
export type ApiSingleTaxonomyQueryResult = Apollo.QueryResult<ApiSingleTaxonomyQuery, ApiSingleTaxonomyQueryVariables>;
export const ApiAddTaxonomyDocument = gql`
    mutation apiAddTaxonomy($name: String!, $path: ltree!, $rankId: String!, $sectionId: Int, $showInListing: Boolean!) {
  insert_taxonomy_one(
    object: {taxonomy_scientific_names: {data: {current: true, name: $name}}, taxonomy_characteristic_sections: {data: {characteristic_section_id: $sectionId, show_in_section_listing: $showInListing}}, path: $path, rank_id: $rankId, identification_published: false, published: false}
  ) {
    id
    scientific_name
    rank_id
    path
    published
    identification_published
    taxonomy_characteristic_sections {
      characteristic_section_id
    }
  }
}
    `;
export type ApiAddTaxonomyMutationFn = Apollo.MutationFunction<ApiAddTaxonomyMutation, ApiAddTaxonomyMutationVariables>;

/**
 * __useApiAddTaxonomyMutation__
 *
 * To run a mutation, you first call `useApiAddTaxonomyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddTaxonomyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddTaxonomyMutation, { data, loading, error }] = useApiAddTaxonomyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      path: // value for 'path'
 *      rankId: // value for 'rankId'
 *      sectionId: // value for 'sectionId'
 *      showInListing: // value for 'showInListing'
 *   },
 * });
 */
export function useApiAddTaxonomyMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddTaxonomyMutation, ApiAddTaxonomyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddTaxonomyMutation, ApiAddTaxonomyMutationVariables>(ApiAddTaxonomyDocument, options);
      }
export type ApiAddTaxonomyMutationHookResult = ReturnType<typeof useApiAddTaxonomyMutation>;
export type ApiAddTaxonomyMutationResult = Apollo.MutationResult<ApiAddTaxonomyMutation>;
export type ApiAddTaxonomyMutationOptions = Apollo.BaseMutationOptions<ApiAddTaxonomyMutation, ApiAddTaxonomyMutationVariables>;
export const ApiChangeTaxonomyPathDocument = gql`
    mutation apiChangeTaxonomyPath($id: bigint!, $path: ltree!) {
  update_taxonomy_by_pk(pk_columns: {id: $id}, _set: {path: $path}) {
    id
    path
  }
}
    `;
export type ApiChangeTaxonomyPathMutationFn = Apollo.MutationFunction<ApiChangeTaxonomyPathMutation, ApiChangeTaxonomyPathMutationVariables>;

/**
 * __useApiChangeTaxonomyPathMutation__
 *
 * To run a mutation, you first call `useApiChangeTaxonomyPathMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeTaxonomyPathMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeTaxonomyPathMutation, { data, loading, error }] = useApiChangeTaxonomyPathMutation({
 *   variables: {
 *      id: // value for 'id'
 *      path: // value for 'path'
 *   },
 * });
 */
export function useApiChangeTaxonomyPathMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeTaxonomyPathMutation, ApiChangeTaxonomyPathMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeTaxonomyPathMutation, ApiChangeTaxonomyPathMutationVariables>(ApiChangeTaxonomyPathDocument, options);
      }
export type ApiChangeTaxonomyPathMutationHookResult = ReturnType<typeof useApiChangeTaxonomyPathMutation>;
export type ApiChangeTaxonomyPathMutationResult = Apollo.MutationResult<ApiChangeTaxonomyPathMutation>;
export type ApiChangeTaxonomyPathMutationOptions = Apollo.BaseMutationOptions<ApiChangeTaxonomyPathMutation, ApiChangeTaxonomyPathMutationVariables>;
export const ApiChangeTaxonomyDocument = gql`
    mutation apiChangeTaxonomy($id: bigint!, $published: Boolean!, $identificationPublished: Boolean!, $characteristicSections: [taxonomy_characteristic_section_insert_input!]!, $audioIds: [taxonomy_audio_insert_input!]!, $rankId: String!) {
  update_taxonomy_by_pk(
    pk_columns: {id: $id}
    _set: {published: $published, identification_published: $identificationPublished, rank_id: $rankId}
  ) {
    scientific_name
    id
    rank_id
    path
    published
    identification_published
  }
  delete_taxonomy_characteristic_section(where: {taxonomy_id: {_eq: $id}}) {
    affected_rows
  }
  insert_taxonomy_characteristic_section(objects: $characteristicSections) {
    affected_rows
  }
  delete_taxonomy_audio(where: {taxonomy_id: {_eq: $id}}) {
    affected_rows
  }
  insert_taxonomy_audio(objects: $audioIds) {
    affected_rows
  }
}
    `;
export type ApiChangeTaxonomyMutationFn = Apollo.MutationFunction<ApiChangeTaxonomyMutation, ApiChangeTaxonomyMutationVariables>;

/**
 * __useApiChangeTaxonomyMutation__
 *
 * To run a mutation, you first call `useApiChangeTaxonomyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeTaxonomyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeTaxonomyMutation, { data, loading, error }] = useApiChangeTaxonomyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      published: // value for 'published'
 *      identificationPublished: // value for 'identificationPublished'
 *      characteristicSections: // value for 'characteristicSections'
 *      audioIds: // value for 'audioIds'
 *      rankId: // value for 'rankId'
 *   },
 * });
 */
export function useApiChangeTaxonomyMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeTaxonomyMutation, ApiChangeTaxonomyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeTaxonomyMutation, ApiChangeTaxonomyMutationVariables>(ApiChangeTaxonomyDocument, options);
      }
export type ApiChangeTaxonomyMutationHookResult = ReturnType<typeof useApiChangeTaxonomyMutation>;
export type ApiChangeTaxonomyMutationResult = Apollo.MutationResult<ApiChangeTaxonomyMutation>;
export type ApiChangeTaxonomyMutationOptions = Apollo.BaseMutationOptions<ApiChangeTaxonomyMutation, ApiChangeTaxonomyMutationVariables>;
export const ApiChangeTaxonomyScientificNamesDocument = gql`
    mutation apiChangeTaxonomyScientificNames($id: bigint!, $values: [taxonomy_scientific_name_insert_input!]!) {
  delete_taxonomy_scientific_name(where: {taxonomy_id: {_eq: $id}}) {
    affected_rows
  }
  insert_taxonomy_scientific_name(objects: $values) {
    affected_rows
  }
}
    `;
export type ApiChangeTaxonomyScientificNamesMutationFn = Apollo.MutationFunction<ApiChangeTaxonomyScientificNamesMutation, ApiChangeTaxonomyScientificNamesMutationVariables>;

/**
 * __useApiChangeTaxonomyScientificNamesMutation__
 *
 * To run a mutation, you first call `useApiChangeTaxonomyScientificNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeTaxonomyScientificNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeTaxonomyScientificNamesMutation, { data, loading, error }] = useApiChangeTaxonomyScientificNamesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useApiChangeTaxonomyScientificNamesMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeTaxonomyScientificNamesMutation, ApiChangeTaxonomyScientificNamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeTaxonomyScientificNamesMutation, ApiChangeTaxonomyScientificNamesMutationVariables>(ApiChangeTaxonomyScientificNamesDocument, options);
      }
export type ApiChangeTaxonomyScientificNamesMutationHookResult = ReturnType<typeof useApiChangeTaxonomyScientificNamesMutation>;
export type ApiChangeTaxonomyScientificNamesMutationResult = Apollo.MutationResult<ApiChangeTaxonomyScientificNamesMutation>;
export type ApiChangeTaxonomyScientificNamesMutationOptions = Apollo.BaseMutationOptions<ApiChangeTaxonomyScientificNamesMutation, ApiChangeTaxonomyScientificNamesMutationVariables>;
export const ApiChangeTaxonomyCommonNamesDocument = gql`
    mutation apiChangeTaxonomyCommonNames($id: bigint, $language: String, $values: [taxonomy_common_name_insert_input!]!) {
  delete_taxonomy_common_name(
    where: {taxonomy_id: {_eq: $id}, language: {_eq: $language}}
  ) {
    affected_rows
  }
  insert_taxonomy_common_name(objects: $values) {
    affected_rows
  }
}
    `;
export type ApiChangeTaxonomyCommonNamesMutationFn = Apollo.MutationFunction<ApiChangeTaxonomyCommonNamesMutation, ApiChangeTaxonomyCommonNamesMutationVariables>;

/**
 * __useApiChangeTaxonomyCommonNamesMutation__
 *
 * To run a mutation, you first call `useApiChangeTaxonomyCommonNamesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeTaxonomyCommonNamesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeTaxonomyCommonNamesMutation, { data, loading, error }] = useApiChangeTaxonomyCommonNamesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useApiChangeTaxonomyCommonNamesMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeTaxonomyCommonNamesMutation, ApiChangeTaxonomyCommonNamesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeTaxonomyCommonNamesMutation, ApiChangeTaxonomyCommonNamesMutationVariables>(ApiChangeTaxonomyCommonNamesDocument, options);
      }
export type ApiChangeTaxonomyCommonNamesMutationHookResult = ReturnType<typeof useApiChangeTaxonomyCommonNamesMutation>;
export type ApiChangeTaxonomyCommonNamesMutationResult = Apollo.MutationResult<ApiChangeTaxonomyCommonNamesMutation>;
export type ApiChangeTaxonomyCommonNamesMutationOptions = Apollo.BaseMutationOptions<ApiChangeTaxonomyCommonNamesMutation, ApiChangeTaxonomyCommonNamesMutationVariables>;
export const ApiAllAudioDocument = gql`
    query apiAllAudio {
  audio {
    id
  }
}
    `;

/**
 * __useApiAllAudioQuery__
 *
 * To run a query within a React component, call `useApiAllAudioQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllAudioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllAudioQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllAudioQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllAudioQuery, ApiAllAudioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllAudioQuery, ApiAllAudioQueryVariables>(ApiAllAudioDocument, options);
      }
export function useApiAllAudioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllAudioQuery, ApiAllAudioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllAudioQuery, ApiAllAudioQueryVariables>(ApiAllAudioDocument, options);
        }
export type ApiAllAudioQueryHookResult = ReturnType<typeof useApiAllAudioQuery>;
export type ApiAllAudioLazyQueryHookResult = ReturnType<typeof useApiAllAudioLazyQuery>;
export type ApiAllAudioQueryResult = Apollo.QueryResult<ApiAllAudioQuery, ApiAllAudioQueryVariables>;
export const ApiFindImageDocument = gql`
    query apiFindImage($searchTerm: String!) {
  image(limit: 10, where: {filename: {_ilike: $searchTerm}}) {
    id
    filename
    urls(args: {presets: "{mini}"}) {
      url
    }
  }
}
    `;

/**
 * __useApiFindImageQuery__
 *
 * To run a query within a React component, call `useApiFindImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiFindImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiFindImageQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useApiFindImageQuery(baseOptions: Apollo.QueryHookOptions<ApiFindImageQuery, ApiFindImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiFindImageQuery, ApiFindImageQueryVariables>(ApiFindImageDocument, options);
      }
export function useApiFindImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiFindImageQuery, ApiFindImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiFindImageQuery, ApiFindImageQueryVariables>(ApiFindImageDocument, options);
        }
export type ApiFindImageQueryHookResult = ReturnType<typeof useApiFindImageQuery>;
export type ApiFindImageLazyQueryHookResult = ReturnType<typeof useApiFindImageLazyQuery>;
export type ApiFindImageQueryResult = Apollo.QueryResult<ApiFindImageQuery, ApiFindImageQueryVariables>;
export const ApiSingleImageDocument = gql`
    query apiSingleImage($id: uuid!) {
  image_by_pk(id: $id) {
    id
    filename
    urls(args: {presets: "{mini}"}) {
      url
    }
  }
}
    `;

/**
 * __useApiSingleImageQuery__
 *
 * To run a query within a React component, call `useApiSingleImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiSingleImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiSingleImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiSingleImageQuery(baseOptions: Apollo.QueryHookOptions<ApiSingleImageQuery, ApiSingleImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSingleImageQuery, ApiSingleImageQueryVariables>(ApiSingleImageDocument, options);
      }
export function useApiSingleImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSingleImageQuery, ApiSingleImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSingleImageQuery, ApiSingleImageQueryVariables>(ApiSingleImageDocument, options);
        }
export type ApiSingleImageQueryHookResult = ReturnType<typeof useApiSingleImageQuery>;
export type ApiSingleImageLazyQueryHookResult = ReturnType<typeof useApiSingleImageLazyQuery>;
export type ApiSingleImageQueryResult = Apollo.QueryResult<ApiSingleImageQuery, ApiSingleImageQueryVariables>;
export const ApiAllCharacteristicSectionsDocument = gql`
    query apiAllCharacteristicSections {
  characteristic_section(order_by: {id: asc}) {
    id
    name
    names
    descriptions
    metadata_descriptions
    enabled_languages
    identification_enabled_languages
    icon_id
    image_id
    url_slugs
    ordernum
  }
}
    `;

/**
 * __useApiAllCharacteristicSectionsQuery__
 *
 * To run a query within a React component, call `useApiAllCharacteristicSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllCharacteristicSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllCharacteristicSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllCharacteristicSectionsQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllCharacteristicSectionsQuery, ApiAllCharacteristicSectionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllCharacteristicSectionsQuery, ApiAllCharacteristicSectionsQueryVariables>(ApiAllCharacteristicSectionsDocument, options);
      }
export function useApiAllCharacteristicSectionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllCharacteristicSectionsQuery, ApiAllCharacteristicSectionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllCharacteristicSectionsQuery, ApiAllCharacteristicSectionsQueryVariables>(ApiAllCharacteristicSectionsDocument, options);
        }
export type ApiAllCharacteristicSectionsQueryHookResult = ReturnType<typeof useApiAllCharacteristicSectionsQuery>;
export type ApiAllCharacteristicSectionsLazyQueryHookResult = ReturnType<typeof useApiAllCharacteristicSectionsLazyQuery>;
export type ApiAllCharacteristicSectionsQueryResult = Apollo.QueryResult<ApiAllCharacteristicSectionsQuery, ApiAllCharacteristicSectionsQueryVariables>;
export const ApiSingleCharacteristicsSectionDocument = gql`
    query apiSingleCharacteristicsSection($id: Int!) {
  characteristic_section_by_pk(id: $id) {
    id
    name
    names
    descriptions
    enabled_languages
    identification_enabled_languages
    icon_id
    image_id
    url_slugs
    ordernum
    variations {
      id
      name
      names
    }
    characteristic_groups {
      name
      id
      characteristics {
        id
        name
        characteristic_values {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __useApiSingleCharacteristicsSectionQuery__
 *
 * To run a query within a React component, call `useApiSingleCharacteristicsSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiSingleCharacteristicsSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiSingleCharacteristicsSectionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiSingleCharacteristicsSectionQuery(baseOptions: Apollo.QueryHookOptions<ApiSingleCharacteristicsSectionQuery, ApiSingleCharacteristicsSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSingleCharacteristicsSectionQuery, ApiSingleCharacteristicsSectionQueryVariables>(ApiSingleCharacteristicsSectionDocument, options);
      }
export function useApiSingleCharacteristicsSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSingleCharacteristicsSectionQuery, ApiSingleCharacteristicsSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSingleCharacteristicsSectionQuery, ApiSingleCharacteristicsSectionQueryVariables>(ApiSingleCharacteristicsSectionDocument, options);
        }
export type ApiSingleCharacteristicsSectionQueryHookResult = ReturnType<typeof useApiSingleCharacteristicsSectionQuery>;
export type ApiSingleCharacteristicsSectionLazyQueryHookResult = ReturnType<typeof useApiSingleCharacteristicsSectionLazyQuery>;
export type ApiSingleCharacteristicsSectionQueryResult = Apollo.QueryResult<ApiSingleCharacteristicsSectionQuery, ApiSingleCharacteristicsSectionQueryVariables>;
export const ApiAddCharacteristicSectionDocument = gql`
    mutation apiAddCharacteristicSection($names: jsonb!, $descriptions: jsonb!, $metadataDescriptions: jsonb!, $urlSlugs: jsonb!, $enabledLanguages: jsonb!, $identificationEnabledLanguages: jsonb!, $imageId: uuid, $iconId: uuid, $ordernum: Int!) {
  insert_characteristic_section_one(
    object: {names: $names, descriptions: $descriptions, metadata_descriptions: $metadataDescriptions, url_slugs: $urlSlugs, enabled_languages: $enabledLanguages, identification_enabled_languages: $identificationEnabledLanguages, image_id: $imageId, icon_id: $iconId, ordernum: $ordernum}
  ) {
    id
    names
    descriptions
    metadata_descriptions
    enabled_languages
    identification_enabled_languages
    icon_id
    image_id
    url_slugs
    ordernum
  }
}
    `;
export type ApiAddCharacteristicSectionMutationFn = Apollo.MutationFunction<ApiAddCharacteristicSectionMutation, ApiAddCharacteristicSectionMutationVariables>;

/**
 * __useApiAddCharacteristicSectionMutation__
 *
 * To run a mutation, you first call `useApiAddCharacteristicSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddCharacteristicSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddCharacteristicSectionMutation, { data, loading, error }] = useApiAddCharacteristicSectionMutation({
 *   variables: {
 *      names: // value for 'names'
 *      descriptions: // value for 'descriptions'
 *      metadataDescriptions: // value for 'metadataDescriptions'
 *      urlSlugs: // value for 'urlSlugs'
 *      enabledLanguages: // value for 'enabledLanguages'
 *      identificationEnabledLanguages: // value for 'identificationEnabledLanguages'
 *      imageId: // value for 'imageId'
 *      iconId: // value for 'iconId'
 *      ordernum: // value for 'ordernum'
 *   },
 * });
 */
export function useApiAddCharacteristicSectionMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddCharacteristicSectionMutation, ApiAddCharacteristicSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddCharacteristicSectionMutation, ApiAddCharacteristicSectionMutationVariables>(ApiAddCharacteristicSectionDocument, options);
      }
export type ApiAddCharacteristicSectionMutationHookResult = ReturnType<typeof useApiAddCharacteristicSectionMutation>;
export type ApiAddCharacteristicSectionMutationResult = Apollo.MutationResult<ApiAddCharacteristicSectionMutation>;
export type ApiAddCharacteristicSectionMutationOptions = Apollo.BaseMutationOptions<ApiAddCharacteristicSectionMutation, ApiAddCharacteristicSectionMutationVariables>;
export const ApiChangeCharacteristicSectionDocument = gql`
    mutation apiChangeCharacteristicSection($id: Int!, $names: jsonb!, $descriptions: jsonb!, $metadataDescriptions: jsonb!, $urlSlugs: jsonb!, $enabledLanguages: jsonb!, $identificationEnabledLanguages: jsonb!, $imageId: uuid, $iconId: uuid, $ordernum: Int!) {
  update_characteristic_section_by_pk(
    pk_columns: {id: $id}
    _set: {names: $names, descriptions: $descriptions, metadata_descriptions: $metadataDescriptions, url_slugs: $urlSlugs, enabled_languages: $enabledLanguages, identification_enabled_languages: $identificationEnabledLanguages, image_id: $imageId, icon_id: $iconId, ordernum: $ordernum}
  ) {
    id
    names
    descriptions
    metadata_descriptions
    enabled_languages
    identification_enabled_languages
    icon_id
    image_id
    url_slugs
    ordernum
  }
}
    `;
export type ApiChangeCharacteristicSectionMutationFn = Apollo.MutationFunction<ApiChangeCharacteristicSectionMutation, ApiChangeCharacteristicSectionMutationVariables>;

/**
 * __useApiChangeCharacteristicSectionMutation__
 *
 * To run a mutation, you first call `useApiChangeCharacteristicSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeCharacteristicSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeCharacteristicSectionMutation, { data, loading, error }] = useApiChangeCharacteristicSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      names: // value for 'names'
 *      descriptions: // value for 'descriptions'
 *      metadataDescriptions: // value for 'metadataDescriptions'
 *      urlSlugs: // value for 'urlSlugs'
 *      enabledLanguages: // value for 'enabledLanguages'
 *      identificationEnabledLanguages: // value for 'identificationEnabledLanguages'
 *      imageId: // value for 'imageId'
 *      iconId: // value for 'iconId'
 *      ordernum: // value for 'ordernum'
 *   },
 * });
 */
export function useApiChangeCharacteristicSectionMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeCharacteristicSectionMutation, ApiChangeCharacteristicSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeCharacteristicSectionMutation, ApiChangeCharacteristicSectionMutationVariables>(ApiChangeCharacteristicSectionDocument, options);
      }
export type ApiChangeCharacteristicSectionMutationHookResult = ReturnType<typeof useApiChangeCharacteristicSectionMutation>;
export type ApiChangeCharacteristicSectionMutationResult = Apollo.MutationResult<ApiChangeCharacteristicSectionMutation>;
export type ApiChangeCharacteristicSectionMutationOptions = Apollo.BaseMutationOptions<ApiChangeCharacteristicSectionMutation, ApiChangeCharacteristicSectionMutationVariables>;
export const ApiDeleteCharacteristicSectionDocument = gql`
    mutation apiDeleteCharacteristicSection($id: Int!) {
  delete_characteristic_section_by_pk(id: $id) {
    id
  }
}
    `;
export type ApiDeleteCharacteristicSectionMutationFn = Apollo.MutationFunction<ApiDeleteCharacteristicSectionMutation, ApiDeleteCharacteristicSectionMutationVariables>;

/**
 * __useApiDeleteCharacteristicSectionMutation__
 *
 * To run a mutation, you first call `useApiDeleteCharacteristicSectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteCharacteristicSectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteCharacteristicSectionMutation, { data, loading, error }] = useApiDeleteCharacteristicSectionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeleteCharacteristicSectionMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteCharacteristicSectionMutation, ApiDeleteCharacteristicSectionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteCharacteristicSectionMutation, ApiDeleteCharacteristicSectionMutationVariables>(ApiDeleteCharacteristicSectionDocument, options);
      }
export type ApiDeleteCharacteristicSectionMutationHookResult = ReturnType<typeof useApiDeleteCharacteristicSectionMutation>;
export type ApiDeleteCharacteristicSectionMutationResult = Apollo.MutationResult<ApiDeleteCharacteristicSectionMutation>;
export type ApiDeleteCharacteristicSectionMutationOptions = Apollo.BaseMutationOptions<ApiDeleteCharacteristicSectionMutation, ApiDeleteCharacteristicSectionMutationVariables>;
export const ApiAllCharacteristicGroupsDocument = gql`
    query apiAllCharacteristicGroups {
  characteristic_group(order_by: {id: asc}) {
    id
    name
    names
    ordernum
    icon_id
    characteristic_section {
      id
      name
      names
    }
    widget_type
  }
}
    `;

/**
 * __useApiAllCharacteristicGroupsQuery__
 *
 * To run a query within a React component, call `useApiAllCharacteristicGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllCharacteristicGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllCharacteristicGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllCharacteristicGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllCharacteristicGroupsQuery, ApiAllCharacteristicGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllCharacteristicGroupsQuery, ApiAllCharacteristicGroupsQueryVariables>(ApiAllCharacteristicGroupsDocument, options);
      }
export function useApiAllCharacteristicGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllCharacteristicGroupsQuery, ApiAllCharacteristicGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllCharacteristicGroupsQuery, ApiAllCharacteristicGroupsQueryVariables>(ApiAllCharacteristicGroupsDocument, options);
        }
export type ApiAllCharacteristicGroupsQueryHookResult = ReturnType<typeof useApiAllCharacteristicGroupsQuery>;
export type ApiAllCharacteristicGroupsLazyQueryHookResult = ReturnType<typeof useApiAllCharacteristicGroupsLazyQuery>;
export type ApiAllCharacteristicGroupsQueryResult = Apollo.QueryResult<ApiAllCharacteristicGroupsQuery, ApiAllCharacteristicGroupsQueryVariables>;
export const ApiAddCharacteristicGroupDocument = gql`
    mutation apiAddCharacteristicGroup($sectionId: Int!, $iconId: uuid!, $names: jsonb!, $ordernum: Int!, $widgetType: String) {
  insert_characteristic_group_one(
    object: {characteristic_section_id: $sectionId, icon_id: $iconId, names: $names, ordernum: $ordernum, legacy_icon: 0, widget_type: $widgetType}
  ) {
    id
    characteristic_section_id
    name
    names
    ordernum
    icon_id
    widget_type
  }
}
    `;
export type ApiAddCharacteristicGroupMutationFn = Apollo.MutationFunction<ApiAddCharacteristicGroupMutation, ApiAddCharacteristicGroupMutationVariables>;

/**
 * __useApiAddCharacteristicGroupMutation__
 *
 * To run a mutation, you first call `useApiAddCharacteristicGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddCharacteristicGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddCharacteristicGroupMutation, { data, loading, error }] = useApiAddCharacteristicGroupMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      iconId: // value for 'iconId'
 *      names: // value for 'names'
 *      ordernum: // value for 'ordernum'
 *      widgetType: // value for 'widgetType'
 *   },
 * });
 */
export function useApiAddCharacteristicGroupMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddCharacteristicGroupMutation, ApiAddCharacteristicGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddCharacteristicGroupMutation, ApiAddCharacteristicGroupMutationVariables>(ApiAddCharacteristicGroupDocument, options);
      }
export type ApiAddCharacteristicGroupMutationHookResult = ReturnType<typeof useApiAddCharacteristicGroupMutation>;
export type ApiAddCharacteristicGroupMutationResult = Apollo.MutationResult<ApiAddCharacteristicGroupMutation>;
export type ApiAddCharacteristicGroupMutationOptions = Apollo.BaseMutationOptions<ApiAddCharacteristicGroupMutation, ApiAddCharacteristicGroupMutationVariables>;
export const ApiChangeCharacteristicGroupDocument = gql`
    mutation apiChangeCharacteristicGroup($id: Int!, $sectionId: Int!, $iconId: uuid!, $names: jsonb!, $ordernum: Int!, $widgetType: String) {
  update_characteristic_group_by_pk(
    pk_columns: {id: $id}
    _set: {characteristic_section_id: $sectionId, icon_id: $iconId, names: $names, ordernum: $ordernum, widget_type: $widgetType}
  ) {
    id
    characteristic_section_id
    name
    names
    ordernum
    icon_id
    widget_type
  }
}
    `;
export type ApiChangeCharacteristicGroupMutationFn = Apollo.MutationFunction<ApiChangeCharacteristicGroupMutation, ApiChangeCharacteristicGroupMutationVariables>;

/**
 * __useApiChangeCharacteristicGroupMutation__
 *
 * To run a mutation, you first call `useApiChangeCharacteristicGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeCharacteristicGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeCharacteristicGroupMutation, { data, loading, error }] = useApiChangeCharacteristicGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sectionId: // value for 'sectionId'
 *      iconId: // value for 'iconId'
 *      names: // value for 'names'
 *      ordernum: // value for 'ordernum'
 *      widgetType: // value for 'widgetType'
 *   },
 * });
 */
export function useApiChangeCharacteristicGroupMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeCharacteristicGroupMutation, ApiChangeCharacteristicGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeCharacteristicGroupMutation, ApiChangeCharacteristicGroupMutationVariables>(ApiChangeCharacteristicGroupDocument, options);
      }
export type ApiChangeCharacteristicGroupMutationHookResult = ReturnType<typeof useApiChangeCharacteristicGroupMutation>;
export type ApiChangeCharacteristicGroupMutationResult = Apollo.MutationResult<ApiChangeCharacteristicGroupMutation>;
export type ApiChangeCharacteristicGroupMutationOptions = Apollo.BaseMutationOptions<ApiChangeCharacteristicGroupMutation, ApiChangeCharacteristicGroupMutationVariables>;
export const ApiDeleteCharacteristicGroupDocument = gql`
    mutation apiDeleteCharacteristicGroup($id: Int!) {
  delete_characteristic_group_by_pk(id: $id) {
    id
  }
}
    `;
export type ApiDeleteCharacteristicGroupMutationFn = Apollo.MutationFunction<ApiDeleteCharacteristicGroupMutation, ApiDeleteCharacteristicGroupMutationVariables>;

/**
 * __useApiDeleteCharacteristicGroupMutation__
 *
 * To run a mutation, you first call `useApiDeleteCharacteristicGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteCharacteristicGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteCharacteristicGroupMutation, { data, loading, error }] = useApiDeleteCharacteristicGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeleteCharacteristicGroupMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteCharacteristicGroupMutation, ApiDeleteCharacteristicGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteCharacteristicGroupMutation, ApiDeleteCharacteristicGroupMutationVariables>(ApiDeleteCharacteristicGroupDocument, options);
      }
export type ApiDeleteCharacteristicGroupMutationHookResult = ReturnType<typeof useApiDeleteCharacteristicGroupMutation>;
export type ApiDeleteCharacteristicGroupMutationResult = Apollo.MutationResult<ApiDeleteCharacteristicGroupMutation>;
export type ApiDeleteCharacteristicGroupMutationOptions = Apollo.BaseMutationOptions<ApiDeleteCharacteristicGroupMutation, ApiDeleteCharacteristicGroupMutationVariables>;
export const ApiAllCharacteristicsWithWhereDocument = gql`
    query apiAllCharacteristicsWithWhere($where: characteristic_bool_exp) {
  characteristic(order_by: {id: asc}, where: $where) {
    id
    name
    names
    description
    descriptions
    ordernum
    icon_id
    help_icon_id
    characteristic_group_id
    widget_type
    widget_data
    characteristic_group {
      id
      name
      widget_type
    }
  }
}
    `;

/**
 * __useApiAllCharacteristicsWithWhereQuery__
 *
 * To run a query within a React component, call `useApiAllCharacteristicsWithWhereQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllCharacteristicsWithWhereQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllCharacteristicsWithWhereQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApiAllCharacteristicsWithWhereQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllCharacteristicsWithWhereQuery, ApiAllCharacteristicsWithWhereQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllCharacteristicsWithWhereQuery, ApiAllCharacteristicsWithWhereQueryVariables>(ApiAllCharacteristicsWithWhereDocument, options);
      }
export function useApiAllCharacteristicsWithWhereLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllCharacteristicsWithWhereQuery, ApiAllCharacteristicsWithWhereQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllCharacteristicsWithWhereQuery, ApiAllCharacteristicsWithWhereQueryVariables>(ApiAllCharacteristicsWithWhereDocument, options);
        }
export type ApiAllCharacteristicsWithWhereQueryHookResult = ReturnType<typeof useApiAllCharacteristicsWithWhereQuery>;
export type ApiAllCharacteristicsWithWhereLazyQueryHookResult = ReturnType<typeof useApiAllCharacteristicsWithWhereLazyQuery>;
export type ApiAllCharacteristicsWithWhereQueryResult = Apollo.QueryResult<ApiAllCharacteristicsWithWhereQuery, ApiAllCharacteristicsWithWhereQueryVariables>;
export const ApiAddCharacteristicDocument = gql`
    mutation apiAddCharacteristic($groupId: Int, $iconId: uuid, $helpIconId: uuid, $names: jsonb!, $descriptions: jsonb!, $ordernum: Int!, $widgetType: String, $widgetData: jsonb) {
  insert_characteristic_one(
    object: {characteristic_group_id: $groupId, icon_id: $iconId, help_icon_id: $helpIconId, names: $names, descriptions: $descriptions, ordernum: $ordernum, widget_type: $widgetType, widget_data: $widgetData}
  ) {
    id
    characteristic_group_id
    name
    names
    description
    descriptions
    ordernum
    icon_id
    help_icon_id
    widget_type
    widget_data
    characteristic_group {
      id
      name
      widget_type
    }
  }
}
    `;
export type ApiAddCharacteristicMutationFn = Apollo.MutationFunction<ApiAddCharacteristicMutation, ApiAddCharacteristicMutationVariables>;

/**
 * __useApiAddCharacteristicMutation__
 *
 * To run a mutation, you first call `useApiAddCharacteristicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddCharacteristicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddCharacteristicMutation, { data, loading, error }] = useApiAddCharacteristicMutation({
 *   variables: {
 *      groupId: // value for 'groupId'
 *      iconId: // value for 'iconId'
 *      helpIconId: // value for 'helpIconId'
 *      names: // value for 'names'
 *      descriptions: // value for 'descriptions'
 *      ordernum: // value for 'ordernum'
 *      widgetType: // value for 'widgetType'
 *      widgetData: // value for 'widgetData'
 *   },
 * });
 */
export function useApiAddCharacteristicMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddCharacteristicMutation, ApiAddCharacteristicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddCharacteristicMutation, ApiAddCharacteristicMutationVariables>(ApiAddCharacteristicDocument, options);
      }
export type ApiAddCharacteristicMutationHookResult = ReturnType<typeof useApiAddCharacteristicMutation>;
export type ApiAddCharacteristicMutationResult = Apollo.MutationResult<ApiAddCharacteristicMutation>;
export type ApiAddCharacteristicMutationOptions = Apollo.BaseMutationOptions<ApiAddCharacteristicMutation, ApiAddCharacteristicMutationVariables>;
export const ApiChangeCharacteristicDocument = gql`
    mutation apiChangeCharacteristic($id: Int!, $groupId: Int, $iconId: uuid, $helpIconId: uuid, $names: jsonb!, $descriptions: jsonb!, $ordernum: Int!, $widgetType: String, $widgetData: jsonb) {
  update_characteristic_by_pk(
    pk_columns: {id: $id}
    _set: {characteristic_group_id: $groupId, icon_id: $iconId, help_icon_id: $helpIconId, names: $names, descriptions: $descriptions, ordernum: $ordernum, widget_type: $widgetType, widget_data: $widgetData}
  ) {
    id
    characteristic_group_id
    name
    names
    description
    descriptions
    ordernum
    icon_id
    help_icon_id
    widget_type
    widget_data
    characteristic_group {
      id
      name
      widget_type
    }
  }
}
    `;
export type ApiChangeCharacteristicMutationFn = Apollo.MutationFunction<ApiChangeCharacteristicMutation, ApiChangeCharacteristicMutationVariables>;

/**
 * __useApiChangeCharacteristicMutation__
 *
 * To run a mutation, you first call `useApiChangeCharacteristicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeCharacteristicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeCharacteristicMutation, { data, loading, error }] = useApiChangeCharacteristicMutation({
 *   variables: {
 *      id: // value for 'id'
 *      groupId: // value for 'groupId'
 *      iconId: // value for 'iconId'
 *      helpIconId: // value for 'helpIconId'
 *      names: // value for 'names'
 *      descriptions: // value for 'descriptions'
 *      ordernum: // value for 'ordernum'
 *      widgetType: // value for 'widgetType'
 *      widgetData: // value for 'widgetData'
 *   },
 * });
 */
export function useApiChangeCharacteristicMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeCharacteristicMutation, ApiChangeCharacteristicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeCharacteristicMutation, ApiChangeCharacteristicMutationVariables>(ApiChangeCharacteristicDocument, options);
      }
export type ApiChangeCharacteristicMutationHookResult = ReturnType<typeof useApiChangeCharacteristicMutation>;
export type ApiChangeCharacteristicMutationResult = Apollo.MutationResult<ApiChangeCharacteristicMutation>;
export type ApiChangeCharacteristicMutationOptions = Apollo.BaseMutationOptions<ApiChangeCharacteristicMutation, ApiChangeCharacteristicMutationVariables>;
export const ApiDeleteCharacteristicDocument = gql`
    mutation apiDeleteCharacteristic($id: Int!) {
  delete_characteristic_by_pk(id: $id) {
    id
  }
}
    `;
export type ApiDeleteCharacteristicMutationFn = Apollo.MutationFunction<ApiDeleteCharacteristicMutation, ApiDeleteCharacteristicMutationVariables>;

/**
 * __useApiDeleteCharacteristicMutation__
 *
 * To run a mutation, you first call `useApiDeleteCharacteristicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteCharacteristicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteCharacteristicMutation, { data, loading, error }] = useApiDeleteCharacteristicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeleteCharacteristicMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteCharacteristicMutation, ApiDeleteCharacteristicMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteCharacteristicMutation, ApiDeleteCharacteristicMutationVariables>(ApiDeleteCharacteristicDocument, options);
      }
export type ApiDeleteCharacteristicMutationHookResult = ReturnType<typeof useApiDeleteCharacteristicMutation>;
export type ApiDeleteCharacteristicMutationResult = Apollo.MutationResult<ApiDeleteCharacteristicMutation>;
export type ApiDeleteCharacteristicMutationOptions = Apollo.BaseMutationOptions<ApiDeleteCharacteristicMutation, ApiDeleteCharacteristicMutationVariables>;
export const ApiAllCharacteristicValuesByCharacteristicDocument = gql`
    query apiAllCharacteristicValuesByCharacteristic($characteristicId: Int!) {
  characteristic_value(
    order_by: {id: asc}
    where: {characteristic_id: {_eq: $characteristicId}}
  ) {
    id
    characteristic_id
    name
    names
    description
    descriptions
    ordernum
    icon_id
    help_image_small_id
    help_image_large_id
    widget_data
    characteristic {
      widget_type
    }
    parent
  }
}
    `;

/**
 * __useApiAllCharacteristicValuesByCharacteristicQuery__
 *
 * To run a query within a React component, call `useApiAllCharacteristicValuesByCharacteristicQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllCharacteristicValuesByCharacteristicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllCharacteristicValuesByCharacteristicQuery({
 *   variables: {
 *      characteristicId: // value for 'characteristicId'
 *   },
 * });
 */
export function useApiAllCharacteristicValuesByCharacteristicQuery(baseOptions: Apollo.QueryHookOptions<ApiAllCharacteristicValuesByCharacteristicQuery, ApiAllCharacteristicValuesByCharacteristicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllCharacteristicValuesByCharacteristicQuery, ApiAllCharacteristicValuesByCharacteristicQueryVariables>(ApiAllCharacteristicValuesByCharacteristicDocument, options);
      }
export function useApiAllCharacteristicValuesByCharacteristicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllCharacteristicValuesByCharacteristicQuery, ApiAllCharacteristicValuesByCharacteristicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllCharacteristicValuesByCharacteristicQuery, ApiAllCharacteristicValuesByCharacteristicQueryVariables>(ApiAllCharacteristicValuesByCharacteristicDocument, options);
        }
export type ApiAllCharacteristicValuesByCharacteristicQueryHookResult = ReturnType<typeof useApiAllCharacteristicValuesByCharacteristicQuery>;
export type ApiAllCharacteristicValuesByCharacteristicLazyQueryHookResult = ReturnType<typeof useApiAllCharacteristicValuesByCharacteristicLazyQuery>;
export type ApiAllCharacteristicValuesByCharacteristicQueryResult = Apollo.QueryResult<ApiAllCharacteristicValuesByCharacteristicQuery, ApiAllCharacteristicValuesByCharacteristicQueryVariables>;
export const ApiAllVariationsDocument = gql`
    query apiAllVariations {
  variation(order_by: {id: asc}) {
    id
    name
    names
    characteristic_section_id
    characteristic_section {
      name
      names
    }
    show
  }
}
    `;

/**
 * __useApiAllVariationsQuery__
 *
 * To run a query within a React component, call `useApiAllVariationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllVariationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllVariationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllVariationsQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllVariationsQuery, ApiAllVariationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllVariationsQuery, ApiAllVariationsQueryVariables>(ApiAllVariationsDocument, options);
      }
export function useApiAllVariationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllVariationsQuery, ApiAllVariationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllVariationsQuery, ApiAllVariationsQueryVariables>(ApiAllVariationsDocument, options);
        }
export type ApiAllVariationsQueryHookResult = ReturnType<typeof useApiAllVariationsQuery>;
export type ApiAllVariationsLazyQueryHookResult = ReturnType<typeof useApiAllVariationsLazyQuery>;
export type ApiAllVariationsQueryResult = Apollo.QueryResult<ApiAllVariationsQuery, ApiAllVariationsQueryVariables>;
export const ApiAddVariationDocument = gql`
    mutation apiAddVariation($sectionId: bigint!, $names: jsonb!, $show: Boolean!) {
  insert_variation_one(
    object: {characteristic_section_id: $sectionId, names: $names, show: $show}
  ) {
    id
    name
    names
    characteristic_section_id
    show
  }
}
    `;
export type ApiAddVariationMutationFn = Apollo.MutationFunction<ApiAddVariationMutation, ApiAddVariationMutationVariables>;

/**
 * __useApiAddVariationMutation__
 *
 * To run a mutation, you first call `useApiAddVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddVariationMutation, { data, loading, error }] = useApiAddVariationMutation({
 *   variables: {
 *      sectionId: // value for 'sectionId'
 *      names: // value for 'names'
 *      show: // value for 'show'
 *   },
 * });
 */
export function useApiAddVariationMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddVariationMutation, ApiAddVariationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddVariationMutation, ApiAddVariationMutationVariables>(ApiAddVariationDocument, options);
      }
export type ApiAddVariationMutationHookResult = ReturnType<typeof useApiAddVariationMutation>;
export type ApiAddVariationMutationResult = Apollo.MutationResult<ApiAddVariationMutation>;
export type ApiAddVariationMutationOptions = Apollo.BaseMutationOptions<ApiAddVariationMutation, ApiAddVariationMutationVariables>;
export const ApiChangeVariationDocument = gql`
    mutation apiChangeVariation($id: Int!, $sectionId: bigint!, $names: jsonb!, $show: Boolean!) {
  update_variation_by_pk(
    pk_columns: {id: $id}
    _set: {characteristic_section_id: $sectionId, names: $names, show: $show}
  ) {
    id
    name
    names
    characteristic_section_id
    show
  }
}
    `;
export type ApiChangeVariationMutationFn = Apollo.MutationFunction<ApiChangeVariationMutation, ApiChangeVariationMutationVariables>;

/**
 * __useApiChangeVariationMutation__
 *
 * To run a mutation, you first call `useApiChangeVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeVariationMutation, { data, loading, error }] = useApiChangeVariationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      sectionId: // value for 'sectionId'
 *      names: // value for 'names'
 *      show: // value for 'show'
 *   },
 * });
 */
export function useApiChangeVariationMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeVariationMutation, ApiChangeVariationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeVariationMutation, ApiChangeVariationMutationVariables>(ApiChangeVariationDocument, options);
      }
export type ApiChangeVariationMutationHookResult = ReturnType<typeof useApiChangeVariationMutation>;
export type ApiChangeVariationMutationResult = Apollo.MutationResult<ApiChangeVariationMutation>;
export type ApiChangeVariationMutationOptions = Apollo.BaseMutationOptions<ApiChangeVariationMutation, ApiChangeVariationMutationVariables>;
export const ApiDeleteVariationDocument = gql`
    mutation apiDeleteVariation($id: Int!) {
  delete_variation_by_pk(id: $id) {
    id
  }
}
    `;
export type ApiDeleteVariationMutationFn = Apollo.MutationFunction<ApiDeleteVariationMutation, ApiDeleteVariationMutationVariables>;

/**
 * __useApiDeleteVariationMutation__
 *
 * To run a mutation, you first call `useApiDeleteVariationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteVariationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteVariationMutation, { data, loading, error }] = useApiDeleteVariationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeleteVariationMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteVariationMutation, ApiDeleteVariationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteVariationMutation, ApiDeleteVariationMutationVariables>(ApiDeleteVariationDocument, options);
      }
export type ApiDeleteVariationMutationHookResult = ReturnType<typeof useApiDeleteVariationMutation>;
export type ApiDeleteVariationMutationResult = Apollo.MutationResult<ApiDeleteVariationMutation>;
export type ApiDeleteVariationMutationOptions = Apollo.BaseMutationOptions<ApiDeleteVariationMutation, ApiDeleteVariationMutationVariables>;
export const ApiAddCharacteristicValueDocument = gql`
    mutation apiAddCharacteristicValue($characteristicId: Int!, $iconId: uuid!, $helpImageSmallId: uuid, $helpImageLargeId: uuid, $names: jsonb!, $descriptions: jsonb!, $ordernum: Int!, $widgetData: jsonb, $parent: Int) {
  insert_characteristic_value_one(
    object: {characteristic_id: $characteristicId, icon_id: $iconId, help_image_small_id: $helpImageSmallId, help_image_large_id: $helpImageLargeId, names: $names, descriptions: $descriptions, ordernum: $ordernum, widget_data: $widgetData, legacy_icon: 0, parent: $parent}
  ) {
    id
    characteristic_id
    name
    names
    description
    descriptions
    ordernum
    icon_id
    help_image_small_id
    help_image_large_id
    widget_data
    parent
  }
}
    `;
export type ApiAddCharacteristicValueMutationFn = Apollo.MutationFunction<ApiAddCharacteristicValueMutation, ApiAddCharacteristicValueMutationVariables>;

/**
 * __useApiAddCharacteristicValueMutation__
 *
 * To run a mutation, you first call `useApiAddCharacteristicValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddCharacteristicValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddCharacteristicValueMutation, { data, loading, error }] = useApiAddCharacteristicValueMutation({
 *   variables: {
 *      characteristicId: // value for 'characteristicId'
 *      iconId: // value for 'iconId'
 *      helpImageSmallId: // value for 'helpImageSmallId'
 *      helpImageLargeId: // value for 'helpImageLargeId'
 *      names: // value for 'names'
 *      descriptions: // value for 'descriptions'
 *      ordernum: // value for 'ordernum'
 *      widgetData: // value for 'widgetData'
 *      parent: // value for 'parent'
 *   },
 * });
 */
export function useApiAddCharacteristicValueMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddCharacteristicValueMutation, ApiAddCharacteristicValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddCharacteristicValueMutation, ApiAddCharacteristicValueMutationVariables>(ApiAddCharacteristicValueDocument, options);
      }
export type ApiAddCharacteristicValueMutationHookResult = ReturnType<typeof useApiAddCharacteristicValueMutation>;
export type ApiAddCharacteristicValueMutationResult = Apollo.MutationResult<ApiAddCharacteristicValueMutation>;
export type ApiAddCharacteristicValueMutationOptions = Apollo.BaseMutationOptions<ApiAddCharacteristicValueMutation, ApiAddCharacteristicValueMutationVariables>;
export const ApiChangeCharacteristicValueDocument = gql`
    mutation apiChangeCharacteristicValue($id: Int!, $characteristicId: Int!, $iconId: uuid!, $helpImageSmallId: uuid, $helpImageLargeId: uuid, $names: jsonb!, $descriptions: jsonb!, $ordernum: Int!, $widgetData: jsonb, $parent: Int) {
  update_characteristic_value_by_pk(
    pk_columns: {id: $id}
    _set: {characteristic_id: $characteristicId, icon_id: $iconId, help_image_small_id: $helpImageSmallId, help_image_large_id: $helpImageLargeId, names: $names, descriptions: $descriptions, ordernum: $ordernum, widget_data: $widgetData, parent: $parent}
  ) {
    id
    characteristic_id
    name
    names
    description
    descriptions
    ordernum
    icon_id
    help_image_small_id
    help_image_large_id
    widget_data
    parent
  }
}
    `;
export type ApiChangeCharacteristicValueMutationFn = Apollo.MutationFunction<ApiChangeCharacteristicValueMutation, ApiChangeCharacteristicValueMutationVariables>;

/**
 * __useApiChangeCharacteristicValueMutation__
 *
 * To run a mutation, you first call `useApiChangeCharacteristicValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeCharacteristicValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeCharacteristicValueMutation, { data, loading, error }] = useApiChangeCharacteristicValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *      characteristicId: // value for 'characteristicId'
 *      iconId: // value for 'iconId'
 *      helpImageSmallId: // value for 'helpImageSmallId'
 *      helpImageLargeId: // value for 'helpImageLargeId'
 *      names: // value for 'names'
 *      descriptions: // value for 'descriptions'
 *      ordernum: // value for 'ordernum'
 *      widgetData: // value for 'widgetData'
 *      parent: // value for 'parent'
 *   },
 * });
 */
export function useApiChangeCharacteristicValueMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeCharacteristicValueMutation, ApiChangeCharacteristicValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeCharacteristicValueMutation, ApiChangeCharacteristicValueMutationVariables>(ApiChangeCharacteristicValueDocument, options);
      }
export type ApiChangeCharacteristicValueMutationHookResult = ReturnType<typeof useApiChangeCharacteristicValueMutation>;
export type ApiChangeCharacteristicValueMutationResult = Apollo.MutationResult<ApiChangeCharacteristicValueMutation>;
export type ApiChangeCharacteristicValueMutationOptions = Apollo.BaseMutationOptions<ApiChangeCharacteristicValueMutation, ApiChangeCharacteristicValueMutationVariables>;
export const ApiDeleteCharacteristicValueDocument = gql`
    mutation apiDeleteCharacteristicValue($id: Int!) {
  delete_characteristic_value_by_pk(id: $id) {
    id
  }
}
    `;
export type ApiDeleteCharacteristicValueMutationFn = Apollo.MutationFunction<ApiDeleteCharacteristicValueMutation, ApiDeleteCharacteristicValueMutationVariables>;

/**
 * __useApiDeleteCharacteristicValueMutation__
 *
 * To run a mutation, you first call `useApiDeleteCharacteristicValueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteCharacteristicValueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteCharacteristicValueMutation, { data, loading, error }] = useApiDeleteCharacteristicValueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeleteCharacteristicValueMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteCharacteristicValueMutation, ApiDeleteCharacteristicValueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteCharacteristicValueMutation, ApiDeleteCharacteristicValueMutationVariables>(ApiDeleteCharacteristicValueDocument, options);
      }
export type ApiDeleteCharacteristicValueMutationHookResult = ReturnType<typeof useApiDeleteCharacteristicValueMutation>;
export type ApiDeleteCharacteristicValueMutationResult = Apollo.MutationResult<ApiDeleteCharacteristicValueMutation>;
export type ApiDeleteCharacteristicValueMutationOptions = Apollo.BaseMutationOptions<ApiDeleteCharacteristicValueMutation, ApiDeleteCharacteristicValueMutationVariables>;
export const ApiChangeTaxonomyCharValuesDocument = gql`
    mutation apiChangeTaxonomyCharValues($taxonomyId: bigint!, $values: [taxonomy_characteristic_value_insert_input!]!) {
  delete_taxonomy_characteristic_value(where: {taxonomy_id: {_eq: $taxonomyId}}) {
    affected_rows
  }
  insert_taxonomy_characteristic_value(objects: $values) {
    affected_rows
  }
}
    `;
export type ApiChangeTaxonomyCharValuesMutationFn = Apollo.MutationFunction<ApiChangeTaxonomyCharValuesMutation, ApiChangeTaxonomyCharValuesMutationVariables>;

/**
 * __useApiChangeTaxonomyCharValuesMutation__
 *
 * To run a mutation, you first call `useApiChangeTaxonomyCharValuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeTaxonomyCharValuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeTaxonomyCharValuesMutation, { data, loading, error }] = useApiChangeTaxonomyCharValuesMutation({
 *   variables: {
 *      taxonomyId: // value for 'taxonomyId'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useApiChangeTaxonomyCharValuesMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeTaxonomyCharValuesMutation, ApiChangeTaxonomyCharValuesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeTaxonomyCharValuesMutation, ApiChangeTaxonomyCharValuesMutationVariables>(ApiChangeTaxonomyCharValuesDocument, options);
      }
export type ApiChangeTaxonomyCharValuesMutationHookResult = ReturnType<typeof useApiChangeTaxonomyCharValuesMutation>;
export type ApiChangeTaxonomyCharValuesMutationResult = Apollo.MutationResult<ApiChangeTaxonomyCharValuesMutation>;
export type ApiChangeTaxonomyCharValuesMutationOptions = Apollo.BaseMutationOptions<ApiChangeTaxonomyCharValuesMutation, ApiChangeTaxonomyCharValuesMutationVariables>;
export const ApiAddArticleTextDocument = gql`
    mutation apiAddArticleText($taxonomyId: bigint!, $lang: String!, $article: String!, $published: Boolean!, $section: String!, $urlSlug: String!, $informationStrike: String) {
  insert_taxonomy_article_one(
    object: {taxonomy_id: $taxonomyId, language: $lang, article: $article, published: $published, url_slug: $urlSlug, section: $section, information_strike: $informationStrike}
  ) {
    taxonomy_id
    language
    article
    published
    section
    url_slug
    legacy_copyright
  }
}
    `;
export type ApiAddArticleTextMutationFn = Apollo.MutationFunction<ApiAddArticleTextMutation, ApiAddArticleTextMutationVariables>;

/**
 * __useApiAddArticleTextMutation__
 *
 * To run a mutation, you first call `useApiAddArticleTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddArticleTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddArticleTextMutation, { data, loading, error }] = useApiAddArticleTextMutation({
 *   variables: {
 *      taxonomyId: // value for 'taxonomyId'
 *      lang: // value for 'lang'
 *      article: // value for 'article'
 *      published: // value for 'published'
 *      section: // value for 'section'
 *      urlSlug: // value for 'urlSlug'
 *      informationStrike: // value for 'informationStrike'
 *   },
 * });
 */
export function useApiAddArticleTextMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddArticleTextMutation, ApiAddArticleTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddArticleTextMutation, ApiAddArticleTextMutationVariables>(ApiAddArticleTextDocument, options);
      }
export type ApiAddArticleTextMutationHookResult = ReturnType<typeof useApiAddArticleTextMutation>;
export type ApiAddArticleTextMutationResult = Apollo.MutationResult<ApiAddArticleTextMutation>;
export type ApiAddArticleTextMutationOptions = Apollo.BaseMutationOptions<ApiAddArticleTextMutation, ApiAddArticleTextMutationVariables>;
export const ApiChangeArticleTextDocument = gql`
    mutation apiChangeArticleText($taxonomyId: bigint!, $lang: String!, $article: String!, $published: Boolean!, $informationStrike: String, $urlSlug: String!) {
  update_taxonomy_article(
    where: {taxonomy_id: {_eq: $taxonomyId}, language: {_eq: $lang}}
    _set: {article: $article, published: $published, information_strike: $informationStrike, url_slug: $urlSlug}
  ) {
    affected_rows
  }
}
    `;
export type ApiChangeArticleTextMutationFn = Apollo.MutationFunction<ApiChangeArticleTextMutation, ApiChangeArticleTextMutationVariables>;

/**
 * __useApiChangeArticleTextMutation__
 *
 * To run a mutation, you first call `useApiChangeArticleTextMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeArticleTextMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeArticleTextMutation, { data, loading, error }] = useApiChangeArticleTextMutation({
 *   variables: {
 *      taxonomyId: // value for 'taxonomyId'
 *      lang: // value for 'lang'
 *      article: // value for 'article'
 *      published: // value for 'published'
 *      informationStrike: // value for 'informationStrike'
 *      urlSlug: // value for 'urlSlug'
 *   },
 * });
 */
export function useApiChangeArticleTextMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeArticleTextMutation, ApiChangeArticleTextMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeArticleTextMutation, ApiChangeArticleTextMutationVariables>(ApiChangeArticleTextDocument, options);
      }
export type ApiChangeArticleTextMutationHookResult = ReturnType<typeof useApiChangeArticleTextMutation>;
export type ApiChangeArticleTextMutationResult = Apollo.MutationResult<ApiChangeArticleTextMutation>;
export type ApiChangeArticleTextMutationOptions = Apollo.BaseMutationOptions<ApiChangeArticleTextMutation, ApiChangeArticleTextMutationVariables>;
export const ApiChangeImageDetailsDocument = gql`
    mutation apiChangeImageDetails($id: uuid!, $copyright: String, $output_format: String, $captions: jsonb!, $alts: jsonb!) {
  update_image_by_pk(
    pk_columns: {id: $id}
    _set: {copyright: $copyright, output_format: $output_format, captions: $captions, alts: $alts}
  ) {
    id
  }
}
    `;
export type ApiChangeImageDetailsMutationFn = Apollo.MutationFunction<ApiChangeImageDetailsMutation, ApiChangeImageDetailsMutationVariables>;

/**
 * __useApiChangeImageDetailsMutation__
 *
 * To run a mutation, you first call `useApiChangeImageDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeImageDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeImageDetailsMutation, { data, loading, error }] = useApiChangeImageDetailsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      copyright: // value for 'copyright'
 *      output_format: // value for 'output_format'
 *      captions: // value for 'captions'
 *      alts: // value for 'alts'
 *   },
 * });
 */
export function useApiChangeImageDetailsMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeImageDetailsMutation, ApiChangeImageDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeImageDetailsMutation, ApiChangeImageDetailsMutationVariables>(ApiChangeImageDetailsDocument, options);
      }
export type ApiChangeImageDetailsMutationHookResult = ReturnType<typeof useApiChangeImageDetailsMutation>;
export type ApiChangeImageDetailsMutationResult = Apollo.MutationResult<ApiChangeImageDetailsMutation>;
export type ApiChangeImageDetailsMutationOptions = Apollo.BaseMutationOptions<ApiChangeImageDetailsMutation, ApiChangeImageDetailsMutationVariables>;
export const ApiChangeTaxonomyImageDocument = gql`
    mutation apiChangeTaxonomyImage($taxonomyId: bigint!, $imageId: uuid!, $copyright: String, $captions: jsonb!, $alts: jsonb!, $identification: Boolean!, $variations: _int4!) {
  update_image_by_pk(
    pk_columns: {id: $imageId}
    _set: {copyright: $copyright, captions: $captions, alts: $alts}
  ) {
    id
  }
  update_taxonomy_image(
    where: {_and: {taxonomy_id: {_eq: $taxonomyId}, image_id: {_eq: $imageId}}}
    _set: {identification: $identification, variations: $variations}
  ) {
    affected_rows
  }
}
    `;
export type ApiChangeTaxonomyImageMutationFn = Apollo.MutationFunction<ApiChangeTaxonomyImageMutation, ApiChangeTaxonomyImageMutationVariables>;

/**
 * __useApiChangeTaxonomyImageMutation__
 *
 * To run a mutation, you first call `useApiChangeTaxonomyImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeTaxonomyImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeTaxonomyImageMutation, { data, loading, error }] = useApiChangeTaxonomyImageMutation({
 *   variables: {
 *      taxonomyId: // value for 'taxonomyId'
 *      imageId: // value for 'imageId'
 *      copyright: // value for 'copyright'
 *      captions: // value for 'captions'
 *      alts: // value for 'alts'
 *      identification: // value for 'identification'
 *      variations: // value for 'variations'
 *   },
 * });
 */
export function useApiChangeTaxonomyImageMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeTaxonomyImageMutation, ApiChangeTaxonomyImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeTaxonomyImageMutation, ApiChangeTaxonomyImageMutationVariables>(ApiChangeTaxonomyImageDocument, options);
      }
export type ApiChangeTaxonomyImageMutationHookResult = ReturnType<typeof useApiChangeTaxonomyImageMutation>;
export type ApiChangeTaxonomyImageMutationResult = Apollo.MutationResult<ApiChangeTaxonomyImageMutation>;
export type ApiChangeTaxonomyImageMutationOptions = Apollo.BaseMutationOptions<ApiChangeTaxonomyImageMutation, ApiChangeTaxonomyImageMutationVariables>;
export const ApiAllPagesDocument = gql`
    query apiAllPages {
  page(where: {deleted: {_eq: false}}) {
    name
    admin_description
    metadata_descriptions
    id
    titles
    created_by
    created_at
    published
    url_slugs
  }
}
    `;

/**
 * __useApiAllPagesQuery__
 *
 * To run a query within a React component, call `useApiAllPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllPagesQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllPagesQuery, ApiAllPagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllPagesQuery, ApiAllPagesQueryVariables>(ApiAllPagesDocument, options);
      }
export function useApiAllPagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllPagesQuery, ApiAllPagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllPagesQuery, ApiAllPagesQueryVariables>(ApiAllPagesDocument, options);
        }
export type ApiAllPagesQueryHookResult = ReturnType<typeof useApiAllPagesQuery>;
export type ApiAllPagesLazyQueryHookResult = ReturnType<typeof useApiAllPagesLazyQuery>;
export type ApiAllPagesQueryResult = Apollo.QueryResult<ApiAllPagesQuery, ApiAllPagesQueryVariables>;
export const ApiAddPageDocument = gql`
    mutation apiAddPage($name: String!, $admin_description: String!) {
  insert_page_one(object: {name: $name, admin_description: $admin_description}) {
    name
    admin_description
    metadata_descriptions
    id
    titles
    created_by
    created_at
    published
    url_slugs
  }
}
    `;
export type ApiAddPageMutationFn = Apollo.MutationFunction<ApiAddPageMutation, ApiAddPageMutationVariables>;

/**
 * __useApiAddPageMutation__
 *
 * To run a mutation, you first call `useApiAddPageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddPageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddPageMutation, { data, loading, error }] = useApiAddPageMutation({
 *   variables: {
 *      name: // value for 'name'
 *      admin_description: // value for 'admin_description'
 *   },
 * });
 */
export function useApiAddPageMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddPageMutation, ApiAddPageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddPageMutation, ApiAddPageMutationVariables>(ApiAddPageDocument, options);
      }
export type ApiAddPageMutationHookResult = ReturnType<typeof useApiAddPageMutation>;
export type ApiAddPageMutationResult = Apollo.MutationResult<ApiAddPageMutation>;
export type ApiAddPageMutationOptions = Apollo.BaseMutationOptions<ApiAddPageMutation, ApiAddPageMutationVariables>;
export const ApiDeletePageDocument = gql`
    mutation apiDeletePage($id: Int!) {
  update_page_by_pk(pk_columns: {id: $id}, _set: {deleted: true}) {
    id
  }
}
    `;
export type ApiDeletePageMutationFn = Apollo.MutationFunction<ApiDeletePageMutation, ApiDeletePageMutationVariables>;

/**
 * __useApiDeletePageMutation__
 *
 * To run a mutation, you first call `useApiDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeletePageMutation, { data, loading, error }] = useApiDeletePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeletePageMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeletePageMutation, ApiDeletePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeletePageMutation, ApiDeletePageMutationVariables>(ApiDeletePageDocument, options);
      }
export type ApiDeletePageMutationHookResult = ReturnType<typeof useApiDeletePageMutation>;
export type ApiDeletePageMutationResult = Apollo.MutationResult<ApiDeletePageMutation>;
export type ApiDeletePageMutationOptions = Apollo.BaseMutationOptions<ApiDeletePageMutation, ApiDeletePageMutationVariables>;
export const ApiUpdatePageDocument = gql`
    mutation apiUpdatePage($id: Int!, $name: String!, $admin_description: String!, $metadata_descriptions: jsonb!, $published: Boolean!, $titles: jsonb!, $url_slugs: jsonb!) {
  update_page_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, admin_description: $admin_description, metadata_descriptions: $metadata_descriptions, published: $published, url_slugs: $url_slugs, titles: $titles}
  ) {
    name
    admin_description
    metadata_descriptions
    id
    titles
    created_by
    created_at
    published
    url_slugs
  }
}
    `;
export type ApiUpdatePageMutationFn = Apollo.MutationFunction<ApiUpdatePageMutation, ApiUpdatePageMutationVariables>;

/**
 * __useApiUpdatePageMutation__
 *
 * To run a mutation, you first call `useApiUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiUpdatePageMutation, { data, loading, error }] = useApiUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      admin_description: // value for 'admin_description'
 *      metadata_descriptions: // value for 'metadata_descriptions'
 *      published: // value for 'published'
 *      titles: // value for 'titles'
 *      url_slugs: // value for 'url_slugs'
 *   },
 * });
 */
export function useApiUpdatePageMutation(baseOptions?: Apollo.MutationHookOptions<ApiUpdatePageMutation, ApiUpdatePageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiUpdatePageMutation, ApiUpdatePageMutationVariables>(ApiUpdatePageDocument, options);
      }
export type ApiUpdatePageMutationHookResult = ReturnType<typeof useApiUpdatePageMutation>;
export type ApiUpdatePageMutationResult = Apollo.MutationResult<ApiUpdatePageMutation>;
export type ApiUpdatePageMutationOptions = Apollo.BaseMutationOptions<ApiUpdatePageMutation, ApiUpdatePageMutationVariables>;
export const ApiSinglePageDocument = gql`
    query apiSinglePage($id: Int!) {
  page_by_pk(id: $id) {
    page_contents(order_by: {ordernum: asc}) {
      contents
      id
      image_id
      ordernum
      page_content_type_id
      image {
        alt
        caption
        id
        filename
        urls(args: {presets: "{large}"}) {
          url
        }
      }
      language
    }
    id
    admin_description
    titles
    url_slugs
    published
  }
}
    `;

/**
 * __useApiSinglePageQuery__
 *
 * To run a query within a React component, call `useApiSinglePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiSinglePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiSinglePageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiSinglePageQuery(baseOptions: Apollo.QueryHookOptions<ApiSinglePageQuery, ApiSinglePageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSinglePageQuery, ApiSinglePageQueryVariables>(ApiSinglePageDocument, options);
      }
export function useApiSinglePageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSinglePageQuery, ApiSinglePageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSinglePageQuery, ApiSinglePageQueryVariables>(ApiSinglePageDocument, options);
        }
export type ApiSinglePageQueryHookResult = ReturnType<typeof useApiSinglePageQuery>;
export type ApiSinglePageLazyQueryHookResult = ReturnType<typeof useApiSinglePageLazyQuery>;
export type ApiSinglePageQueryResult = Apollo.QueryResult<ApiSinglePageQuery, ApiSinglePageQueryVariables>;
export const ApiAllPageContentTypesDocument = gql`
    query apiAllPageContentTypes {
  page_content_type {
    id
  }
}
    `;

/**
 * __useApiAllPageContentTypesQuery__
 *
 * To run a query within a React component, call `useApiAllPageContentTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllPageContentTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllPageContentTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllPageContentTypesQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllPageContentTypesQuery, ApiAllPageContentTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllPageContentTypesQuery, ApiAllPageContentTypesQueryVariables>(ApiAllPageContentTypesDocument, options);
      }
export function useApiAllPageContentTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllPageContentTypesQuery, ApiAllPageContentTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllPageContentTypesQuery, ApiAllPageContentTypesQueryVariables>(ApiAllPageContentTypesDocument, options);
        }
export type ApiAllPageContentTypesQueryHookResult = ReturnType<typeof useApiAllPageContentTypesQuery>;
export type ApiAllPageContentTypesLazyQueryHookResult = ReturnType<typeof useApiAllPageContentTypesLazyQuery>;
export type ApiAllPageContentTypesQueryResult = Apollo.QueryResult<ApiAllPageContentTypesQuery, ApiAllPageContentTypesQueryVariables>;
export const ApiAddPageContentDocument = gql`
    mutation apiAddPageContent($pageId: Int!, $type: String!, $contents: jsonb!, $ordernum: Int!, $language: String) {
  insert_page_content_one(
    object: {ordernum: $ordernum, page_content_type_id: $type, contents: $contents, page_id: $pageId, language: $language}
  ) {
    id
  }
}
    `;
export type ApiAddPageContentMutationFn = Apollo.MutationFunction<ApiAddPageContentMutation, ApiAddPageContentMutationVariables>;

/**
 * __useApiAddPageContentMutation__
 *
 * To run a mutation, you first call `useApiAddPageContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddPageContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddPageContentMutation, { data, loading, error }] = useApiAddPageContentMutation({
 *   variables: {
 *      pageId: // value for 'pageId'
 *      type: // value for 'type'
 *      contents: // value for 'contents'
 *      ordernum: // value for 'ordernum'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useApiAddPageContentMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddPageContentMutation, ApiAddPageContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddPageContentMutation, ApiAddPageContentMutationVariables>(ApiAddPageContentDocument, options);
      }
export type ApiAddPageContentMutationHookResult = ReturnType<typeof useApiAddPageContentMutation>;
export type ApiAddPageContentMutationResult = Apollo.MutationResult<ApiAddPageContentMutation>;
export type ApiAddPageContentMutationOptions = Apollo.BaseMutationOptions<ApiAddPageContentMutation, ApiAddPageContentMutationVariables>;
export const ApiDeletePageContentDocument = gql`
    mutation apiDeletePageContent($id: Int!) {
  delete_page_content_by_pk(id: $id) {
    id
  }
}
    `;
export type ApiDeletePageContentMutationFn = Apollo.MutationFunction<ApiDeletePageContentMutation, ApiDeletePageContentMutationVariables>;

/**
 * __useApiDeletePageContentMutation__
 *
 * To run a mutation, you first call `useApiDeletePageContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeletePageContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeletePageContentMutation, { data, loading, error }] = useApiDeletePageContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeletePageContentMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeletePageContentMutation, ApiDeletePageContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeletePageContentMutation, ApiDeletePageContentMutationVariables>(ApiDeletePageContentDocument, options);
      }
export type ApiDeletePageContentMutationHookResult = ReturnType<typeof useApiDeletePageContentMutation>;
export type ApiDeletePageContentMutationResult = Apollo.MutationResult<ApiDeletePageContentMutation>;
export type ApiDeletePageContentMutationOptions = Apollo.BaseMutationOptions<ApiDeletePageContentMutation, ApiDeletePageContentMutationVariables>;
export const ApiUpdatePageContentDocument = gql`
    mutation apiUpdatePageContent($id: Int!, $contents: jsonb!, $ordernum: Int!, $image_id: uuid) {
  update_page_content_by_pk(
    pk_columns: {id: $id}
    _set: {contents: $contents, ordernum: $ordernum, image_id: $image_id}
  ) {
    id
  }
}
    `;
export type ApiUpdatePageContentMutationFn = Apollo.MutationFunction<ApiUpdatePageContentMutation, ApiUpdatePageContentMutationVariables>;

/**
 * __useApiUpdatePageContentMutation__
 *
 * To run a mutation, you first call `useApiUpdatePageContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiUpdatePageContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiUpdatePageContentMutation, { data, loading, error }] = useApiUpdatePageContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      contents: // value for 'contents'
 *      ordernum: // value for 'ordernum'
 *      image_id: // value for 'image_id'
 *   },
 * });
 */
export function useApiUpdatePageContentMutation(baseOptions?: Apollo.MutationHookOptions<ApiUpdatePageContentMutation, ApiUpdatePageContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiUpdatePageContentMutation, ApiUpdatePageContentMutationVariables>(ApiUpdatePageContentDocument, options);
      }
export type ApiUpdatePageContentMutationHookResult = ReturnType<typeof useApiUpdatePageContentMutation>;
export type ApiUpdatePageContentMutationResult = Apollo.MutationResult<ApiUpdatePageContentMutation>;
export type ApiUpdatePageContentMutationOptions = Apollo.BaseMutationOptions<ApiUpdatePageContentMutation, ApiUpdatePageContentMutationVariables>;
export const ApiSpeciesListDocument = gql`
    query apiSpeciesList($lang: String!) {
  taxonomy(where: {taxonomy_common_names: {language: {_eq: $lang}}}) {
    id
    taxonomy_common_names(where: {language: {_eq: $lang}}, limit: 1) {
      name
    }
    scientific_name
  }
}
    `;

/**
 * __useApiSpeciesListQuery__
 *
 * To run a query within a React component, call `useApiSpeciesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiSpeciesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiSpeciesListQuery({
 *   variables: {
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useApiSpeciesListQuery(baseOptions: Apollo.QueryHookOptions<ApiSpeciesListQuery, ApiSpeciesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSpeciesListQuery, ApiSpeciesListQueryVariables>(ApiSpeciesListDocument, options);
      }
export function useApiSpeciesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSpeciesListQuery, ApiSpeciesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSpeciesListQuery, ApiSpeciesListQueryVariables>(ApiSpeciesListDocument, options);
        }
export type ApiSpeciesListQueryHookResult = ReturnType<typeof useApiSpeciesListQuery>;
export type ApiSpeciesListLazyQueryHookResult = ReturnType<typeof useApiSpeciesListLazyQuery>;
export type ApiSpeciesListQueryResult = Apollo.QueryResult<ApiSpeciesListQuery, ApiSpeciesListQueryVariables>;
export const ApiAddTaxonomyImageDocument = gql`
    mutation apiAddTaxonomyImage($taxonomy_id: bigint!, $ordernum: Int, $id: uuid!, $filename: String, $file_extension: String, $width: Int, $height: Int, $copyright: String, $output_format: String) {
  insert_taxonomy_image_one(
    object: {taxonomy_id: $taxonomy_id, ordernum: $ordernum, image: {data: {id: $id, filename: $filename, file_extension: $file_extension, width: $width, height: $height, copyright: $copyright, output_format: $output_format}}}
  ) {
    image_id
    ordernum
    image {
      id
    }
    taxonomy_id
  }
}
    `;
export type ApiAddTaxonomyImageMutationFn = Apollo.MutationFunction<ApiAddTaxonomyImageMutation, ApiAddTaxonomyImageMutationVariables>;

/**
 * __useApiAddTaxonomyImageMutation__
 *
 * To run a mutation, you first call `useApiAddTaxonomyImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddTaxonomyImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddTaxonomyImageMutation, { data, loading, error }] = useApiAddTaxonomyImageMutation({
 *   variables: {
 *      taxonomy_id: // value for 'taxonomy_id'
 *      ordernum: // value for 'ordernum'
 *      id: // value for 'id'
 *      filename: // value for 'filename'
 *      file_extension: // value for 'file_extension'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      copyright: // value for 'copyright'
 *      output_format: // value for 'output_format'
 *   },
 * });
 */
export function useApiAddTaxonomyImageMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddTaxonomyImageMutation, ApiAddTaxonomyImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddTaxonomyImageMutation, ApiAddTaxonomyImageMutationVariables>(ApiAddTaxonomyImageDocument, options);
      }
export type ApiAddTaxonomyImageMutationHookResult = ReturnType<typeof useApiAddTaxonomyImageMutation>;
export type ApiAddTaxonomyImageMutationResult = Apollo.MutationResult<ApiAddTaxonomyImageMutation>;
export type ApiAddTaxonomyImageMutationOptions = Apollo.BaseMutationOptions<ApiAddTaxonomyImageMutation, ApiAddTaxonomyImageMutationVariables>;
export const ApiDeleteTaxonomyImageDocument = gql`
    mutation apiDeleteTaxonomyImage($id: uuid!) {
  delete_taxonomy_image(where: {image: {id: {_eq: $id}}}) {
    affected_rows
  }
  delete_image_by_pk(id: $id) {
    id
  }
}
    `;
export type ApiDeleteTaxonomyImageMutationFn = Apollo.MutationFunction<ApiDeleteTaxonomyImageMutation, ApiDeleteTaxonomyImageMutationVariables>;

/**
 * __useApiDeleteTaxonomyImageMutation__
 *
 * To run a mutation, you first call `useApiDeleteTaxonomyImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteTaxonomyImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteTaxonomyImageMutation, { data, loading, error }] = useApiDeleteTaxonomyImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeleteTaxonomyImageMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteTaxonomyImageMutation, ApiDeleteTaxonomyImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteTaxonomyImageMutation, ApiDeleteTaxonomyImageMutationVariables>(ApiDeleteTaxonomyImageDocument, options);
      }
export type ApiDeleteTaxonomyImageMutationHookResult = ReturnType<typeof useApiDeleteTaxonomyImageMutation>;
export type ApiDeleteTaxonomyImageMutationResult = Apollo.MutationResult<ApiDeleteTaxonomyImageMutation>;
export type ApiDeleteTaxonomyImageMutationOptions = Apollo.BaseMutationOptions<ApiDeleteTaxonomyImageMutation, ApiDeleteTaxonomyImageMutationVariables>;
export const ApiImagesDocument = gql`
    query apiImages($limit: Int, $where: image_bool_exp) {
  image(limit: $limit, order_by: {created_at: desc, filename: asc}, where: $where) {
    id
    image_tags {
      tag
    }
    urls(args: {presets: "{thumbnail}"}) {
      preset
      url
    }
    copyright
    filename
    file_extension
    created_at
    alt
    alts
    caption
    captions
    width
    height
    uploaded_by
    legacy_id
    output_format
  }
}
    `;

/**
 * __useApiImagesQuery__
 *
 * To run a query within a React component, call `useApiImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiImagesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useApiImagesQuery(baseOptions?: Apollo.QueryHookOptions<ApiImagesQuery, ApiImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiImagesQuery, ApiImagesQueryVariables>(ApiImagesDocument, options);
      }
export function useApiImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiImagesQuery, ApiImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiImagesQuery, ApiImagesQueryVariables>(ApiImagesDocument, options);
        }
export type ApiImagesQueryHookResult = ReturnType<typeof useApiImagesQuery>;
export type ApiImagesLazyQueryHookResult = ReturnType<typeof useApiImagesLazyQuery>;
export type ApiImagesQueryResult = Apollo.QueryResult<ApiImagesQuery, ApiImagesQueryVariables>;
export const ApiSingleLargeImageDocument = gql`
    query apiSingleLargeImage($id: uuid!) {
  image_by_pk(id: $id) {
    id
    image_tags {
      tag
    }
    urls(args: {presets: "{large}"}) {
      preset
      url
    }
    copyright
    filename
    file_extension
    created_at
    alt
    alts
    caption
    captions
    width
    height
    uploaded_by
    legacy_id
    output_format
    taxonomy_images {
      taxonomy {
        id
        scientific_name
        common_name
      }
    }
  }
}
    `;

/**
 * __useApiSingleLargeImageQuery__
 *
 * To run a query within a React component, call `useApiSingleLargeImageQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiSingleLargeImageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiSingleLargeImageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiSingleLargeImageQuery(baseOptions: Apollo.QueryHookOptions<ApiSingleLargeImageQuery, ApiSingleLargeImageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSingleLargeImageQuery, ApiSingleLargeImageQueryVariables>(ApiSingleLargeImageDocument, options);
      }
export function useApiSingleLargeImageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSingleLargeImageQuery, ApiSingleLargeImageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSingleLargeImageQuery, ApiSingleLargeImageQueryVariables>(ApiSingleLargeImageDocument, options);
        }
export type ApiSingleLargeImageQueryHookResult = ReturnType<typeof useApiSingleLargeImageQuery>;
export type ApiSingleLargeImageLazyQueryHookResult = ReturnType<typeof useApiSingleLargeImageLazyQuery>;
export type ApiSingleLargeImageQueryResult = Apollo.QueryResult<ApiSingleLargeImageQuery, ApiSingleLargeImageQueryVariables>;
export const ApiSingleImageWithPresetsDocument = gql`
    query apiSingleImageWithPresets($id: uuid!, $presets: _text) {
  image_by_pk(id: $id) {
    id
    filename
    urls(args: {presets: $presets}) {
      preset
      url
    }
  }
}
    `;

/**
 * __useApiSingleImageWithPresetsQuery__
 *
 * To run a query within a React component, call `useApiSingleImageWithPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiSingleImageWithPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiSingleImageWithPresetsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      presets: // value for 'presets'
 *   },
 * });
 */
export function useApiSingleImageWithPresetsQuery(baseOptions: Apollo.QueryHookOptions<ApiSingleImageWithPresetsQuery, ApiSingleImageWithPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSingleImageWithPresetsQuery, ApiSingleImageWithPresetsQueryVariables>(ApiSingleImageWithPresetsDocument, options);
      }
export function useApiSingleImageWithPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSingleImageWithPresetsQuery, ApiSingleImageWithPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSingleImageWithPresetsQuery, ApiSingleImageWithPresetsQueryVariables>(ApiSingleImageWithPresetsDocument, options);
        }
export type ApiSingleImageWithPresetsQueryHookResult = ReturnType<typeof useApiSingleImageWithPresetsQuery>;
export type ApiSingleImageWithPresetsLazyQueryHookResult = ReturnType<typeof useApiSingleImageWithPresetsLazyQuery>;
export type ApiSingleImageWithPresetsQueryResult = Apollo.QueryResult<ApiSingleImageWithPresetsQuery, ApiSingleImageWithPresetsQueryVariables>;
export const ApiDeleteImageDocument = gql`
    mutation apiDeleteImage($id: uuid!) {
  delete_image_by_pk(id: $id) {
    id
  }
}
    `;
export type ApiDeleteImageMutationFn = Apollo.MutationFunction<ApiDeleteImageMutation, ApiDeleteImageMutationVariables>;

/**
 * __useApiDeleteImageMutation__
 *
 * To run a mutation, you first call `useApiDeleteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteImageMutation, { data, loading, error }] = useApiDeleteImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeleteImageMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteImageMutation, ApiDeleteImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteImageMutation, ApiDeleteImageMutationVariables>(ApiDeleteImageDocument, options);
      }
export type ApiDeleteImageMutationHookResult = ReturnType<typeof useApiDeleteImageMutation>;
export type ApiDeleteImageMutationResult = Apollo.MutationResult<ApiDeleteImageMutation>;
export type ApiDeleteImageMutationOptions = Apollo.BaseMutationOptions<ApiDeleteImageMutation, ApiDeleteImageMutationVariables>;
export const ApiAddImageDocument = gql`
    mutation apiAddImage($id: uuid!, $filename: String, $file_extension: String, $width: Int, $height: Int, $output_format: String) {
  insert_image(
    objects: {id: $id, filename: $filename, file_extension: $file_extension, width: $width, height: $height, output_format: $output_format}
  ) {
    affected_rows
  }
}
    `;
export type ApiAddImageMutationFn = Apollo.MutationFunction<ApiAddImageMutation, ApiAddImageMutationVariables>;

/**
 * __useApiAddImageMutation__
 *
 * To run a mutation, you first call `useApiAddImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddImageMutation, { data, loading, error }] = useApiAddImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      filename: // value for 'filename'
 *      file_extension: // value for 'file_extension'
 *      width: // value for 'width'
 *      height: // value for 'height'
 *      output_format: // value for 'output_format'
 *   },
 * });
 */
export function useApiAddImageMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddImageMutation, ApiAddImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddImageMutation, ApiAddImageMutationVariables>(ApiAddImageDocument, options);
      }
export type ApiAddImageMutationHookResult = ReturnType<typeof useApiAddImageMutation>;
export type ApiAddImageMutationResult = Apollo.MutationResult<ApiAddImageMutation>;
export type ApiAddImageMutationOptions = Apollo.BaseMutationOptions<ApiAddImageMutation, ApiAddImageMutationVariables>;
export const ApiFnMoveTaxonomyDocument = gql`
    mutation apiFnMoveTaxonomy($destinationId: bigint, $sourceId: bigint) {
  fn_move_taxonomy(args: {destination: $destinationId, source: $sourceId}) {
    id
  }
}
    `;
export type ApiFnMoveTaxonomyMutationFn = Apollo.MutationFunction<ApiFnMoveTaxonomyMutation, ApiFnMoveTaxonomyMutationVariables>;

/**
 * __useApiFnMoveTaxonomyMutation__
 *
 * To run a mutation, you first call `useApiFnMoveTaxonomyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiFnMoveTaxonomyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiFnMoveTaxonomyMutation, { data, loading, error }] = useApiFnMoveTaxonomyMutation({
 *   variables: {
 *      destinationId: // value for 'destinationId'
 *      sourceId: // value for 'sourceId'
 *   },
 * });
 */
export function useApiFnMoveTaxonomyMutation(baseOptions?: Apollo.MutationHookOptions<ApiFnMoveTaxonomyMutation, ApiFnMoveTaxonomyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiFnMoveTaxonomyMutation, ApiFnMoveTaxonomyMutationVariables>(ApiFnMoveTaxonomyDocument, options);
      }
export type ApiFnMoveTaxonomyMutationHookResult = ReturnType<typeof useApiFnMoveTaxonomyMutation>;
export type ApiFnMoveTaxonomyMutationResult = Apollo.MutationResult<ApiFnMoveTaxonomyMutation>;
export type ApiFnMoveTaxonomyMutationOptions = Apollo.BaseMutationOptions<ApiFnMoveTaxonomyMutation, ApiFnMoveTaxonomyMutationVariables>;
export const ApiAllImageTagsDocument = gql`
    query apiAllImageTags {
  image_tag(distinct_on: tag) {
    tag
    image_id
  }
}
    `;

/**
 * __useApiAllImageTagsQuery__
 *
 * To run a query within a React component, call `useApiAllImageTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllImageTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllImageTagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllImageTagsQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllImageTagsQuery, ApiAllImageTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllImageTagsQuery, ApiAllImageTagsQueryVariables>(ApiAllImageTagsDocument, options);
      }
export function useApiAllImageTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllImageTagsQuery, ApiAllImageTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllImageTagsQuery, ApiAllImageTagsQueryVariables>(ApiAllImageTagsDocument, options);
        }
export type ApiAllImageTagsQueryHookResult = ReturnType<typeof useApiAllImageTagsQuery>;
export type ApiAllImageTagsLazyQueryHookResult = ReturnType<typeof useApiAllImageTagsLazyQuery>;
export type ApiAllImageTagsQueryResult = Apollo.QueryResult<ApiAllImageTagsQuery, ApiAllImageTagsQueryVariables>;
export const ApiAddImageTagDocument = gql`
    mutation apiAddImageTag($image_id: uuid!, $tag: String!) {
  insert_image_tag_one(object: {image_id: $image_id, tag: $tag}) {
    image_id
    tag
  }
}
    `;
export type ApiAddImageTagMutationFn = Apollo.MutationFunction<ApiAddImageTagMutation, ApiAddImageTagMutationVariables>;

/**
 * __useApiAddImageTagMutation__
 *
 * To run a mutation, you first call `useApiAddImageTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddImageTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddImageTagMutation, { data, loading, error }] = useApiAddImageTagMutation({
 *   variables: {
 *      image_id: // value for 'image_id'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useApiAddImageTagMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddImageTagMutation, ApiAddImageTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddImageTagMutation, ApiAddImageTagMutationVariables>(ApiAddImageTagDocument, options);
      }
export type ApiAddImageTagMutationHookResult = ReturnType<typeof useApiAddImageTagMutation>;
export type ApiAddImageTagMutationResult = Apollo.MutationResult<ApiAddImageTagMutation>;
export type ApiAddImageTagMutationOptions = Apollo.BaseMutationOptions<ApiAddImageTagMutation, ApiAddImageTagMutationVariables>;
export const ApiReplaceImageTagsDocument = gql`
    mutation apiReplaceImageTags($imageId: uuid!, $tags: [image_tag_insert_input!]!) {
  delete_image_tag(where: {image_id: {_eq: $imageId}}) {
    affected_rows
  }
  insert_image_tag(objects: $tags) {
    affected_rows
  }
}
    `;
export type ApiReplaceImageTagsMutationFn = Apollo.MutationFunction<ApiReplaceImageTagsMutation, ApiReplaceImageTagsMutationVariables>;

/**
 * __useApiReplaceImageTagsMutation__
 *
 * To run a mutation, you first call `useApiReplaceImageTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiReplaceImageTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiReplaceImageTagsMutation, { data, loading, error }] = useApiReplaceImageTagsMutation({
 *   variables: {
 *      imageId: // value for 'imageId'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useApiReplaceImageTagsMutation(baseOptions?: Apollo.MutationHookOptions<ApiReplaceImageTagsMutation, ApiReplaceImageTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiReplaceImageTagsMutation, ApiReplaceImageTagsMutationVariables>(ApiReplaceImageTagsDocument, options);
      }
export type ApiReplaceImageTagsMutationHookResult = ReturnType<typeof useApiReplaceImageTagsMutation>;
export type ApiReplaceImageTagsMutationResult = Apollo.MutationResult<ApiReplaceImageTagsMutation>;
export type ApiReplaceImageTagsMutationOptions = Apollo.BaseMutationOptions<ApiReplaceImageTagsMutation, ApiReplaceImageTagsMutationVariables>;
export const ApiDeleteImageTagDocument = gql`
    mutation apiDeleteImageTag($image_id: uuid!, $tag: String!) {
  delete_image_tag_by_pk(image_id: $image_id, tag: $tag) {
    image_id
    tag
  }
}
    `;
export type ApiDeleteImageTagMutationFn = Apollo.MutationFunction<ApiDeleteImageTagMutation, ApiDeleteImageTagMutationVariables>;

/**
 * __useApiDeleteImageTagMutation__
 *
 * To run a mutation, you first call `useApiDeleteImageTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteImageTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteImageTagMutation, { data, loading, error }] = useApiDeleteImageTagMutation({
 *   variables: {
 *      image_id: // value for 'image_id'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useApiDeleteImageTagMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteImageTagMutation, ApiDeleteImageTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteImageTagMutation, ApiDeleteImageTagMutationVariables>(ApiDeleteImageTagDocument, options);
      }
export type ApiDeleteImageTagMutationHookResult = ReturnType<typeof useApiDeleteImageTagMutation>;
export type ApiDeleteImageTagMutationResult = Apollo.MutationResult<ApiDeleteImageTagMutation>;
export type ApiDeleteImageTagMutationOptions = Apollo.BaseMutationOptions<ApiDeleteImageTagMutation, ApiDeleteImageTagMutationVariables>;
export const ApiAllImageExtensionsDocument = gql`
    query apiAllImageExtensions {
  image_file_extension {
    extension
  }
}
    `;

/**
 * __useApiAllImageExtensionsQuery__
 *
 * To run a query within a React component, call `useApiAllImageExtensionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllImageExtensionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllImageExtensionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllImageExtensionsQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllImageExtensionsQuery, ApiAllImageExtensionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllImageExtensionsQuery, ApiAllImageExtensionsQueryVariables>(ApiAllImageExtensionsDocument, options);
      }
export function useApiAllImageExtensionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllImageExtensionsQuery, ApiAllImageExtensionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllImageExtensionsQuery, ApiAllImageExtensionsQueryVariables>(ApiAllImageExtensionsDocument, options);
        }
export type ApiAllImageExtensionsQueryHookResult = ReturnType<typeof useApiAllImageExtensionsQuery>;
export type ApiAllImageExtensionsLazyQueryHookResult = ReturnType<typeof useApiAllImageExtensionsLazyQuery>;
export type ApiAllImageExtensionsQueryResult = Apollo.QueryResult<ApiAllImageExtensionsQuery, ApiAllImageExtensionsQueryVariables>;
export const ApiAddTaxonomyWidgetDocument = gql`
    mutation apiAddTaxonomyWidget($taxonomy_id: bigint!, $widget_type_id: String!, $widget_data: jsonb!) {
  insert_taxonomy_widget_one(
    object: {taxonomy_id: $taxonomy_id, widget_type_id: $widget_type_id, widget_data: $widget_data}
  ) {
    taxonomy_id
    widget_type_id
    widget_data
  }
}
    `;
export type ApiAddTaxonomyWidgetMutationFn = Apollo.MutationFunction<ApiAddTaxonomyWidgetMutation, ApiAddTaxonomyWidgetMutationVariables>;

/**
 * __useApiAddTaxonomyWidgetMutation__
 *
 * To run a mutation, you first call `useApiAddTaxonomyWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddTaxonomyWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddTaxonomyWidgetMutation, { data, loading, error }] = useApiAddTaxonomyWidgetMutation({
 *   variables: {
 *      taxonomy_id: // value for 'taxonomy_id'
 *      widget_type_id: // value for 'widget_type_id'
 *      widget_data: // value for 'widget_data'
 *   },
 * });
 */
export function useApiAddTaxonomyWidgetMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddTaxonomyWidgetMutation, ApiAddTaxonomyWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddTaxonomyWidgetMutation, ApiAddTaxonomyWidgetMutationVariables>(ApiAddTaxonomyWidgetDocument, options);
      }
export type ApiAddTaxonomyWidgetMutationHookResult = ReturnType<typeof useApiAddTaxonomyWidgetMutation>;
export type ApiAddTaxonomyWidgetMutationResult = Apollo.MutationResult<ApiAddTaxonomyWidgetMutation>;
export type ApiAddTaxonomyWidgetMutationOptions = Apollo.BaseMutationOptions<ApiAddTaxonomyWidgetMutation, ApiAddTaxonomyWidgetMutationVariables>;
export const ApiDeleteTaxonomyWidgetDocument = gql`
    mutation apiDeleteTaxonomyWidget($taxonomy_id: bigint!, $widget_type_id: String!) {
  delete_taxonomy_widget(
    where: {taxonomy_id: {_eq: $taxonomy_id}, widget_type_id: {_eq: $widget_type_id}}
  ) {
    affected_rows
  }
}
    `;
export type ApiDeleteTaxonomyWidgetMutationFn = Apollo.MutationFunction<ApiDeleteTaxonomyWidgetMutation, ApiDeleteTaxonomyWidgetMutationVariables>;

/**
 * __useApiDeleteTaxonomyWidgetMutation__
 *
 * To run a mutation, you first call `useApiDeleteTaxonomyWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteTaxonomyWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteTaxonomyWidgetMutation, { data, loading, error }] = useApiDeleteTaxonomyWidgetMutation({
 *   variables: {
 *      taxonomy_id: // value for 'taxonomy_id'
 *      widget_type_id: // value for 'widget_type_id'
 *   },
 * });
 */
export function useApiDeleteTaxonomyWidgetMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteTaxonomyWidgetMutation, ApiDeleteTaxonomyWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteTaxonomyWidgetMutation, ApiDeleteTaxonomyWidgetMutationVariables>(ApiDeleteTaxonomyWidgetDocument, options);
      }
export type ApiDeleteTaxonomyWidgetMutationHookResult = ReturnType<typeof useApiDeleteTaxonomyWidgetMutation>;
export type ApiDeleteTaxonomyWidgetMutationResult = Apollo.MutationResult<ApiDeleteTaxonomyWidgetMutation>;
export type ApiDeleteTaxonomyWidgetMutationOptions = Apollo.BaseMutationOptions<ApiDeleteTaxonomyWidgetMutation, ApiDeleteTaxonomyWidgetMutationVariables>;
export const ApiChangeTaxonomyWidgetDocument = gql`
    mutation apiChangeTaxonomyWidget($taxonomy_id: bigint!, $widget_type_id: String!, $widget_data: jsonb!) {
  update_taxonomy_widget(
    where: {taxonomy_id: {_eq: $taxonomy_id}, widget_type_id: {_eq: $widget_type_id}}
    _set: {widget_data: $widget_data}
  ) {
    affected_rows
  }
}
    `;
export type ApiChangeTaxonomyWidgetMutationFn = Apollo.MutationFunction<ApiChangeTaxonomyWidgetMutation, ApiChangeTaxonomyWidgetMutationVariables>;

/**
 * __useApiChangeTaxonomyWidgetMutation__
 *
 * To run a mutation, you first call `useApiChangeTaxonomyWidgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeTaxonomyWidgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeTaxonomyWidgetMutation, { data, loading, error }] = useApiChangeTaxonomyWidgetMutation({
 *   variables: {
 *      taxonomy_id: // value for 'taxonomy_id'
 *      widget_type_id: // value for 'widget_type_id'
 *      widget_data: // value for 'widget_data'
 *   },
 * });
 */
export function useApiChangeTaxonomyWidgetMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeTaxonomyWidgetMutation, ApiChangeTaxonomyWidgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeTaxonomyWidgetMutation, ApiChangeTaxonomyWidgetMutationVariables>(ApiChangeTaxonomyWidgetDocument, options);
      }
export type ApiChangeTaxonomyWidgetMutationHookResult = ReturnType<typeof useApiChangeTaxonomyWidgetMutation>;
export type ApiChangeTaxonomyWidgetMutationResult = Apollo.MutationResult<ApiChangeTaxonomyWidgetMutation>;
export type ApiChangeTaxonomyWidgetMutationOptions = Apollo.BaseMutationOptions<ApiChangeTaxonomyWidgetMutation, ApiChangeTaxonomyWidgetMutationVariables>;
export const ApiAllFeedbackDocument = gql`
    query apiAllFeedback($where: feedback_bool_exp!, $where_aggregate: feedback_bool_exp!, $limit: Int!) {
  feedback(where: $where, order_by: {id: desc}, limit: $limit) {
    id
    status
    email
    message
    created_at
    feedback_taxonomy_references {
      taxonomy {
        id
        common_name
        scientific_name
      }
    }
  }
  feedback_aggregate(where: $where_aggregate) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useApiAllFeedbackQuery__
 *
 * To run a query within a React component, call `useApiAllFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllFeedbackQuery({
 *   variables: {
 *      where: // value for 'where'
 *      where_aggregate: // value for 'where_aggregate'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useApiAllFeedbackQuery(baseOptions: Apollo.QueryHookOptions<ApiAllFeedbackQuery, ApiAllFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllFeedbackQuery, ApiAllFeedbackQueryVariables>(ApiAllFeedbackDocument, options);
      }
export function useApiAllFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllFeedbackQuery, ApiAllFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllFeedbackQuery, ApiAllFeedbackQueryVariables>(ApiAllFeedbackDocument, options);
        }
export type ApiAllFeedbackQueryHookResult = ReturnType<typeof useApiAllFeedbackQuery>;
export type ApiAllFeedbackLazyQueryHookResult = ReturnType<typeof useApiAllFeedbackLazyQuery>;
export type ApiAllFeedbackQueryResult = Apollo.QueryResult<ApiAllFeedbackQuery, ApiAllFeedbackQueryVariables>;
export const ApiSingleFeedbackDocument = gql`
    query apiSingleFeedback($id: bigint!) {
  feedback_by_pk(id: $id) {
    id
    status
    email
    message
    created_at
    metadata
    feedback_taxonomy_references {
      taxonomy {
        id
        common_name
        scientific_name
      }
    }
    feedback_comments(order_by: {id: asc}) {
      id
      message
      created_by {
        id
        email
      }
      created_at
    }
  }
}
    `;

/**
 * __useApiSingleFeedbackQuery__
 *
 * To run a query within a React component, call `useApiSingleFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiSingleFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiSingleFeedbackQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiSingleFeedbackQuery(baseOptions: Apollo.QueryHookOptions<ApiSingleFeedbackQuery, ApiSingleFeedbackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiSingleFeedbackQuery, ApiSingleFeedbackQueryVariables>(ApiSingleFeedbackDocument, options);
      }
export function useApiSingleFeedbackLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiSingleFeedbackQuery, ApiSingleFeedbackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiSingleFeedbackQuery, ApiSingleFeedbackQueryVariables>(ApiSingleFeedbackDocument, options);
        }
export type ApiSingleFeedbackQueryHookResult = ReturnType<typeof useApiSingleFeedbackQuery>;
export type ApiSingleFeedbackLazyQueryHookResult = ReturnType<typeof useApiSingleFeedbackLazyQuery>;
export type ApiSingleFeedbackQueryResult = Apollo.QueryResult<ApiSingleFeedbackQuery, ApiSingleFeedbackQueryVariables>;
export const ApiChangeFeedbackStatusDocument = gql`
    mutation apiChangeFeedbackStatus($feedback_id: bigint!, $status: String!) {
  update_feedback_by_pk(pk_columns: {id: $feedback_id}, _set: {status: $status}) {
    id
    status
  }
}
    `;
export type ApiChangeFeedbackStatusMutationFn = Apollo.MutationFunction<ApiChangeFeedbackStatusMutation, ApiChangeFeedbackStatusMutationVariables>;

/**
 * __useApiChangeFeedbackStatusMutation__
 *
 * To run a mutation, you first call `useApiChangeFeedbackStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiChangeFeedbackStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiChangeFeedbackStatusMutation, { data, loading, error }] = useApiChangeFeedbackStatusMutation({
 *   variables: {
 *      feedback_id: // value for 'feedback_id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useApiChangeFeedbackStatusMutation(baseOptions?: Apollo.MutationHookOptions<ApiChangeFeedbackStatusMutation, ApiChangeFeedbackStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiChangeFeedbackStatusMutation, ApiChangeFeedbackStatusMutationVariables>(ApiChangeFeedbackStatusDocument, options);
      }
export type ApiChangeFeedbackStatusMutationHookResult = ReturnType<typeof useApiChangeFeedbackStatusMutation>;
export type ApiChangeFeedbackStatusMutationResult = Apollo.MutationResult<ApiChangeFeedbackStatusMutation>;
export type ApiChangeFeedbackStatusMutationOptions = Apollo.BaseMutationOptions<ApiChangeFeedbackStatusMutation, ApiChangeFeedbackStatusMutationVariables>;
export const ApiAddFeedbackCommentDocument = gql`
    mutation apiAddFeedbackComment($feedback_id: bigint!, $message: String!) {
  insert_feedback_comment_one(
    object: {feedback_id: $feedback_id, message: $message}
  ) {
    id
    feedback_id
    message
    created_by {
      id
      email
    }
    created_at
  }
}
    `;
export type ApiAddFeedbackCommentMutationFn = Apollo.MutationFunction<ApiAddFeedbackCommentMutation, ApiAddFeedbackCommentMutationVariables>;

/**
 * __useApiAddFeedbackCommentMutation__
 *
 * To run a mutation, you first call `useApiAddFeedbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiAddFeedbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiAddFeedbackCommentMutation, { data, loading, error }] = useApiAddFeedbackCommentMutation({
 *   variables: {
 *      feedback_id: // value for 'feedback_id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useApiAddFeedbackCommentMutation(baseOptions?: Apollo.MutationHookOptions<ApiAddFeedbackCommentMutation, ApiAddFeedbackCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiAddFeedbackCommentMutation, ApiAddFeedbackCommentMutationVariables>(ApiAddFeedbackCommentDocument, options);
      }
export type ApiAddFeedbackCommentMutationHookResult = ReturnType<typeof useApiAddFeedbackCommentMutation>;
export type ApiAddFeedbackCommentMutationResult = Apollo.MutationResult<ApiAddFeedbackCommentMutation>;
export type ApiAddFeedbackCommentMutationOptions = Apollo.BaseMutationOptions<ApiAddFeedbackCommentMutation, ApiAddFeedbackCommentMutationVariables>;
export const ApiDeleteFeedbackCommentDocument = gql`
    mutation apiDeleteFeedbackComment($id: bigint!) {
  delete_feedback_comment(where: {id: {_eq: $id}}) {
    affected_rows
  }
}
    `;
export type ApiDeleteFeedbackCommentMutationFn = Apollo.MutationFunction<ApiDeleteFeedbackCommentMutation, ApiDeleteFeedbackCommentMutationVariables>;

/**
 * __useApiDeleteFeedbackCommentMutation__
 *
 * To run a mutation, you first call `useApiDeleteFeedbackCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiDeleteFeedbackCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiDeleteFeedbackCommentMutation, { data, loading, error }] = useApiDeleteFeedbackCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiDeleteFeedbackCommentMutation(baseOptions?: Apollo.MutationHookOptions<ApiDeleteFeedbackCommentMutation, ApiDeleteFeedbackCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiDeleteFeedbackCommentMutation, ApiDeleteFeedbackCommentMutationVariables>(ApiDeleteFeedbackCommentDocument, options);
      }
export type ApiDeleteFeedbackCommentMutationHookResult = ReturnType<typeof useApiDeleteFeedbackCommentMutation>;
export type ApiDeleteFeedbackCommentMutationResult = Apollo.MutationResult<ApiDeleteFeedbackCommentMutation>;
export type ApiDeleteFeedbackCommentMutationOptions = Apollo.BaseMutationOptions<ApiDeleteFeedbackCommentMutation, ApiDeleteFeedbackCommentMutationVariables>;
export const ApiHomeStatisticsDocument = gql`
    query apiHomeStatistics($time: timestamptz!) {
  feedback_open: feedback_aggregate(where: {status: {_eq: "open"}}) {
    aggregate {
      count
    }
  }
  feedback_closed: feedback_aggregate(where: {status: {_eq: "closed"}}) {
    aggregate {
      count
    }
  }
  feedback_since: feedback_aggregate(where: {created_at: {_gte: $time}}) {
    aggregate {
      count
    }
  }
  taxonomy_aggregate {
    aggregate {
      count
    }
  }
  taxonomy_article_aggregate {
    aggregate {
      count
    }
  }
  image_aggregate {
    aggregate {
      count
    }
  }
  taxonomy_image_aggregate {
    aggregate {
      count
    }
  }
  taxonomy_common_name_aggregate {
    aggregate {
      count
    }
  }
  taxonomy_scientific_name_aggregate {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useApiHomeStatisticsQuery__
 *
 * To run a query within a React component, call `useApiHomeStatisticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiHomeStatisticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiHomeStatisticsQuery({
 *   variables: {
 *      time: // value for 'time'
 *   },
 * });
 */
export function useApiHomeStatisticsQuery(baseOptions: Apollo.QueryHookOptions<ApiHomeStatisticsQuery, ApiHomeStatisticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiHomeStatisticsQuery, ApiHomeStatisticsQueryVariables>(ApiHomeStatisticsDocument, options);
      }
export function useApiHomeStatisticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiHomeStatisticsQuery, ApiHomeStatisticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiHomeStatisticsQuery, ApiHomeStatisticsQueryVariables>(ApiHomeStatisticsDocument, options);
        }
export type ApiHomeStatisticsQueryHookResult = ReturnType<typeof useApiHomeStatisticsQuery>;
export type ApiHomeStatisticsLazyQueryHookResult = ReturnType<typeof useApiHomeStatisticsLazyQuery>;
export type ApiHomeStatisticsQueryResult = Apollo.QueryResult<ApiHomeStatisticsQuery, ApiHomeStatisticsQueryVariables>;
export const ApiAllImgProxyPresetsDocument = gql`
    query apiAllImgProxyPresets {
  imgproxy_preset {
    name
    processing_opts
  }
}
    `;

/**
 * __useApiAllImgProxyPresetsQuery__
 *
 * To run a query within a React component, call `useApiAllImgProxyPresetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiAllImgProxyPresetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiAllImgProxyPresetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiAllImgProxyPresetsQuery(baseOptions?: Apollo.QueryHookOptions<ApiAllImgProxyPresetsQuery, ApiAllImgProxyPresetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiAllImgProxyPresetsQuery, ApiAllImgProxyPresetsQueryVariables>(ApiAllImgProxyPresetsDocument, options);
      }
export function useApiAllImgProxyPresetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiAllImgProxyPresetsQuery, ApiAllImgProxyPresetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiAllImgProxyPresetsQuery, ApiAllImgProxyPresetsQueryVariables>(ApiAllImgProxyPresetsDocument, options);
        }
export type ApiAllImgProxyPresetsQueryHookResult = ReturnType<typeof useApiAllImgProxyPresetsQuery>;
export type ApiAllImgProxyPresetsLazyQueryHookResult = ReturnType<typeof useApiAllImgProxyPresetsLazyQuery>;
export type ApiAllImgProxyPresetsQueryResult = Apollo.QueryResult<ApiAllImgProxyPresetsQuery, ApiAllImgProxyPresetsQueryVariables>;