export interface UploadImage {
  file: File;
  dataUrl: string;
}

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const toUploadImage = (file: File): Promise<UploadImage> =>
  new Promise(async (resolve, reject) => {
    try {
      const dataUrl = await toBase64(file);
      resolve({ file, dataUrl });
    } catch (e) {
      console.error('Error reading file');
      reject(e);
    }
  });

export const fileListToUploadImages = (files: FileList | null): Promise<UploadImage[]> => {
  let fileArr = files !== null ? Array.from(files) : [];
  return Promise.all(fileArr.map(toUploadImage));
};
