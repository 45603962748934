import React, { useMemo, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { ImageList } from '../components/ImageList';
import { ImageUpload } from '../components/ImageUpload';
import { Tablinks } from '../components/Tablinks';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Image_Bool_Exp, useApiImagesQuery } from '../api/generated/graphql';
import { useDebounce } from 'use-debounce';
import { validate as uuidValidate } from 'uuid';

export function ImageManagement() {
  const match = useRouteMatch();
  const linkPath = useMemo(() => match.path.replace('/*', ''), [match]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [tagTerms, setTagTerms] = useState<any[]>([]);
  const [searchTerm] = useDebounce(searchValue, 400);
  const searchType: Image_Bool_Exp = uuidValidate(searchTerm)
    ? { id: { _eq: searchTerm } }
    : tagTerms.length > 0
    ? { filename: { _ilike: `%${searchTerm}%` }, image_tags: { tag: { _in: tagTerms } } }
    : { filename: { _ilike: `%${searchTerm}%` } };
  const { data, loading, refetch } = useApiImagesQuery({
    variables: {
      where: searchType,
      limit: 30,
    },
  });

  const handleSearch = (text: string) => {
    setSearchValue(text);
  };

  const handleTagSearch = (tags: any) => {
    setTagTerms(tags.map((tag: any) => tag.value));
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="pagecontainer">
        <Tablinks
          links={[
            { link: `imagemanagement/imagelist`, title: 'Images' },
            { link: `imagemanagement/imageupload`, title: 'Upload images' },
          ]}
        />
        <Switch>
          <Route exact path={[`${linkPath}/imagelist`, `${linkPath}`]}>
            <ImageList
              data={data}
              loading={loading}
              refetch={refetch}
              handleSearch={handleSearch}
              searchValue={searchValue}
              handleTagSearch={handleTagSearch}
              tagValue={tagTerms}
            />
          </Route>
          <Route exact path={`${linkPath}/imageupload`}>
            <ImageUpload refetch={refetch} />
          </Route>
        </Switch>
      </div>
    </DndProvider>
  );
}
