import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Table from 'rc-table';
import { ColumnsType } from 'rc-table/lib/interface';
import { ApiSingleTaxonomyDocument, useApiChangeTaxonomyScientificNamesMutation } from '../api/generated/graphql';
import { ReactComponent as Delete } from '../assets/delete.svg';

interface Props {
  taxonomyId: number;
  value: { name: string; current: boolean }[];
}

interface TableRow {
  key: string;
  name: string;
  current: boolean;
}

export const ScientificNames: FC<Props> = ({ taxonomyId, value }) => {
  const [change, changeStatus] = useApiChangeTaxonomyScientificNamesMutation();

  const [tableData, setTableData] = useState<TableRow[] | undefined>(undefined);

  useEffect(() => {
    if (tableData === undefined) {
      setTableData(value.map((x, index) => ({ ...x, key: index.toString() })));
    }
  }, [value, tableData]);

  const handleSave = async () => {
    if (!tableData) return;
    try {
      await change({
        variables: {
          id: taxonomyId,
          values: tableData.map((x) => ({ name: x.name, current: x.current, taxonomy_id: taxonomyId })),
        },
        refetchQueries: [{ query: ApiSingleTaxonomyDocument, variables: { id: taxonomyId } }],
      });
      toast.success('Save successful!');
    } catch {
      toast.error('Save failed!');
    }
  };

  const data = tableData ?? [];

  const hasCurrent = data.some((x) => x.current);
  const hasEmptyNames = data.some((x) => x.name.length === 0);
  const hasDuplicates = new Set(data.map((x) => x.name)).size === data.length;

  const columns: ColumnsType<TableRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 350,
      render: (value, row, index) => (
        <input
          id={`scientific-name-${index}`}
          type="text"
          className="lp-input-text mt-1"
          value={value}
          onChange={(e) => {
            setTableData(
              tableData?.map((x, thisIndex) => (index === thisIndex ? { ...x, name: e.target.value } : x)) ?? []
            );
          }}
        />
      ),
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
      width: 50,
      render: (value, row, index) => (
        <label className="p-4 block">
          <input
            name="current"
            type="radio"
            className="lp-input-radio"
            checked={value}
            onChange={(e) =>
              setTableData(
                tableData?.map((x, thisIndex) =>
                  index === thisIndex ? { ...x, current: e.target.checked } : { ...x, current: false }
                )
              )
            }
          />
        </label>
      ),
    },
    {
      title: 'Operations',
      width: 50,
      key: 'operations',
      render: (_value, _row, index) => (
        <button
          type="button"
          className="h-8 w-8 flex items-center"
          onClick={() => {
            const newTableData = tableData?.slice() ?? [];
            newTableData.splice(index, 1);
            setTableData(newTableData);
          }}
        >
          <Delete />
        </button>
      ),
    },
  ];

  return (
    <div className="w-full md:max-w-sm space-y-4">
      <button
        className="lp-button mb-4"
        type="button"
        disabled={false}
        onClick={() => {
          const data = tableData ?? [];
          setTableData([...data, { key: data.length.toString(), name: '', current: data.every((x) => !x.current) }]);
        }}
      >
        Add new scientific name
      </button>

      <Table className="lp-table" columns={columns} data={tableData} />

      <div>
        {!hasCurrent && <span className="text-red-500 text-sm">Select one name to be current. </span>}
        {hasEmptyNames && <span className="text-red-500 text-sm">Fill all names. </span>}
        {!hasDuplicates && <span className="text-red-500 text-sm">Remove duplicate names. </span>}
      </div>

      <button
        type="button"
        disabled={changeStatus.loading || !hasCurrent || hasEmptyNames || !hasDuplicates}
        className="lp-button flex ml-auto"
        onClick={handleSave}
      >
        Save
      </button>
    </div>
  );
};
