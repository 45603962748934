import { useApiHomeStatisticsQuery } from '../api/generated/graphql';
import { Loader } from 'react-feather';
import Select from 'react-select';
import { useState } from 'react';
import { addHours, addDays, startOfDay, startOfHour } from 'date-fns';

const PERIODS = {
  last_24_hours: {
    label: 'Last 24 hours',
    createStart: () => startOfHour(addHours(new Date(), -24)).toISOString(),
  },
  last_7_days: {
    label: 'Last 7 days',
    createStart: () => startOfDay(addDays(new Date(), -7)).toISOString(),
  },
  last_30_days: {
    label: 'Last 30 days',
    createStart: () => startOfDay(addDays(new Date(), -30)).toISOString(),
  },
  last_3_months: {
    label: 'Last 90 days',
    createStart: () => startOfDay(addDays(new Date(), -90)).toISOString(),
  },
} as const;

const ALL_PERIODS = Object.keys(PERIODS) as Period[];

type Period = keyof typeof PERIODS;

export function Home() {
  const [period, setPeriod] = useState<Period>('last_7_days');

  const query = useApiHomeStatisticsQuery({
    variables: { time: PERIODS[period].createStart() },
    fetchPolicy: 'cache-and-network',
  });

  if (query.data) {
    const data = query.data;
    const last7DaysStats = [{ name: 'Feedback', stat: data.feedback_since.aggregate?.count }];

    const alltimeStats = [
      { name: 'Feedback (open)', stat: data.feedback_open.aggregate?.count },
      { name: 'Feedback (closed)', stat: data.feedback_closed.aggregate?.count },
      { name: 'Taxonomies', stat: data?.taxonomy_aggregate.aggregate?.count },
      { name: 'Articles', stat: data?.taxonomy_article_aggregate.aggregate?.count },
      { name: 'All images', stat: data?.image_aggregate.aggregate?.count },
      { name: 'Taxonomy images', stat: data?.taxonomy_image_aggregate.aggregate?.count },
      { name: 'Taxonomy scientific names', stat: data?.taxonomy_scientific_name_aggregate.aggregate?.count },
      { name: 'Taxonomy common names', stat: data?.taxonomy_common_name_aggregate.aggregate?.count },
    ];
    return (
      <div className="lp-page">
        <Select
          className="w-64"
          options={ALL_PERIODS.map((period) => ({
            label: PERIODS[period].label,
            value: period,
          }))}
          isClearable={false}
          isSearchable={false}
          value={{ label: PERIODS[period].label, value: period }}
          onChange={(e) => setPeriod(e!.value)}
        />
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {last7DaysStats.map((item) => (
            <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
            </div>
          ))}
        </dl>
        <h3 className="text-lg leading-6 font-medium text-gray-900 mt-8">All time</h3>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {alltimeStats.map((item) => (
            <div key={item.name} className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">{item.stat}</dd>
            </div>
          ))}
        </dl>
      </div>
    );
  } else if (query.loading) {
    return <Loader className="animate-spin" />;
  } else {
    return <div />;
  }
}
