import { FC, useEffect, useState } from 'react';
import Table from 'rc-table';
import {
  ApiAllPagesQuery,
  useApiAddPageMutation,
  useApiAllLanguageQuery,
  useApiAllPagesQuery,
  useApiDeletePageMutation,
  useApiUpdatePageMutation,
} from '../../api/generated/graphql';
import { ColumnsType } from 'rc-table/lib/interface';
import { toast } from 'react-toastify';
import { SlideOver } from '../SlideOver';
import { TLink } from '../TLink';

interface TableRow {
  key: string;
  id: number;
  name: string;
  description: string | null | undefined;
  published: string;
}

type ContentPage = ApiAllPagesQuery['page'][0];

export const ContentPageList: FC = () => {
  const [tableData, settableData] = useState<TableRow[]>([]);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [selectedPage, setSelectedPage] = useState<ContentPage | null | undefined>(null);

  const languagesQuery = useApiAllLanguageQuery();
  const languages = languagesQuery.data?.language ?? [];

  const { data, loading, refetch } = useApiAllPagesQuery();
  const [addPage, addMutationStatus] = useApiAddPageMutation();
  const [deletePage, deleteMutationStatus] = useApiDeletePageMutation();
  const [updatePage, updateMutationStatus] = useApiUpdatePageMutation();

  useEffect(() => {
    if (!data) {
      return;
    }
    settableData(
      data.page.map((x) => {
        return {
          key: x.id.toString(),
          id: x.id,
          name: x.name,
          description: x.admin_description,
          published: x.published ? 'Yes' : 'No',
        };
      })
    );
  }, [data]);
  const columns: ColumnsType<TableRow> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Published',
      dataIndex: 'published',
      key: 'published',
    },
    {
      title: 'Operations',
      dataIndex: '',
      key: 'operations',
      render: (_value, _row, index) => (
        <div className="flex">
          <button
            type="button"
            className="h-8 w-8 flex items-center mr-4"
            onClick={() => {
              setSelectedPage(data?.page.find((x) => x.id === _row.id));
              setSlideOverOpen(true);
            }}
          >
            Edit
          </button>
          <TLink type="button" className="h-8 w-8 flex items-center" to={`contentpages/${_row.id}`}>
            Open
          </TLink>
        </div>
      ),
    },
  ];

  const handlePageAdd = async () => {
    try {
      await addPage({
        variables: {
          name,
          admin_description: description,
        },
      });
      setDescription('');
      await refetch();
      toast.success('Update successful!');
    } catch {
      toast.error('Update failed!');
    }
  };

  const handlePageDelete = async () => {
    if (!selectedPage?.id) {
      toast.error('Update failed!');
      return;
    }
    try {
      await deletePage({
        variables: {
          id: selectedPage?.id,
        },
      });
      await refetch();
      toast.success('Update successful!');
      setSlideOverOpen(false);
    } catch {
      toast.error('Update failed!');
    }
  };

  const handlePageUpdate = async () => {
    if (!selectedPage) {
      toast.error('Update failed!');
      return;
    }
    try {
      await updatePage({
        variables: {
          id: selectedPage.id,
          name: selectedPage.name,
          admin_description: selectedPage.admin_description ?? '',
          metadata_descriptions: selectedPage.metadata_descriptions,
          published: selectedPage.published,
          titles: selectedPage.titles,
          url_slugs: selectedPage.url_slugs,
        },
      });
      await refetch();
      toast.success('Update successful!');
    } catch {
      toast.error('Update failed!');
    }
  };

  return (
    <>
      <div className="controls mb-4">
        <div className="max-w-2xl flex flex-row items-center">
          <span className="pageaddheader mr-2">Add a new Content Page</span>
          <input
            className="lp-input-text my-2 mr-2"
            type="text"
            value={name}
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="lp-input-text my-2 mr-2"
            type="text"
            value={description}
            placeholder="Description"
            onChange={(e) => setDescription(e.target.value)}
          />
          <button
            className="lp-button"
            type="button"
            onClick={() => handlePageAdd()}
            disabled={!description || addMutationStatus.loading}
          >
            Add
          </button>
        </div>
      </div>
      <div className="lp-table-container">
        {tableData && !loading && tableData.length > 0 && (
          <Table className="lp-table" columns={columns} data={tableData} />
        )}
      </div>

      <SlideOver
        show={slideOverOpen}
        title={selectedPage?.admin_description ? selectedPage.admin_description : ''}
        saving={updateMutationStatus.loading}
        deleting={deleteMutationStatus.loading}
        onCancelClick={() => {
          setSelectedPage(null);
          return setSlideOverOpen(false);
        }}
        onDeleteClick={() => {
          // eslint-disable-next-line no-restricted-globals
          if (confirm('Are you sure you want to delete the page?')) {
            handlePageDelete();
          } else {
            return;
          }
        }}
        onSaveClick={() => handlePageUpdate()}
      >
        <div className="space-y-6 pt-6 pb-5">
          <div className="space-y-3">
            <label className="lp-input-text-label" htmlFor={description}>
              Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className="lp-input-text mt-1 w-full"
              value={selectedPage?.name ?? ''}
              onChange={(e) => setSelectedPage({ ...selectedPage, name: e.target.value } as ContentPage)}
            />
            <label className="lp-input-text-label" htmlFor={description}>
              Description
            </label>
            <input
              id="description"
              name="description"
              type="text"
              className="lp-input-text mt-1 w-full"
              value={selectedPage?.admin_description ?? ''}
              onChange={(e) => setSelectedPage({ ...selectedPage, admin_description: e.target.value } as ContentPage)}
            />
            <div className="flex items-center mt-2">
              <input
                id="published"
                name="published"
                type="checkbox"
                className="lp-input-checkbox"
                checked={selectedPage?.published ?? false}
                onChange={(e) => {
                  setSelectedPage({ ...selectedPage, published: e.target.checked } as ContentPage);
                }}
              />
              <label className="font-sm ml-2" htmlFor="published">
                Published
              </label>
            </div>
          </div>
          <div className="space-y-3">
            <h2>Titles</h2>
            {languages.map((language) => (
              <div key={`titles_${language.key}`}>
                <label className="lp-input-text-label" htmlFor={`title_${language.key}`}>
                  {language.name}
                </label>
                <input
                  id={`title_${language.key}`}
                  name={`title_${language.key}`}
                  type="text"
                  className="lp-input-text mt-1 w-full"
                  value={selectedPage?.titles[language.key] ?? ''}
                  onChange={(e) =>
                    setSelectedPage({
                      ...selectedPage,
                      titles: { ...selectedPage?.titles, [language.key]: e.target.value },
                    } as ContentPage)
                  }
                />
              </div>
            ))}
          </div>
          <div className="space-y-3">
            <h2>URL Slugs</h2>
            {languages.map((language) => (
              <div key={`url_slugs_${language.key}`}>
                <label className="lp-input-text-label" htmlFor={`url_slug_${language.key}`}>
                  {language.name}
                </label>
                <input
                  id={`url_slug_${language.key}`}
                  name={`url_slug_${language.key}`}
                  type="text"
                  className="lp-input-text mt-1 w-full"
                  value={selectedPage?.url_slugs[language.key] ?? ''}
                  onChange={(e) =>
                    setSelectedPage({
                      ...selectedPage,
                      url_slugs: { ...selectedPage?.url_slugs, [language.key]: e.target.value },
                    } as ContentPage)
                  }
                />
              </div>
            ))}
          </div>
          <div className="space-y-3">
            <h2>Metadata descriptions</h2>
            {languages.map((language) => (
              <div key={`url_slugs_${language.key}`}>
                <label className="lp-input-text-label" htmlFor={`metadata_descriptions_${language.key}`}>
                  {language.name}
                </label>
                <textarea
                  id={`metadata_descriptions_${language.key}`}
                  name={`metadata_descriptions_${language.key}`}
                  className="lp-textarea h-32"
                  value={selectedPage?.metadata_descriptions[language.key] ?? ''}
                  onChange={(e) =>
                    setSelectedPage({
                      ...selectedPage,
                      metadata_descriptions: { ...selectedPage?.metadata_descriptions, [language.key]: e.target.value },
                    } as ContentPage)
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </SlideOver>
    </>
  );
};
