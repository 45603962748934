import { FC, useEffect, useState } from 'react';
import Table from 'rc-table';
import {
  useApiAddCharacteristicMutation,
  useApiAllLanguageQuery,
  useApiChangeCharacteristicMutation,
  useApiDeleteCharacteristicMutation,
  useApiAllCharacteristicsWithWhereQuery,
} from '../../api/generated/graphql';
import { ColumnsType } from 'rc-table/lib/interface';
import { toast } from 'react-toastify';
import { SlideOver } from '../SlideOver';
import { ImageSelect } from '../ImageSelect';
import { CharacteristicGroupSelect } from './CharacteristicGroupSelect';
import { withFallbackName } from '../../utils';
import { WidgetType } from '../../model';
import Select from 'react-select';
import { NamesEdit } from '../NamesEdit';
import { LanguagesPreview } from '../LanguagesPreview';

interface Props {}

interface TableRow {
  key: string;
  id: number;
  groupId: number | undefined;
  name: string;
  names: Record<string, string>;
  descriptions: Record<string, string>;
  ordernum: number;
  iconId: string | undefined;
  helpIconId: string | undefined;
  widgetType: string | undefined;
  groupWidgetType: string | undefined;
  widgetData: string | undefined;
}

interface NewCharacteristic {
  id: number | undefined;
  groupId: number | undefined;
  names: Record<string, string>;
  descriptions: Record<string, string>;
  ordernum: number;
  iconId: string | undefined;
  helpIconId: string | undefined;
  widgetType: string | undefined;
  groupWidgetType: string | undefined;
  widgetData: string | undefined;
}

const widgetOptions = [
  { value: WidgetType.MapAreaBalticSea, label: 'Baltic sea map' },
  { value: WidgetType.MapAreaBiotaProvinces, label: 'Biota provinces map' },
  { value: WidgetType.MapAreaFishes, label: 'Fishes map' },
  { value: WidgetType.MapAreaFungi, label: 'Fungi map' },
];

export const Characteristics: FC<Props> = () => {
  const [selectedGroup, setSelectedGroup] = useState<number | undefined>(undefined);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [newCharacteristic, setNewCharacteristic] = useState<NewCharacteristic>({
    descriptions: {},
    groupId: undefined,
    helpIconId: undefined,
    iconId: undefined,
    id: undefined,
    names: {},
    ordernum: 0,
    widgetType: undefined,
    groupWidgetType: undefined,
    widgetData: undefined,
  });

  const { data, loading, refetch } = useApiAllCharacteristicsWithWhereQuery({
    variables: {
      where:
        selectedGroup !== undefined
          ? { characteristic_group_id: { _eq: selectedGroup } }
          : { characteristic_group_id: { _is_null: true } },
    },
  });

  const languagesQuery = useApiAllLanguageQuery();
  const languages = languagesQuery.data?.language ?? [];

  const [add, addStatus] = useApiAddCharacteristicMutation();
  const [change, changeStatus] = useApiChangeCharacteristicMutation();
  const [delete_, deleteStatus] = useApiDeleteCharacteristicMutation();

  const tableData: TableRow[] =
    data?.characteristic.map((y) => {
      return {
        key: y.id.toString(),
        id: y.id,
        groupId: y.characteristic_group_id ?? undefined,
        name: withFallbackName(y),
        names: y.names,
        descriptions: y.descriptions,
        ordernum: y.ordernum,
        iconId: y.icon_id ?? undefined,
        helpIconId: y.help_icon_id ?? undefined,
        widgetType: y.widget_type && y.widget_type.length > 0 ? y.widget_type : undefined,
        groupWidgetType: y.characteristic_group?.widget_type ?? undefined,
        widgetData: y.widget_data ?? undefined,
      };
    }) ?? [];

  useEffect(() => {
    refetch();
  }, [refetch]);

  const columns: ColumnsType<TableRow> = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 30,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Name translations',
      dataIndex: '',
      key: 'nameTranslations',
      width: 200,
      render: (_value, row, _index) => <LanguagesPreview translations={row.names} />,
    },
    {
      title: 'Order',
      dataIndex: 'ordernum',
      key: 'ordernum',
      width: 60,
    },
    {
      title: 'Operations',
      dataIndex: '',
      key: 'operations',
      render: (value, row, index) => (
        <button
          onClick={() => {
            setNewCharacteristic({ ...row });
            setSlideOverOpen(true);
          }}
          className="lp-button-link"
        >
          Edit
        </button>
      ),
    },
  ];

  const handleCloseSlideOver = () => {
    setSlideOverOpen(false);
    setNewCharacteristic({
      id: undefined,
      groupId: undefined,
      names: {},
      descriptions: {},
      ordernum: 0,
      iconId: undefined,
      helpIconId: undefined,
      widgetType: undefined,
      groupWidgetType: undefined,
      widgetData: undefined,
    });
  };

  const handleAdd = async () => {
    try {
      await add({
        variables: {
          descriptions: newCharacteristic.descriptions,
          groupId: newCharacteristic.groupId,
          helpIconId: newCharacteristic.helpIconId,
          iconId: newCharacteristic.iconId,
          names: newCharacteristic.names,
          ordernum: newCharacteristic.ordernum,
          widgetType:
            newCharacteristic.widgetType && newCharacteristic.widgetType.length > 0
              ? newCharacteristic.widgetType
              : null,
          widgetData:
            newCharacteristic.widgetData && newCharacteristic.widgetData.length > 0
              ? newCharacteristic.widgetData
              : null,
        },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Add successful!');
    } catch {
      toast.error('Add failed!');
    }
  };

  const handleUpdate = async () => {
    if (newCharacteristic.id === undefined) return;
    try {
      await change({
        variables: {
          descriptions: newCharacteristic.descriptions,
          groupId: newCharacteristic.groupId,
          helpIconId: newCharacteristic.helpIconId,
          iconId: newCharacteristic.iconId,
          id: newCharacteristic.id,
          names: newCharacteristic.names,
          ordernum: newCharacteristic.ordernum,
          widgetType:
            newCharacteristic.widgetType && newCharacteristic.widgetType.length > 0
              ? newCharacteristic.widgetType
              : null,
          widgetData:
            newCharacteristic.widgetData && newCharacteristic.widgetData.length > 0
              ? newCharacteristic.widgetData
              : null,
        },
      });
      await refetch();
      handleCloseSlideOver();
      toast.success('Save successful!');
    } catch {
      toast.error('Save failed!');
    }
  };

  const handleDelete = async () => {
    if (newCharacteristic.id === undefined) {
      handleCloseSlideOver();
    } else {
      if (!window.confirm('Delete characteristic?')) return;
      try {
        await delete_({ variables: { id: newCharacteristic.id } });
        await refetch();
        handleCloseSlideOver();
        toast.success('Delete successful!');
      } catch {
        toast.error('Delete failed!');
      }
    }
  };

  return (
    <>
      <div className="w-full max-w-sm mb-4">
        <label className="lp-input-text-label" htmlFor="select-characteristic-group">
          1. Select group
        </label>
        <CharacteristicGroupSelect
          inputId="select-characteristic-group"
          value={selectedGroup}
          onChange={(value) => setSelectedGroup(value)}
        />
      </div>

      <button
        className="lp-button mb-4"
        type="button"
        disabled={false}
        onClick={() => {
          setSlideOverOpen(true);
          setNewCharacteristic({ ...newCharacteristic, groupId: selectedGroup });
        }}
      >
        Add new characteristic
      </button>

      {data && !loading && tableData.length > 0 && <Table className="lp-table" columns={columns} data={tableData} />}

      <SlideOver
        show={slideOverOpen}
        title={newCharacteristic.id === undefined ? 'New characteristic' : 'Edit characteristic'}
        saving={addStatus.loading || changeStatus.loading}
        deleting={deleteStatus.loading}
        onCancelClick={handleCloseSlideOver}
        onDeleteClick={handleDelete}
        onSaveClick={() => {
          if (newCharacteristic.id === undefined) {
            handleAdd();
          } else {
            handleUpdate();
          }
        }}
      >
        <div className="space-y-6 pt-6 pb-5">
          <div>
            <h3>General</h3>
            <div>
              <label className="lp-input-text-label" htmlFor="group">
                Group
              </label>
              <CharacteristicGroupSelect
                inputId="group"
                value={newCharacteristic.groupId}
                onChange={(value) => setNewCharacteristic({ ...newCharacteristic, groupId: value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="icon">
                Icon
              </label>
              <ImageSelect
                inputId="icon"
                value={newCharacteristic.iconId}
                onChange={(value) => setNewCharacteristic({ ...newCharacteristic, iconId: value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="helpIcon">
                Help icon
              </label>
              <ImageSelect
                inputId="helpIcon"
                value={newCharacteristic.helpIconId}
                onChange={(value) => setNewCharacteristic({ ...newCharacteristic, helpIconId: value })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="ordernum">
                Order *
              </label>
              <input
                id="ordernum"
                type="number"
                className="lp-input-text mt-1 w-full"
                value={newCharacteristic?.ordernum ?? 0}
                onChange={(e) => setNewCharacteristic({ ...newCharacteristic, ordernum: parseInt(e.target.value) })}
              />
            </div>
            <div>
              <label className="lp-input-text-label" htmlFor="widget">
                Widget
              </label>
              <Select
                inputId="widget"
                isClearable={true}
                value={widgetOptions.find((x) => x.value === newCharacteristic.widgetType)}
                options={widgetOptions}
                isLoading={loading}
                onChange={(dropdownValue) => {
                  setNewCharacteristic({ ...newCharacteristic, widgetType: dropdownValue?.value });
                }}
              />
            </div>
            {newCharacteristic.groupWidgetType && (
              <div>
                <label className="lp-input-text-label" htmlFor="group_widget_data">
                  Group widget data ({newCharacteristic.groupWidgetType})
                </label>
                <input
                  id="group_widget_data"
                  type="text"
                  className="lp-input-text w-full"
                  value={newCharacteristic.widgetData}
                  onChange={(e) => setNewCharacteristic({ ...newCharacteristic, widgetData: e.target.value })}
                />
              </div>
            )}
          </div>
          <NamesEdit
            value={newCharacteristic.names}
            onChange={(names) => setNewCharacteristic({ ...newCharacteristic, names })}
          />
          <div className="space-y-2">
            <h3>Descriptions</h3>
            {languages.map((language) => (
              <div key={language.key}>
                <label className="lp-input-text-label" htmlFor={`${language.key}-description`}>
                  {language.name}
                </label>
                <textarea
                  id={`${language.key}-description`}
                  className="lp-textarea h-32"
                  value={newCharacteristic.descriptions[language.key] ?? ''}
                  onChange={(e) =>
                    setNewCharacteristic({
                      ...newCharacteristic,
                      descriptions: { ...newCharacteristic.descriptions, [language.key]: e.target.value },
                    })
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </SlideOver>
    </>
  );
};
