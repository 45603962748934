import { Transition } from '@headlessui/react';
import { useState } from 'react';
import { NavLinks } from '../routes';
import { TLink } from './TLink';
import { useRecoilState } from 'recoil';
import { userState } from '../state';
import { ReactComponent as Hamburger } from '../assets/hamburger.svg';
import { ReactComponent as Logo } from '../assets/luontoportti-logo.svg';
import { ReactComponent as Close } from '../assets/close.svg';
import '../styles/dashboard.scss';
import { classNames, currentEnvironment } from '../utils';

interface Props {
  children: any;
}

const env = currentEnvironment();

export function Dashboard({ children }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const [user] = useRecoilState(userState);
  return (
    <div className="dashboard">
      <Transition show={isOpen} className="md:hidden">
        <div className="mobilesidebarcontainer">
          <Transition.Child
            show={isOpen}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="mobileoverlay">
              <div className="overlaycolor"></div>
            </div>
          </Transition.Child>
          <Transition.Child
            show={isOpen}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="mobilesidebar">
              <div className="closecontainer">
                <button onClick={() => setIsOpen(false)} className="menubuttonclose">
                  <span className="sr-only">Close sidebar</span>
                  <Close />
                </button>
              </div>
              <div className="mobilemenu">
                <TLink to="" className="homelink">
                  <Logo className="h-8 w-auto" />
                </TLink>
                <nav className="navcontainer">
                  <NavLinks />
                </nav>
              </div>
              <div className="profilecontainer">
                <TLink to="profile" className="profilelink">
                  <div className="flex items-center">
                    <div className="ml-3">
                      <p className="text-base font-medium text-white">{user !== null ? user.attributes.email : ''}</p>
                      <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">View profile</p>
                    </div>
                  </div>
                </TLink>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14"></div>
        </div>
      </Transition>

      <div className="desktopsidebar">
        <div className="desktopmenu">
          <div className="inner">
            <div className="linkscontainer">
              <TLink to="" className="flex items-center flex-shrink-0 px-4">
                <Logo className="h-8 w-auto" />
              </TLink>
              <nav className="navcontainer">
                <NavLinks />
              </nav>
            </div>
            <div className="profilecontainer">
              <TLink to="profile" className="profilelink">
                <div className="flex items-center">
                  <div className="ml-3">
                    <p className="text-base font-medium text-white">{user !== null ? user.attributes.email : ''}</p>
                    <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">View profile</p>
                  </div>
                </div>
              </TLink>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        <div className="menubuttoncontainer">
          <button onClick={() => setIsOpen(true)} className="menubuttonopen">
            <span className="sr-only">Open sidebar</span>
            <Hamburger />
          </button>
        </div>
        <main className={classNames({ innercontent: true, 'border-t-4 border-red-500': env === 'prod' })}>
          {env === 'prod' && <span className="absolute top-2 right-2 font text-red-500 text-sm">PROD</span>}
          <div className="p-6">{children}</div>
        </main>
      </div>
    </div>
  );
}
